import { BuildClass, DateObj } from '../../../universal'
import { React, _ } from '../../lib'
import { Combobox, ComboboxProps } from './combobox'
import { DateBox, DateboxProps } from './date'
import { Textbox, TextboxProps } from './textbox'

const excludeFields = [
	'value',
	'onUpdate',
	'lbl',
	'tooltip',
	'onFocus',
	'onBlur',
	'className',
	'width',
] as const

export const TextboxFloating = (
	props: Omit<TextboxProps, 'placeholder'> & {
		lbl: string
		tooltip?: string
		width?: number
	},
) => {
	// Track refs and set `hasValue` if the value is changed from props
	const refFloater = React.useRef<FloatingWrapperInstance>(null)
	React.useEffect(() => {
		if (refFloater.current) {
			const hasValue = Boolean(props.value)
			refFloater.current.setHasValue(hasValue)
		}
	}, [props.value])

	// Render
	return (
		<FloatingWrapper
			ref={refFloater}
			lbl={props.lbl}
			tooltip={props.tooltip}
			initHasValue={Boolean(props.value)}
			className={props.className}
			width={props.width}
		>
			{input => (
				<Textbox
					className="w-full"
					value={props.value}
					onUpdate={v => {
						props.onUpdate?.(v)
						input.onUpdate(Boolean(v))
					}}
					onFocus={ev => {
						props.onFocus?.(ev)
						input.onFocus()
					}}
					onBlur={ev => {
						props.onBlur?.(ev)
						input.onBlur()
					}}
					placeholder=""
					{..._.omit(props, excludeFields)}
				/>
			)}
		</FloatingWrapper>
	)
}

export const DateBoxFloating = (
	props: Omit<DateboxProps<DateObj, DateObj>, 'placeholder'> & {
		lbl: string
		tooltip?: string
		width?: number
	},
) => {
	// Track refs and set `hasValue` if the value is changed from props
	const refFloater = React.useRef<FloatingWrapperInstance>(null)
	React.useEffect(() => {
		if (refFloater.current) {
			const hasValue = Boolean(props.value)
			refFloater.current.setHasValue(hasValue)
		}
	}, [props.value])

	// Render
	return (
		<FloatingWrapper
			ref={refFloater}
			lbl={props.lbl}
			tooltip={props.tooltip}
			initHasValue={Boolean(props.value)}
			className={props.className}
			width={props.width}
		>
			{input => (
				<DateBox
					className="w-full"
					value={props.value}
					onUpdate={v => {
						props.onUpdate?.(v)
						input.onUpdate(Boolean(v))
					}}
					onFocus={ev => {
						props.onFocus?.(ev)
						input.onFocus()
					}}
					onBlur={ev => {
						props.onBlur?.(ev)
						input.onBlur()
					}}
					placeholder=""
					{..._.omit(props, excludeFields)}
				/>
			)}
		</FloatingWrapper>
	)
}

export const DropdownFloating = <T extends string | number, M extends boolean>(
	props: Omit<ComboboxProps<T, M>, 'placeholder'> & {
		lbl: string
		tooltip?: string
		width?: number
	},
) => {
	// Track refs and set `hasValue` if the value is changed from props
	const refFloater = React.useRef<FloatingWrapperInstance>(null)
	React.useEffect(() => {
		if (refFloater.current) {
			const hasValue = Boolean(props.value)
			console.log({ hasValue, value: props.value })
			refFloater.current.setHasValue(hasValue)
		}
	}, [props.value])

	// Render
	return (
		<FloatingWrapper
			ref={refFloater}
			lbl={props.lbl}
			tooltip={props.tooltip}
			initHasValue={Boolean(props.value)}
			className={props.className}
			width={props.width}
		>
			{input => (
				<Combobox
					className="w-full"
					value={props.value}
					onUpdate={v => {
						props.onUpdate?.(v)
						input.onUpdate(Boolean(v))
					}}
					onFocus={ev => {
						props.onFocus?.(ev)
						input.onFocus()
					}}
					onBlur={ev => {
						props.onBlur?.(ev)
						input.onBlur()
					}}
					placeholder=""
					{..._.omit(props, excludeFields)}
				/>
			)}
		</FloatingWrapper>
	)
}

type FloatingWrapperInstance = {
	setIsFocused: (isFocused: boolean) => void
	setHasValue: (hasValue: boolean) => void
}

const FloatingWrapper = React.forwardRef(
	(
		props: {
			lbl: string
			tooltip?: string
			initHasValue: boolean
			className?: string
			width?: number
			children: (input: {
				onFocus: () => void
				onBlur: () => void
				onUpdate: (hasValue: boolean) => void
			}) => React.JSX.Element
		},
		ref: React.Ref<FloatingWrapperInstance>,
	) => {
		/// State
		const [isFocused, setIsFocused] = React.useState(false)
		const [hasValue, setHasValue] = React.useState(props.initHasValue)

		// Instance
		React.useImperativeHandle(ref, () => ({
			setIsFocused,
			setHasValue,
		}))

		// Render
		const isFloating = isFocused || hasValue
		return (
			<label
				className={BuildClass([
					'relative h-12 py-[2px]',
					'align-top inline-block',
					props.className,
				])}
				style={{ width: props.width ? `${props.width}px` : 'auto' }}
			>
				<div
					title={props.tooltip || props.lbl}
					className={BuildClass({
						'absolute px-1': true,
						'transform origin-top-left': true,
						'transition-all duration-200': true,
						'text-gray-500 translate-y-4 rounded': true,
						'pointer-events-none select-none': true,
						'translate-x-[1px] translate-y-0 scale-75': isFloating,
						'bg-purple-600 text-white': isFocused,
						'leading-7': !isFloating,
					})}
				>
					{props.lbl}
				</div>
				<div className="pt-4">
					{props.children({
						onFocus: () => {
							setIsFocused(true)
						},
						onBlur: () => {
							setIsFocused(false)
						},
						onUpdate: hasValue => {
							setHasValue(hasValue)
						},
					})}
				</div>
			</label>
		)
	},
)
