import { BuildClass, Do, Maybe, clamp, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import { List, ListInstance, ListRow } from './list'
import { getInternalValue, getValuesAfterClick } from './list-util'
import {
	ConditionalObject,
	Focusable,
	RSInstance,
	RSInstanceD,
	useEffectCompare,
	useRSInstance,
	useStateSync,
} from './meta-types'
import { Modal } from './modal'
import { stubDiv, stubInput, stubListInstance } from './stubs'
import { TextSearched, searchText } from './text'

// Magic value for the internal representation of the null / all value selections
// All/null are only used when the combobox has the `multiple` property added
const cbValAllS = '****all****'
const cbValNullS = '****null****'

// Other magic values
const optionRowHeight = 21
const flipHeightThreshold = 6 * optionRowHeight + 2 // 128px - holds 6 rows exactly
const maxOptionsHeight = 19 * optionRowHeight + 2 // 401px - holds up to 19 rows exactly

// EXTERNAL TYPES ########################################################################

export type ComboBoxOptions<T extends string | number> = (
	| ComboboxOption<T>
	| ComboboxOptionGroup<T>
)[]

/**
 * Defines an option for a `Combobox`
 * @param value The string or int ID
 * @param text Label to show in the row
 * @param className Custom class
 * @param title Override for tooltip text
 * @param visible Whether the row is visible (default true)
 * @param selectable Whether the item is selectable (default true)
 */
export type ComboboxOption<T extends string | number> = {
	value: T
	text: string
	display?: (search: string) => React.JSX.Element
	className?: string
	title?: string
	visible?: boolean
	selectable?: boolean
}

/**
 * Defines a group of items in a combobox. Note that you cannot nest groups and groups
 * are not themselves selectable nor have their own value.
 * @param text Label to show in the row
 * @param options Array of child options
 * @param prefix Override for prefix text to show when flattening child rows (default `text`)
 * @param className Custom class
 * @param title Override for tooltip text
 * @param visible Whether the row is visible (default true)
 * @param selectable Whether the item is selectable (default true)
 */
export type ComboboxOptionGroup<T extends string | number> = {
	text: string
	options: ComboboxOption<T>[]
	prefix?: string
	className?: string
	title?: string
	visible?: boolean
	selectable?: boolean
}

// INTERNAL TYPES ########################################################################

type ComboboxOptionInternal = {
	value: string
	text: string /** Label to show */
	display: Maybe<(search: string) => React.JSX.Element> /** Override custom render */
	fullText: string /** Includes parent group prefix - used when searching */
	indent: number /** Indents if under a group */
	className: string /** Custom class */
	title: string /** Override for tooltip text */
	visible: boolean /** Whether the row is visible */
	selectable: boolean /** Whether the item can be selected as a value */
	selectableList: boolean /** Whether the item can be selected in the list */
	isSelectAll: boolean /** Whether this is the special select-all row */
	isNumber: Maybe<boolean> /** Whether the prop version of the value is a number */
	options: Maybe<ComboboxOptionInternal[]> /** Groups have child items */
}

// MAIN HOOKS ############################################################################

const ComboboxCmpt = <T extends string | number, S extends boolean = false>(
	props: ComboboxProps<T, S>,
	ref: React.ForwardedRef<Focusable<HTMLDivElement>>,
) => {
	// Reducer
	const rs = useRSInstance<ComboboxProps<T, S>, ComboboxState, ComboboxRefs, Payload>({
		props: props,
		refs: {
			txt: React.useRef(stubInput),
			optionsList: React.useRef(stubListInstance),
			root: React.useRef(stubDiv),
		},
		defaultState: getDefaultStateFromProps,
		actionToDelta: getPartialStateFromAction,
	})

	// Define external methods and link references
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			rs.refs.txt.current?.focus()
		},
		select: () => {
			rs.refs.txt.current?.select()
		},
		getElement: () => rs.refs.root.current,
	}))

	// Sync the state value with the prop value
	useStateSync({
		stateVal: rs.state.value,
		propVal: props.value,
		setState: v => {
			rs.dispatch([
				Action.ResetValueFromProps,
				{
					value: getInternalValueCB(props, v),
					text: getValueText<T, S>(v, props),
				},
			])
		},
		setProp: v => {
			const isNumeric = arePropsNumeric(rs.props)
			const values = v.map(x => convertInternalToT(rs.props, x, isNumeric))
			if (props.multiple) {
				props.onUpdate(values as S extends true ? T[] : T)
			} else {
				props.onUpdate(_.first(values) as S extends true ? T[] : T)
			}
		},
		compareFn: (p, s) => _.isEqual(s, getInternalValueCB(props, p)),
	})

	// When the option props change, refresh the text state
	useEffectCompare(() => {
		rs.dispatch([Action.ResetText, { text: getValueText<T, S>(props.value, props) }])
	}, [props.options])

	// Sync the `fixedWidth` state from props (one-way)
	React.useEffect(() => {
		if (rs.state.fixedWidth != props.fixedWidth) {
			rs.dispatch([
				Action.ResetFixedWidthFromProps,
				{ fixedWidth: props.fixedWidth },
			])
		}
	}, [rs, props.fixedWidth])

	// Cache events
	const evChange = React.useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			rs.dispatch([Action.onUpdateText, { text: ev.target.value }])
		},
		[rs],
	)
	const evKeyDown = React.useCallback(
		(ev: React.KeyboardEvent<HTMLInputElement>) => {
			rs.dispatch([Action.OnKeyDown, { ev: ev }])
		},
		[rs],
	)
	const evClickBox = React.useCallback(
		(ev: React.MouseEvent<HTMLInputElement>) => {
			rs.dispatch([Action.OnClickBox, { ev: ev }])
		},
		[rs],
	)
	const evClickArrow = React.useCallback(
		(ev: React.MouseEvent<HTMLSpanElement>) => {
			rs.dispatch([Action.OnClickArrow, { ev: ev }])
		},
		[rs],
	)
	const evFocus = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			rs.props.onFocus?.(ev)
			rs.dispatch([Action.OnFocus, { ev }])
		},
		[rs],
	)
	const evBlur = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			rs.props.onBlur?.(ev)
			requestAnimationFrame(() => {
				rs.dispatch([Action.OnBlur])
			})
		},
		[rs],
	)

	// Render
	return (
		<div
			ref={rs.refs.root}
			title={props.title}
			className={BuildClass({
				'ui5 ui5-combobox': true,
				[props.className ?? '']: true,
				isActive: rs.state.isActive,
				isOpen: rs.state.isOpen,
				disabled: props.disabled ?? false,
				multiple: props.multiple ?? false,
			})}
			style={props.style}
		>
			{/* Input box */}
			<input
				className="txt underlined"
				ref={rs.refs.txt}
				// Set the initial properties based on input
				disabled={props.disabled ? true : undefined}
				placeholder={_.isEmpty(props.value) ? props.placeholder : ''}
				tabIndex={props.tabIndex}
				autoFocus={props.autoFocus}
				autoComplete="new-password" // Attempt to work around new versions
				data-lpignore={true}
				value={rs.state.text}
				onMouseDown={evClickBox}
				onChange={evChange}
				onKeyDown={evKeyDown}
				onFocus={evFocus}
				onBlur={evBlur}
			/>
			{/* Arrow to the right of the textbox to show the menu */}
			<span className="arrow noselect underlined" onMouseDown={evClickArrow}>
				<img className="arrow-img" src="/static/img/svg/cb-arrow.svg" />
			</span>
			{/* Options list - only shows when textbox focused */}
			<Modal>
				<div
					className={BuildClass({
						'ui5-combobox-options': true,
						[props.className ?? '']: true,
						hidden: !rs.state.isOpen,
						isFlippedUp: !rs.state.optionsPosition.isDown,
						multiple: props.multiple ?? false,
					})}
					// Define the position of the dropdown
					style={{
						top: `${rs.state.optionsPosition.top}px`,
						maxHeight: `${rs.state.optionsPosition.height}px`,
						left: `${rs.state.optionsPosition.left}px`,
						minWidth: `${rs.state.optionsPosition.minWidth}px`,
						width: ConditionalObject(
							rs.state.fixedWidth != null,
							`${rs.state.fixedWidth}px`,
						),
					}}
				>
					{/* Build the list */}
					{ConditionalObject(rs.state.isActive, () =>
						buildOptionList(rs, evClickArrow),
					)}
				</div>
			</Modal>
		</div>
	)
}

const buildOptionList = <T extends string | number, S extends boolean>(
	rs: ReducerStateD<T, S>,
	evClickArrow: (ev: React.MouseEvent<HTMLSpanElement>) => void,
) => {
	// Return nothing if not open
	if (!rs.state.isActive) {
		return <></>
	}

	// Get all options and all values
	const isEditing = rs.state.isOpen && rs.state.text != ''
	const allOptions = getOptionsFull(rs.props, isEditing, rs.state.text)
	const allSelectableValues = fsmData(
		getOptionsFiltered(getOptions(rs.props), isEditing, rs.state.text),
		{
			filter: x => x.selectable ?? true,
			map: x => x.value,
		},
	)

	// In a multi-select, above the options we show a full-width arrow to close
	// This is just a larger version of the small one on the RHS of the box
	// This is because multi-selects don't close when you select something and users
	// sometimes get confused about how to make the list go away once they're done
	const fullWidthCloseArrow = ConditionalObject(
		rs.props.multiple ?? false,
		<div className="close-arrow">
			<span className="arrow noselect underlined" onMouseDown={evClickArrow}>
				<img className="arrow-img" src="/static/img/svg/cb-arrow.svg" />
			</span>
		</div>,
	)

	// Get the option rows
	const rows: ListRow<string>[] = fsmData(allOptions, {
		filter: x => x.visible ?? true,
		map: item => ({
			value: item.value,
			selectable: item.selectableList,
			content: () => (
				<ComboboxOptionCmpt
					dispatch={rs.dispatch}
					option={item}
					isEditing={isEditing}
					highlighted={
						(rs.props.multiple ?? false) &&
						item.value === rs.state.highlighted
					}
					selected={Do(() => {
						if (item.isSelectAll) {
							return areAllSelectableSelected(
								allSelectableValues,
								rs.state.value,
							)
						} else if (item.options) {
							return areAllSelectableSelected(
								fsmData(item.options, {
									filter: x => x.selectable,
									map: x => x.value,
								}),
								rs.state.value,
							)
						} else if (rs.props.multiple) {
							return rs.state.value.includes(item.value)
						} else if (
							item.value === cbValNullS &&
							_.isEqual(rs.state.value, [])
						) {
							return true
						}
						return item.value === _.first(rs.state.value)
					})}
					searchText={rs.state.isOpen ? rs.state.text : ''}
					indent={item.indent}
					showCheckbox={rs.props.multiple ?? false}
					isSelectAll={item.isSelectAll}
				/>
			),
		}),
	})

	// The list component DOM
	const optionsListComponent = (
		<List<string, false>
			ref={rs.refs.optionsList}
			value={
				(rs.props.multiple ?? false)
					? rs.state.highlighted
					: (_.first(rs.state.value) as string)
			}
			onUpdate={value => {
				rs.dispatch([Action.onUpdateHighlighted, { value }])
			}}
			multiple={false}
			disableFocusControl={true}
			disableClickHandlers={true}
			height={21}
			rows={rows}
			vpHeight={rs.state.optionsPosition.height - (rs.props.multiple ? 21 : 0)}
		/>
	)

	// Return layout
	return (
		<>
			{fullWidthCloseArrow}
			{optionsListComponent}
		</>
	)
}

const ComboboxOptionCmpt = (props: {
	dispatch: (value: Payload) => void
	option: ComboboxOptionInternal
	isEditing: boolean
	highlighted: boolean
	selected: boolean
	searchText: string
	indent: number
	showCheckbox: boolean
	isSelectAll: boolean
}) => {
	// Determine if this item is selectable
	const selectable = !props.option.options
		? props.option.selectable || props.option.isSelectAll
		: props.option.options.filter(x => x.selectable).length

	// Build the class
	const className = BuildClass({
		[props.option.className ?? '']: true,
		selected: props.selected,
		highlight: props.highlighted,
		'no-select': !selectable,
		showAll: props.isSelectAll,
		[`indent-${props.indent}`]:
			!props.isEditing && props.indent != 0 && props.indent > 0,
	})

	// Click event
	const onClick = React.useCallback(
		(ev: React.MouseEvent<HTMLDivElement>) => {
			if (selectable) {
				props.dispatch([
					Action.ItemClicked,
					{ ev: ev, value: props.option.value },
				])
			} else {
				ev.stopPropagation()
				ev.preventDefault()
			}
		},
		[props, selectable],
	)

	// Render
	return (
		<div
			className={className}
			title={props.option.title ?? props.option.fullText}
			onMouseDown={onClick}
		>
			{ConditionalObject(
				props.showCheckbox,
				<div className="ui5 ui5-checkbox">
					<div
						className={BuildClass({
							inner: true,
							selected: props.selected,
						})}
					/>
				</div>,
			)}
			<span>
				{props.option.display ? (
					props.option.display?.(props.searchText)
				) : (
					<TextSearched
						text={props.isEditing ? props.option.fullText : props.option.text}
						needles={props.searchText.split(/\s+/).filter(x => x)}
					/>
				)}
			</span>
		</div>
	)
}

// REDUCER TYPES #########################################################################

export type ComboboxProps<T extends string | number, S extends boolean = false> = {
	// Core compulsory options
	options: ComboBoxOptions<T>
	value: S extends true ? T[] : Maybe<T>
	onUpdate: (value: S extends true ? T[] : Maybe<T>) => void
	// Options for multi-select
	multiple?: S
	noSelectAll?: boolean
	textView?: (value: T[], allSelected: boolean) => string
	// Other options
	onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void
	onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void
	className?: string
	autoFocus?: boolean
	autoPickOnly?: boolean
	disabled?: boolean
	nullable?: string
	placeholder?: string
	tabIndex?: number
	title?: string
	fixedWidth?: Maybe<number>
	maxHeight?: Maybe<number>
	selected?: boolean
	style?: React.CSSProperties
}
type ComboboxState = {
	// Value
	value: string[]
	text: string
	highlighted: Maybe<string>
	// State flags
	canSendUpdates: boolean
	isActive: boolean
	isOpen: boolean
	backspacePressed: boolean
	// Positioning internals
	fixedWidth: Maybe<number>
	optionsPosition: {
		left: number
		top: number
		height: number
		width: number
		minWidth: number
		isDown: boolean
	}
}
type ComboboxRefs = {
	txt: React.MutableRefObject<HTMLInputElement>
	optionsList: React.MutableRefObject<ListInstance<string, false>>
	root: React.MutableRefObject<HTMLDivElement>
}
type ReducerStateD<T extends string | number, S extends boolean> = RSInstanceD<
	ComboboxProps<T, S>,
	ComboboxState,
	ComboboxRefs,
	Payload
>
type ReducerState<T extends string | number, S extends boolean> = RSInstance<
	ComboboxProps<T, S>,
	ComboboxState,
	ComboboxRefs
>

// ACTION INDEX ##########################################################################

enum Action {
	ResetValueFromProps,
	ResetFixedWidthFromProps,
	onUpdateHighlighted,
	onUpdateText,
	OnKeyDown,
	OnClickBox,
	OnClickArrow,
	OnFocus,
	OnBlur,
	ItemClicked,
	ResetText,
}

type Payload =
	| [Action.ResetValueFromProps, { value: string[]; text: string }]
	| [Action.ResetFixedWidthFromProps, { fixedWidth: Maybe<number> }]
	| [Action.onUpdateHighlighted, { value: string }]
	| [Action.onUpdateText, { text: string }]
	| [Action.OnKeyDown, { ev: React.KeyboardEvent<HTMLInputElement> }]
	| [Action.OnClickBox, { ev: React.MouseEvent<HTMLInputElement> }]
	| [Action.OnClickArrow, { ev: React.MouseEvent<HTMLSpanElement> }]
	| [Action.OnFocus, { ev: React.FocusEvent<HTMLInputElement> }]
	| [Action.OnBlur]
	| [Action.ItemClicked, { value: string; ev: React.MouseEvent<HTMLDivElement> }]
	| [Action.ResetText, { text: string }]

const getPartialStateFromAction = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	p: Payload,
): Maybe<Partial<ComboboxState>> => {
	switch (p[0]) {
		case Action.ResetValueFromProps:
			return resetValueFromProps(rs, p[1].value, p[1].text)
		case Action.ResetFixedWidthFromProps:
			return { fixedWidth: p[1].fixedWidth }
		case Action.onUpdateHighlighted:
			return updateHighlighted(rs, p[1].value)
		case Action.onUpdateText:
			return updateText(rs, p[1].text)
		case Action.OnKeyDown:
			return onKeyDown(rs, p[1].ev)
		case Action.OnClickBox:
			return onClickBox(rs)
		case Action.OnClickArrow:
			return onClickArrow(rs, p[1].ev)
		case Action.OnFocus:
			return onFocus(rs, p[1].ev)
		case Action.OnBlur:
			return onBlur(rs)
		case Action.ItemClicked:
			return onItemClicked(rs, p[1].ev, p[1].value)
		case Action.ResetText:
			return resetTextFromProps(rs, p[1].text)
	}
}

const getDefaultStateFromProps = <T extends string | number, S extends boolean>(
	props: ComboboxProps<T, S>,
): ComboboxState => {
	const text = getValueText<T, S>(props.value, props)
	return {
		// Value
		value: getInternalValueCB(props, props.value),
		text: text,
		highlighted: null,
		// Internal flags
		canSendUpdates: false,
		isActive: false,
		isOpen: false,
		backspacePressed: false,
		// Positioning
		optionsPosition: {
			// Default to underneath at full height
			left: 0,
			top: 0,
			height: _.min([props.maxHeight, maxOptionsHeight]) ?? maxOptionsHeight,
			width: 300,
			minWidth: 0,
			isDown: true,
		},
		fixedWidth: props.fixedWidth,
	}
}

// ACTIONS ###############################################################################

const resetValueFromProps = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	value: string[],
	text: string,
): Partial<ComboboxState> => {
	// Don't update the text if it's in text editing mode
	if (rs.state.isOpen) {
		return { value }
	}
	return { value, text }
}

const resetTextFromProps = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	text: string,
): Partial<ComboboxState> => {
	// Don't update the text if it's in text editing mode
	if (rs.state.isOpen) {
		return {}
	}
	return { text }
}

const updateHighlighted = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	value: string,
): Partial<ComboboxState> => {
	// Force list re-render
	requestAnimationFrame(() => {
		rs.refs.optionsList.current?.noop()
	})

	// Multi-select updates the highlighted amount
	if (rs.props.multiple) {
		return { highlighted: value }
	}

	// In single-select, the value is updated instead
	// If closed, update text - allows arrowing and seeing current value
	const newVal = [value]
	if (rs.state.isOpen) {
		return { value: newVal }
	}
	const isNumeric = arePropsNumeric(rs.props)
	return {
		value: newVal,
		text: getValueText<T, S>(
			convertInternalToT(rs.props, newVal[0] as string, isNumeric),
			rs.props,
		),
	}
}

const updateText = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	text: string,
): Partial<ComboboxState> => {
	// Exit early if disabled
	if (rs.props.disabled) {
		return {}
	}

	// Ensure that whatever happens here, the list scroll-jacks accordingly
	refreshScrollJack(rs)

	// If it's not already open, it is now. Pass along text and isEditing by default
	const defaultChange: Partial<ComboboxState> = {
		...ConditionalObject(!rs.state.isOpen, () => openBox(rs)),
		highlighted: null,
		text: text,
	}

	// Get options without a filter
	const options = getOptions<T, S>(rs.props)

	// Lots of lowercase conversions below - shorter helper function
	const lower = (s: string) => s.toLowerCase()

	// Check if this string matches any of the existing values
	// If it's an exact match, set the value
	const matches_exact = options.filter(opt => lower(opt.text) === lower(text))
	if (matches_exact.length > 0) {
		const option = matches_exact[0] as ComboboxOptionInternal
		const isNumeric = arePropsNumeric(rs.props)
		const txt = getValueText<T, S>(
			convertInternalToT<T, S>(rs.props, option.value, isNumeric),
			rs.props,
		)
		if (rs.props.multiple) {
			return {
				...defaultChange,
				highlighted: option.value,
				text: txt,
			}
		}
		return {
			...defaultChange,
			value: [option.value],
			text: txt,
		}
	}

	// Start partial matching the options for an auto-complete

	// Check if the cursor is at the end
	const selectionAtEnd = Do(() => {
		const [selStart, selEnd] = [
			rs.refs.txt.current.selectionStart,
			rs.refs.txt.current.selectionEnd,
		]
		return selStart === selEnd && selStart === text.length
	})

	// If the cursor is not at the end, OR if the last pressed key was backspace,
	// Skip the auto-complete code and just update the text as requested
	// If the user just pressed backspace and we auto-complete that will be annoying
	if (!selectionAtEnd || rs.state.backspacePressed) {
		return defaultChange
	}

	// Add a highlighted suffix to the text input to auto-complete based on
	// the results that have the same prefix as the entered text
	let index = 0
	let suffix = ''

	// Find all with the same prefix
	const matches_prefix = fsmData(options, {
		filter: o => lower(o.text).startsWith(lower(text)),
		map: o => o.text,
	})

	// Within the subset of options with this prefix, check how many more characters are
	// common to all of them as a prefix. This allows us to add a highlighted auto-complete
	// since no options start with `text` but don't also start with `text` + `suffix`
	const shortest_match_prefix = _.min(matches_prefix.map(t => t.length)) ?? 0
	while (index < shortest_match_prefix) {
		const diffs = matches_prefix.map(t => lower(t[index] ?? ''))
		if (_.uniq(diffs).length > 1) {
			break
		}
		index += 1
	}
	suffix = matches_prefix[0]?.slice(text.length, index) ?? ''

	// If it's a single match result, keep case. Otherwise, convert suffix to lowercase
	if (matches_prefix.length > 1) {
		suffix = lower(suffix)
	}

	// Update the state. On callback, adjust the input selection as required
	if (suffix) {
		requestAnimationFrame(() => {
			rs.refs.txt.current.setSelectionRange(text.length, index)
		})
	}
	return {
		...defaultChange,
		text: text + suffix,
	}
}

const onKeyDown = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ev: React.KeyboardEvent<HTMLInputElement>,
): Partial<ComboboxState> => {
	// Helper function to get the single visible option (if there is only one)
	const getSingleOptionSelectionDelta = () => {
		const isEditing = rs.state.isOpen && rs.state.text != ''
		const options = fsmData(getOptionsFull(rs.props, isEditing, rs.state.text), {
			filter: x => x.visible,
			map: x => x?.value ?? null,
		})
		const first = options[0]
		if (options.length != 1 || first == null) {
			return {}
		}
		const isNumeric = arePropsNumeric(rs.props)
		const val = convertInternalToT(rs.props, first, isNumeric)
		const vals = val ? [val] : null
		return {
			text: getValueText<T, S>(vals, rs.props),
			...simulateItemClick(rs, first, false),
		}
	}

	// Forward the arrowing events to the list component to be handled
	// We will wait for it to emit the value update instead
	if ([33, 34, 35, 36, 38, 40].includes(ev.which)) {
		ev.preventDefault()
		ev.stopPropagation()
		const ts = ev.timeStamp
		const evWhich = ev.which
		const evShiftKey = ev.shiftKey
		const opts = rs.refs.optionsList.current
		requestAnimationFrame(() => {
			opts?.forwardEventKeyDown(ts, evWhich, evShiftKey)
		})
		return { backspacePressed: false }
	}

	// Handle the backspace (or delete) situation
	// Don't stop default behaviour
	if ([8, 46].includes(ev.which)) {
		return { backspacePressed: true }
	}

	// Escape closes the box
	if (ev.which == 27) {
		ev.preventDefault()
		ev.stopPropagation()
		return { backspacePressed: false, ...closeBox(rs) }
	}

	// Space (on multi-select with a highlight) will toggle select on the highlighted
	// This is the same as clicking on the highlighted item (hence simulating click)
	// Holding shift while pressing space will do the the shift+click action
	if (ev.which == 32 && rs.props.multiple && rs.state.highlighted) {
		ev.preventDefault()
		ev.stopPropagation()
		return {
			backspacePressed: false,
			...simulateItemClick(rs, rs.state.highlighted, ev.shiftKey),
		}
	}

	// Pressing tab, if the autocomplete has only a single option, it should be seleted
	// This only applies to single selection
	if (ev.which == 9 && !rs.props.multiple) {
		return {
			backspacePressed: false,
			...closeBox(rs),
			...getSingleOptionSelectionDelta(),
		}
	}

	// Pressing enter in a multi-select is the same as "space"
	// Pressing enter in a single-select is the same as "esc", but it endorses the result
	if (ev.which == 13) {
		ev.preventDefault()
		ev.stopPropagation()

		// If closed, open
		if (!rs.state.isOpen) {
			return openBox(rs)
		}

		// If multi-select, this is an alias for pressing "space" (see above)
		if (rs.props.multiple) {
			const valChange = rs.state.highlighted
				? simulateItemClick(rs, rs.state.highlighted, ev.shiftKey)
				: {}
			return {
				backspacePressed: false,
				...valChange,
			}
		}

		// Single select, it closes the box and selects the single filtered item (if there is)
		const delta = {
			backspacePressed: false,
			...closeBox(rs),
			...getSingleOptionSelectionDelta(),
		}
		return delta
	}

	// Let the default event happen
	return { backspacePressed: false }
}

const onClickBox = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
): Partial<ComboboxState> => {
	if (rs.props.disabled) {
		return {}
	}
	if (!rs.state.isOpen) {
		rs.refs.txt.current.focus()
		return openBox(rs)
	}
	return { highlighted: null }
}

const onClickArrow = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ev: React.MouseEvent<HTMLSpanElement>,
): Partial<ComboboxState> => {
	ev.preventDefault()
	ev.stopPropagation()
	if (rs.props.disabled) {
		return {}
	}
	if (rs.state.isOpen) {
		return closeBox(rs)
	}
	rs.refs.txt.current.focus()
	return openBox(rs)
}

const onFocus = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ev: React.FocusEvent<HTMLInputElement>,
): Partial<ComboboxState> => {
	if (rs.props.disabled) {
		return {}
	}
	rs.props.onFocus?.(ev)
	return openBox(rs)
}

const openBox = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
): Partial<ComboboxState> => {
	// Scrolljack to show the selected item in the viewport
	refreshScrollJack(rs)

	// Determine basic position style for the pop-out
	const rect = rs.refs.root.current.getBoundingClientRect()
	const opts = getOptionsFull(rs.props, false, rs.state.text)
	const offset = rs.props.multiple ? 24 : 2
	const heightRaw =
		_.min([
			rs.props.maxHeight,
			maxOptionsHeight,
			optionRowHeight * opts.length + offset,
		]) ?? 0

	// Check how much height we have available going up vs down
	// If it's better to flip up above the textbox, do that instead
	const height_down = clamp(window.innerHeight - rect.bottom, 0, heightRaw)
	const height_up = clamp(rect.top, 0, heightRaw)
	const isFlipped = height_down < flipHeightThreshold && height_up > height_down
	const height = isFlipped ? height_up : height_down

	// Build the options position state
	const optionsPosition = {
		left: rect.left,
		top: isFlipped ? rect.top - height_up - 1 : rect.bottom - 1,
		width: rect.width,
		height: height,
		minWidth: rect.width,
		isDown: !isFlipped,
	}

	// Update the state
	return {
		isActive: true,
		isOpen: true,
		text: '',
		optionsPosition: optionsPosition,
	}
}

const onBlur = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
): Partial<ComboboxState> => {
	if (rs.props.disabled) {
		return {}
	}
	return {
		...closeBox(rs),
		isActive: false,
		highlighted: null,
	}
}

const closeBox = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
): Partial<ComboboxState> => {
	const isNumeric = arePropsNumeric(rs.props)
	const val = rs.state.value.map(v => convertInternalToT(rs.props, v, isNumeric))
	return {
		isOpen: false,
		text: getValueText<T, S>(_.compact(val), rs.props),
	}
}

const onItemClicked = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ev: React.MouseEvent<HTMLDivElement>,
	value: string,
): Partial<ComboboxState> => {
	// Stop default event behaviour
	ev.stopPropagation()
	ev.preventDefault()

	// Get delta from a simulated click
	const delta = simulateItemClick(rs, value, ev.shiftKey)

	// If it's a multi-select, keep the box open
	if (rs.props.multiple) {
		return delta
	}

	// If the delta was undefined, don't do anything - they probably clicked a heading
	if (delta === undefined) {
		return {}
	}

	// Single-select item clicks also close the box, updating the text
	const isNumeric = arePropsNumeric(rs.props)
	const val = convertInternalToT(
		rs.props,
		_.first(delta.value) ?? cbValNullS,
		isNumeric,
	)
	const vals = val ? [val] : []
	return {
		...delta,
		...closeBox(rs),
		text: getValueText<T, S>(vals, rs.props),
	}
}

const simulateItemClick = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	value: string,
	shiftKey: boolean,
): Partial<ComboboxState> => {
	// Get all selectable option values - used in a couple of spots below
	const options = getOptions(rs.props)
	const isEditing = rs.state.isOpen && rs.state.text != ''
	const allSelectableValues = fsmData(
		getOptionsFiltered(options, isEditing, rs.state.text),
		{
			filter: x => x.selectable ?? true,
			map: x => x.value ?? '',
		},
	)

	// Handle the select all case
	if (rs.props.multiple && value === cbValAllS) {
		// Check if everything was selected
		if (areAllSelectableSelected(allSelectableValues, rs.state.value)) {
			return {
				value: [],
				highlighted: cbValAllS,
			}
		}
		return {
			value: allSelectableValues,
			highlighted: cbValAllS,
		}
	}

	// Handle the group heading case (multi only)
	if (value.startsWith('__group-')) {
		// Groups are only selectable in a multi-select situation
		if (!rs.props.multiple) {
			return {}
		}

		// Get the child option values
		const option = _.first(getOptions(rs.props).filter(x => x.value == value))
		const childVals = fsmData(option?.options ?? [], {
			filter: x => x.selectable,
			map: x => x.value,
		})

		// If all child values are currently selected, unselect them
		// Otherwise add them to the end
		if (areAllSelectableSelected(childVals, rs.state.value)) {
			return {
				value: rs.state.value.filter(x => !childVals.includes(x)),
				highlighted: value,
			}
		}
		return {
			value: _.uniq([...rs.state.value, ...childVals]),
			highlighted: value,
		}
	}

	// Override the ctrl/shift key behaviour as required
	// 1: none, 2: ctrl, 3: shift
	let modifier: 1 | 2 | 3 = 1
	if (!rs.props.multiple) {
		modifier = 1
	} else if (shiftKey) {
		modifier = 3
	} else {
		modifier = 2
	}

	// Get the new selected value
	const newValue = getValuesAfterClick(
		allSelectableValues,
		rs.state.value,
		value,
		modifier,
	)

	// Return the state update and refresh the text
	// If single-select, also close the box
	return {
		value: newValue,
		highlighted: value,
	}
}

// UTILITY FUNCTIONS #####################################################################

const getOptions = <T extends string | number, S extends boolean>(
	props: ComboboxProps<T, S>,
): ComboboxOptionInternal[] => {
	const options: ComboboxOptionInternal[] = []
	_.forEach(props.options, (item, index) => {
		// It's an individual
		if (!('options' in item)) {
			options.push({
				value: String(item.value),
				text: item.text,
				fullText: item.text,
				display: item.display,
				indent: 0,
				className: item.className ?? '',
				title: item.title ?? '',
				visible: item.visible ?? true,
				selectable: item.selectable ?? true,
				selectableList: item.selectable ?? true,
				isSelectAll: false,
				isNumber: _.isNumber(item.value),
				options: null,
			})
			return
		}

		// It's a group
		// Start by getting the child items
		const prefix = item.prefix ?? item.text
		const selectable = item.selectable ?? true
		const visible = item.visible ?? true
		const subOptions: ComboboxOptionInternal[] = _.map(item.options, subItem => ({
			value: String(subItem.value),
			text: subItem.text,
			fullText: `${prefix} → ${subItem.text}`,
			display: subItem.display,
			indent: 1,
			className: subItem.className ?? '',
			title: subItem.title ?? '',
			visible: visible && (subItem.visible ?? true),
			selectable: selectable && (subItem.selectable ?? true),
			selectableList: selectable && (subItem.selectable ?? true),
			isSelectAll: false,
			isNumber: _.isNumber(subItem.value),
			options: null,
		}))

		// Add the top-level group item
		options.push({
			value: `__group-${index}`,
			text: item.text,
			fullText: item.text,
			display: null,
			indent: 0,
			className: BuildClass({
				[item.className ?? '']: true,
				'group-heading': true,
			}),
			title: item.title ?? '',
			visible: item.visible ?? true,
			selectable: false, // Group headings are only selectable in the list sense
			selectableList: Boolean(props.multiple) && (item.selectable ?? true), // Only if multi
			isSelectAll: false,
			isNumber: null,
			options: subOptions,
		})

		// Add the child items
		options.push(...subOptions)
	})
	return options
}

const getOptionsFiltered = (
	optionsFlat: ComboboxOptionInternal[],
	isEditing: boolean,
	text: string,
): ComboboxOptionInternal[] =>
	_.filter(optionsFlat, opt => {
		// If in editing mode, filter to only those containing the text
		if (!isEditing) {
			return true
		}
		if (!opt.selectable) {
			return false
		}
		if (!text) {
			return true
		}
		return searchText(opt.fullText, text.split(/\s+/), true)
	})

const getOptionsFull = <T extends string | number, S extends boolean>(
	props: ComboboxProps<T, S>,
	isEditing: boolean,
	text: string,
): ComboboxOptionInternal[] => {
	// Flatten the option groups and flag which ones are non-selectable
	const optionsFlat = getOptions<T, S>(props)
	const options = _.sortBy(getOptionsFiltered(optionsFlat, isEditing, text), opt => {
		// No custom sort if not editing
		if (!isEditing) {
			return 1
		}
		// Helper function to convert to lowercase
		// Check whether it's a prefix match
		const tl = (s: string) => s.toLowerCase()
		const prefix_match = tl(opt.text).startsWith(tl(text))
		return prefix_match ? 0 : 1
	})

	// Check if it's filtered
	const isFiltered = options.length !== optionsFlat.length

	// Add the nullable item at the top - if not filtered
	if (props.nullable != null && !isEditing) {
		options.unshift({
			value: cbValNullS,
			text: props.nullable,
			fullText: props.nullable,
			display: null,
			indent: 0,
			className: '',
			title: '',
			visible: true,
			selectable: true,
			selectableList: true,
			isSelectAll: false,
			isNumber: null,
			options: null,
		})
	}

	// Add a show all toggle at the top, if this is a multi-select and the
	// flag to prevent this hasn't been set
	if (props.multiple && !props.noSelectAll && options.length !== 1) {
		const lbl = Do(() => {
			if (options.length === 0) {
				return 'No options meet this filter'
			} else if (isFiltered) {
				return 'Select Filtered'
			}
			return 'Select All'
		})
		options.unshift({
			value: cbValAllS,
			text: lbl,
			fullText: lbl,
			display: null,
			indent: 0,
			className: '',
			title: '',
			selectable: false,
			selectableList: true,
			visible: true,
			isSelectAll: true,
			isNumber: null,
			options: null,
		})
	}

	// Return the options array
	return options
}

const getValueText = <T extends string | number, S extends boolean>(
	val: T[] | Maybe<T>,
	props: ComboboxProps<T, S>,
): string => {
	// Gets the text to put in the box based on an explicit value

	// Get the length of all selectable options
	const optLength = _.size(_.filter(getOptions(props), x => x.selectable))

	// If the selected value (singular) is null, just show that
	// TODO - shouldn't need this many checks - make value for nullable consistent
	if (props.nullable && (val == null || val === cbValNullS || _.isEqual(val, []))) {
		return props.nullable
	}

	// Single selection mode just shows the text of the option with the selected value
	if (!props.multiple) {
		const value = isValueMulti<T>(val) ? _.first(val) : val
		return fsmData(_.concat(getOptionsFull<T, S>(props, false, '')), {
			filter: o => o.value === String(value),
			map: o => o?.text ?? '',
			takeFirst: true,
		})
	}

	// It's multi-select, so the values are an array
	const values = isValueMulti<T>(val) ? val : [val]

	// If a custom display function is given, use that above all else
	// If the custom function returns null, fallback to the default
	// Second parameter is whether all items are selected
	if (props.multiple && props.textView != null) {
		const textCalc = props.textView(
			values.filter(x => x != null),
			(values?.length ?? 0) === optLength,
		)
		if (textCalc != null) {
			return textCalc
		}
	}

	// Multiple selection is just a comma separated list of text
	// If there are more than 2 of them, just show the count
	const isNumeric = arePropsNumeric(props)
	const matches = fsmData(getOptions(props), {
		filter: o => values.includes(convertInternalToT(props, o.value, isNumeric)),
		map: o => o.text,
	})
	if (matches.length === optLength) {
		return 'All Selected'
	}
	if (matches.length > 2) {
		return `${matches.length} Selected`
	}
	return matches.join(', ')
}

const refreshScrollJack = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
) => {
	requestAnimationFrame(() => {
		rs.refs.optionsList.current?.refreshScrollJack()
	})
}

const isValueMulti = <T extends string | number>(v: T[] | Maybe<T>): v is T[] =>
	_.isArray(v)

const areAllSelectableSelected = <T extends string | number>(
	selectable: T[],
	selected: T[],
): boolean =>
	_.isEqual(_.sortBy(selectable), _.sortBy(_.intersection(selectable, selected)))

const getInternalValueCB = <T extends string | number, S extends boolean>(
	props: ComboboxProps<T, S>,
	propVal: Maybe<T> | T[],
): string[] => {
	if (props.nullable && !props.nullable && propVal == null) {
		return []
	}
	return getInternalValue(propVal).map(String)
}

const arePropsNumeric = <T extends string | number, S extends boolean>(
	props: ComboboxProps<T, S>,
): boolean => {
	const firstDefined = _.find(getOptions(props), x => x.isNumber != null)
	return firstDefined?.isNumber ?? false
}

const convertInternalToT = <T extends string | number, S extends boolean>(
	props: ComboboxProps<T, S>,
	val: string,
	isNumeric: boolean,
): Maybe<T> => {
	if (props.nullable != null && !props.multiple && val === cbValNullS) {
		return null
	}
	return isNumeric ? (+val as T) : (val as T)
}

/** Combobox - fancy dropdown with built-in text search, multi-select and groups */
export const Combobox = React.forwardRef(ComboboxCmpt)
