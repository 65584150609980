import { BuildClass, Do, fsmoData } from '../../../../universal'
import { React, _ } from '../../../lib'
import { FormButtonSet } from '../buttons'
import { useStateSync } from '../meta-types'
import { FormFillButton } from './llm-fill'
import { FormManager, FormSaveOutcome } from './manager'
import {
	FormFieldJSX,
	FormManagerInstance,
	FormMessage,
	FormMsgType,
	FormProps,
	FormPropsFields,
} from './types'

const FormComponentInner = <F extends FormPropsFields>(
	props: FormProps<F>,
	ref: React.ForwardedRef<FormManagerInstance<F>>,
): React.JSX.Element => {
	const manager = new FormManager({
		fields: props.fields,
		formFixer: props.formFixer,
		disableFormFill: props.disableFormFill,
		getDefaultValues: props.defaultValue,
	})

	// Form values
	const [state, setState] = React.useState(props.value ?? manager.defaultState)
	useStateSync({
		propVal: props.value ?? manager.defaultState,
		setProp: props.onUpdate,
		stateVal: state,
		setState: setState,
	})

	// Track form element reference for drawing a box around it
	const refFormElement = React.useRef<HTMLFormElement | null>(null)

	// Form state
	const [, setFocused] = React.useState<string | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const [message, setMessageRaw] = React.useState<FormMessage>({
		msg: '',
		type: 'bad',
	})

	// Timing refs - needs to be cancellable if a new timer is set
	const timerRef = React.useRef<number | null>(null)
	const setMessage = (msg: string, type?: FormMsgType) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current)
		}
		setMessageRaw({
			msg: msg,
			type: type ?? 'bad',
		})
	}

	// Determine whether anything has changed
	// Note that `state` can contain values that are not in the current `fields`
	// If the form can have dynamic fields, we just ignore the values that don't apply
	const stateFieldsOnly = _.pick(state, _.keys(props.fields))
	const hasChanged = !_.isEqual(manager.defaultState, stateFieldsOnly)

	// Hook for the onMount event
	React.useEffect(() => {
		props.onMount?.()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Render components
	const components = fsmoData<
		FormFieldJSX<F, unknown>, // Input value
		string, // String key (explicit)
		React.JSX.Element, // Output value
		string, // String key (explicit)
		true // Values only
	>(props.fields, {
		filter: x => !(x.hidden ?? false),
		map: (_field, key) => [
			key,
			manager.renderComponentFormRow({
				currentState: state,
				key: key,
				lblWidth: props.lblWidth,
				highlightChanges: !props.disableChangeHighlights,
				onUpdate: v => {
					setState(v)
					setMessage('')
				},
				onFocus: () => {
					setFocused(key)
				},
				onBlur: () => {
					setFocused(null)
				},
			}),
		],
		valuesOnly: true,
	})

	// Reference instance
	React.useImperativeHandle(ref, () => ({
		manager,
		currentState: state,
		setCurrentState: setState,
		setFocused,
		hasUnsavedChanges: hasChanged,
	}))

	// Render buttons
	const buttons = !props.excludeButtons && (
		<FormButtonSet
			loading={isLoading}
			msg={message.msg}
			tick={message.type == 'good'}
			neutral={message.type == 'neutral'}
			widths={props.buttonWidth}
			buttons={[
				// Save button
				{
					lbl: props.lblSave ?? 'Save',
					type: 'submit',
					title: hasChanged
						? (props.lblSaveTooltip ?? 'Save changes')
						: 'Nothing to save',
					onClick: () => {
						// This is a submit button so the action is managed by the
						// Form's onSubmit event
					},
				},
				// Reset button
				{
					lbl: 'Reset',
					hidden: props.excludeReset ?? false,
					title: hasChanged ? 'Resets the form' : 'Nothing to reset',
					disabled: !hasChanged,
					onClick: () => {
						setState(manager.defaultState)
					},
				},
				// Extra actions
				...(props.extraActions ?? []).map(x => ({
					lbl: x.lbl,
					onClick: () => {
						x.onClick({
							state: manager.getPublicState(state),
							setLoading: setIsLoading,
							setMessage: setMessage,
							markAsSuccessful: () => {
								setMessage('', 'good')
								if (timerRef.current) {
									clearTimeout(timerRef.current)
								}
								timerRef.current = Number(
									setTimeout(() => {
										setMessage('')
									}, 2000),
								)
								props.onSuccessfulSave?.()
							},
						})
					},
					type: x.type,
					disabled: Do(() => {
						if (x.requiresChanges && !hasChanged) {
							return true
						}
						if (x.requiresNoChanges && hasChanged) {
							return true
						}
						return x.checkIfUsable?.(manager.getPublicState(state)) ?? false
					}),
				})),
			]}
		/>
	)

	// Routine to run when submitting the form
	const submitEvent = async () => {
		// Clear the form state
		setIsLoading(true)
		setMessage('')

		// Run the save handler
		const result = await manager.trySaving({
			allowSavingWithoutChanges: props.allowSavingWithoutChanges ?? false,
			currentState: state,
			setState: setState, // This might update the state if there were pending fixers
			onSave: props.onSave,
		})
		setIsLoading(false)

		// If there were errors, show them
		if (result.status == FormSaveOutcome.Error) {
			setMessage(result.message)
			return
		}

		// If it was a neutral message, show it, but hide after a timeout
		if (result.status == FormSaveOutcome.Neutral) {
			setMessage(result.message, 'neutral')
			timerRef.current = Number(
				setTimeout(() => {
					setMessage('')
				}, 1000),
			)
			return
		}

		// Request completed successfully, show a tick and reset the form
		setMessage(result.message, 'good')
		timerRef.current = Number(
			setTimeout(() => {
				setMessage('')
			}, 4000),
		)

		// Trigger the successful save event
		// For flyouts, this closes them - job done
		props.onSuccessfulSave?.()
	}

	// Render the entire form
	return (
		<form
			// Allow focusing (used for the voice input keyboard events)
			tabIndex={-1}
			ref={refFormElement}
			// Styling
			className={BuildClass({
				[props.className ?? '']: true,
				'font-condensed relative': true,
			})}
			// Save event
			action={undefined}
			onSubmit={e => {
				e.preventDefault()
				submitEvent().catch(console.error)
			}}
			// Check for keyboard events
			onKeyDown={e => {
				// Check if this is doing a ctrl + S to save the form
				if (e.ctrlKey && e.code === 'KeyS') {
					e.stopPropagation()
					e.preventDefault()
					submitEvent().catch(console.error)
					return
				}
				// Voice recording event
				manager.hotkeyPress(e, {
					currentState: state,
					setState: setState,
					formPrompt: props.formPrompt,
					voicePrompt: props.voicePrompt,
					containingElement: refFormElement.current ?? document.body,
				})
			}}
			onKeyUp={e => {
				// Voice recording event
				manager.hotkeyRelease(e)
			}}
		>
			{/* Components and buttons */}
			{components}
			{buttons}
			{/* Form fill */}
			<div
				className={BuildClass({
					'absolute right-0': true,
					'bottom-8': !(props.excludeButtons ?? false),
					'-bottom-8': props.excludeButtons ?? false,
				})}
			>
				<FormFillButton
					manager={manager}
					currentState={state}
					setState={setState}
					formPrompt={props.formPrompt}
					voicePrompt={props.voicePrompt}
					containingElement={refFormElement.current ?? document.body}
				/>
			</div>
		</form>
	)
}

/**
 * An easy way to define standard forms with standard fields without all the boilerplate.
 * See the `FormProps` type for the structure.
 * Generally fields are defined using `FormType` functions.
 */
export const FormComponent = React.forwardRef(FormComponentInner)
