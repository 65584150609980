import {
	BuildClass,
	Maybe,
	ReportJob,
	TW_OVERFLOW_ELLIPSIS,
	fsmData,
} from '../../../../universal'
import { React, _ } from '../../../lib'
import { IRIS } from '../../component-iris'
import { cacheZipFile } from '../../component-pdf-viewer'
import { AddButton, Button, ButtonTW } from '../buttons'
import { Checkbox } from '../checkbox'
import { Combobox } from '../combobox'
import { Datebox } from '../date'
import { LoadingSpinnerLarge } from '../loading'
import { CJSX } from '../meta-types'
import { Textbox } from '../textbox'
import { dummyFP } from '../wrappers'
import { Action, buildReport, sendOnUpdate } from './actions'
import { ReducerStateD, ReportComponent } from './types'

const errClass = 'block text-xl mt-20 text-center text-[#888]'

export const ReportOptionPane = (props: {
	rs: ReducerStateD
	report: string | number | null
}): React.JSX.Element => {
	// Cache still loading
	if (!props.rs.state.cache) {
		return <LoadingSpinnerLarge />
	}

	// No report selected
	if (props.report == null) {
		return <div className={errClass}>Select a Report</div>
	}

	// Check if this is the report job meta item giving info
	if (props.report === 'report-jobs') {
		// Only show the detailed information if there are no report jobs
		return getReportJobMetaForm(props.rs)
	}

	// Check if this is a report job
	const rj = props.rs.props.reportJobs?.items[-props.report]
	if (rj != null) {
		return getReportJobOptionsForm(props.rs, rj)
	}

	// Get the report object and the parameters/exports
	const report = props.rs.props.reportIndex[props.report]
	if (!report) {
		return <div className={errClass}>Select a Report</div>
	}
	const params = props.rs.state.cache?.params?.[report.Key] ?? []
	const exports = props.rs.state.cache.exports?.[report.Key] ?? []

	// Get the components for the options form
	const cmpts = _.map(params, param =>
		getComponent(props.rs, param.Type, param.Key, param.Label, param.Options),
	)

	// If this is a report group
	if (props.report == null) {
		return <div className={errClass}>Select a report</div>
	}

	// Add the build report buttons and return
	return (
		<>
			<div className="overflow-y-scroll max-h-[calc(100%-66px)]">{cmpts}</div>
			<div key="buttons" className="text-center mb-[10px]">
				<div
					className={BuildClass({
						'mb-1': true,
						invisible: _.isEqual(
							props.rs.state.options[report.Key],
							props.rs.state.defaultOptions[report.Key],
						),
					})}
				>
					<a
						className="text-blue-600 hover:text-red-600 text-sm cursor-pointer"
						onClick={() => {
							props.rs.dispatch([
								Action.ResetOptions,
								{ reportKey: report.Key },
							])
						}}
					>
						Reset options to default
					</a>
				</div>
				<CJSX cond={_.includes(exports, 1)}>
					<ButtonTW
						className="min-w-[92px] max-w-[92px] bg-[#edc] hover:bg-[#fdd] active:bg-[#fdd]"
						style={{ marginLeft: '1px', marginRight: '1px' }}
						lbl="Build PDF"
						onClick={() => {
							buildReport(props.rs, 1)
						}}
					/>
				</CJSX>
				<CJSX cond={_.includes(exports, 2)}>
					<ButtonTW
						className="min-w-[92px] max-w-[92px] bg-[#ded] hover:bg-[#cfc] active:bg-[#cfc]"
						style={{ marginLeft: '1px', marginRight: '1px' }}
						lbl="Build XLSX"
						onClick={() => {
							buildReport(props.rs, 2)
						}}
					/>
				</CJSX>
				<CJSX cond={_.includes(exports, 3)}>
					<ButtonTW
						className="min-w-[92px] max-w-[92px]"
						style={{ marginLeft: '1px', marginRight: '1px' }}
						lbl="Build CSV"
						onClick={() => {
							buildReport(props.rs, 3)
						}}
					/>
				</CJSX>
			</div>
		</>
	)
}

const getReportJobMetaForm = (rs: ReducerStateD) => (
	<div className="text-center align-middle m-[10px] leading-5">
		<p className="mb-4 text-left">
			Report jobs are custom-made lists of reports with the parameters and export
			types already set.
		</p>
		<p className="mb-4 text-left">
			You can click the "Jobs" button on the left to open the report job manager to
			add or edit report jobs.
		</p>
		<div>
			<AddButton
				type="ui5-borderless-24"
				lbl="Add Report Job"
				onClick={() => {
					rs.props.reportJobs?.openWindow?.(-1)
				}}
			/>
		</div>
	</div>
)

const getReportJobOptionsForm = (rs: ReducerStateD, rj: ReportJob) => {
	const count = _.size(rj.Reports)
	const s = count === 1 ? '' : 's'
	const runReportJob = () => {
		const rpt = rs.state.selectedReport
		if (!rpt) {
			return
		}

		// Form is now loading, and prior report is trashed
		rs.dispatch([
			Action.UpdateFormState,
			{
				reportKey: rpt,
				isLoading: true,
				loadProg: null,
				iframeSrc: null,
				pdfURL: null,
				errorMessage: '',
			},
		])

		// Exit early if report jobs aren't defined
		if (rs.props.reportJobs == null) {
			rs.dispatch([
				Action.UpdateFormState,
				{
					reportKey: rpt,
					errorMessage: 'Report jobs are not defined',
					isLoading: false,
				},
			])
			return
		}

		// Send request
		IRIS.Send({
			data: {
				progID: rs.props.progID,
				funcID: rs.props.reportJobs?.funcID,
				reportJob: rj.ID,
			},
			prog: x => {
				rs.dispatch([Action.UpdateFormState, { reportKey: rpt, loadProg: x }])
			},
			any: () => {
				rs.dispatch([
					Action.UpdateFormState,
					{
						reportKey: rpt,
						isLoading: false,
						loadProg: null,
					},
				])
			},
			no: data => {
				rs.dispatch([
					Action.UpdateFormState,
					{ reportKey: rpt, errorMessage: data.message },
				])
			},
			yes: (data: { hashCode: string; output: string }) => {
				const name = rj.Name.replace(/[^a-zA-Z0-9-]/g, '-')
				cacheZipFile(data.hashCode, data.output, name, url => {
					rs.dispatch([
						Action.UpdateFormState,
						{ reportKey: rpt, iframeSrc: url },
					])
				}).catch(err => {
					console.error(err)
				})
			},
		})
	}
	return (
		<div className="text-center align-middle">
			<div className="p-3 text-center">
				<a
					className="text-blue-600 hover:text-red-600 cursor-pointer hover:no-underline"
					onClick={e => {
						e.preventDefault()
						rs.props.reportJobs?.openWindow?.(rj.ID)
						return false
					}}
				>
					Edit report job
				</a>
			</div>
			<Button
				type="submit"
				lbl={`Build ${count} Report${s}`}
				onClick={runReportJob}
			/>
			<ul className="text-left my-4 pl-6 list-disc">
				{fsmData(rj.Reports, {
					sort: x => x.Name,
					map: x => (
						<li>
							<div className={TW_OVERFLOW_ELLIPSIS}>{x.Name}</div>
						</li>
					),
				})}
			</ul>
		</div>
	)
}

export const getComponentType = (rs: ReducerStateD, type: string): ReportComponent => {
	const cmpt = definedComponentsGeneric[type] ?? rs.props.getReportComponent(type)
	if (!cmpt) {
		console.error(`Unknown report component: "${type}"`)
		return {
			defVal: () => null,
			cmpt: () => <div>Error loading option of type "{type}"</div>,
		}
	}
	return cmpt
}

const getComponent = (
	rs: ReducerStateD,
	cmpt: string,
	key: string,
	lbl: string,
	options: Maybe<{ value: string; text: string }[]>,
): React.JSX.Element => {
	const rpt = getComponentType(rs, cmpt)
	const rkey = rs.props.reportIndex[rs.state.selectedReport ?? -1]?.Key
	if (!rkey) {
		console.error(`Could not find report key: ${rkey}`)
		return <></>
	}
	const component = React.cloneElement(rpt.cmpt({ label: lbl, options: options }), {
		key: 'cmpt',
		className: BuildClass({
			[rpt.className ?? '']: true,
			'max-w-full min-w-full': true,
		}),
		value: (rs.state.options[rkey]?.[key] ?? null) as unknown,
		onUpdate: (v: unknown) => {
			const val: unknown = (rpt.validate ?? _.identity)(v)
			rs.dispatch([Action.UpdateOptions, { [key]: val }])
			sendOnUpdate(rs)
		},
	})

	const lblDisplay = cmpt != 'TypeBool' ? lbl : ''
	return (
		<label
			key={key}
			className={BuildClass({
				block: true,
				[rpt.paddingClass ?? 'pt-[5px] pb-[10px] px-[10px]']: true,
				[rpt.className ?? '']: true,
			})}
		>
			<span className="block font-condensed font-light px-1 text-[#555]">
				{lblDisplay}
			</span>
			{component}
		</label>
	)
}

export const definedComponentsGeneric: { [key: string]: ReportComponent } = {
	TypeBool: {
		defVal: () => false,
		paddingClass: 'pt-0 pb-0 px-[10px]',
		cmpt: x => (
			<Checkbox
				{...dummyFP}
				className="text-sm text-[#444]"
				classNameInner="before:inline before:text-xl before:leading-5 hover:text-black"
				classNameLabel="inline leading-[21px]"
				lbl={x.label}
			/>
		),
	},
	TypeString: { defVal: () => '', cmpt: () => <Textbox {...dummyFP} /> },
	TypeInteger: { cmpt: () => <Textbox {...dummyFP} /> },
	TypeFloat: { cmpt: () => <Textbox {...dummyFP} /> },
	TypeDate: { cmpt: () => <Datebox {...dummyFP} /> },
	TypeGeneric_SetOfInts: { cmpt: () => <Textbox {...dummyFP} /> },
	TypeEnum: {
		defVal: () => null,
		cmpt: x => <Combobox options={x.options ?? []} {...dummyFP} />,
	},
}
