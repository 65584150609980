import { Alerts } from '../..'
import { BuildClass } from '../../../../universal'
import { React } from '../../../lib'
import { AddButton } from '../buttons'
import { FileUploadDropZone, FileUploadItem } from '../file-upload'
import { ListGridInstance } from '../list-grid'
import { LoadingSpinnerLarge } from '../loading'
import { CJSX, Focusable, useRSInstance } from '../meta-types'
import { ResizeSplitter } from '../resize-split'
import { stubFocusable, stubInput, stubListGrid } from '../stubs'
import { Toolbar } from '../toolbar'
import { addNewNoteForm } from './add'
import { NoteDetails } from './details'
import { NoteList } from './list'
import { NoteInfo, NotesComponentProps, NotesComponentState, ReducerState } from './types'

const getDefaultState = <T extends Record<string, unknown>>(
	p: NotesComponentProps<T>,
): NotesComponentState => ({
	searchText: p.state?.searchText ?? '',
	showArchived: p.state?.showArchived ?? false,
	showDeleted: p.state?.showDeleted ?? false,
	showTagIndicators: p.state?.showTagIndicators ?? true,
	selectedNote: p.state?.selectedNote ?? null,
	paneSizes: p.state?.paneSizes ?? null,
	loadingCoverMessage: null,
})

export const NotesComponent = <T extends Record<string, unknown>>(
	props: NotesComponentProps<T>,
): React.JSX.Element => {
	// Reducer state
	const rs: ReducerState<T> = useRSInstance({
		props,
		refs: {
			grid: React.useRef<ListGridInstance<NoteInfo<T>, false>>(stubListGrid),
			searchBox: React.useRef<Focusable<HTMLInputElement>>(
				stubFocusable(stubInput),
			),
		},
		defaultState: getDefaultState,
		actionToDelta: null,
	})

	// Bulk upload processing function (used twice below)
	const processUploadedFiles = (files: FileUploadItem[]): Promise<void> =>
		new Promise<void>(resolve => {
			// No confirmation prompt? Run straight away
			if (!rs.props.adding?.bulkUpload?.confirmationPrompt) {
				rs.props.adding?.bulkUpload?.onUpload(files, resolve)
			}

			// Ask the user if they're sure
			Alerts.Confirm({
				msg: rs.props.adding?.bulkUpload?.confirmationPrompt?.(files),
				yes: () => {
					rs.props.adding?.bulkUpload?.onUpload(files, resolve)
				},
				no: () => {
					resolve()
				},
			})
		})

	// If there are no notes
	if (props.notes.length == 0) {
		return (
			<div className={BuildClass(['tailwind-wrapper', props.className ?? ''])}>
				<FileUploadDropZone
					className="h-full"
					multiple={true}
					disabled={!rs.props.adding?.bulkUpload}
					draggingOverMessage={
						rs.props.adding?.bulkUpload?.dragDropCoverMessage
					}
					onUpload={processUploadedFiles}
				>
					<Toolbar
						widthRHS={150}
						lhs={<></>}
						rhs={
							<AddButton
								lbl="Add Content"
								onClick={() => {
									addNewNoteForm(rs)
								}}
							/>
						}
					/>
					<div className="cntr error_msg_2">No Notes</div>
				</FileUploadDropZone>
			</div>
		)
	}

	// Render
	return (
		<div className={BuildClass(['tailwind-wrapper', props.className ?? ''])}>
			<FileUploadDropZone
				className="h-full"
				multiple={true}
				disabled={!rs.props.adding?.bulkUpload}
				draggingOverMessage={rs.props.adding?.bulkUpload?.dragDropCoverMessage}
				onUpload={processUploadedFiles}
			>
				<CJSX cond={rs.state.loadingCoverMessage != null}>
					<div className="absolute grid place-items-center w-full h-full z-10">
						<LoadingSpinnerLarge />
						<div className="text-2xl text-white">
							{rs.state.loadingCoverMessage}
						</div>
					</div>
				</CJSX>
				<ResizeSplitter
					value={rs.state.paneSizes}
					onUpdate={v => {
						rs.updateState({ paneSizes: v })
						rs.props.state?.onUpdate?.({ paneSizes: v })
					}}
					panes={[
						{
							minWidth: 200,
							maxWidth: null,
							collapsible: true,
							defaultWidth: 290,
							flexWidth: null,
							content: <NoteList rs={rs} />,
						},
						{
							minWidth: 200,
							maxWidth: null,
							collapsible: true,
							defaultWidth: null,
							flexWidth: 1,
							content: <NoteDetails rs={rs} />,
						},
					]}
				/>
			</FileUploadDropZone>
		</div>
	)
}
