import { Do, clamp, fsmData, guid, timer } from '../../universal'
import { React, _ } from '../lib'
import { Alerts, objDiff } from './component-main'
import {
	J2rCheckbox,
	J2rObject,
	J2rText,
	J2rTooltip,
	j2r,
	j2rProps,
	reactFlyout,
} from './component-react'
import {
	Bindings,
	CJSX,
	ConditionalObject,
	ContextMenuItem,
	CopyState,
	FormType,
	HelpTooltip,
	LoadingSpinnerLarge,
	Modal,
	openFlyoutForm,
	setContextMenu,
} from './ui5'

type WMDesktop = {
	index: number
	key: number
	name?: string
}

type WindowManagerSerialized = {
	covered: boolean
	desktop: number
	desktops: WMDesktop[]
	windows: {
		func: string
		params: any
		pos: WindowPosition
		size: WindowSize
		sizeDef: WindowSize
		preMaximMinimPos: WindowPositionBasic
		preMaximMinimSize: WindowSize
		isMinimized: boolean
		isMaximized: boolean
	}[]
}

type WindowPositionBasic = { x: number; y: number }
type WindowPosition = { d: number; x: number; y: number; z: number }
type WindowSize = { x: number; y: number }
export type TypeWindowManagerWindow = {
	key: string
	cmpt: React.Component
	content: string
	fields: string[]
	func: string
	index: number
	params: any
	parent: WindowManagerUI
	pos: WindowPosition
	preMaximMinimPos: WindowPositionBasic
	preMaximMinimSize: WindowSize
	ref: React.RefObject<any>
	sid: string
	size: WindowSize
	sizeDef: WindowSize
	sizeMax: WindowSize
	sizeMin: WindowSize
	tag: any
	title: string
}

// TODO - make these concrete
type NewWindowFunction = any
type NewWindowOptions = any

declare let Mousetrap: any
declare let Sortable: any

export type WindowManagerUIOptions = {
	cl?: string
	disableVirtualDesktops?: boolean
	disableBirdsEyeButton?: boolean
	fixedPageContent?: Function // Returns element tree
	namespace?: string
	// Events
	onCreate?: (arg: WindowManagerUI) => void
	onDestroy?: (arg: WindowManagerUI) => void
	onLoad?: (resolve, reject?) => any
	onLoadData?: (resolve, reject?) => any
	onSave?: Function
	getTitle?: Function
}
export class WindowManagerUI extends React.Component<
	{
		parent?: any // Element
		toolbar?: any // WindowManagerToolbar
		mobile?: boolean
		options?: WindowManagerUIOptions
	},
	{
		focused: string
		hasInitData: boolean
		hasInitModel: boolean
		hoverBirdsEye: boolean
		exploded?: {
			h: number
			o: WindowManagerWindow
			w: number
			x: number
			y: number
		}[]
		covered: boolean
		coveredFocus?: number
		frontOverlay?: J2rObject
		frontOverlayBlurring: boolean
		desktop: number
		desktops: WMDesktop[]
		windows: { [key: string]: TypeWindowManagerWindow }
	}
> {
	cachedFunctions: any
	windowRefs: { [key: string]: React.RefObject<WindowManagerWindow> }
	canvasElement: React.RefObject<HTMLDivElement>
	mouseTrapRoot: React.RefObject<HTMLDivElement>
	fixedContent: React.RefObject<HTMLDivElement>
	fixedContentInner: React.RefObject<any>
	generatedPosZ: number
	UpdateThrottled: _.DebouncedFunc<() => any>
	OnResize: _.DebouncedFunc<() => any>
	savedSerialisedData: any
	hasLaunchedFetchData: boolean

	constructor(props) {
		super(props)
		Bindings(this, [
			this.CascadeWindows,
			this.ChangeDesktop,
			this.ChangeFocus,
			this.CloseAllOnDesktop,
			this.CloseWindow,
			this.CompactZPosition,
			this.DefaultSizes,
			this.Deserialize,
			this.Explode,
			this.GetCanvasSize,
			this.MergeDesktops,
			this.MinimizeAllOnDesktop,
			this.NewWindow,
			this.OnResizeProc,
			this.openGlobalContextMenu,
			this.refreshDesktops,
			this.Serialize,
			this.SetCover,
			this.TileWindows,
			this.Unexplode,
			this.UnexplodeNoCB,
			this.Update,
			this.UpdateProc,
		])
		this.state = {
			focused: null,
			hasInitData: false,
			hasInitModel: false,
			hoverBirdsEye: false,
			exploded: null,
			covered: false,
			coveredFocus: null,
			frontOverlay: null,
			frontOverlayBlurring: false,
			desktop: null,
			desktops: [],
			windows: {},
		}

		// Cached functions
		this.cachedFunctions = {}

		// Window references
		this.windowRefs = {}

		// Canvas DOM reference
		this.canvasElement = React.createRef()
		this.mouseTrapRoot = React.createRef()
		this.fixedContent = React.createRef()
		this.fixedContentInner = React.createRef()

		// Keep track of how many windows have been opened - for DOM IDs and z-index
		this.generatedPosZ = 1

		// Create the throttled update function (bound to instance)
		this.UpdateThrottled = _.debounce(this.UpdateProc, 1000, {
			trailing: true,
			leading: false,
		})
		this.UpdateThrottled = this.UpdateThrottled.bind(this)

		// Create the throttled onResize function (bound to instance)
		this.OnResize = _.debounce(this.OnResizeProc, 50, {
			trailing: true,
			leading: false,
		})
		this.OnResize = this.OnResize.bind(this)
	}

	override componentDidMount() {
		this.savedSerialisedData = null

		// Start the async code to fetch the window and application data models
		// Start with the window data model - generally faster
		const fnOnLoad = this.props.options.onLoad ?? (rs => rs(null))
		new Promise(fnOnLoad).then(model => {
			this.setState(this.Deserialize(model))
			this.setState({ hasInitModel: true }, () =>
				this.props.parent.forceUpdate(() => {
					this.refreshDesktops()
					this.CompactZPosition()
				}),
			)
		})

		// Fetch the data model for the page if there's a function for it
		// If not, then the page is fine to display as soon as the window model loads
		// Note that this component doesn't care what data loads - just that it has and
		// we're ready to start instantiating windows that might depend on it
		const fnOnLoadData = this.props.options.onLoadData ?? (rs => rs())
		if (!this.hasLaunchedFetchData) {
			new Promise(fnOnLoadData).then(() => {
				this.setState({ hasInitData: true }, () =>
					this.props.parent.forceUpdate(() => {
						this.refreshDesktops()
						this.UpdateTitle()
					}),
				)
			})
			this.hasLaunchedFetchData = true
		}

		// Create the key-bindings
		this.createKeyBindings()

		// Listen for a window resize event
		const resizeThrottle = _.throttle(this.OnResize, 500)
		window.addEventListener('resize', resizeThrottle)

		// Call the resize event now that the DOM has loaded to confirm sizes
		this.OnResize()

		// Run the `onCreate` event
		;(this.props.options?.onCreate ?? _.noop)(this)
	}

	override componentWillUnmount() {
		// Save the current window state as an instance variable
		// This is so any `UpdateProc` events that are coming will be saved with the
		// correct data
		this.savedSerialisedData = this.Serialize()

		// Run the `onDestroy` event
		;(this.props.options?.onDestroy ?? _.noop)(this)
	}

	override render() {
		return (
			<div
				className={Do(() => {
					let C = 'windowCanvas noselect react'
					if (this.props.options.cl != null) {
						C += ` ${this.props.options.cl}`
					}
					if (this.props.options.fixedPageContent != null) {
						if (this.state.covered) {
							C += ' covered'
						}
						if (!this.state.covered) {
							C += ' uncovered'
						}
					}
					return C
				})}
				ref={this.mouseTrapRoot}
				onContextMenu={this.openGlobalContextMenu}
				onClick={e => {
					this.UnexplodeNoCB()
					this.closeOverlayClicked(e)
				}}
			>
				<div className="snapping_div" />
				<div className="backdrop noselect">
					<CJSX cond={this.state.hasInitModel}>
						<img key="backdrop-img" src="/static/img/svg/trionline.svg" />
					</CJSX>
				</div>
				<div
					className={Do(() => {
						let C = 'fixed-page canselect'
						if (this.state.exploded != null) {
							C += ' exploded'
						}
						return C
					})}
					ref={this.fixedContent}
				>
					{Do(() => {
						const el = (this.props.options.fixedPageContent ?? _.noop)()
						if (el != null) {
							return j2r(
								_.assign({}, el, {
									key: 'fixed-page-inner',
									ref: this.fixedContentInner,
								}),
							)
						}
						return undefined
					})}
				</div>
				<div className="fixed-page-cover" />
				<div
					className={Do(() => {
						let C = 'fixed-page-stub-text'
						if (_.size(this.windowsIterable(true)) !== 0) {
							C += ' hidden'
						}
						return C
					})}
				>
					<div className="inner">
						<div className="title">No open windows</div>
						<div className="subtitle">Click anywhere to close overlay</div>
					</div>
				</div>
				<div
					className={Do(() => {
						let C = 'windowCanvas'
						if (this.state.exploded != null) {
							C += ' exploded'
						}
						if (this.state.hoverBirdsEye && this.state.exploded == null) {
							C += ' birdhover'
						}
						if (this.state.frontOverlayBlurring) {
							C += ' blurred'
						}
						return C
					})}
					tabIndex={-1}
					ref={this.canvasElement}
					onScroll={e => {
						e.currentTarget.scrollTop = 0
					}}
				>
					{Do(() => {
						// Show a loading spinner if the window model hasn't loaded yet
						if (!this.state.hasInitModel) {
							return <LoadingSpinnerLarge key="loading" />
						}

						// Render the windows
						return j2r(
							_.compact(
								_.flatten([
									this.buildWindows(),
									(this.state.exploded ?? []).map(e => (
										<div
											className="explode-handler"
											key={`explode-handler-${e.o.state.key}`}
											style={{
												left: `${e.x}px`,
												top: `${e.y}px`,
												width: `${e.w}px`,
												height: `${e.h}px`,
											}}
											onClick={() => {
												this.Unexplode(() => {
													this.ChangeFocus(e.o.state.key)
												})
											}}
										>
											<span>
												{Do(() => {
													const fn =
														this.cachedFunctions[
															e.o.props.title
														] ?? _.noop
													return (
														fn(e.o.state.params ?? {}, e.o) ??
														'No Title'
													)
												})}
											</span>
										</div>
									)),
								]),
							),
						)
					})}
				</div>
				<div
					className={Do(() => {
						let C = 'window-front-overlay'
						if (this.state.frontOverlay != null) {
							C += ' has-contents'
						}
						return C
					})}
				>
					{ConditionalObject(this.state.frontOverlay != null, () =>
						j2r(_.assign({ key: 'inner' }, this.state.frontOverlay)),
					)}
				</div>
			</div>
		)
	}

	buildWindows() {
		return fsmData(this.state.windows, {
			sort: x => x.key,
			map: x =>
				j2r(
					_.assign({}, x, {
						tag: WindowManagerWindow,
						parent: this as WindowManagerUI,
						isLoading: !this.state.hasInitData,
						ref: Do(() => {
							if (this.windowRefs[x.key] == null) {
								this.windowRefs[x.key] = React.createRef()
							}
							return this.windowRefs[x.key]
						}),
						isFocused: x.key === this.state.focused,
						index: x.key,
						key: x.key,
						lastForcedReactUpdate: window.lastForcedReactUpdate,
					}),
				),
		})
	}

	windowsIterable(desktop_only = false) {
		const objs: { [key: string]: WindowManagerWindow } = {}
		_.keys(this.state.windows).forEach(x => {
			const obj = this.windowRefs[x].current
			if (
				obj != null &&
				!(desktop_only && obj.state.pos.d !== this.state.desktop)
			) {
				objs[x] = obj
			}
		})
		return objs
	}

	getWindow(key) {
		return this.windowsIterable()[key]
	}

	refreshDesktops() {
		// If no loaded yet, skip
		if (!this.state.hasInitModel) {
			return
		}

		// Get the set of used desktops
		const used_desktops = _.uniq(_.map(this.windowsIterable(), w => w.state.pos.d))

		// Ensure that all desktops are represented in the desktops model
		const to_add = []
		used_desktops.forEach(d => {
			const found = this.state.desktops.filter(x => x.key === d)
			if (_.size(found) === 0) {
				to_add.push({
					key: d,
					index: to_add.length + 1,
					name: null,
				})
			}
		})

		// Remove all but the first empty desktop
		const empty_desktops = fsmData(this.state.desktops, {
			filter: d => {
				if (d.name != null) {
					return false
				}
				const matches = _.filter(
					this.windowsIterable(),
					w => w.state.pos.d === d.key,
				)
				return matches.length === 0
			},
			sort: d => d.key,
			map: d => d.key,
		})
		const desktops_to_remove = empty_desktops.slice(1)

		// Get the new desktops model, adding/removing as needed
		let new_desktops = this.state.desktops
			.concat(to_add)
			.filter(d => !_.includes(desktops_to_remove, d.key))

		// Add one if there were no empties
		if (empty_desktops.length === 0) {
			const used = new_desktops.map(x => x.key)
			_.forEach(_.range(1, 10), x => {
				if (!_.includes(used, x)) {
					new_desktops = new_desktops.concat({
						key: x,
						index: new_desktops.length + 1,
						name: null,
					})
					return false
				}
				return true
			})
		}

		// Ensure that the currently-selected desktop exists
		const matches = new_desktops.filter(x => x.key === this.state.desktop)
		const desktop = matches.length === 0 ? 1 : this.state.desktop

		// Rebuild the index of the desktops
		new_desktops = fsmData(new_desktops, {
			sort: x => x.index,
			map: (x, i) => ({
				key: x.key,
				index: i + 1,
				name: x.name || null,
			}),
		})

		// Skip early if nothing has changed
		if (
			_.isEqual(this.state.desktops, new_desktops) &&
			desktop === this.state.desktop
		) {
			return
		}

		// Add the desktops and update the toolbar
		this.setState(
			{
				desktops: new_desktops,
				desktop,
			},
			() => {
				this.Update()
			},
		)
	}

	deserializeWindows(w) {
		// Helper function to fix up sizes
		const getSize = (s, fb) => ({
			x: s?.x ?? s?.[0] ?? fb?.x ?? fb?.[0],
			y: s?.y ?? s?.[1] ?? fb?.y ?? fb?.[1],
		})

		// Create each of the windows
		const windows = {}
		let last_key = null
		_.forEach(w, (x, i) => {
			const key = i + 1

			// Build up the parameter objects
			const props = {
				params: x.params,
				pos: x.pos,
				size: x.size,
				preMaximMinimPos: x.preMaximMinimPos,
				preMaximMinimSize: x.preMaximMinimSize,
				isMinimized: x.isMinimized,
				isMaximized: x.isMaximized,
			}

			// Create the window object to go into the state
			// Skip if the function cannot be found (logged to console)
			const fn = this.getFunction(x.func)
			if (fn == null) {
				return
			}
			const defaultProps = fn(x.params)

			// Cache functions
			const fn_content_key = guid()
			const fn_title_key = guid()
			this.cachedFunctions[fn_title_key] = defaultProps.title
			this.cachedFunctions[fn_content_key] = this.getContentFunction(defaultProps)

			// Build the full state
			const wobj = _.assign({}, defaultProps, props, {
				tag: WindowManagerWindow,
				parent: this,
				title: fn_title_key,
				content: fn_content_key,
				ref: Do(() => {
					if (this.windowRefs[key] == null) {
						this.windowRefs[key] = React.createRef()
					}
					return this.windowRefs[key]
				}),
				index: key,
				key,
			})

			// Fix up the given sizes
			wobj.sizeDef = getSize(defaultProps.size, [640, 480])
			wobj.sizeMax = getSize(wobj.sizeMax, [99999, 99999])
			wobj.sizeMin = getSize(wobj.sizeMin, [240, 28])

			// Force to desktop 1 if not using virtual desktops
			if (this.props.options.disableVirtualDesktops) {
				wobj.pos.d = 1
			}

			// Save to the windows object
			windows[key] = wobj
			last_key = key
		})

		// Focus the last window - highest Z-index
		this.setState({ focused: last_key })

		// Return the window state
		return windows
	}

	getFunction(s): Function {
		if (!s) {
			return null
		}
		if (this.props.options.namespace != null) {
			s = `${this.props.options.namespace}.${s}`
		}
		const parts = s.split('.')
		let currNamespace: any = window
		let currNamespaceStr = 'window'
		_.forEach(parts, part => {
			currNamespace = currNamespace[part]
			if (currNamespace == null) {
				console.error(`Could not find ${currNamespaceStr}.${part}`)
				currNamespace = null
				return false
			}
			currNamespaceStr += `.${part}`
			return true
		})
		return currNamespace
	}

	getNextPosZ() {
		// Pull from the counter
		let i = this.generatedPosZ
		this.generatedPosZ++

		// Compare to the current Z indices
		const max_z = _.max(_.map(this.windowsIterable(), x => x.state.pos.z))
		if (max_z >= i) {
			this.generatedPosZ = max_z + 1
			i = this.generatedPosZ
			this.generatedPosZ++
		}

		// Return the value
		return i
	}

	getNextPosFull() {
		return [
			10 + 20 * this.generatedPosZ, // X
			10 + 20 * this.generatedPosZ, // Y
		]
	}

	createKeyBindings() {
		// Helper function to check if there's a focused input element
		const isFocusingInput = () => {
			const el = document.querySelector(':focus')
			return ['TEXTAREA', 'INPUT'].includes(el?.tagName)
		}

		// The parent element that all events are bound to
		const canvas = Mousetrap(this.mouseTrapRoot.current)

		// Changing desktop
		{
			const changeVirtualDesktop = e => {
				if (!isFocusingInput()) {
					let index = e.which - 48
					if (index === 0) {
						index = 10
					}
					this.ChangeDesktop(index)
				}
			}
			canvas.bind('d 1', changeVirtualDesktop)
			canvas.bind('d 2', changeVirtualDesktop)
			canvas.bind('d 3', changeVirtualDesktop)
			canvas.bind('d 4', changeVirtualDesktop)
			canvas.bind('d 5', changeVirtualDesktop)
			canvas.bind('d 6', changeVirtualDesktop)
			canvas.bind('d 7', changeVirtualDesktop)
			canvas.bind('d 8', changeVirtualDesktop)
			canvas.bind('d 9', changeVirtualDesktop)
			canvas.bind('d 0', changeVirtualDesktop)
			canvas.bind('d left', () => {
				if (!isFocusingInput()) {
					const new_key = clamp(this.state.desktop - 1, 1, 10)
					this.ChangeDesktop(new_key)
				}
			})
			canvas.bind('d left', () => {
				if (!isFocusingInput()) {
					const new_key = clamp(this.state.desktop + 1, 1, 10)
					this.ChangeDesktop(new_key)
				}
			})
		}

		// Tiling
		{
			const tile = e => {
				if (!isFocusingInput()) {
					const index = e.which - 48
					if (index === 0) {
						this.CascadeWindows()
					} else {
						this.TileWindows(index)
					}
				}
			}
			canvas.bind('t 0', tile)
			canvas.bind('t 1', tile)
			canvas.bind('t 2', tile)
			canvas.bind('t 3', tile)
			canvas.bind('t 4', tile)
			canvas.bind('t 5', tile)
			canvas.bind('t 6', tile)
		}

		// Fan-out all
		canvas.bind('f f', () => {
			if (!isFocusingInput()) {
				this.FanOutWindows()
			}
		})

		// Birds-eye view
		canvas.bind('b b', () => {
			if (!isFocusingInput()) {
				this.Explode()
			}
		})

		// Toggle overlay
		canvas.bind('o o', () => {
			if (!isFocusingInput()) {
				this.SetCover()
			}
		})

		// Minimise all
		canvas.bind('m m', () => {
			if (!isFocusingInput()) {
				this.MinimizeAllOnDesktop()
			}
		})

		// Expand all
		canvas.bind('x x', () => {
			if (!isFocusingInput()) {
				this.ExpandAllOnDesktop()
			}
		})

		// Closing all windows
		canvas.bind('c c', () => {
			if (!isFocusingInput()) {
				this.CloseAllOnDesktop()
			}
		})

		// Set binding for the fixed content to open the overlay
		Mousetrap(this.fixedContent.current).bind('o o', () => {
			if (!isFocusingInput()) {
				this.SetCover()
			}
		})
	}

	closeOverlayClicked(e) {
		// Ignore if the context menu was triggered on an element inside of the canvas
		// This means right-clicking on a wnidow will default to the browser's usual menu
		// This is the difference between `currentTarget` and `target`
		if (!e.target.classList.contains('windowCanvas')) {
			return
		}

		// Exit early if there is no overlay
		if (this.props.options.fixedPageContent == null) {
			return
		}

		// Close the overlay
		this.SetCover(false)
	}

	openGlobalContextMenu(e) {
		// Ignore if the context menu was triggered on an element inside of the canvas
		// This means right-clicking on a wnidow will default to the browser's usual menu
		// This is the difference between `currentTarget` and `target`
		if (!e.target.classList.contains('windowCanvas')) {
			return
		}

		// Don't show a native browser context menu
		e.preventDefault()

		// Open the context menu
		this.openGlobalContextMenuInner({
			x: e.clientX,
			y: e.clientY,
		})
	}

	openGlobalContextMenuInner({ x, y }) {
		setContextMenu({
			position: { x, y },
			width: 184,
			items: _.compact(
				_.flatten([
					// Main options - toggling birds-eye and overlay
					{
						label: 'Toggle birds-eye',
						shortcut: 'B B',
						onClick: this.Explode,
					},
					ConditionalObject(this.props.options.fixedPageContent != null, {
						label: 'Toggle overlay',
						shortcut: 'O O',
						onClick: () => {
							this.SetCover()
						},
					}),
					'---',

					// This sub-section depends on desktops being enabled but hidden
					ConditionalObject(
						window.innerWidth <= 1024 &&
							!this.props.options.disableVirtualDesktops,
						() => ({
							label: 'Switch Desktop',
							items: this.state.desktops.map(x => ({
								label: x.name ?? `Desktop ${x.key}`,
								icon: ConditionalObject(
									this.state.desktop === x.key,
									'/static/img/i8/material-outline-checkmark.svg',
								),
								onClick: () => {
									this.ChangeDesktop(x.key)
								},
							})),
						}),
					),

					// This sub-section depends on not having VD disabled
					ConditionalObject(!this.props.options.disableVirtualDesktops, () => [
						{
							label: 'Move all',
							childWidth: 160,
							items: this.state.desktops.map(x => ({
								label: x.name ?? `Desktop ${x.key}`,
								onClick: () => {
									this.MoveWindowsToDesktop(x.key)
								},
							})),
						},
						{
							label: 'Merge desktops',
							onClick: this.MergeDesktops,
						},
						'---',
					]),

					// Window size/position globals
					{
						label: 'Cascade all',
						shortcut: 'T 0',
						onClick: this.CascadeWindows,
					},
					{
						label: 'Default sizes',
						shortcut: 'T 0',
						onClick: this.DefaultSizes,
					},
					{
						label: 'Fan-out',
						hidden: true,
						shortcut: 'F F',
						onClick: this.FanOutWindows,
					},
					{
						label: 'Tile all',
						childWidth: 160,
						items: [1, 2, 3, 4].map(i => ({
							label: `Rows: ${i}`,
							shortcut: `T ${i}`,
							onClick: () => {
								this.TileWindows(i)
							},
						})),
					},
					{
						label: 'Minimise all',
						shortcut: 'M M',
						onClick: this.MinimizeAllOnDesktop,
					},

					// Close
					'---',
					{
						label: 'Close all',
						shortcut: 'C C',
						onClick: this.CloseAllOnDesktop,
					},
				] as ContextMenuItem[]),
			),
		})
	}

	getMinimizeCoords(required) {
		// Define the spacing between minimised windows
		const spacing = { x: 205, y: 32 }
		const padding = 5

		// Get the canvas size to determine boundaries
		const canvas = this.GetCanvasSize()

		// Get all minimized windows on this desktop by X/Y coord
		const windows_on_desktop = this.windowsIterable(true)
		const heights = fsmData(windows_on_desktop, {
			filter: w => w.state.isMinimized,
			sort: w => w.state.pos.y,
			map: w => w.state.pos.y,
		})
		const widths = fsmData(windows_on_desktop, {
			filter: w => w.state.isMinimized,
			sort: w => w.state.pos.x,
			map: w => w.state.pos.x,
		})

		// Find the first slot that is open starting at (padding, padding)
		let [x, y] = [padding, padding]
		const results: { x: number; y: number }[] = []
		while (x < canvas.x - spacing.x - padding) {
			while (y < canvas.y - spacing.y - padding) {
				if (!heights.includes(y) || !widths.includes(x)) {
					results.push({ x, y })
					if (results.length >= required) {
						return results
					}
				}
				y += spacing.y
			}
			x += spacing.x
			y = padding
		}

		// Nothing was found
		console.error('Could not find anywhere to put the window...', results, { x, y })
		return results
	}

	getContentFunction(properties) {
		// If a content function is explicitly defined, use that
		// Note that this shouldn't co-exist with the structured format
		if (properties.content != null) {
			if (properties.cmpt != null) {
				console.error('A `content` and `cmpt` function is defined - pick one')
			}
			return properties.content
		}

		// Return a function with a tag of the component and one property per field
		return p => {
			const base = {
				tag: properties.cmpt,
				_fields: properties.fields,
			}
			const params = {}
			_.forEach(properties.fields, k => {
				params[k] = p[k]
			})
			return _.assign(base, params)
		}
	}

	setFrontOverlay(blurring, elements) {
		this.setState({
			frontOverlayBlurring: blurring,
			frontOverlay: elements,
		})
	}

	removeFrontOverlay() {
		this.setState({
			frontOverlayBlurring: false,
			frontOverlay: null,
		})
	}

	// Public methods #

	GetCanvasSize() {
		// Assume the canvas has an effectively infinite size if on mobile
		// This is so no size/position constrictions are done
		if (this.canvasElement.current != null && window.innerWidth >= 768) {
			const rect = this.canvasElement.current.getBoundingClientRect()
			return { x: rect.width, y: rect.height }
		}
		return { x: 999999, y: 999999 }
	}

	OnResizeProc() {
		// Ensure all windows are a valid size/position
		_.map(this.windowsIterable(), w => {
			w.ConfirmValidPosSize()
		})
		timer(() => {
			this.forceUpdate()
		})
	}

	NewWindow(
		fn: NewWindowFunction,
		params?: NewWindowOptions,
		cb: (arg: WindowManagerWindow) => void = _.noop,
	) {
		// Cancel any explosion
		this.Unexplode()

		// First check to make sure the SID doesn't already exist
		const properties = fn(params ?? {})
		const clash_window = fsmData(this.windowsIterable(), {
			filter: x => x.state.sid != null && x.state.sid === properties.sid,
			takeFirst: true,
		})

		// If it does, just pulse that window instead and don't open a new one
		if (clash_window != null) {
			// Helper function to focus/pulse the window
			const focusPulse = () => {
				clash_window.Focus()
				clash_window.Pulse()
			}

			// If it's on another desktop - move to that desktop
			if (this.state.desktop !== clash_window.state.pos.d) {
				this.ChangeDesktop(clash_window.state.pos.d, () => timer(200, focusPulse))
			} else {
				timer(focusPulse)
			}

			// Cover if not already with this new window as the focus
			this.SetCover(true, clash_window.state.key)
			return
		}

		// Confirm that the window has the correct tag
		// This could be placed at the library-level, but it makes it easier to find all
		// window defintitions by doing `Ctrl + Shift + F` "tag: WindowManagerWindow"
		if (properties.tag !== WindowManagerWindow) {
			console.error('Missing `tag: WindowManagerWindow` from window definition')
		}

		// All clear. The window will open, so we need to ensure that the functions
		// are cached. This is the content function and the title function. They are
		// given as anonymous functions on the parameters but they are static and caching
		// them here will ensure that they don't cause extra re-renders
		const fn_content_key = guid()
		const fn_title_key = guid()
		this.cachedFunctions[fn_title_key] = properties.title
		this.cachedFunctions[fn_content_key] = this.getContentFunction(properties)

		// All clear - open the window
		const max_key = _.max(_.map(_.keys(this.state.windows), x => +x))
		const key = (max_key || 0) + 1
		const newWindow = _.assign({}, properties, {
			tag: WindowManagerWindow,
			title: fn_title_key,
			content: fn_content_key,
			parent: this,
			params: params ?? {},
			ref: Do(() => {
				if (this.windowRefs[key] == null) {
					this.windowRefs[key] = React.createRef()
				}
				return this.windowRefs[key]
			}),
			index: key,
			key,
		})

		// Sanitize values
		const getPos = (p, fb?) => ({
			x: p?.[0] ?? fb[0],
			y: p?.[1] ?? fb[1],
			z: this.getNextPosZ(),
			d: this.state.desktop,
		})

		const getSize = (s, fb) => ({
			x: s?.[0] ?? fb[0],
			y: s?.[1] ?? fb[1],
		})

		newWindow.preMaximMinimSize = getSize(newWindow.preMaximMinimSize, newWindow.size)
		newWindow.preMaximMinimPos = getSize(
			newWindow.preMaximMinimPos,
			newWindow.pos ?? this.getNextPosFull(),
		)
		newWindow.pos = getPos(newWindow.pos ?? this.getNextPosFull())
		newWindow.sizeDef = getSize(newWindow.sizeDef, newWindow.size)
		newWindow.size = getSize(newWindow.size, [640, 480])
		newWindow.sizeMax = getSize(newWindow.sizeMax, [99999, 99999])
		newWindow.sizeMin = getSize(newWindow.sizeMin, [240, 28])

		// Cover if not already with this new window as the focus
		this.SetCover(true, key)

		// Add the window
		this.setState(
			{
				windows: _.assign({}, this.state.windows, { [key]: newWindow }),
			},
			() => {
				this.Update()
				this.ChangeFocus(key)
				cb(this.getWindow(key))
			},
		)
	}

	CloseWindow(key) {
		// Start the closing animation
		this.state.windows[key]?.ref.current?.SetOpacity(0)

		// Remove the window after the animation delay
		timer(200, () => {
			const delta = s => ({ windows: _.omit(s.windows, [key]) })

			this.setState(delta, () => {
				// Update the focus to the top-most window on this desktop
				const new_focus = fsmData(this.windowsIterable(true), {
					sort: x => x.state.pos.z,
					reverse: true,
					takeFirst: true,
					map: x => x?.state.key ?? null,
				})

				this.ChangeFocus(new_focus, () => {
					this.Update()

					// If there are now no windows open, remove cover
					if (new_focus == null) {
						this.SetCover(false)
					}
				})
			})
		})

		// If this window was the cover focus, toggle the overlay off
		if (this.state.coveredFocus === key) {
			this.SetCover(false)
		}
	}

	CloseAllOnDesktop() {
		_.forEach(this.windowsIterable(true), x => {
			this.CloseWindow(x.state.key)
		})
	}

	CompactZPosition() {
		let highest_z = 0
		fsmData(this.windowsIterable(), {
			sort: x => x.state.pos.z,
			map: (x, i) => {
				highest_z = i + 1
				x.update(() => ({
					pos: {
						z: highest_z,
					},
				}))
			},
		})
		this.generatedPosZ = highest_z
	}

	ChangeFocus(key, cb?) {
		// Change focus delta - null if nothing has changed
		// Also clears the cover focus if it's changed
		// Apply the state change with post-cleanup
		this.setState(
			s => {
				if (key === s.focused) {
					return null
				}
				const d = CopyState(s)
				d.focused = key
				if (key !== s.coveredFocus) {
					d.coveredFocus = null
				}
				return d
			},
			() => {
				this.windowRefs[key]?.current?.update(() => ({
					pos: { z: this.getNextPosZ() },
				}))
				this.windowRefs[key]?.current?.onFocus()
				this.UpdateTitle()
				;(cb ?? _.noop)()
			},
		)
	}

	UpdateTitle() {
		// Update the document title
		// Run it through a custom override function
		let title
		title = this.state.covered
			? this.windowRefs[this.state.focused]?.current?.getTitle()
			: this.fixedContentInner.current?.getTitle?.()
		if (title == null) {
			title = window.rootData.Title
		}
		title = (this.props.options.getTitle ?? _.identity)(title)
		document.title = `${title} | TriOnline`
	}

	Serialize(): WindowManagerSerialized {
		// Return the saved data if it exists - this means the window manager is gone
		const windows = this.windowsIterable()
		return (
			this.savedSerialisedData ?? {
				desktop: this.state.desktop,
				desktops: this.state.desktops,
				windows: fsmData(windows, {
					sort: x => x.state.pos.z,
					map: x => ({
						func: x.state.func,
						params: x.state.params,
						pos: x.state.pos,
						size: x.state.size,
						sizeDef: x.state.sizeDef,
						preMaximMinimSize: x.state.preMaximMinimSize,
						preMaximMinimPos: x.state.preMaximMinimPos,
						isMinimized: x.state.isMinimized,
						isMaximized: x.state.isMaximized,
					}),
				}),
				covered: Do(() => {
					// A cover with no windows is not kept through a refresh
					if (_.size(windows) === 0) {
						return false
					}
					return this.state.covered
				}),
			}
		)
	}

	Deserialize(state) {
		// Parse the serialized string if it's not already
		if (typeof state === 'string') {
			state = JSON.parse(state)
		}

		// Return the new windows object
		return {
			windows: this.deserializeWindows(state?.windows ?? []),
			covered: state?.covered ?? false,
			desktops: state?.desktops || [
				{
					key: 1,
					index: 1,
					name: null,
				},
			],
			desktop: Do(() => {
				// Force to desktop 1 if not using virtual desktops
				if (this.props.options.disableVirtualDesktops) {
					return 1
				}
				return state?.desktop ?? 1
			}),
		}
	}

	UpdateProc() {
		const save_state = this.Serialize()
		;(this.props.options.onSave ?? _.noop)(save_state)
	}

	Update() {
		this.refreshDesktops()
		this.UpdateThrottled()
		this.props.toolbar?.forceUpdate()
	}

	SetCover(x?, focus_key?) {
		this.setState((s: any) => {
			if (x === s.covered) {
				return null
			}
			return {
				covered: x ?? !s.covered,
				coveredFocus: focus_key ?? null,
			}
		})
		this.UpdateThrottled()
	}

	Explode() {
		// TODO - optimise so it doesn't re-render inside of each window
		// TODO - centre-align each row in birdseye view - one day

		// If uncovered, cover
		this.SetCover(true)

		// If already exploded, toggle it off
		if (this.state.exploded != null) {
			this.Unexplode()
			return
		}

		// Divide the height of the screen up into 2 sections, 10px from the top
		// and then ((100% - 30px) / 2) for each window's max-height.
		// Keep aspect ratios and don't expand the size of any window larger than
		// its current natural size.

		// Get the canvas size
		const canvas = this.GetCanvasSize()

		// Define the padding
		const padding_x = 10
		const padding_y = 10
		const peek_offset = 30

		// Get the row height and formula for each row's offset
		let row_height = Math.floor((canvas.y - padding_y * 2 - peek_offset) / 2)
		row_height = clamp(row_height, 200, 400)
		const getRowOffset = row => row * (row_height + padding_y) + padding_y

		// Get each row's available width
		const row_width = canvas.x - padding_x * 2

		// Keep track of which row number we're on and how many horizontal pixels
		// have been used on the row
		let current_row = 0
		let current_pixels = padding_x

		// Loop over each window (most recently used first) and tile them,
		// getting the size and position each will need to take
		const coords = fsmData(this.windowsIterable(true), {
			sort: x => 10000 - x.state.pos.z,
			map: x => {
				// Get the multiplier to fit this window on the row - must be <= 1
				let multiplier = row_height / x.state.size.y
				multiplier = Math.min(multiplier, 1)

				// Break to a new row if we're going to overflow
				const horizontal_used = x.state.size.x * multiplier
				if (current_pixels + horizontal_used > row_width) {
					current_row += 1
					current_pixels = padding_x
				}

				// Update the number of horizontal pixels used on the row
				const horizontal_offset = current_pixels
				current_pixels += horizontal_used + padding_x

				return {
					wobj: x,
					multiplier,
					size: {
						x: horizontal_used,
						y: x.state.size.y * multiplier,
					},
					pos: {
						x: horizontal_offset,
						y: getRowOffset(current_row),
					},
					preMaximMinimPos: {
						x: horizontal_offset,
						y: getRowOffset(current_row),
					},
					offset: {
						x: horizontal_offset - x.state.pos.x,
						y: getRowOffset(current_row) - x.state.pos.y,
					},
				}
			},
		})

		// Move them
		coords.forEach(w => {
			w.wobj.setState({
				transform: Do(() => {
					const [x, y, m] = [w.offset.x, w.offset.y, w.multiplier]
					return `translate(${x}px, ${y}px) scale(${m})`
				}),
			})
		})

		// Mark the window manager as exploded
		const delta = {
			exploded: coords.map(w => ({
				o: w.wobj,
				x: w.pos.x,
				y: w.pos.y,
				w: w.size.x,
				h: w.size.y,
			})),
		}
		this.setState(delta, () => this.props.toolbar?.forceUpdate())
	}

	UnexplodeNoCB() {
		this.Unexplode()
	}

	Unexplode(cb?) {
		// Skip if we're not exploded
		if (this.state.exploded == null) {
			;(cb ?? _.noop)()
			return
		}

		// Remove all transforms from winodws
		_.forEach(this.windowsIterable(), wobj => {
			wobj.setState({ transform: null })
			wobj.onResizeEvent()
		})

		// Set the exploded state to null
		this.setState({ exploded: null }, () => {
			// Scroll back to the top and execute the callback function
			this.canvasElement.current.scrollTop = 0
			this.props.toolbar?.forceUpdate()
			;(cb ?? _.noop)()
		})
	}

	ChangeDesktop(key, cb?) {
		// Update the state
		window.lastForcedReactUpdate = _.now()
		this.setState({ desktop: key }, () =>
			// Unexplode (if necessary)
			{
				this.Unexplode(() => {
					// Save changes and ensure toolbar is updated
					this.Update()
					;(cb ?? _.noop)()
				})
			},
		)
	}

	MoveWindowsToDesktop(key) {
		const windows = this.windowsIterable(true)
		this.ChangeDesktop(key)
		_.forEach(windows, w => {
			w.update(() => ({ pos: { d: key } }))
		})
	}

	MinimizeAllOnDesktop() {
		const windowsToMinimise = _.filter(
			this.windowsIterable(true),
			w => !w.state.isMinimized,
		)
		const coords = this.getMinimizeCoords(windowsToMinimise.length)
		windowsToMinimise.forEach((w, i) => {
			w.minimize(coords[i])
		})
	}

	ExpandAllOnDesktop() {
		_.forEach(this.windowsIterable(true), w => {
			if (!w.state.isMaximized) {
				w.maximizeRestore()
			}
		})
	}

	MergeDesktops() {
		// Move all windows to the currently-selected desktop
		_.forEach(this.windowsIterable(), w => {
			w.update(() => ({ pos: { d: this.state.desktop } }))
		})

		// Remove all desktops except this one
		const delta = s => ({
			desktops: s.desktops.filter(d => d.key === this.state.desktop),
		})

		this.setState(delta, () => {
			this.Update()
		})
	}

	CascadeWindows() {
		let currX = 10
		let currY = 10
		const dx = 60
		const dy = 26
		fsmData(this.windowsIterable(true), {
			sort: w => w.state.pos.z,
			map: w => {
				const [x, y] = [currX + 0, currY + 0]
				w.update(() => ({
					isMinimized: false,
					isMaximized: false,
					pos: { x, y },
					preMaximMinimPos: { x, y },
					size: w.state.sizeDef,
				}))
				currX += dx
				currY += dy
			},
		})
	}

	DefaultSizes() {
		_.forEach(this.windowsIterable(true), w => {
			if (!w.state.isMinimized) {
				w.update(() => ({ size: w.state.sizeDef }))
			}
		})
	}

	TileWindows(rows) {
		// Sort windows so that the most recently-used is at the start of the array
		const windows = _.sortBy(
			this.windowsIterable(true),
			(x: any) => 10000 - x.state.pos.z,
		)

		// Get the dimensions of the grid
		const cols = Math.ceil(windows.length / rows)

		// Get dimensions of each cell in the grid
		const canvas = this.GetCanvasSize()
		const box_height = canvas.y / rows
		const box_width = canvas.x / cols

		// Apply the new size and position to each window
		windows.forEach((wobj, index) => {
			const row_index = index % rows
			const col_index = Math.floor(index / rows)
			wobj.update(() => ({
				isMinimized: false,
				isMaximized: false,
				size: {
					x: Math.round(box_width),
					y: Math.round(box_height),
				},
				pos: {
					x: Math.round(box_width * col_index),
					y: Math.round(box_height * row_index),
				},
				preMaximMinimPos: {
					x: Math.round(box_width * col_index),
					y: Math.round(box_height * row_index),
				},
			}))
		})
	}

	FanOutWindows() {
		// TODO - once done, also re-enable the context menu item
		console.log('Fanning-out windows')
	}

	ChangeDesktopName(key, index, name) {
		const delta = s => ({
			desktops: s.desktops.map(desktop => {
				if (desktop.key === key) {
					return {
						key,
						index,
						name: name || null,
					}
				}
				return desktop
			}),
		})

		this.setState(delta, () => {
			this.Update()
		})
	}

	DeleteDesktop(key) {
		// Close all windows on the desktop
		fsmData(this.windowsIterable(), {
			filter: x => x.state.pos.d === key,
			map: x => {
				x.Close()
			},
		})

		// Remove the desktop
		const delta = s => ({
			desktops: s.desktops.filter(d => d.key !== key),
		})

		this.setState(delta, () => {
			this.Update()
		})
	}
}

export class WindowManagerToolbar extends React.Component<{
	wmanager: WindowManagerUI
}> {
	vdesktops: React.RefObject<HTMLDivElement>
	sortableObj: any

	constructor(props) {
		super(props)
		Bindings(this, [this.openGlobalContextMenu])
		this.vdesktops = React.createRef()
		this.sortableObj = null
	}

	override componentDidMount() {
		if (this.vdesktops.current == null) {
			return
		}
		this.sortableObj = Sortable.create(this.vdesktops.current, {
			animation: 200,
			direction: 'horizontal',
			onEnd: () =>
				// TODO
				{
					console.warn('Sorting desktops not yet implemented')
				},
		})
	}

	override componentWillUnmount() {
		this.sortableObj?.destroy()
	}

	override render() {
		return j2r({
			cl: 'window-manager-toolbar',
			children: [
				// Button to toggle the overlay - only shows if there's fixed content
				ConditionalObject(
					window.innerWidth > 1024 &&
						this.props.wmanager.props.options.fixedPageContent != null,
					{
						tag: J2rTooltip,
						title: 'Toggle overlays',
						key: 'toggle-overlay',
						id: 'toggle-overlay',
						content: [
							{
								tag: 'span',
								key: 'img-outer',
								children: [
									{
										tag: 'img',
										key: 'bot',
										cl: 'overlay-piece bot',
										src: '/static/img/svg/overlay-piece.svg',
									},
									{
										tag: 'img',
										key: 'mid',
										cl: 'overlay-piece mid',
										src: '/static/img/svg/overlay-piece.svg',
									},
									{
										tag: 'img',
										key: 'top',
										cl: 'overlay-piece top',
										src: '/static/img/svg/overlay-piece.svg',
									},
								],
							},
						],
						onContextMenu: this.openGlobalContextMenu,
						onClick: () => {
							this.props.wmanager.SetCover()
						},
					},
				),

				// Virtual desktop options - can be hidden
				ConditionalObject(
					window.innerWidth > 1024 &&
						!this.props.wmanager.props.options.disableVirtualDesktops,
					{
						key: 'vdesktops',
						id: 'vdesktops',
						ref: this.vdesktops,
						children: fsmData(this.props.wmanager.state.desktops ?? [], {
							sort: x => x.index,
							map: x => this.buildDesktopIndicator(x),
						}),
					},
				),

				// Birds-eye view
				ConditionalObject(
					!this.props.wmanager.props.options.disableBirdsEyeButton,
					{
						tag: J2rTooltip,
						title: 'Birds-Eye View',
						key: 'birds-eye',
						id: 'btnBirdsEye',
						cl: Do(() => {
							let C = 'birds-eye-button'
							if (this.props.wmanager.state.exploded != null) {
								C += ' exploded'
							}
							return C
						}),
						content: [
							{
								tag: 'span',
								key: 'img-outer',
								children: [
									{
										tag: 'img',
										key: 'bl',
										cl: 'explode-part bl fade-in',
										src: '/static/img/svg/explode-part.svg',
									},
									{
										tag: 'img',
										key: 'tr',
										cl: 'explode-part tr fade-in',
										src: '/static/img/svg/explode-part.svg',
									},
									{
										tag: 'img',
										key: 'lhs',
										cl: 'explode-part lhs',
										src: '/static/img/svg/explode-part.svg',
									},
									{
										tag: 'img',
										key: 'rhs',
										cl: 'explode-part rhs',
										src: '/static/img/svg/explode-part.svg',
									},
								],
								onMouseEnter: () => {
									this.props.wmanager.setState({
										hoverBirdsEye: true,
									})
								},
								onMouseLeave: () => {
									this.props.wmanager.setState({
										hoverBirdsEye: false,
									})
								},
							},
						],
						onContextMenu: this.openGlobalContextMenu,
						onClick: e => {
							if (window.innerWidth <= 1024) {
								this.openGlobalContextMenu(e)
							} else {
								this.props.wmanager.Explode()
							}
						},
					},
				),
			],
		})
	}

	buildDesktopIndicator(desktop) {
		return {
			tag: J2rTooltip,
			key: desktop.key,
			cl: Do(() => {
				let C = 'vdIndicator'
				if (this.props.wmanager.state.desktop === desktop.key) {
					C += ' selected'
				}
				return C
			}),
			title: `Open Virtual Desktop #${desktop.index}`,
			options: { delay: 2000 },
			content: [
				{
					tag: 'span',
					key: 'label',
					cl: 'label',
					text: desktop.name ?? desktop.index,
				},
				{
					tag: 'span',
					key: 'count',
					cl: 'count',
					text: Do(() => {
						const ws = this.props.wmanager.windowsIterable()
						const count = _.size(
							_.filter(ws, w => w.state.pos.d === desktop.key),
						)
						return String(count || '')
					}),
				},
			],
			onClick: () => {
				this.props.wmanager.ChangeDesktop(desktop.key)
			},
			onContextMenu: e => {
				e.preventDefault()
				setContextMenu({
					position: {
						x: e.clientX,
						y: e.clientY,
					},
					width: 120,
					items: [
						{
							label: 'Rename',
							icon: '/static/img/edit.png',
							onClick: () => {
								this.renameDesktop(desktop.key, desktop.name)
							},
						},
						{
							label: 'Delete',
							icon: '/static/img/cross.png',
							onClick: () => {
								this.deleteDesktop(desktop.key)
							},
						},
					],
				})
			},
		}
	}

	openGlobalContextMenu(e) {
		// Don't show a native browser context menu
		// Open the context menu
		e.preventDefault()
		this.props.wmanager.openGlobalContextMenuInner({
			x: e.clientX,
			y: e.clientY,
		})
	}

	renameDesktop(key: number, existing_name: string) {
		openFlyoutForm({
			title: 'Edit Desktop Name',
			size: [270, null],
			lblWidth: 50,
			buttonWidth: 110,
			fields: {
				name: FormType.Text({
					lbl: 'Name',
					def: () => existing_name ?? '',
					placeholder: `Desktop #${key} name`,
					maxLength: 20,
				}),
			},
			formPrompt: 'The user is entering the name for a virtual desktop',
			onSave: (model, cb) => {
				// Get the index of this desktop
				const index = fsmData(this.props.wmanager.state.desktops ?? [], {
					filter: x => x.key === key,
					takeFirst: true,
					map: x => x?.index,
				})

				// Change it
				this.props.wmanager.ChangeDesktopName(key, index, model.name)
				cb(true)
			},
		})
	}

	deleteDesktop(key) {
		Alerts.Confirm({
			msg: `\
Deleting this desktop will close all its windows and remove any custom \
name. Are you sure?\
`,
			yes: () => {
				this.props.wmanager.DeleteDesktop(key)
			},
		})
	}
}

export class WindowManagerWindow extends React.PureComponent<
	{
		index: number
		func?: Function
		params?: { [key: string]: any }
		sid?: string
		size?: WindowSize
		sizeMax?: WindowSize
		sizeMin?: WindowSize
		preMaximMinimSize?: WindowSize
		pos?: WindowPosition
		preMaximMinimPos?: WindowPosition
		isMaximized?: boolean
		isMinimized?: boolean
		onResize?: Function
		onFocus?: Function
		sizeDef?: any
		isFocused?: boolean
		cl?: string
		parent?: any
		isLoading: boolean
		content?: any
		title?: string
		fields?: any
	},
	{
		// Basic
		key: string
		func: Function
		params: { [key: string]: any }
		sid: string
		// Sizes
		size: WindowSize
		sizeMax: WindowSize
		sizeMin: WindowSize
		sizeDef: WindowSize
		preMaximMinimSize: WindowSize
		// Position
		pos: WindowPosition
		preMaximMinimPos: WindowPositionBasic
		// States
		thrownError: null
		isDragging: boolean
		dragStartCoords: WindowPositionBasic
		titlebarClickTimestamp: number
		resizing: boolean
		opacity?: number
		isMaximized: boolean
		isMinimized: boolean
		transform: string
		// Events
		onResize?: Function
		onFocus?: Function
	}
> {
	rootEl: React.RefObject<HTMLDivElement>
	contentComponent: React.RefObject<any>
	checkNotNeeded: boolean
	onResizeEvent: _.DebouncedFunc<() => any>

	constructor(props) {
		super(props)
		Bindings(this, [
			this.Close,
			this.ConfirmValidPosSize,
			this.contextMenuTitlebar,
			this.dragStart,
			this.dragEvent,
			this.dragEnd,
			this.Focus,
			this.GetContent,
			this.kebabClick,
			this.maximizeRestore,
			this.minimize,
			this.openKebabMenu,
			this.Pulse,
			this.setToDefaultSize,
			this.snap,
			this.update,
			this.UpdateCreationState,
			this.UpdateSID,
			this.UpdatePositionSize,
		])

		// Refs
		this.rootEl = React.createRef()
		this.contentComponent = React.createRef()

		// Timing control for duplicate geometry checks
		this.checkNotNeeded = false

		// Throttled resize event
		this.onResizeEvent = _.debounce(this.onResizeEventProc, 100, {
			trailing: true,
			leading: false,
		})

		// Create the initial state
		const state = {
			// Basic
			key: this.props.index,
			func: this.props.func,
			params: this.props.params ?? {},
			sid: this.props.sid,
			// Sizes
			size: this.props.size,
			sizeMax: this.props.sizeMax,
			sizeMin: this.props.sizeMin,
			sizeDef: this.props.sizeDef,
			preMaximMinimSize: this.props.preMaximMinimSize ?? this.props.size,
			// Position
			pos: this.props.pos,
			preMaximMinimPos: this.props.preMaximMinimPos ?? this.props.pos,
			// States
			thrownError: null,
			isDragging: false,
			dragStartCoords: null,
			titlebarClickTimestamp: -1,
			resizing: false,
			opacity: 0,
			isMaximized: this.props.isMaximized ?? false,
			isMinimized: this.props.isMinimized ?? false,
			transform: null,
			// Events
			onResize: this.props.onResize ?? null,
			onFocus: this.props.onFocus ?? null,
		}

		// Generated based on state above
		state.preMaximMinimSize = this.props.preMaximMinimSize ?? state.size
		state.preMaximMinimPos = this.props.preMaximMinimPos ?? state.pos
		state.sizeDef = this.props.sizeDef ?? state.size

		// Validate
		this.state = this.validatePosSize(state)
	}

	static getDerivedStateFromError(error) {
		return { thrownError: error }
	}

	override componentDidCatch(err, info) {
		console.error(err)
		console.warn(info)
	}

	override componentDidMount() {
		// Create the resizeable and dragable events
		this.applyResizer()

		// Set the opacity to 1 so it fades in
		this.SetOpacity(null)
	}

	applyResizer() {
		const $el = $(this.rootEl.current)
		$el.resizable({
			containment: 'parent',
			handles: 'n, e, s, w, ne, se, sw, nw',
			minHeight: this.state.sizeMin.y,
			minWidth: this.state.sizeMin.x,
			maxHeight: this.state.sizeMax.y,
			maxWidth: this.state.sizeMax.x,
			grid: [1, 1],
			start: () => {
				this.Focus()
				this.update(() => ({ resizing: true }))
			},
			resize: () => this.onResizeEvent(),
			stop: () => {
				const results = {
					left: $el.css('left'),
					top: $el.css('top'),
					width: $el.width(),
					height: $el.height(),
				}
				this.update(() => ({
					resizing: false,
					pos: {
						x: parseInt(results.left),
						y: parseInt(results.top),
					},
					preMaximMinimPos: {
						x: parseInt(results.left),
						y: parseInt(results.top),
					},
					size: {
						x: Math.round(results.width),
						y: Math.round(results.height),
					},
				}))
				this.onFocus()
			},
		})
	}

	override render() {
		return (
			<div
				className={Do(() => {
					let C = this.props.cl ?? ''
					C += ' window ui-resizable react'
					if (this.props.isFocused) {
						C += ' focus'
					}
					if (this.state.resizing) {
						C += ' resizing'
					}
					if (this.state.isDragging) {
						C += ' dragging'
					}
					if (this.state.isMinimized) {
						C += ' minim'
					}
					if (this.state.isMaximized) {
						C += ' maxim'
					}
					if (this.isHidingWindow()) {
						C += ' hide'
					}
					return C
				})}
				ref={this.rootEl}
				tabIndex={1}
				style={Do(() => {
					const hidden = this.isHidingWindow()
					const styles = Do(() => {
						if (window.innerWidth > 490) {
							return {
								top: this.state.pos.y,
								left: this.state.pos.x,
								height: this.state.size.y,
								width: this.state.size.x,
							}
						}
						return {
							top: 0,
							left: 0,
							height: '100%',
							width: '100%',
						}
					})
					return _.assign(styles, {
						zIndex: this.state.pos.z,
						transform: this.state.transform,
						opacity: !hidden ? this.state.opacity : undefined,
					})
				})}
				onClick={this.Focus}
			>
				{this.buildTitlebar()}
				{this.buildWindowContent()}
				{this.buildDraggingModal()}
			</div>
		)
	}

	isHidingWindow() {
		// If the window is on another desktop and it's not mobile view
		// Hide the window completely
		const is_mobile = window.innerWidth < 768
		const on_another_desktop = this.props.parent.state.desktop !== this.state.pos.d
		if (!is_mobile && on_another_desktop) {
			return true
		}

		// Otherwise show the window
		return false
	}

	isHidingContent() {
		const is_mobile = window.innerWidth < 768
		const on_another_desktop = this.props.parent.state.desktop !== this.state.pos.d

		// Minimized windows don't render content
		if (this.state.isMinimized) {
			return true
		}

		// In mobile view, only the ocused window can show content
		if (is_mobile && !this.props.isFocused) {
			return true
		}

		// If it's not mobile view and it's on another desktop, hide as well
		if (!is_mobile && on_another_desktop) {
			return true
		}

		// No tests failed, the content should render
		return false
	}

	onResizeEventProc() {
		this.contentComponent.current?.Update()
		;(this.state.onResize ?? _.noop)(this)
	}

	buildWindowContent() {
		return (
			<div key="content" className="content">
				{/* TODO - Minimum sizing with scrollbars for smaller devices */}
				{Do(() => {
					// Check if the content is not to be rendered
					if (this.isHidingContent()) {
						return null
					}

					// Show a loading spinner if the parent says it's not initialised yet
					if (this.props.isLoading) {
						return (
							<>
								<LoadingSpinnerLarge />
							</>
						)
					}

					// Check if an error was thrown
					if (this.state.thrownError) {
						return (
							<>
								<div className="content">
									<div className="error_msg_2 cntr">
										Unexpected error - re-open or refresh?
									</div>
								</div>
							</>
						)
					}

					// Display the content - wrapped in a caching component
					const content =
						this.props.parent.cachedFunctions[this.props.content] ?? _.noop
					const contentParams = _.assign(
						{},
						content(this.state.params ?? {}, this),
						{
							window: this,
							key: 'content-inner',
							ref: this.contentComponent,
							lastForcedReactUpdate: window.lastForcedReactUpdate,
						},
					)
					contentParams.tagProxy = contentParams.tag
					contentParams.tag = WindowManagerWindowContentWrapper
					return <>{j2r(contentParams)}</>
				})}
			</div>
		)
	}

	buildTitlebar() {
		const title = this.getTitle()
		return (
			<div
				key="titlebar"
				className="titlebar"
				onContextMenu={this.contextMenuTitlebar}
				onMouseDown={this.dragStart}
				onTouchStart={this.dragStart}
				onTouchMove={this.dragEvent}
				onTouchEnd={this.dragEnd}
			>
				{/* Title text */}
				<div className="title" title={title}>
					{title}
				</div>
				{/* Action buttons in the top-right corner */}
				<HelpTooltip title="Options">
					<div
						className="kebab icon"
						onClick={this.kebabClick}
						onMouseDown={e => {
							e.stopPropagation()
						}}
					>
						<img src="/static/img/svg/wmanage/kebab.svg" />
					</div>
				</HelpTooltip>
				<HelpTooltip title="Minimize Window">
					<div
						className="minim icon"
						onMouseDown={e => {
							e.stopPropagation()
						}}
						onClick={() => {
							this.minimize()
						}}
					>
						<img src="/static/img/svg/wmanage/minimize.svg" />
					</div>
				</HelpTooltip>
				<HelpTooltip title="Maximize Window">
					<div
						className="maxim icon"
						onMouseDown={e => {
							e.stopPropagation()
						}}
						onClick={() => {
							this.maximizeRestore()
							this.onFocus()
						}}
					>
						<img
							src={
								this.state.isMaximized
									? '/static/img/svg/wmanage/restore.svg'
									: '/static/img/svg/wmanage/maximize.svg'
							}
						/>
					</div>
				</HelpTooltip>
				<HelpTooltip title="Close Window">
					<div
						className="close icon"
						onMouseDown={e => {
							e.stopPropagation()
						}}
						onClick={this.Close}
					>
						<img src="/static/img/svg/wmanage/cross.svg" />
					</div>
				</HelpTooltip>
			</div>
		)
	}

	dragStart(e) {
		e.stopPropagation()

		// Disable dragging if it's mobile
		if (window.innerWidth < 768) {
			return
		}

		// If this isn't a left-click or a touch, we don't care
		if (e.button !== 0 && e.touches == null) {
			return
		}

		// Check if this is a double-click - another click within 200ms
		const diff = Date.now() - this.state.titlebarClickTimestamp
		if (diff < 500) {
			this.maximizeRestore()
			this.onFocus()
			return
		}

		// Otherwise enter dragging mode and focus the window
		this.Focus()
		const x = e.clientX ?? e.changedTouches[0]?.clientX
		const y = e.clientY ?? e.changedTouches[0]?.clientY
		this.setState({
			isDragging: true,
			dragStartCoords: { x, y },
			titlebarClickTimestamp: Date.now(),
		})

		// Add global mouse-up event listener
		window.addEventListener('mouseup', this.dragEnd)
		window.addEventListener('mousemove', this.dragEvent)
	}

	dragEvent(e) {
		e.stopPropagation()

		if (this.state.dragStartCoords == null) {
			return
		}

		// Move the window
		const x = e.clientX ?? e.changedTouches[0]?.clientX
		const y = e.clientY ?? e.changedTouches[0]?.clientY
		const dx = x - this.state.dragStartCoords.x
		const dy = y - this.state.dragStartCoords.y
		const nx = this.state.pos.x + dx
		const ny = this.state.pos.y + dy
		if (this.rootEl.current != null) {
			this.rootEl.current.style.left = `${nx}px`
			this.rootEl.current.style.top = `${ny}px`
		}
	}

	dragEnd(e) {
		e.stopPropagation()

		// Cancel temporary event handlers
		window.removeEventListener('mouseup', this.dragEnd)
		window.removeEventListener('mousemove', this.dragEvent)

		// Get the new position
		const x = e.clientX ?? e.changedTouches[0]?.clientX
		const y = e.clientY ?? e.changedTouches[0]?.clientY
		const new_pos = {
			x: this.state.pos.x + x - this.state.dragStartCoords.x,
			y: this.state.pos.y + y - this.state.dragStartCoords.y,
		}

		// If it's moved more than 5 pixels, disable the double-click
		let newTs = this.state.titlebarClickTimestamp
		const Dx = (new_pos.x - this.state.pos.x) ** 2
		const Dy = (new_pos.y - this.state.pos.y) ** 2
		const distance = Math.sqrt(Dx + Dy)
		if (distance > 5) {
			newTs = -1
		}

		// Reset all the dragging state info
		if (this.rootEl.current != null) {
			this.rootEl.current.style.transform = null
		}

		// Save the new position
		this.update(() => ({
			isDragging: false,
			dragStartCoords: null,
			titlebarClickTimestamp: newTs,
			resizing: false,
			pos: new_pos,
			preMaximMinimPos: new_pos,
		}))

		// Focus
		this.Focus()
	}

	buildDraggingModal() {
		return (
			<Modal key="dragging-modal">
				<CJSX cond={this.state.isDragging}>
					<div
						className="dragging-modal"
						onMouseMove={this.dragEvent}
						onTouchMove={this.dragEvent}
						onMouseUp={this.dragEnd}
						onTouchEnd={this.dragEnd}
					/>
				</CJSX>
			</Modal>
		)
	}

	update(deltaFn?, cb?) {
		// Ensure that the delta is a function
		if (!_.isFunction(deltaFn)) {
			console.error('Invalid delta for window `update`:', deltaFn)
			return
		}

		// Get the new state object by recursively overriding with the new delta
		// Get the validated values
		const deltaFnWrapped = s => {
			// Unpack the diff from the supplied function
			const delta = deltaFn(s)

			// Flesh out the nested object state
			// For each of the nested state objects defined, ensure all keys
			// are present, falling back to the existing state value if not supplied
			if (delta.size != null) {
				delta.size = {
					x: delta.size.x ?? s.size.x,
					y: delta.size.y ?? s.size.y,
				}
			}
			if (delta.sizeMax != null) {
				delta.sizeMax = {
					x: delta.sizeMax.x ?? s.sizeMax.x,
					y: delta.sizeMax.y ?? s.sizeMax.y,
				}
			}
			if (delta.sizeMin != null) {
				delta.sizeMin = {
					x: delta.sizeMin.x ?? s.sizeMin.x,
					y: delta.sizeMin.y ?? s.sizeMin.y,
				}
			}
			if (delta.sizeDef != null) {
				delta.sizeDef = {
					x: delta.sizeDef.x ?? s.sizeDef.x,
					y: delta.sizeDef.y ?? s.sizeDef.y,
				}
			}
			if (delta.pos != null) {
				delta.pos = {
					x: delta.pos.x ?? s.pos.x,
					y: delta.pos.y ?? s.pos.y,
					z: delta.pos.z ?? s.pos.z,
					d: delta.pos.d ?? s.pos.d,
				}
			}

			// Remove undefined nodes
			if (delta.pos == null) {
				delete delta.pos
			}
			if (delta.size == null) {
				delete delta.size
			}
			if (delta.sizeMin == null) {
				delete delta.sizeMin
			}
			if (delta.sizeMax == null) {
				delete delta.sizeMax
			}
			if (delta.sizeDef == null) {
				delete delta.sizeDef
			}

			// Note whether the size has changed (for resize event)
			// If the size has changed, call the resize event
			const has_resized = delta.size != null && !_.isEqual(delta.size, s.size)
			if (has_resized) {
				this.onResizeEvent()
				timer(250, () => this.onResizeEvent())
			}

			// Remove any nodes in the diff that are actually unchanged
			const final_diff = {}
			_.keys(objDiff(delta, s)).forEach(k => {
				final_diff[k] = delta[k]
			})

			// Return the final diff
			return final_diff
		}

		// Ensure that we check the geometry after this change
		this.checkNotNeeded = false

		// Update the state
		this.setState(deltaFnWrapped, () => {
			// Verify the geometry - throttled via `@checkNotNeeded`
			this.ConfirmValidPosSize()

			// Run the custom callback event
			;(cb ?? _.noop)()

			// Update the data model on the parent window manager
			this.props.parent.Update()
		})
	}

	validatePosSize(state, skip_max_check?) {
		if (skip_max_check == null) {
			skip_max_check = false
		}
		const S = _.cloneDeep(state)

		// Validate the position and size - start by getting default values
		if (S.pos == null) {
			S.pos = { x: null, y: null, z: null, d: null }
		}
		if (S.size == null) {
			S.size = { x: null, y: null }
		}
		if (S.pos.x == null) {
			S.pos.x = this.state.pos.x
		}
		if (S.pos.y == null) {
			S.pos.y = this.state.pos.y
		}
		if (S.pos.z == null) {
			S.pos.z = this.state.pos.z
		}
		if (S.pos.d == null) {
			S.pos.d = this.state.pos.d
		}
		if (S.size.x == null) {
			S.size.x = this.state.sizeDef.x
		}
		if (S.size.y == null) {
			S.size.y = this.state.sizeDef.y
		}

		// Get the size of the canvas
		const canvas = this.props.parent.GetCanvasSize()

		// Ensure the window dimensions don't exceed the canvas dimensions
		if (S.size.x > canvas.x) {
			S.size.x = canvas.x
		}
		if (S.size.y > canvas.y) {
			S.size.y = canvas.y
		}

		// Ensure the window is smaller than its max size
		if (S.size.x > S.sizeMax.x) {
			S.size.x = S.sizeMax.x
		}
		if (S.size.y > S.sizeMax.y) {
			S.size.y = S.sizeMax.y
		}

		// Ensure the window is larger than its min size - unless minimized
		if (!S.isMinimized) {
			if (S.size.x < S.sizeMin.x) {
				S.size.x = S.sizeMin.x
			}
			if (S.size.y < S.sizeMin.y) {
				S.size.y = S.sizeMin.y
			}
		}

		// If it's off-screen underneath, try to move it up/left
		if (S.pos.x + S.size.x > canvas.x) {
			S.pos.x = canvas.x - S.size.x
		}
		if (S.pos.y + S.size.y > canvas.y) {
			S.pos.y = canvas.y - S.size.y
		}

		// If the top left coords are below zero, cap it
		if (S.pos.x < 0) {
			S.pos.x = 0
		}
		if (S.pos.y < 0) {
			S.pos.y = 0
		}

		// Check if the window is maximised
		if (!skip_max_check) {
			let hypothetical_state = _.assign({}, S, { size: S.sizeMax })
			hypothetical_state = this.validatePosSize(hypothetical_state, true)
			S.isMaximized = _.isEqual(S.size, hypothetical_state.size) && !S.isMinimized
		}

		// Return the validated position and size
		return S
	}

	maximizeRestore() {
		// If it's not currently maximized nor minimized, maximize
		if (!this.state.isMaximized && !this.state.isMinimized) {
			this.update(s => ({
				isMinimized: false,
				isMaximized: true,
				size: s.sizeMax,
				pos: s.pos,
				preMaximMinimSize: !s.isMinimized ? s.size : undefined,
				preMaximMinimPos: s.pos,
			}))

			// Otherwise, restore back to original size and position
		} else {
			this.update(s => ({
				isMinimized: false,
				isMaximized: false,
				size: Do(() => {
					// If the pre-maxim size is the same, use default
					if (_.isEqual(s.preMaximMinimSize, s.size)) {
						return s.sizeDef
					}
					return s.preMaximMinimSize ?? s.sizeDef
				}),
				pos: _.assign({}, s.preMaximMinimPos ?? s.pos, {
					z: s.pos.z,
					d: s.pos.d,
				}),
				preMaximMinimSize: !s.isMinimized ? s.size : undefined,
				preMaximMinimPos: s.pos,
			}))
		}
	}

	minimize(pos?) {
		// Don't allow this to happen again if already minimized
		if (this.state.isMinimized) {
			return
		}

		// Run the update function
		this.update(s => ({
			isMinimized: !s.isMinimized,
			isMaximized: false,
			size: {
				x: 200,
				y: 28,
			},
			pos: Do(() => {
				pos = pos ?? this.props.parent.getMinimizeCoords(1)[0]
				return _.assign({}, pos, {
					z: s.pos.z,
					d: s.pos.d,
				})
			}),
			preMaximMinimSize: !s.isMaximized ? s.size : undefined,
			preMaximMinimPos: s.pos,
		}))
	}

	setToDefaultSize() {
		this.update(() => ({ size: this.state.sizeDef }))
	}

	kebabClick() {
		// Get the position of the kebab icon
		const el = this.rootEl.current.querySelector('.kebab.icon')
		const rect = el.getBoundingClientRect()
		this.openKebabMenu({
			x: (rect.right + rect.left) / 2,
			y: (rect.top + rect.bottom) / 2,
		})
	}

	contextMenuTitlebar(e) {
		// Don't show the browser's native context menu
		// Show the kebab menu at the mouse position
		e.preventDefault()
		e.stopPropagation()
		this.openKebabMenu({
			x: e.clientX,
			y: e.clientY,
		})
	}

	openKebabMenu({ x, y }) {
		// Create the context menu
		setContextMenu({
			position: { x, y },
			width: 160,
			items: _.compact([
				{
					label: 'Default Size',
					onClick: () => {
						this.setToDefaultSize()
						this.onFocus()
					},
				},
				{
					label: 'Snap to',
					childWidth: 160,
					items: [
						{
							label: 'Left-hand side',
							onClick: () => {
								this.snap('left')
								this.onFocus()
							},
						},
						{
							label: 'Right-hand side',
							onClick: () => {
								this.snap('right')
								this.onFocus()
							},
						},
					],
				},
				ConditionalObject(
					!this.props.parent.props.options.disableVirtualDesktops,
					{
						label: 'Move to',
						childWidth: 160,
						items: this.props.parent.state.desktops.map(x => ({
							label: x.name ?? `Desktop ${x.index}`,
							onClick: () => {
								const delta = () => ({ pos: { d: x.key } })
								this.update(delta, () =>
									this.props.parent.ChangeDesktop(x.key),
								)
								this.onFocus()
							},
						})),
					},
				),
				'---',
				{
					label: 'Minimise',
					icon: '/static/img/svg/wmanage/minimize.svg',
					iconGrayscale: true,
					onClick: () => {
						this.minimize()
					},
				},
				{
					label: this.state.isMaximized ? 'Restore' : 'Maximise',
					icon: Do(() => {
						const f = this.state.isMaximized ? 'restore' : 'maximize'
						return `/static/img/svg/wmanage/${f}.svg`
					}),
					iconGrayscale: true,
					onClick: () => {
						this.maximizeRestore()
						this.onFocus()
					},
				},
				{
					label: 'Close',
					icon: '/static/img/svg/wmanage/cross.svg',
					iconGrayscale: true,
					onClick: this.Close,
				},
			]),
		})
	}

	snap(dir) {
		const width = Math.min(this.props.sizeMax.x, window.innerWidth / 2)
		const left = Do(() => {
			if (dir === 'left') {
				return 0
			} else if (dir === 'right') {
				return window.innerWidth - width
			}
			console.error('Invalid snap direction')
			return 0
		})
		this.update(() => ({
			isMinimized: false,
			isMaximized: false,
			pos: {
				x: left,
				y: 0,
			},
			preMaximMinimPos: {
				x: left,
				y: 0,
			},
			size: {
				x: width,
				y: this.props.sizeMax.y,
			},
		}))
	}

	onFocus() {
		// This method is run when the window is focused
		// Generally done to focus a primary input component
		if (this.props.onFocus != null) {
			this.props.onFocus(this.GetContent(), this)
		}
	}

	getTitle() {
		const titleFn = this.props.parent.cachedFunctions[this.props.title]
		if (_.isFunction(titleFn)) {
			return titleFn(this.state.params ?? {}, this) ?? 'Loading...'
		}
		const msg = 'ERROR: INVALID TITLE FORMAT - MUST BE A FUNCTION'
		console.error(msg)
		return msg
	}

	// Public methods #

	Close() {
		this.props.parent.CloseWindow(this.state.key)
	}

	UpdateCreationState(obj = {}) {
		// Add fields to the object dynamically if available
		const C = this.GetContent()
		if (this.props.fields != null && C != null) {
			_.forEach(this.props.fields, f => {
				// Skip if already defined in the custom object
				if (_.has(obj, f)) {
					return
				}

				// Get the value from state before props
				let val = C.state?.[f]
				if (val === undefined) {
					val = C.props?.[f]
				}
				if (val !== undefined) {
					obj[f] = val
				}
			})
		}

		// Override from the current params
		obj = _.assign({}, this.state.params, obj)

		// If it differs, process the update to the window saved state
		if (!_.isEqual(obj, this.state.params)) {
			const delta = () => ({ params: obj })
			this.setState(delta, () => this.props.parent.Update())
		}
	}

	ConfirmValidPosSize() {
		// Exit early if a check is not needed
		// This is done so that multiple calls to `update` in the one frame
		// don't cause this check to be run multiple times
		if (this.checkNotNeeded) {
			return
		}

		// Perform the check
		this.update(() => this.validatePosSize(this.state))

		// For the next 100ms (unless set back explicitly somewhere else),
		// assume that no checks of the geometry are required
		this.checkNotNeeded = true
		timer(() => {
			this.checkNotNeeded = false
		})
	}

	UpdatePositionSize(delta) {
		// Extract the keys from the object resulting from the input function
		delta = _.isFunction(delta) ? delta() : delta
		const { size, sizeDef, sizeMin, sizeMax, pos } = delta

		// Send the diff to the internal `update` method
		const diff = () => ({
			pos: pos ?? undefined,
			size: size ?? undefined,
			sizeDef: sizeDef ?? undefined,
			sizeMin: sizeMin ?? undefined,
			sizeMax: sizeMax ?? undefined,
		})
		this.update(diff, () => {
			// Re-apply the resizer if the min/max bounds have changed
			if (sizeMin != null || sizeMax != null) {
				this.applyResizer()
			}
		})
	}

	UpdateSID(newSID) {
		this.setState(() => ({ sid: newSID }))
	}

	GetContent() {
		return this.contentComponent.current?.CC.current ?? null
	}

	Pulse() {
		// Helper function for the actual pulse
		const pulse = () => {
			this.rootEl.current?.classList.add('pulse')
			timer(200, () => this.rootEl.current?.classList.remove('pulse'))
		}

		// Ensure it's not minmised
		if (this.state.isMinimized) {
			this.maximizeRestore()
			timer(200, () => {
				pulse()
			})
		} else {
			pulse()
		}
	}

	Focus() {
		if (!this.props.isFocused) {
			this.props.parent.ChangeFocus(this.state.key)
			this.onFocus()
		}
	}

	SetOpacity(lvl?: number) {
		this.setState({ opacity: lvl })
	}
}

// This just wraps the content to prevent re-renders
type windowManagerContentWrapperProps = {
	tagProxy: any
	[key: string]: any
}
class WindowManagerWindowContentWrapper extends React.Component<
	windowManagerContentWrapperProps,
	any // TODO: fix state type
> {
	CC: React.RefObject<any>

	constructor(props: windowManagerContentWrapperProps) {
		super(props)
		this.CC = React.createRef()
	}

	override shouldComponentUpdate(nextProps) {
		// If the props have been updated, re-render
		if (!_.isEqual(nextProps, this.props)) {
			return true
		}

		// Otherwise there's no reason to update
		return false
	}

	override render() {
		return j2r(() => {
			const tag = this.props.tagProxy
			const params = _.omit(this.props as windowManagerContentWrapperProps, [
				'tag',
				'tagProxy',
				'ref',
			])
			params.tag = tag
			params.ref = this.CC
			return params
		})
	}

	Update() {
		this.forceUpdate()
	}
}

class DemoWindow extends React.Component<
	{
		text?: string
		check?: boolean
		window?: any
	},
	any // TODO: fix state type
> {
	constructor(props) {
		super(props)
		this.state = {
			text: this.props.text ?? '',
			check: this.props.check ?? false,
		}
	}

	override componentDidMount() {
		this.props.window.UpdateCreationState()
	}

	updateState(delta) {
		this.setState(delta, () => this.props.window.UpdateCreationState())
	}

	override render() {
		return j2r({
			cl: 'example-window',
			children: [
				j2rProps(this, 'text', () => ({ tag: J2rText })),
				j2rProps(this, 'check', () => ({
					tag: J2rCheckbox,
					label: 'Checkbox',
					triggerClick: true,
				})),
			],
		})
	}
}

export const openDemoWindow = () => ({
	tag: WindowManagerWindow,
	title: p => `Demo window - ${p.text} - ${p.check ? 1 : 0}`,
	size: [480, 360],
	func: 'openDemoWindow',
	cmpt: DemoWindow,
	fields: ['text', 'checkbox'],
})

export const openWindowAsFlyout = (fn, params) => {
	let obj
	if (params == null) {
		params = {}
	}

	// Get the properties
	const properties = fn(params)

	// Get the content
	if (properties.cmpt != null) {
		obj = { tag: properties.cmpt }
		_.forEach(params, (v, k) => {
			obj[k] = v
		})
	} else {
		obj = properties.content(params)
	}

	// Get the title and size
	// Extract the size, adding extra for padding and the title
	let title = properties.title(params)
	const size: [number, number] = [
		properties.size[0] + 20,
		properties.size[1] + 20 + 42 - 28,
	]

	// Add a fake window object property for close and state management
	let flyout = null
	obj.window = {
		Close: () => flyout.Close(),
		UpdateSID: _.noop,
		UpdateCreationState: _.noop,
		UpdatePositionSize: (delta: () => { pos: WindowPosition; size: WindowSize }) => {
			const d = delta()
			flyout.ChangeSize({
				width: d.size?.x != null ? d.size.x + 20 : undefined,
				height: d.size?.y != null ? d.size.y + 20 + 42 - 28 : undefined,
				left: d.pos?.x ? d.pos.x : undefined,
				top: d.pos?.y ? d.pos.y : undefined,
			})
		},
	}

	// Let the inner component know it's inside of a window
	obj.isInFlyout = true

	// Allow the inner component to control the titlebar space in flyout-mode
	title = properties.buildOwnTitleFlyoutMode ? '' : title

	// Create the flyout (shadow flyout for the close function)
	// Give the flyout the back-compat class to fix small style inconsistencies
	flyout = reactFlyout(title, size, obj)
	flyout.Element.classList.add('window-back-compat')
}
