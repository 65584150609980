import _ from 'lodash'
import { Maybe } from '../../../../universal'
import { Alerts } from '../../component-main'
import { FormType, openFlyoutForm } from '../form'
import { getAttachmentGeneratedFilename } from './attachments'
import { FormFieldNoteTags } from './tags'
import { NoteInfo, ReducerState } from './types'

export const editNote = <T extends Record<string, unknown>>(input: {
	rs: ReducerState<T>
	note: NoteInfo<T>
	options?: {
		generateTitle?: boolean
		generateSummary?: boolean
		translate?: boolean
	}
}): void => {
	const rs = input.rs

	// If there is no editing configuration, just show a message explaining that
	// It shouldn't be possible to see this screen under normal usage
	if (!rs.props.editing) {
		Alerts.Alert({ msg: 'No tags available' })
		return
	}

	// Open the flyout to edit the tags
	openFlyoutForm({
		title: 'Edit Note',
		size: [480, null],
		formPrompt: 'Editing a note',
		defaultValue: () => ({
			title: input.note.Title ?? '',
			note: input.note.Note ?? '',
			tags: input.note.Tags ?? [],
			file: input.note.Attachment
				? {
						Checksum: input.note.Attachment.Checksum,
						Filename: getAttachmentGeneratedFilename(input.note.Attachment),
					}
				: null,
		}),
		lblWidth: 40,
		fields: {
			title: FormFieldNoteTitle(),
			tags: FormFieldNoteTags(rs),
			note: FormFieldNoteBody(rs),
			file: FormFieldNoteAttachment(),
		},
		onSave: async (model, callback) => {
			console.log({ model })

			// Run the two promises at the same time to edit tags and base details
			const results = await Promise.all([
				// Save the base note - sends a diff of only changed fields for better logging
				await saveNotePromise({
					rs,
					note: input.note,
					newNote: {
						Title: model.current.title,
						Note: model.current.note,
						Attachment: model.current.file?.Checksum,
					},
				}),

				// Save the tags
				await saveTagUpdatesPromise({
					rs,
					note: input.note,
					newNoteTags: model.current.tags,
				}),
			])

			// Display errors if there are any, otherwise let the form know we're done
			const errors = results.filter(x => typeof x === 'string')
			if (errors.length > 0) {
				callback(errors.join(', '))
			}
			callback(true)
		},
	})
}

export const editNoteTags = <T extends Record<string, unknown>>(input: {
	rs: ReducerState<T>
	note: NoteInfo<T>
}): void => {
	const rs = input.rs

	// If there are no tags available, just show a message explaining that
	// It shouldn't be possible to see this screen under normal usage
	if (!rs.props.tagging || !rs.props.tagging?.onUpdate) {
		Alerts.Alert({ msg: 'No tags available' })
		return
	}

	// Open the flyout to edit the tags
	openFlyoutForm({
		title: 'Edit Note Tags',
		size: [320, null],
		formPrompt: 'Editing the tags for a note',
		defaultValue: () => ({
			noteTags: input.note.Tags,
		}),
		lblWidth: 80,
		fields: {
			noteTags: FormFieldNoteTags(rs),
		},
		onSave: async (model, callback) => {
			const result = await saveTagUpdatesPromise({
				rs,
				note: input.note,
				newNoteTags: model.current.noteTags,
			})
			callback(result)
		},
	})
}

const saveNotePromise = async <T extends Record<string, unknown>>(input: {
	rs: ReducerState<T>
	note: NoteInfo<T>
	newNote: {
		Title: string
		Note: string
		Attachment: Maybe<string>
	}
}): Promise<string | true> => {
	return new Promise<string | true>(resolve => {
		// Get the changed fields
		const currentValues = {
			title: input.note.Title,
			note: input.note.Note,
			attachment: input.note.Attachment,
		}
		const newValues = {
			title: input.newNote.Title,
			note: input.newNote.Note,
			attachment: input.newNote.Attachment,
		}
		const changedValues = _.pickBy(
			newValues,
			(value, key) => value !== currentValues[key as keyof typeof newValues],
		)
		console.log({ changedValues })

		// If there are no changes, just resolve true
		if (Object.keys(changedValues).length === 0) {
			resolve(true)
			return
		}

		// Call the user function to perform the action
		input.rs.props.editing?.onEdit({
			noteID: input.note.ID,
			changes: changedValues,
			onComplete: successOrError => {
				resolve(successOrError)
			},
		})
	})
}

const saveTagUpdatesPromise = async <T extends Record<string, unknown>>(input: {
	rs: ReducerState<T>
	note: NoteInfo<T>
	newNoteTags: string[]
}): Promise<string | true> => {
	return new Promise<string | true>(resolve => {
		// Get the changes to the note tags
		const existing = input.note.Tags
		const newTags = input.newNoteTags
		const additions = _.difference(newTags, existing)
		const deletions = _.difference(existing, newTags)

		// If there are no changes, just resolve true
		if (additions.length === 0 && deletions.length === 0) {
			resolve(true)
			return
		}

		// Call the user function to perform the action
		input.rs.props.tagging?.onUpdate?.({
			additions: additions.map(x => ({
				noteID: input.note.ID,
				tagID: x,
			})),
			deletions: deletions.map(x => ({
				noteID: input.note.ID,
				tagID: x,
			})),
			onComplete: successOrError => {
				resolve(successOrError)
			},
		})
	})
}

export const FormFieldNoteTitle = () =>
	FormType.Text({
		lbl: 'Title',
		placeholder: '(Optional)...',
		maxLength: 80,
	})

export const FormFieldNoteBody = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
) =>
	FormType.Textarea({
		lbl: 'Note',
		fullWidth: true,
		voicePrompt: rs.props.adding?.formPrompt,
		placeholder: 'Add your note here...',
		maxLength: 65535,
		rows: 8,
	})

export const FormFieldNoteAttachment = () =>
	FormType.FileUploadSingle({
		lbl: 'Attachment',
		dragText: 'Optional file attachment',
		fullWidth: true,
		height: 64,
	})
