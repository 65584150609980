import { Alerts } from '../../component-main'
import { openFlyoutForm } from '../form'
import { FormFieldNoteAttachment, FormFieldNoteBody, FormFieldNoteTitle } from './edit'
import { FormFieldNoteTags } from './tags'
import { ReducerState } from './types'

export const addNewNoteForm = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
) => {
	// Check if we're allowed to add. If not, show an error that should never really happen
	if (!rs.props.adding) {
		Alerts.Alert({ msg: 'You are not allowed to add notes' })
		return
	}

	// Open the form
	openFlyoutForm({
		title: rs.props.adding.formTitle,
		size: [480, null],
		lblWidth: 40,
		defaultValue: () => ({
			title: '',
			tags: [],
			note: '',
			file: null,
		}),
		formPrompt: rs.props.adding.formPrompt,
		fields: {
			title: FormFieldNoteTitle(),
			tags: FormFieldNoteTags(rs),
			note: FormFieldNoteBody(rs),
			file: FormFieldNoteAttachment(),
		},
		formFixer: (_oldState, newState, changedField) => {
			// Update a blank title to match the just-uploaded filename
			if (changedField == 'file' && !newState.title && newState.file) {
				return {
					title: `File: ${newState.file.Filename}`,
				}
			}
			return null
		},
		onSave: (model, formCallback) => {
			// Run the application-level function to add the note
			// This is implemented for each place using the notes component
			rs.props.adding?.onAdd({
				note: {
					Title: model.current.title,
					Note: model.current.note,
					Tags: model.current.tags,
					Attachment: model.current.file?.Checksum ?? null,
				},
				onComplete: (newID, result) => {
					// Send result to form component to show tick/error
					formCallback(result)
					// If there's a new ID, update the state to select it
					if (newID) {
						rs.updateState({
							selectedNote: newID,
						})
					}
				},
			})
		},
	})
}
