import { Alerts } from '..'
import { BuildClass, Maybe } from '../../../universal'
import { React, _ } from '../../lib'
import {
	ListGrid,
	ListGridField,
	ListGridInstance,
	ListGridProps,
	ListGridView,
} from './list-grid'
import { RSInstance, RSInstanceD, useRSInstance, useStateSync } from './meta-types'
import { ResizeSplitter } from './resize-split'
import { stubListGrid } from './stubs'
import { ToolbarNew } from './toolbar'

export type SidebarListGridView = Partial<{
	/** The search text */
	searchText: string
	/** Whether to show inactive items */
	showInactive: boolean
	/** The widths of the splitters in the resize splitter. If not defined, the default widths will be used */
	splitWidths: Maybe<Maybe<number>[]>
	/** Raw view of the listgrid */
	listGridView: ListGridView
}>

type SidebarListGridProps<T, V extends string | number, M extends boolean> = {
	/** Add styling classes to various parts of the component tree */
	className?: {
		resizeSplitter?: string
		listGrid?: string
		toolbar?: string
	}
	/** The lambda function to get the primary key of an item */
	pk: (item: T) => V
	/** Data source for the listgrid */
	data: T[]
	/** The fields to show in the listgrid sidebar */
	fields: ListGridField<T>[]
	/** Builds the form to show in the RHS when a value (or values) are selected */
	buildForm: (selectedValues: M extends true ? V[] : Maybe<V>) => React.JSX.Element
	/** Whether the listgrid allows multi-select */
	multiple: M
	/** The controllable state of the selected item(s) value */
	value: M extends true ? V[] : Maybe<V>
	/** Event function when the selected item(s) value has updated */
	onUpdateValue?: (value: M extends true ? V[] : Maybe<V>) => void
	/** The controllable state of the UI view */
	view?: Partial<SidebarListGridView>
	/** Event function when the view has updated */
	onUpdateView?: (view: Partial<SidebarListGridView>) => void
	/** You can opt-in to allowing the user to click an add button, shown in the sidebar */
	adding?: Maybe<{
		/**
		 * The label to show in the add button. Defeaults to "Add"
		 * If a custom label is defined, you need to define the width of the button
		 */
		lbl?: {
			text: string
			width: number
		}
		/** The function to call when the add button is clicked */
		onClick: () => void
	}>
	/**
	 * You can opt-in to the items having a sense of "inactivity"
	 * If left null, there will be no "Show Inactive" checkbox
	 * Otherwise, you need to define some configuration for it
	 */
	inactivity?: Maybe<{
		/**
		 * The label to show in the "Show Inactive" checkbox. Defaults to "Inactive"
		 * If a custom label is defined, you need to define the width of the checkbox
		 */
		lbl?: {
			text: string
			width: number
		}
		/** The lambda function to determine if an item is inactive or not */
		isInactive: (item: T) => boolean
		/** The tailwind class to apply to an inactive item. Defaults to `text-neutral-500` */
		className?: string
	}>
	/** Definitions for the sidebar width in the resize splitter. Leaving null uses defaults */
	sidebarWidth?: Maybe<{
		/** Whether the sidebar part of the resize splitter is collapsible. Defaults to true */
		collapsible?: boolean
		/** The minimum width of the sidebar part of the resize splitter. Defaults to 160 */
		minWidth?: Maybe<number>
		/** The maximum width of the sidebar part of the resize splitter. Defaults to null */
		maxWidth?: Maybe<number>
		/** The default width of the sidebar part of the resize splitter. Defaults to 240 */
		defaultWidth?: number
	}>
	/**
	 * You can opt-in to tracking whether there are unsaved changes which should soft-block
	 * the user from being able to change the selected items without a confirmation.
	 * If left null, it won't be checked
	 */
	unsavedChanges?: Maybe<{
		/** The lambda function to determine if there are unsaved changes */
		isUnsaved: () => boolean
		/**
		 * The warning to show to the user when there are unsaved changes
		 * If defined, you need to specify the width/height of the alert to match
		 */
		warning?: null | {
			/** The size of the warning. Defaults to [360, 200] */
			size?: [number, number]
			/** The message of the warning. Defaults to "You have unsaved changes. Are you sure you want to change selection?" */
			message?: string
		}
	}>
	/** Searchbox customisations. Only needed if you need to override something */
	searchBox?: Maybe<{
		/** There's not generally much point to disabling search so it's nested in here */
		disable?: boolean
		/** How wide the searchbox should be. Defaults to 72 */
		width?: number
		/** The placeholder text to show in the searchbox. Defaults to "Search..." */
		placeholder?: string
	}>
	// TODO - implement this
	// /** If defined, the list takes up the full width when nothing is selected. You can show different fields in this view */
	// fullWidth?: Maybe<{
	// 	fieldsFull: ListGridField<T>[]
	// }>
	/** Any other customisations for the ListGrid component */
	listGridSettings?: Omit<
		ListGridProps<T, V, M>,
		| 'className'
		| 'data'
		| 'fields'
		| 'pk'
		| 'searchText'
		| 'value'
		| 'onUpdate'
		| 'multiple'
		| 'view'
		| 'onUpdateView'
	>
	/** The label to show in the stub message when nothing is selected */
	stubMessage?: {
		/** The label to show in the stub message */
		lbl: string
		/** Tailwind styles to apply. Defaults to something sensible */
		className?: string
	}
}

type sidebarListGridRefs = {
	listGrid: React.RefObject<ListGridInstance<any, any>>
}

type ReducerState<T, V extends string | number, M extends boolean> = RSInstanceD<
	SidebarListGridProps<T, V, M>,
	SidebarListGridState<V, M>,
	sidebarListGridRefs,
	Payload<V, M>
>

type ReducerStateNoDispatch<T, V extends string | number, M extends boolean> = RSInstance<
	SidebarListGridProps<T, V, M>,
	SidebarListGridState<V, M>,
	sidebarListGridRefs
>

type SidebarListGridState<V extends string | number, M extends boolean> = {
	selectedItem: M extends true ? V[] : Maybe<V>
	searchText: string
	showInactive: boolean
	splitWidths: Maybe<Maybe<number>[]>
	listGridView: ListGridView
	isAskingToChange: boolean
}

const getDefaultState = <T, V extends string | number, M extends boolean>(
	props: SidebarListGridProps<T, V, M>,
): SidebarListGridState<V, M> => ({
	selectedItem:
		props.value ?? ((props.multiple ? [] : null) as M extends true ? V[] : Maybe<V>),
	searchText: props.view?.searchText ?? '',
	showInactive: props.view?.showInactive ?? false,
	splitWidths: props.view?.splitWidths ?? null,
	listGridView: props.view?.listGridView ?? {},
	isAskingToChange: false,
})

export const SidebarListGrid = <T, V extends string | number, M extends boolean>(
	props: SidebarListGridProps<T, V, M>,
): React.JSX.Element => {
	// State management
	const rs: ReducerState<T, V, M> = useRSInstance({
		props: props,
		defaultState: getDefaultState,
		refs: {
			listGrid: React.useRef<ListGridInstance<T, M>>(stubListGrid),
		},
		actionToDelta: getPartialStateFromAction,
	})

	// Sync values with prop changes
	useStateSync({
		propVal: props.value,
		stateVal: rs.state.selectedItem,
		setState: v => {
			rs.dispatch([
				Action.UpdateValue,
				{
					value: v as M extends true ? V[] : V,
				},
			])
		},
		setProp: v => {
			rs.props.onUpdateValue?.(v)
		},
	})

	// Render
	const hasSelectedSomething = rs.props.multiple
		? _.size(rs.state.selectedItem as V[]) > 0
		: Boolean(rs.state.selectedItem)
	return (
		// Wrap resize splitter in a full-height tailwind wrapper to reduce boilerplate
		// in situations where the SidebarListGrid is the only component in a legacy window
		<div className="tailwind-wrapper" style={{ height: '100%' }}>
			<ResizeSplitter
				className={rs.props.className?.resizeSplitter}
				value={rs.state.splitWidths}
				onUpdate={v => {
					rs.dispatch([Action.UpdateView, { splitWidths: v }])
				}}
				panes={[
					{
						minWidth: rs.props.sidebarWidth?.minWidth ?? 160,
						maxWidth: rs.props.sidebarWidth?.maxWidth ?? null,
						defaultWidth: rs.props.sidebarWidth?.defaultWidth ?? 240,
						collapsible: rs.props.sidebarWidth?.collapsible ?? true,
						flexWidth: null,
						content: <SidebarListGridSidebar rs={rs} />,
					},
					{
						defaultWidth: null,
						minWidth: null,
						maxWidth: null,
						flexWidth: 1,
						collapsible: false,
						content: (
							<>
								{hasSelectedSomething ? (
									rs.props.buildForm(rs.state.selectedItem)
								) : (
									<div
										className={
											rs.props.stubMessage?.className ??
											'w-full h-full pt-5 bg-neutral-200 text-center font-light text-xl text-neutral-500'
										}
									>
										{rs.props.stubMessage?.lbl ?? 'Nothing Selected'}
									</div>
								)}
							</>
						),
					},
				]}
			/>
		</div>
	)
}

export const SidebarListGridSidebar = <
	T,
	V extends string | number,
	M extends boolean,
>(props: {
	rs: ReducerState<T, V, M>
}) => {
	const rs = props.rs
	const hasUnsavedChanges = rs.props.unsavedChanges?.isUnsaved?.()
	return (
		<>
			{/* Toolbar */}
			<ToolbarNew
				className={rs.props.className?.toolbar}
				lhs={[
					{
						type: 'searchbox',
						width: rs.props.searchBox?.width ?? 72,
						placeholder: rs.props.searchBox?.placeholder ?? 'Search...',
						visible: !Boolean(rs.props.searchBox?.disable ?? false),
						value: rs.state.searchText,
						onUpdate: v => {
							rs.dispatch([Action.UpdateView, { searchText: v }])
						},
						onArrowDelta: (delta, shiftKey) => {
							rs.refs.listGrid.current
								?.getList()
								?.moveSelection(delta, shiftKey)
						},
					},
					{
						type: 'checkbox',
						lbl: rs.props.inactivity?.lbl?.text ?? 'Inactive',
						visible: Boolean(rs.props.inactivity),
						width: rs.props.inactivity?.lbl?.width ?? 76,
						value: rs.state.showInactive,
						onUpdate: v => {
							rs.dispatch([Action.UpdateView, { showInactive: v }])
						},
					},
				]}
				rhs={[
					{
						type: 'button-add',
						visible: Boolean(rs.props.adding),
						width: rs.props.adding?.lbl?.width ?? 74,
						lbl: rs.props.adding?.lbl?.text ?? 'Add',
						onClick: () => {
							confirmUnsavedChanges(rs, () => {
								rs.props.adding?.onClick?.()
							})
						},
					},
				]}
			/>
			{/* ListGrid */}
			<ListGrid
				// Ref and style class
				ref={rs.refs.listGrid}
				className={BuildClass([
					'!h-[calc(100%-40px)]',
					rs.props.className?.listGrid ?? '',
				])}
				// Handle the value state
				value={rs.state.selectedItem}
				onUpdate={v => {
					rs.dispatch([
						Action.UpdateValue,
						{ value: v as M extends true ? V[] : V },
					])
				}}
				// Handle the view state
				view={rs.state.listGridView}
				onUpdateView={v => {
					rs.dispatch([Action.UpdateView, { listGridView: v }])
				}}
				// Filter the data based on inactive items
				data={_.filter(rs.props.data, x => {
					if (!rs.props.inactivity || rs.state.showInactive) {
						return true
					}
					return !rs.props.inactivity.isInactive(x)
				})}
				// Pass-through primary list grid props
				multiple={rs.props.multiple}
				pk={rs.props.pk}
				fields={rs.props.fields}
				searchText={rs.state.searchText}
				// Merge in the inactive class with the user-defined one
				classRow={x => {
					const isInactive = Boolean(x && rs.props.inactivity?.isInactive(x))
					const otherClasses = rs.props.listGridSettings?.classRow?.(x) ?? ''
					const inactive = rs.props.inactivity?.className ?? 'text-neutral-500'
					return BuildClass({
						[otherClasses]: true,
						[inactive]: isInactive,
					})
				}}
				// Handle unsaved changes
				// Make the list grid read only and ask for confirmation on click
				readOnly={
					rs.props.listGridSettings?.readOnly ||
					(hasUnsavedChanges && !rs.state.isAskingToChange)
				}
				onClick={
					hasUnsavedChanges
						? (x, e) => {
								e?.stopPropagation()
								e?.preventDefault()
								confirmUnsavedChanges(rs, () => {
									const value = [String(rs.props.pk(x))]
									const lg = rs.refs.listGrid.current
									lg?.getList().setSelected(value)
									lg?.focus()
								})
							}
						: undefined
				}
				// Extra props
				{..._.omit(rs.props.listGridSettings, ['classRow', 'readOnly'])}
			/>
		</>
	)
}

const confirmUnsavedChanges = <T, V extends string | number, M extends boolean>(
	rs: ReducerState<T, V, M>,
	yes: () => void,
) => {
	// If there are no unsaved changes, just execute the function immediately
	if (!rs.props.unsavedChanges?.isUnsaved?.()) {
		yes()
		return
	}

	// Ask the user if they're sure about discarding changes before proceeding
	// Set the state that we're asking so we can remove the readOnly attribute from the listgrid which makes state management difficult
	rs.updateState({ isAskingToChange: true })
	Alerts.Confirm({
		size: rs.props.unsavedChanges?.warning?.size ?? [360, 200],
		msg:
			rs.props.unsavedChanges?.warning?.message ??
			'You have unsaved changes. Are you sure you want to do this?',
		no: () => {
			rs.updateState({ isAskingToChange: false })
		},
		yes: () => {
			rs.updateState({ isAskingToChange: false })
			// Run the function
			yes()
		},
	})
}

enum Action {
	UpdateValue,
	UpdateView,
}

type Payload<V extends string | number, M extends boolean> =
	| [Action.UpdateValue, { value: M extends true ? V[] : Maybe<V> }]
	| [Action.UpdateView, SidebarListGridView]

const getPartialStateFromAction = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateNoDispatch<T, V, M>,
	p: Payload<V, M>,
): Maybe<Partial<SidebarListGridState<V, M>>> => {
	switch (p[0]) {
		case Action.UpdateValue:
			const value = p[1].value
			rs.props.onUpdateValue?.(value)
			return { selectedItem: value }
		case Action.UpdateView:
			rs.props.onUpdateView?.({
				..._.omit(rs.state, 'selectedItem'),
				...p[1],
			})
			return p[1]
	}
}
