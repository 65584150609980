import { React, _ } from '../../lib'
import { reactFlyout } from '../component-react'
import { NewSystemFlyoutInstance } from '../flyouts'
import { FormButton, FormButtonSet } from './buttons'

type ConfirmPropsBase = {
	msg: string | React.JSX.Element
	yes: (callback: (trueOrError: string | true) => void) => void
	no?: () => void
	askForConfirmation?: boolean
}

export const PromptConfirm = (
	props: {
		title?: string
		size?: [number, number]
	} & ConfirmPropsBase,
): void => {
	reactFlyout(
		props.title ?? 'Are you sure?',
		props.size ?? [320, 240],
		<ConfirmUI {...props} />,
	)
}

const ConfirmUI = (
	props: ConfirmPropsBase & {
		flyout?: NewSystemFlyoutInstance
	},
): React.JSX.Element => {
	const [loading, setLoading] = React.useState(false)
	const [message, setMessage] = React.useState('')
	return (
		<div className="tailwind-wrapper">
			<div className="leading-normal my-2">{props.msg}</div>
			<FormButtonSet loading={loading} msg={message}>
				<FormButton
					lbl="Yes"
					type="submit"
					onClick={() => {
						setLoading(true)
						setMessage('')
						props.yes(result => {
							console.log({ result })
							setLoading(false)
							if (_.isString(result)) {
								setMessage(result)
								return
							}
							props.flyout?.Close()
							return
						})
					}}
				/>
				<FormButton
					lbl="Cancel"
					onClick={() => {
						props.no?.()
						props.flyout?.Close()
					}}
				/>
			</FormButtonSet>
		</div>
	)
}
