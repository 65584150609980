import { BuildClass, Do, Maybe, clamp, timer } from '../../../universal'
import { React, _ } from '../../lib'
import { isDarkTheme } from '../component-main'
import { ConditionalObject } from './meta-types'

// How long the context menu item is hovered with a still mouse before it opens
const MOUSEPAUSEOPENCONTEXTMENU = 100
const CONTEXT_MENU_HEIGHT_ROW = () => (window.innerWidth <= 1024 ? 37 : 23)
const CONTEXT_MENU_HEIGHT_DIVIDER = 11
const CONTEXT_MENU_HEIGHT_PADDING = 6
const DEFAULT_WIDTH = 240

// TYPES #################################################################################

export type ContextMenuOptions = {
	position: {
		x: number // X coordinate (px) relative to the viewport
		y: number // Y coordinate (px) relative to the viewport
		preferLeft?: boolean // If true, the context menu will prefer to open to the left
	}
	width?: number // Gives more width if required (height dynamically calculated)
	items: ContextMenuItemArray // Items to show
	onClose?: () => void // Runs when an item is selected - passes the item object chosen (or null)
}

export type ContextMenuItem = ContextMenuItemNoDivider | '---'

export type ContextMenuItemNoDivider = {
	label: string | (() => string) // Text to show on the option's line
	childWidth?: number
	labelDesc?: string // Text to show when hovering the option's line
	onClick?: (e: React.MouseEvent) => void // Code to run when the option is selected
	key?: string | number // React indexing PK (defaults to index in array)
	icon?: string | (() => string | undefined) // Icon to display next to the option
	iconGrayscale?: boolean // Whether the icon is displayed with a grayscale filter
	shortcut?: string // Text to show on the right for the keyboard shortcut
	shortcutDesc?: string // Tooltip for the optional shortcut text
	disabled?: boolean // Whether it's clickable
	hidden?: boolean // Whether it's hidden altogether
	dontClose?: boolean // If true, the context menu stays open on-click
	items?: ContextMenuItemArray
}
type ContextMenuItemArray = ContextMenuItem[] | (() => ContextMenuItem[])

// HOOKS #################################################################################

export const ContextMenuWrapper = (props: {
	data: ContextMenuOptions
	onClose: () => void
}): React.JSX.Element => {
	const onClose = React.useCallback(() => {
		props.data?.onClose?.()
		props.onClose()
		// Only recalc when onClose event changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data?.onClose, props.onClose])
	if (props.data == null) {
		return <></>
	}
	return (
		<div className="tailwind-wrapper" style={{ width: '100vw', height: '100vh' }}>
			<div
				className={BuildClass({
					'fixed top-0 left-0 w-full h-full z-[99999]': true,
					'transition-[background] duration-[100]': true,
					'select-none': true,
					'bg-[hsla(0,0%,0%,0.1)]': props.data != null,
					'pointer-events-none bg-[hsla(0,0%,0%,0)]': !(props.data != null),
				})}
				onClick={onClose}
			>
				{ConditionalObject(props.data != null, () => (
					<ContextMenu
						key="context-menu"
						position={props.data.position}
						width={props.data.width}
						items={props.data.items}
						onClose={onClose}
					/>
				))}
			</div>
		</div>
	)
}

const ContextMenu = (props: {
	items: ContextMenuItemArray
	width?: number
	position?: {
		x: number
		y: number
		preferLeft?: boolean
	}
	onClose: () => void
}): React.JSX.Element => {
	// State
	const [opened, setOpened] = React.useState<(string | number)[]>([])
	const [isInstantiated, setIsInstantiated] = React.useState<boolean>(false)
	const [, setIncrement] = React.useState<number>(0)

	// Manage mount/dismount
	React.useEffect(() => {
		setIsInstantiated(true)
		return () => {
			setIsInstantiated(false)
		}
	}, [])

	// If the props change, reset what is open
	React.useEffect(() => {
		setOpened([])
	}, [props.items])

	// Build the box
	return buildBox({
		items: props.items,
		root: true,
		path: [],
		x1: props.position?.x ?? 0,
		x2: props.position?.x ?? 0,
		y1: props.position?.y ?? 0,
		y2: props.position?.y ?? 0,
		width: props.width ?? DEFAULT_WIDTH,
		isInstantiated: isInstantiated,
		prefer_left: props.position?.preferLeft ?? false,
		opened: opened,
		onClose: props.onClose,
		setOpened: setOpened,
		rerender: () => {
			setIncrement(x => x + 1)
		},
	})
}

const buildBox = (obj: {
	items: ContextMenuItemArray
	root: boolean
	path: (string | number)[]
	x1: number
	x2: number
	y1: number
	y2: number
	width: number
	prefer_left?: boolean
	isInstantiated: boolean
	opened: (string | number)[]
	onClose: () => void
	rerender: () => void
	setOpened: (value: (string | number)[]) => void
}) => {
	// Get the filtered, evaluated item array
	const items = getItems(obj.items)

	// Calculate the cumulative height at each item
	let cummulativeHeight = 0
	const cummulativeHeights = [0]
	_.forEach(items, x => {
		cummulativeHeight +=
			typeof x === 'string'
				? CONTEXT_MENU_HEIGHT_DIVIDER
				: CONTEXT_MENU_HEIGHT_ROW()
		cummulativeHeights.push(cummulativeHeight)
	})

	// Get the dimensions
	const D = getDimensions({
		root: obj.root,
		width: obj.width,
		height: cummulativeHeight + CONTEXT_MENU_HEIGHT_PADDING + 1,
		x1: obj.x1,
		x2: obj.x2,
		y1: obj.y1,
		y2: obj.y2,
		prefer_left: obj.prefer_left ?? false,
	})

	// If no items are found, return null
	if (!items || items.length == 0) {
		return <></>
	}

	// Build the context menu div
	return (
		<div
			className={BuildClass({
				'fixed py-[3px] px-0': true,
				'text-[14px]': true,
				'bg-[#ffffff]': true,
				'border-t-[1px] border-l-[1px] border-r-0 border-b-0 border-solid border-[#888]':
					true,
				'rounded-[5px]': true,
				'shadow-[3px_3px_5px_#444]': true,
				'transition-transform duration-[50ms]': true,
				'overflow-y-auto': true,
				'max-lg:leading-[37px]': true,
				'scale-0': !obj.isInstantiated && obj.root,
				'bg-[hsl(225,8%,10%)] text-[#bbb] border-[#444] shadow-[3px_3px_5px_#222]':
					isDarkTheme(),
			})}
			style={{
				left: `${D.left}px`,
				top: `${D.top}px`,
				width: `${D.width}px`,
				height: `${D.height}px`,
				transformOrigin: D.transformOrigin,
			}}
		>
			{_.map(items, (x, i) => {
				// If it's a separator, return that
				if (isSeparator(x)) {
					return (
						<div
							key={`separator-${i}`}
							className="p-[5px] cursor-default"
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<div className="border-t-[1px] border-solid border-[#ddd]" />
						</div>
					)
				}

				// Get calculated details about this item
				const item_key = x.key ?? i
				const this_path = obj.path.concat(item_key)
				const is_open = _.isEqual(
					this_path,
					obj.opened.slice(0, this_path.length),
				)

				// Build the item
				return (
					<div
						key={item_key}
						className="relative w-full"
						onClick={e => {
							if (x.onClick == null || x.disabled) {
								e.stopPropagation()
								return
							}
							x.onClick?.(e)
							const closing = !(x.dontClose ?? false)
							if (closing) {
								obj.onClose()
							} else {
								obj.rerender()
								e.stopPropagation()
							}
						}}
					>
						{/* Sub-items */}
						{ConditionalObject(
							is_open,
							buildBox({
								...obj,
								items: x.items ?? [],
								root: false,
								path: this_path,
								x1: D.left,
								x2: D.left + D.width,
								y1: D.top + (cummulativeHeights[i] ?? 0),
								y2: D.top + (cummulativeHeights[i] ?? 0),
								width: x.childWidth ?? DEFAULT_WIDTH,
								prefer_left: D.went_left,
							}),
						)}

						{/* Line that the user clicks */}
						<ContextMenuLine
							key="line"
							item={x}
							onHover={() => {
								obj.setOpened(this_path)
							}}
						/>
					</div>
				)
			})}
		</div>
	)
}

export const ContextMenuLine = (props: {
	item: ContextMenuItemNoDivider
	onHover?: () => void
}): React.JSX.Element => {
	// Refs
	const isMouseInside = React.useRef<boolean>(false)
	const mounted = React.useRef<boolean>(false)
	const delayedOpenTimer = React.useRef<number>()

	// Set mounted on first render
	React.useEffect(() => {
		mounted.current = true
		return () => {
			mounted.current = false
		}
	}, [])

	// Event functions
	const onMouseOver = () => {
		isMouseInside.current = true
		clearTimeout(delayedOpenTimer.current)
	}
	const onMouseLeave = () => {
		isMouseInside.current = false
		// No longer waiting for a still mouse
		clearTimeout(delayedOpenTimer.current)
	}
	const onMouseMove = () => {
		// Stop any existing timer waiting for a still mouse
		clearTimeout(delayedOpenTimer.current)
		// If no other mouse events are received after X milliseconds,
		// then the mouse has remained stationary within this line for long enough to
		// open any children it has
		delayedOpenTimer.current = timer(MOUSEPAUSEOPENCONTEXTMENU, () => {
			if (mounted.current) {
				props.onHover?.()
			}
		})
	}
	const onClick = () => {
		// Stop any existing timer waiting for a still mouse
		clearTimeout(delayedOpenTimer.current)
		// Immediately expand the sub-items
		props.onHover?.()
	}

	// Render
	const isDark = isDarkTheme()
	const lbl = _.isFunction(props.item.label) ? props.item.label() : props.item.label
	return (
		<div
			className={BuildClass({
				'flex items-center': true,
				'cursor-pointer': !(props.item.disabled ?? false),
				'cursor-default text-[#aaa]': props.item.disabled ?? false,
				'hover:bg-[hsl(225,8%,20%)]': isDark,
				'hover:bg-[#ddd]': !isDark,
			})}
			title={props.item.labelDesc ?? lbl}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			onMouseMove={onMouseMove}
			onClick={onClick}
		>
			<div
				className={BuildClass([
					'relative w-[26px] h-[23px] p-[3px_5px]',
					'max-lg:h-[37px] max-lg:py-[10px]',
				])}
			>
				{ConditionalObject(
					props.item.icon != null,
					<img
						className={BuildClass({
							'absolute top-1/2 left-1/2 max-w-[16px] max-h-[16px]': true,
							'transform -translate-x-1/2 -translate-y-1/2': true,
							'opacity-80': isDark,
							'filter grayscale': Boolean(props.item.iconGrayscale),
							'filter invert': Boolean(props.item.iconGrayscale) && isDark,
						})}
						src={
							_.isFunction(props.item.icon)
								? props.item.icon()
								: props.item.icon
						}
					/>,
				)}
			</div>
			<div className="flex-1 truncate px-[1px]">{lbl}</div>
			<div
				className={BuildClass([
					'px-[1px] pt-[1px] pb-0',
					'text-[12px] leading-[22px] text-right text-[#666]',
				])}
				title={props.item.shortcutDesc}
			>
				{props.item.shortcut}
			</div>
			<div className="w-[16px]">
				{ConditionalObject(
					props.item.items != null,
					<img
						className={BuildClass({
							'w-[10px]': true,
							'opacity-80 filter invert': isDark,
							'opacity-70': !isDark,
						})}
						src="/static/img/svg/arrow-right.svg"
					/>,
				)}
			</div>
		</div>
	)
}

// HELPERS ###############################################################################

const getItems = (propsItems: ContextMenuItemArray): Maybe<ContextMenuItem[]> => {
	// Store the processed items in this variable
	let items: ContextMenuItem[] = []

	// If no items are returned, null
	if (propsItems == null) {
		return null
	}
	items = _.isFunction(propsItems) ? propsItems() : _.clone(propsItems)

	// Get the items to show - filter out the hidden ones
	items = _.filter(items, x => x != null)
	items = _.filter(items, x => typeof x === 'string' || !x.hidden)

	// Remove any leading or trailing separators
	if (typeof items[0] === 'string') {
		items = items.slice(1)
	}
	if (typeof items[items.length - 1] === 'string') {
		items.pop()
	}

	// Return the final array
	return items
}

const isSeparator = (item: ContextMenuItem): item is '---' => {
	if (typeof item !== 'string') {
		return false
	} else if (_.isEqual(_.uniq(item), ['-'])) {
		return true
	}
	console.error('Item is not an object nor a separator', item)
	return false
}

const getDimensions = (args: {
	root: boolean
	width: number
	height: number
	x1: number
	x2: number
	y1: number
	y2: number
	prefer_left: boolean
}) => {
	// Calculate the dimensions and position of the box
	let { x1, y1, x2, y2, height } = args
	const { root, width, prefer_left } = args

	// Clamp all coordinates within the viewport
	x1 = clamp(x1, 0, window.innerWidth)
	x2 = clamp(x2, 0, window.innerWidth)
	y1 = clamp(y1, 0, window.innerHeight)
	y2 = clamp(y2, 0, window.innerHeight)

	// Get X position, flip if too close to the right
	// If going both left and right are options, go right unless `prefer_left` is true
	// Prefer_left indicates that we've started going left and will maintain course
	const left = Do(() => {
		if (x2 + width > window.innerWidth) {
			return x1 - width
		} else if (x1 - width < 0) {
			return x2
		} else if (prefer_left) {
			return x1 - width
		}
		return x2
	})

	// Get Y position - flip if too close to the bottom
	let top = y2 + height > window.innerHeight ? y1 - height : y2

	// Get the transform origin (for the animation)
	const transform_x = left < x1 ? 'right' : 'left'
	const transform_y = top < y1 ? 'bottom' : 'top'
	const transformOrigin = `${transform_x} ${transform_y}`

	// Check if this went left (so we can maintain course)
	const went_left = left < x1

	// If it's at the bottom, shuffle it down one row height
	// Only do this for child menus
	if (!root && transform_y === 'bottom') {
		top += CONTEXT_MENU_HEIGHT_ROW() + CONTEXT_MENU_HEIGHT_PADDING
	}

	// If it cannot fit on screen, give it as much as possible
	const max_height = window.innerHeight - CONTEXT_MENU_HEIGHT_PADDING * 4
	if (height > max_height) {
		height = max_height
		top = CONTEXT_MENU_HEIGHT_PADDING * 2
	} else if (top < CONTEXT_MENU_HEIGHT_PADDING * 2) {
		top = CONTEXT_MENU_HEIGHT_PADDING * 2
	}

	// Return the results
	return { width, height, left, top, transformOrigin, went_left }
}

// IMPERATIVES ###########################################################################

export const setContextMenu = (obj: ContextMenuOptions): void => {
	const rui = window.rootUI as { setContextMenu: (obj: ContextMenuOptions) => void }
	if (typeof rui !== 'undefined' && rui !== null) {
		rui.setContextMenu(obj)
	} else {
		console.error('Cannot open a context menu on a non-frame page')
	}
}
