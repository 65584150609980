import { BuildClass, DateObj, DateTimeObj, Maybe } from '../../../../universal'
import { _, React } from '../../../lib'
import { AddButton } from '../buttons'
import { setContextMenu } from '../context-menu'
import { KebabMenu, KebabMenuToolbar } from '../kebab'
import { ListGrid } from '../list-grid'
import { CJSX } from '../meta-types'
import { TextSearched } from '../text'
import { SearchBox } from '../textbox'
import { Toolbar } from '../toolbar'
import { getMetaActions, getNoteActions } from './actions'
import { addNewNoteForm } from './add'
import { getFlattenedTags, TagIndicator } from './tags'
import {
	ACTION_CONTEXT_MENU_WIDTH,
	FileAttachment,
	NoteInfo,
	NoteTag,
	ReducerState,
} from './types'

export const NoteList = <T extends Record<string, unknown>>(props: {
	rs: ReducerState<T>
}) => {
	const rs = props.rs

	// Shorthand timestamp-formatting function
	const fmt = (obj: NoteInfo<T>, formatString: string) =>
		DateTimeObj.parseISO(obj.Created).fmt(formatString)

	// Render
	const flatTags = getFlattenedTags(rs)
	return (
		<>
			<Toolbar
				widthRHS={120}
				lhs={
					<>
						<SearchBox
							className="w-full"
							ref={rs.refs.searchBox}
							value={rs.state.searchText}
							onUpdate={v => {
								rs.updateState({ searchText: v })
								rs.props.state?.onUpdate?.({ searchText: v })
							}}
							onArrowDelta={(delta, shiftKey) => {
								const list = rs.refs.grid.current?.getList()
								list?.moveSelection(delta, shiftKey)
							}}
						/>
					</>
				}
				rhs={
					<>
						<CJSX cond={Boolean(rs.props.adding)}>
							<AddButton
								lbl="Add"
								onClick={() => {
									addNewNoteForm(rs)
								}}
							/>
						</CJSX>
						<KebabMenuToolbar
							preferLeft={true}
							width={180}
							items={() => getMetaActions(rs)}
						/>
					</>
				}
			/>
			<ListGrid
				multiple={false}
				value={rs.state.selectedNote}
				onUpdate={x => {
					rs.updateState({ selectedNote: x })
					rs.props.state?.onUpdate?.({ selectedNote: x })
				}}
				data={rs.props.notes
					.filter(x => !x.Deleted || rs.state.showDeleted)
					.filter(x => !x.Archived || rs.state.showArchived)}
				pk={x => x.ID}
				ref={rs.refs.grid}
				defaultSortingKey="date"
				defaultSortingReverse={true}
				className="!h-[calc(100%-40px)]"
				classRow={() => 'group'}
				height={() => 46}
				searchText={rs.state.searchText}
				fields={[
					{
						key: 'date',
						lbl: 'Date',
						width: { init: 70 },
						sortVal: x => x.Created,
						text: x =>
							fmt(x, 'dd/MM/yy ccc HH:mm | dd MMMM yyyy ccc hh:mm:ss a'),
						tooltip: x => {
							const date = fmt(x, 'cccc dd MMMM yyyy, h:mm:ss a')
							const extraDeleted = x.Deleted
								? ' - note has been marked as deleted'
								: ''
							const extraArchived = x.Archived
								? ' - note has been archived'
								: ''
							return `${date}${extraDeleted}${extraArchived}`
						},
						groupVal: x => DateTimeObj.parse(x.Created).date().fmt(),
						groupName: k => DateObj.parse(k).fmtDMY(),
						filterVal: {
							getKey: x => DateTimeObj.parse(x.Created).date().fmt(),
							getText: k => DateObj.parse(String(k)).fmtDMY(),
						},
						display: x => (
							<span
								className={BuildClass({
									'group-hover:text-black': true,
									'text-neutral-600': !x.Deleted,
									'text-red-700': Boolean(x.Deleted),
								})}
							>
								<div>
									<TextSearched
										text={fmt(x, 'dd/MM/yy')}
										needles={rs.state.searchText}
										tailwind={true}
									/>
								</div>
								<div className="text-sm">
									<TextSearched
										text={fmt(x, 'ccc HH:mm')}
										needles={rs.state.searchText}
										tailwind={true}
									/>
								</div>
							</span>
						),
					},
					{
						key: 'note',
						lbl: 'Note',
						text: x => {
							const parts = [
								x.Title ?? '',
								x.Meta?.text ?? '',
								x.Note ?? '',
								...x.Tags.map(t => flatTags[t].DisplayName),
							]
							return _.compact(parts).join(' ')
						},
						tooltip: x => x.Title ?? '',
						display: x => (
							<NotePreviewWidget rs={rs} note={x} flatTags={flatTags} />
						),
					},
				]}
				onClick={() => {
					rs.refs.searchBox.current?.focus()
				}}
				onContextMenu={(x, e) => {
					e.preventDefault()
					rs.updateState({ selectedNote: x.ID })
					setContextMenu({
						position: {
							x: e.clientX,
							y: e.clientY,
						},
						width: ACTION_CONTEXT_MENU_WIDTH,
						items: () => getNoteActions(rs, x),
					})
				}}
			/>
		</>
	)
}

const NotePreviewWidget = <T extends Record<string, unknown>>(props: {
	rs: ReducerState<T>
	note: NoteInfo<T>
	flatTags: Record<number, NoteTag>
}): React.JSX.Element => {
	const rs = props.rs
	const x = props.note
	const attachment = getAttachmentInfo(x.Attachment)
	const archivedPrefix = x.Archived ? '[Archived]: ' : ''
	const textPreview = x.Note?.substring(0, 160).replaceAll('\n', ' ') ?? ''
	return (
		<div className="h-full relative whitespace-normal">
			<div className="line-clamp-2">
				{/* Tag indicators */}
				<CJSX cond={props.rs.state.showTagIndicators}>
					{x.Tags.map(t => props.flatTags[t]).map(tag => (
						<TagIndicator tag={tag} includeText={false} />
					))}
				</CJSX>

				{/* Bold title */}
				<span className="font-bold text-black inline">
					<TextSearched
						text={x.Title ?? ''}
						needles={rs.state.searchText}
						tailwind={true}
					/>
				</span>

				{/* Separator */}
				{x.Title && x.Note && (
					<span className="text-neutral-400 inline"> • </span>
				)}

				{/* Body text preview */}
				<span className="text-neutral-600 inline group-hover:text-black">
					<TextSearched
						text={`${archivedPrefix}${textPreview}`}
						needles={rs.state.searchText}
						tailwind={true}
					/>
				</span>
			</div>

			{/* Attachment icon */}
			<CJSX cond={Boolean(attachment)}>
				<div
					className={BuildClass([
						'absolute bottom-0 right-0',
						'w-8 h-5',
						'flex items-center justify-end',
						'pointer-events-none',
					])}
					title={attachment?.title}
				>
					<div
						className={BuildClass([
							'absolute inset-0',
							// Gradient overlay
							'bg-gradient-to-l',
							'from-[var(--row-bg)]',
							'via-[var(--row-bg)]',
							'to-transparent',
						])}
					/>
					<img
						className="w-4 h-4 mr-1 relative z-10"
						src={attachment?.icon}
						alt={attachment?.title}
					/>
				</div>
			</CJSX>

			{/* Kebab menu */}
			<div
				className={BuildClass([
					'absolute w-12 h-8 z-20',
					'top-[-3px] right-[-5px]',
					// Hide until row is hovered
					'opacity-0 group-hover:opacity-100',
					'transition-opacity duration-100',
				])}
			>
				<div
					className={BuildClass([
						'w-full h-full text-right',
						// Gradient overlay
						'bg-gradient-to-bl',
						'from-[var(--row-bg)]',
						'via-[var(--row-bg)]',
						'to-transparent',
					])}
				>
					<KebabMenu
						className={{
							div: [
								'w-8 h-full rounded-md ml-auto',
								'cursor-pointer hover:bg-[hsla(0,0%,0%,0.2)]',
								'grid place-items-center',
							],
							img: 'w-4 h-4',
						}}
						preferLeft={true}
						width={ACTION_CONTEXT_MENU_WIDTH}
						items={() => getNoteActions(rs, x)}
					/>
				</div>
			</div>
		</div>
	)
}

const getAttachmentInfo = (
	attachment: Maybe<FileAttachment>,
): Maybe<{
	icon: string
	title: string
}> => {
	if (!attachment || !attachment.MimeType) {
		return null
	}
	if (attachment.MimeType == 'application/pdf') {
		return {
			icon: '/static/img/i8/cute-pdf-icon.svg',
			title: 'PDF document attachment',
		}
	}
	if (attachment.MimeType.startsWith('image/')) {
		return {
			icon: '/static/img/i8/w11c-image.svg',
			title: 'Image attachment',
		}
	}
	return {
		icon: '/static/img/i8/color-note.svg',
		title: 'Attachment',
	}
}
