import {
	AddressCard,
	BuildClass,
	ContactCard,
	Do,
	Maybe,
	ModelType,
	PostCode,
	fsmData,
	leftpad,
	timer,
} from '../../universal'
import { React, _ } from '../lib'
import { J2rButton } from './component-buttons'
import { J2rComboBox } from './component-combobox'
import { IRIS } from './component-iris'
import { Alerts } from './component-main'
import { J2rText, j2r, j2rLabel, reactFlyout, reactFlyoutNew } from './component-react'
import { J2rTextAutoComplete } from './component-react-autocomplete'
import { NewSystemFlyoutInstance } from './flyouts'
import { Bindings, Focusable, stubDiv, stubInput } from './ui5'

type AddressWidgetProps = {
	value?: Partial<AddressCard>
	isPostal: boolean
	textOverride?: string
	cl?: string
	title?: string
	onUpdate?: (value: AddressCard, dmodel: ModelType<AddressCard>) => void
	getPostCodes?: (
		resolve: (value: { [key: string]: PostCode }) => void,
		reject: (reason?: any) => void,
	) => void
	noClick?: boolean
}

// Address widget
export class AddressWidget extends React.Component<
	AddressWidgetProps,
	{
		isFocused: boolean
	}
> {
	hiddenInput: React.RefObject<HTMLInputElement>

	constructor(props: AddressWidgetProps) {
		super(props)
		Bindings(this, [this.openModalForm, this.evKeyPress])
		this.hiddenInput = React.createRef()
		this.state = { isFocused: false }
	}

	override render() {
		return j2r({
			cl: BuildClass({
				address_preview_widget: true,
				react: true,
				[this.props.cl ?? '']: true,
				filled: this.props.value != null,
				noclick: this.props.noClick ?? false,
				focused: this.state.isFocused,
			}),
			children: [
				this.buildHiddenInput(),
				{
					tag: 'span',
					cl: 'icon',
					key: 'icon',
					children: [
						{
							tag: 'img',
							key: 'img',
							src: '/static/img/svg/map-marker.svg',
						},
					],
				},
				{
					tag: 'span',
					cl: 'lbl',
					key: 'lbl',
					text: Do(() => {
						const A = this.props.value
						if (this.props.textOverride != null) {
							return this.props.textOverride
						} else if (A == null) {
							return ''
						} else if (A.Complete) {
							return `${A.Address}, ${A.Locale} ${A.State} ${A.PostCode}`
						}
						return A.Note ?? ''
					}),
				},
			],
			onClick: this.openModalForm,
		})
	}

	buildHiddenInput() {
		return {
			tag: 'span',
			cl: 'focus-control-wrapper',
			key: 'focus-wrapper',
			children: [
				{
					tag: 'input',
					key: 'focus-control',
					cl: 'focus-control',
					onFocus: () => {
						this.setState({ isFocused: true })
					},
					onBlur: () => {
						this.setState({ isFocused: false })
					},
					onKeyPress: this.evKeyPress,
					onClick: (e: React.MouseEvent) => {
						e.stopPropagation()
					},
					ref: this.hiddenInput,
				},
			],
		}
	}

	evKeyPress(e: React.KeyboardEvent) {
		const stopProp = () => {
			e.stopPropagation()
			e.preventDefault()
		}
		switch (e.which) {
			// Enter and space open up the form
			case 13:
			case 32:
				stopProp()
				this.openModalForm()
				break
		}
	}

	openModalForm() {
		// Don't open it if the click setting was false
		if (this.props.noClick) {
			return
		}

		// Get the title of the fly-out
		let title = Do(() => {
			if (this.props.value != null) {
				return 'Edit Address Card'
			}
			return 'New Address Card'
		})
		if (this.props.title) {
			title += `: ${this.props.title}`
		}

		// Create fly-out to edit the address
		reactFlyoutNew(title, [720, 400], {
			tag: AddressEditForm,
			address: this.props.value,
			getPostCodes: this.props.getPostCodes,
			isPostal: this.props.isPostal,
			sourceElement: this.hiddenInput.current,
			onUpdate: (v: number, dmodelAddresses: ModelType<AddressCard>) => {
				const addr = dmodelAddresses?.[v] ?? null
				;(this.props.onUpdate ?? _.noop)(addr, dmodelAddresses)
			},
		})
	}
}

type AddressEditFormProps = {
	address?: Partial<AddressCard>
	onUpdate?: (value: number, dmodel: ModelType<AddressCard>) => void
	getPostCodes?: (
		resolve: (value: { [key: string]: PostCode }) => void,
		reject: (reason?: any) => void,
	) => void
	isPostal: boolean
	sourceElement: HTMLInputElement
	flyout: NewSystemFlyoutInstance
}

// Address form
class AddressEditForm extends React.Component<
	AddressEditFormProps,
	{
		address: string
		locale: string
		state: string
		postCode: string
		note: string
		previewURL: string
		showWidget: boolean
		isLoading: boolean
		previewURLStatic?: string
		previewURLWidget?: string
	}
> {
	static states = ['ACT', 'VIC', 'NSW', 'QLD', 'TAS', 'SA', 'WA', 'NT']

	constructor(props: AddressEditFormProps) {
		super(props)
		Bindings(this, [this.save, this.cancel, this.preview])
		const value = this.props.address ?? {}
		this.state = {
			// Address information in form
			address: value.Address ?? '',
			locale: value.Locale ?? '',
			state: value.State ?? '',
			postCode: Do(() => {
				const pc = value.PostCode ?? ''
				if (pc) {
					return leftpad(String(pc), 4, '0')
				}
				return ''
			}),
			note: value.Note ?? '',
			// Previews
			previewURL: '',
			showWidget: false,
			isLoading: false,
		}
	}

	override componentWillUnmount() {
		timer(() => {
			this.props.sourceElement?.focus()
		})
	}

	override render() {
		return j2r({
			cl: 'frmEditAddress',
			children: [
				{
					cl: 'lhs col',
					key: 'lhs',
					children: [
						// Address
						j2rLabel('Address', '', () => ({
							tag: J2rText,
							cl: this.props.isPostal ? 'multi-line' : undefined,
							multiLine: this.props.isPostal,
							autoFocus: true,
							value: this.state.address,
							onUpdate: (v: string) => {
								this.setState({ address: v })
							},
							autoComplete: 'off',
						})),

						// Locale
						j2rLabel('Locale', '', () => ({
							tag: J2rTextAutoComplete,
							value: this.state.locale,
							placeholder: 'Start typing to auto-complete...',
							searchExtra: true,
							getOptions: async (
								resolve: (
									value: { key: number; text: string; extra: string }[],
								) => void,
							) => {
								const fn = this.props.getPostCodes ?? Promise.resolve
								return new Promise(fn).then(postCodes => {
									resolve(
										fsmData(postCodes, {
											filter: pc => pc.IsValid,
											map: pc => ({
												key: pc.ID,
												text: pc.Locality,
												extra: Do(() => {
													const postcode = leftpad(
														String(pc.PostCode),
														4,
														'0',
													)
													return `${pc.State} ${postcode}`
												}),
											}),
										}),
									)
								})
							},
							onUpdate: async (txt: string, objKey: Maybe<string>) => {
								if (objKey == null) {
									this.setState({ locale: txt })
									return undefined
								}
								// Fill the state and postcode from this locality
								const fn = this.props.getPostCodes ?? Promise.resolve
								return new Promise(fn).then(postCodes => {
									const locale = postCodes[objKey]
									if (locale) {
										this.setState({
											locale: locale.Locality,
											state: locale.State,
											postCode: leftpad(locale.PostCode, 4),
										})
									}
								})
							},
						})),

						// State and post code
						{
							cl: 'j2r-label state-postcode',
							key: 'state-postcode',
							children: [
								{
									tag: 'span',
									cl: 'lbl',
									key: 'lbl-state',
									text: 'State',
								},
								{
									tag: J2rComboBox,
									key: 'state',
									options: fsmData(AddressEditForm.states, {
										sort: x => x,
										map: x => ({ value: x, text: x }),
									}),
									maxLength: 3,
									value: this.state.state,
									onUpdate: (v: { value: Maybe<string> }) => {
										this.setState({ state: v.value ?? '' })
									},
								},
								{
									tag: 'span',
									key: 'lbl-postcode',
									cl: 'lbl lbl-postcode',
									text: 'Post Code',
								},
								{
									tag: J2rText,
									key: 'postcode',
									autoComplete: 'off',
									maxLength: 4,
									value: this.state.postCode,
									onUpdate: (v: string) => {
										this.setState({ postCode: v })
									},
								},
							],
						},

						// Note
						j2rLabel('Note', '', () => ({
							tag: J2rText,
							autoComplete: 'off',
							value: this.state.note,
							onUpdate: (v: string) => {
								this.setState({ note: v })
							},
						})),

						// Map preview button
						{
							key: 'map-preview-wrapper',
							cl: 'cntr map-preview',
							children: [
								{
									tag: J2rButton,
									key: 'map-preview',
									type: 'standard',
									label: 'Map Preview →',
									onClick: this.preview,
								},
							],
						},
					],
				},
				{
					cl: 'rhs col',
					key: 'rhs',
					children: [
						Do(() => {
							if (this.state.previewURLStatic && !this.state.showWidget) {
								return {
									tag: 'img',
									key: 'img',
									src: this.state.previewURLStatic,
									title: 'Click to convert to an interactive Google Map',
									onClick: () => {
										this.setState({ showWidget: true })
									},
								}
							} else if (
								this.state.showWidget &&
								this.state.previewURLWidget
							) {
								return {
									tag: 'iframe',
									key: 'iframe',
									src: this.state.previewURLWidget,
								}
							}
							return undefined
						}),
					],
				},
				{
					cl: 'buttons cntr',
					key: 'buttons',
					children: [
						{
							tag: J2rButton,
							type: 'submit',
							key: 'save',
							label: 'Save',
							disabled: this.state.isLoading,
							onClick: this.save,
						},
						{
							tag: J2rButton,
							type: 'standard',
							key: 'cancel',
							label: 'Cancel',
							disabled: this.state.isLoading,
							onClick: this.cancel,
						},
					],
				},
			],
		})
	}

	preview() {
		// Get the full address text
		const text = Do(() => {
			const [a, b, c, d] = [
				this.state.address.trim(),
				this.state.locale.trim(),
				this.state.state.trim(),
				String(this.state.postCode).trim(),
			]
			if (!(a && b && c && d)) {
				return null
			}
			return `${a}, ${b} ${c} ${d}`
		})

		// Exit early if there's no address to preview
		if (text == null) {
			return
		}

		// Put the preview in the box
		const key = 'AIzaSyBip-ZTD43PTW0B04uDRv_hum_u-m5muc8'
		this.setState({
			previewURLStatic: Do(() => {
				const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
				const addr_text = encodeURIComponent(text)
				let args = `?markers=${addr_text}&key=${key}`
				args += '&zoom=16&size=350x290&maptype=roadmap'
				return baseUrl + args
			}),
		})

		// Build the new widget and place on screen
		this.setState({
			previewURLWidget: Do(() => {
				const baseUrl = 'https://www.google.com/maps/embed/v1/place'
				return `${baseUrl}?q=${encodeURIComponent(text)}&key=${key}`
			}),
		})

		// No longer showing the widget (yet)
		this.setState({ showWidget: false })
	}

	cancel() {
		;(this.props.flyout.Close ?? _.noop)()
	}

	save() {
		// Check if all fields are empty - if so, the address is now null
		const vals = [
			this.state.address,
			this.state.locale,
			this.state.state,
			this.state.postCode,
			this.state.note,
		]
		if (vals.filter(x => x).length === 0) {
			;(this.props.onUpdate ?? _.noop)(null)
			;(this.props.flyout.Close ?? _.noop)()
			return
		}

		// Send request to server to add the new address and get the key
		this.setState({ isLoading: true })
		IRIS.Send({
			data: {
				progID: 2,
				funcID: 7,
				address: {
					Address: this.state.address.trim(),
					Locale: this.state.locale.trim(),
					State: this.state.state.trim(),
					PostCode: String(this.state.postCode).trim(),
					Note: this.state.note.trim(),
				},
			},
			no: data => Alerts.Alert({ msg: data.message }),
			yes: data => {
				// Update data model, and set the value in the callback to
				// point to the new address key that the server returned
				// Remove the fly-out from view
				this.props.onUpdate?.(
					data.new_key as number,
					data.addresses as ModelType<AddressCard>,
				)
				this.props.flyout.Close?.()
			},
			any: () => {
				this.setState({ isLoading: false })
			},
		})
	}
}

type ContactWidgetProps = {
	cl?: string
	value?: Partial<ContactCard>
	title?: string
	noClick?: boolean
	onUpdate?: (value: ContactCard, dmodel: ModelType<ContactCard>) => void
	onFocus?: () => void
	onBlur?: () => void
	readOnly?: boolean
	// Backup event in case it's in a grid and can't use `onUpdate`
	onUpdateBackup?: (value: ContactCard, dmodel: ModelType<ContactCard>) => void
}

export const ContactWidgetHook = React.forwardRef(
	(props: ContactWidgetProps, ref: React.ForwardedRef<Focusable<HTMLInputElement>>) => {
		// State and refs
		const [isFocused, setIsFocused] = React.useState(false)
		const element = React.useRef(stubDiv)
		const hiddenInput = React.useRef(stubInput)

		// Handle keypress events
		const evKeyPress = (e: React.KeyboardEvent) => {
			const stopProp = () => {
				e.stopPropagation()
				e.preventDefault()
			}
			switch (e.which) {
				// Enter and space open up the form
				case 13:
				case 32:
					stopProp()
					openModalForm()
					break
			}
		}

		// Event to open flyout to edit the contact card
		const openModalForm = () => {
			if (props.readOnly ?? false) {
				return
			}
			// Don't do this if it has noClick enabled
			if (!props.noClick) {
				reactFlyout(
					'Edit Contact Card',
					[320, 370],
					<ContactEditForm
						contact={props.value}
						sourceElement={hiddenInput.current}
						onUpdate={(v: number, dmodelContacts: ModelType<ContactCard>) => {
							const card = dmodelContacts[v]
							props.onUpdate?.(card, dmodelContacts)
							props.onUpdateBackup?.(card, dmodelContacts)
						}}
					/>,
				)
			}
		}

		// Imperative handle
		React.useImperativeHandle(ref, () => ({
			getElement: () => hiddenInput.current,
			focus: () => {
				hiddenInput.current.focus()
			},
			select: () => {
				hiddenInput.current.focus()
			},
		}))

		// Render DOM
		const buildSpan = (icon: string, value: Maybe<string>) => (
			<span
				className={BuildClass({
					icon: true,
					[icon]: true,
					exists: Boolean(value),
				})}
				title={value ? `${icon}: ${value}` : `No ${icon}`}
			>
				<img src={`/static/img/svg/${icon}.svg`} />
			</span>
		)
		return (
			<div
				className={BuildClass({
					contact_preview_widget: true,
					react: true,
					[props.cl ?? '']: true,
					noclick: props.noClick ?? false,
					focused: isFocused,
				})}
				title={props.title}
				ref={element}
				onClick={openModalForm}
			>
				<span className="focus-control-wrapper">
					<input
						className="focus-control"
						onFocus={() => {
							props.onFocus?.()
							setIsFocused(true)
						}}
						onBlur={() => {
							props.onBlur?.()
							setIsFocused(false)
						}}
						onKeyPress={evKeyPress}
						onClick={(e: React.MouseEvent) => {
							e.stopPropagation()
						}}
						ref={hiddenInput}
					/>
				</span>
				{buildSpan('email', props.value?.Email)}
				{buildSpan('phone', props.value?.Phone)}
				{buildSpan('mobile', props.value?.Mobile)}
				{buildSpan('fax', props.value?.Fax)}
				{buildSpan('website', props.value?.Website)}
			</div>
		)
	},
)

// Contact widget
export class ContactWidget extends React.Component<
	ContactWidgetProps,
	{
		isFocused: boolean
	}
> {
	element: React.RefObject<HTMLDivElement>
	hiddenInput: React.RefObject<HTMLInputElement>

	constructor(props: ContactWidgetProps) {
		super(props)
		Bindings(this, [this.openModalForm, this.evKeyPress])
		this.element = React.createRef()
		this.hiddenInput = React.createRef()
		this.state = { isFocused: false }
	}

	override render() {
		return j2r({
			ref: this.element,
			cl: BuildClass({
				contact_preview_widget: true,
				react: true,
				[this.props.cl ?? '']: true,
				noclick: this.props.noClick ?? false,
				focused: this.state.isFocused,
			}),
			children: [
				this.buildHiddenInput(),
				this.span('email', this.props.value?.Email),
				this.span('phone', this.props.value?.Phone),
				this.span('mobile', this.props.value?.Mobile),
				this.span('fax', this.props.value?.Fax),
				this.span('website', this.props.value?.Website),
			],
			onClick: this.openModalForm,
		})
	}

	buildHiddenInput() {
		return {
			tag: 'span',
			cl: 'focus-control-wrapper',
			key: 'focus-wrapper',
			children: [
				{
					tag: 'input',
					key: 'focus-control',
					cl: 'focus-control',
					onFocus: () => {
						this.setState({ isFocused: true })
					},
					onBlur: () => {
						this.setState({ isFocused: false })
					},
					onKeyPress: this.evKeyPress,
					onClick: (e: React.MouseEvent) => {
						e.stopPropagation()
					},
					ref: this.hiddenInput,
				},
			],
		}
	}

	span(icon: string, value: Maybe<string>) {
		return {
			tag: 'span',
			key: icon,
			cl: BuildClass({
				icon: true,
				[icon]: true,
				exists: Boolean(value),
			}),
			children: [
				{
					tag: 'img',
					key: 'icon',
					src: `/static/img/svg/${icon}.svg`,
				},
			],
			title: Do(() => {
				if (value) {
					return `${icon}: ${value}`
				}
				return `No ${icon}`
			}),
		}
	}

	evKeyPress(e: React.KeyboardEvent) {
		const stopProp = () => {
			e.stopPropagation()
			e.preventDefault()
		}
		switch (e.which) {
			// Enter and space open up the form
			case 13:
			case 32:
				stopProp()
				this.openModalForm()
				break
		}
	}

	openModalForm() {
		// Don't do this if it has noClick enabled
		if (!this.props.noClick) {
			reactFlyoutNew('Edit Contact Card', [320, 370], {
				tag: ContactEditForm,
				contact: this.props.value,
				sourceElement: this.hiddenInput.current,
				onUpdate: (v: number, dmodelContacts: ModelType<ContactCard>) => {
					const card = dmodelContacts[v]
					;(this.props.onUpdate ?? _.noop)(card, dmodelContacts)
					;(this.props.onUpdateBackup ?? _.noop)(card, dmodelContacts)
				},
			})
		}
	}
}

type ContactEditFormProps = {
	contact?: Partial<ContactCard>
	onUpdate?: (value: number, dmodel: ModelType<ContactCard>) => void
	sourceElement: HTMLInputElement
	flyout?: NewSystemFlyoutInstance
}

// Contact form
class ContactEditForm extends React.Component<
	ContactEditFormProps,
	{
		email: string
		phone: string
		mobile: string
		fax: string
		website: string
		errors: {
			email: boolean
			phone: boolean
			mobile: boolean
			fax: boolean
			website: boolean
		}
	}
> {
	constructor(props: ContactEditFormProps) {
		super(props)
		Bindings(this, [this.save, this.cancel, this.validateForm])

		// Create the initial state
		this.state = {
			email: this.props.contact?.Email ?? '',
			phone: this.props.contact?.Phone ?? '',
			mobile: this.props.contact?.Mobile ?? '',
			fax: this.props.contact?.Fax ?? '',
			website: this.props.contact?.Website ?? '',
			errors: {
				email: false,
				phone: false,
				mobile: false,
				fax: false,
				website: false,
			},
		}

		// Verify the state and auto-format stuff (e.g. phone number spacing groups)
		this.state = _.assign({}, this.state, this.validateForm(false))
	}

	override componentWillUnmount() {
		timer(() => {
			this.props.sourceElement?.focus()
		})
	}

	override render() {
		return j2r({
			cl: 'frmEditContact',
			children: [
				// Email
				j2rLabel('Email', '', () => ({
					tag: J2rText,
					cl: this.state.errors.email ? 'error' : undefined,
					autoFocus: true,
					autoComplete: 'off',
					value: this.state.email,
					onBlur: () => this.validateForm(true),
					onUpdate: (v: string) => {
						this.setState({ email: v }, () => this.validateForm())
					},
				})),

				// Phone
				j2rLabel('Phone', '', () => ({
					tag: J2rText,
					cl: this.state.errors.phone ? 'error' : undefined,
					autoComplete: 'off',
					value: this.state.phone,
					onBlur: () => this.validateForm(true),
					onUpdate: (v: string) => {
						this.setState({ phone: v }, () => this.validateForm())
					},
				})),

				// Mobile
				j2rLabel('Mobile', '', () => ({
					tag: J2rText,
					cl: this.state.errors.mobile ? 'error' : undefined,
					autoComplete: 'off',
					value: this.state.mobile,
					onBlur: () => this.validateForm(true),
					onUpdate: (v: string) => {
						this.setState({ mobile: v }, () => this.validateForm())
					},
				})),

				// Fax
				j2rLabel('Fax', '', () => ({
					tag: J2rText,
					cl: this.state.errors.fax ? 'error' : undefined,
					autoComplete: 'off',
					value: this.state.fax,
					onBlur: () => this.validateForm(true),
					onUpdate: (v: string) => {
						this.setState({ fax: v }, () => this.validateForm())
					},
				})),

				// Website
				j2rLabel('Website', '', () => ({
					tag: J2rText,
					cl: this.state.errors.website ? 'error' : undefined,
					autoComplete: 'off',
					value: this.state.website,
					onBlur: () => this.validateForm(true),
					onUpdate: (v: string) => {
						this.setState({ website: v }, () => this.validateForm())
					},
				})),

				// Save/Cancel buttons
				{
					cl: 'buttons cntr',
					key: 'buttons',
					children: [
						{
							tag: J2rButton,
							type: 'submit',
							disabled: _.size(_.compact(_.values(this.state.errors))) > 0,
							key: 'save',
							label: 'Save',
							onClick: this.save,
						},
						{
							tag: J2rButton,
							type: 'standard',
							key: 'cancel',
							label: 'Cancel',
							onClick: this.cancel,
						},
					],
				},
			],
		})
	}

	validateForm(validate = false) {
		// Validate the values
		const email = this.isValidEmail(this.state.email)
		let phone = this.isValidPhone(this.state.phone)
		let mobile = this.isValidMobile(this.state.mobile)
		const fax = this.isValidPhone(this.state.fax)
		const website = this.isValidWebsite(this.state.website)

		// Check if the mobile/phone numbers can be exchanged
		if (!phone && !this.state.mobile && this.isValidMobile(this.state.phone)) {
			mobile = this.isValidMobile(this.state.phone)
			phone = ''
		}
		if (!mobile && !this.state.phone && this.isValidPhone(this.state.mobile)) {
			mobile = ''
			phone = this.isValidPhone(this.state.mobile)
		}

		// Set the form values
		const stateDelta = {
			email: email === false ? this.state.email : email,
			phone: phone === false ? this.state.phone : phone,
			mobile: mobile === false ? this.state.mobile : mobile,
			fax: fax === false ? this.state.fax : fax,
			website: website === false ? this.state.website : website,
			// Set the error flags
			errors: {
				email: email === false,
				phone: phone === false,
				mobile: mobile === false,
				fax: fax === false,
				website: website === false,
			},
		}
		if (validate && _.size(stateDelta) > 0) {
			this.setState(stateDelta)
		}
		return stateDelta
	}

	isValidEmail(email: Maybe<string>) {
		// Get value trimmed
		email = (email ?? '').replace(/\s/g, '')
		if (!email) {
			return ''
		}

		// Test the format of the email address
		const re = new RegExp(`\
(\
([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)\
|(".+")\
)\
@\
(\
(\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])\
|\
(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})\
)\
$\
`)
		if (!re.test(email)) {
			return false
		}
		return email
	}

	isValidPhone(phone: Maybe<string>) {
		// Get value trimmed
		let b, c
		phone = (phone ?? '').replace(/[^0-9]/g, '')
		if (!phone) {
			return ''
		}

		// Remove country prefix
		if (phone.substring(0, 2) === '61') {
			phone = `0${phone.substring(2)}`
		}

		// If it's not the correct number of digits, it's an error
		if (phone.length !== 10 && phone.length !== 8) {
			return false
		}

		// If a mobile number, also an error
		if (phone.length === 10 && phone.substring(0, 2) === '04') {
			return false
		}

		// Format number properly
		if (phone.length === 10) {
			let a
			;[a, b, c] = [
				phone.substring(0, 2),
				phone.substring(2, 6),
				phone.substring(6, 10),
			]
			return `${a} ${b} ${c}`
		}
		;[b, c] = [phone.substring(0, 4), phone.substring(4, 8)]
		return `${b} ${c}`
	}

	isValidMobile(mobile: Maybe<string>) {
		// Get value trimmed
		mobile = (mobile ?? '').replace(/[^0-9]/g, '')
		if (!mobile) {
			return ''
		}

		// Remove country prefix
		if (mobile.substring(0, 2) === '61') {
			mobile = `0${mobile.substring(2)}`
		}

		// If it's not the correct number of digits, it's an error
		if (mobile.length !== 10) {
			return false
		}

		// Check prefix and length
		if (mobile.substring(0, 2) !== '04' || mobile.length !== 10) {
			return false
		}

		// Format the number properly
		const [a, b, c] = [
			mobile.substring(0, 4),
			mobile.substring(4, 7),
			mobile.substring(7, 10),
		]
		return `${a} ${b} ${c}`
	}

	isValidWebsite(website: Maybe<string>) {
		// Get value trimmed
		website = (website ?? '').replace(/\s/g, '').toLowerCase()
		if (website.substring(0, 8) === 'https://') {
			website = website.substring(8)
		}
		if (website.substring(0, 7) === 'http://') {
			website = website.substring(7)
		}

		if (!website) {
			return ''
		}

		// Test the format of the website address
		const re =
			/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
		if (!re.test(website)) {
			return false
		}
		return website
	}

	cancel() {
		;(this.props.flyout.Close ?? _.noop)()
	}

	save() {
		IRIS.Send({
			data: {
				progID: 2,
				funcID: 8,
				contact: {
					Email: this.state.email,
					Phone: this.state.phone,
					Mobile: this.state.mobile,
					Fax: this.state.fax,
					Website: this.state.website,
				},
			},
			yes: data => {
				// Update data model, and set the value in the spreadsheet
				// to point to the new address key that the server returned
				// Remove the fly-out from view
				this.props.onUpdate?.(
					data.new_key as number,
					data.contacts as ModelType<ContactCard>,
				)
				this.props.flyout.Close?.()
			},
		})
	}
}
