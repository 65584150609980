import { BuildClass, DOMClassNameInput } from '../../../universal'
import { React } from '../../lib'
import { ContextMenuItem, setContextMenu } from './context-menu'
import { stubImage } from './stubs'

type KebabMenuProps = {
	/** Items to display in the menu */
	items: () => ContextMenuItem[]
	/** DOM classes for the wrapper div and icon */
	className?: {
		div?: DOMClassNameInput
		img?: DOMClassNameInput
	}
	/** Move the spawn position of the context menu, relative to where it would be */
	offset?: {
		x?: number
		y?: number
	}
	/** Tooltip for the icon (defaults to "Click for more options") */
	title?: string
	/** Set the width of the context menu */
	width?: number
	/** If true, the context menu will spawn to the left of the icon */
	preferLeft?: boolean
	/** Called when the context menu is closed */
	onClose?: () => void
	/** Prevents mouse down event bubbling */
	stopPropagation?: boolean
}

/**
 * Renders a kebab menu icon inside of a wrapper div that, when clicked, displays a context menu
 * The context menu is positioned based on the icon and not the cursor
 */
export const KebabMenu = (props: KebabMenuProps): React.JSX.Element => {
	// Hold image reference to get its position when clicking
	const refImg = React.useRef<HTMLImageElement>(stubImage)

	// Track whether it's open. If it is and the props change, we need to update
	const [isOpen, setIsOpen] = React.useState(false)

	// Event when clicking the button
	const openMenu = () => {
		// Get the position of the icon
		const rect = refImg.current.getBoundingClientRect()
		const left = (props.preferLeft ?? false) ? rect.right : rect.left
		const top = rect.bottom

		// Set the context menu
		setIsOpen(true)
		setContextMenu({
			position: {
				x: left + (props.offset?.x ?? 0),
				y: top + (props.offset?.y ?? 0),
				preferLeft: props.preferLeft,
			},
			items: props.items,
			width: props.width,
			onClose: () => {
				setIsOpen(false)
				props.onClose?.()
			},
		})
	}

	// If the props change, we need to update
	React.useEffect(() => {
		if (isOpen) {
			openMenu()
		}
	}, [isOpen, props.items.length])

	// Render a kebab icon image inside of a wrapper div
	return (
		<button
			className={BuildClass(props.className?.div ?? '')}
			onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {
				// Stop propagation, if requested
				e.preventDefault()
				if (props.stopPropagation) {
					e.stopPropagation()
				}
				openMenu()
			}}
			onClick={e => {
				e.preventDefault()
				e.stopPropagation()
				openMenu()
			}}
			title={props.title ?? 'Click for more options'}
		>
			<img
				ref={refImg}
				className={BuildClass(props.className?.img ?? '')}
				src="/static/img/svg/kebab.svg"
				alt="Kebab Menu"
			/>
		</button>
	)
}

/** A wrapper for `KebabMenu` with tailwind styles to fit nicely in a <Toolbar /> */
export const KebabMenuToolbar = (
	props: Omit<KebabMenuProps, 'className'>,
): React.JSX.Element => (
	<div
		className={BuildClass([
			'relative w-4 h-8 left-[-2px] mr-[2px]',
			'inline-flex items-center justify-center',
			'opacity-70 hover:opacity-100',
		])}
	>
		<KebabMenu
			{...props}
			offset={{
				y: 5,
				...props.offset,
			}}
			className={{
				div: [
					'absolute w-8 h-8 left-[-8px]',
					'flex items-center justify-center',
					'border border-transparent rounded-[3px]',
					'focus:border-[#ccc]',
					'cursor-pointer hover:bg-[#ffc] hover:border-[#ccc]',
					'hover:shadow-[0_8px_5px_-5px_rgba(0,0,0,0.25),0_1px_2px_rgba(0,0,0,0.15),0_0_20px_rgba(0,0,0,0.05)_inset]',
				],
				img: ['w-4 h-4'],
			}}
		/>
	</div>
)
