import { BuildClass, DateTimeObj } from '../../../../universal'
import { _, React } from '../../../lib'
import { setContextMenu } from '../context-menu'
import { HelpTooltip } from '../help'
import { KebabMenu } from '../kebab'
import { CJSX } from '../meta-types'
import { TextSearched } from '../text'
import { getNoteActions } from './actions'
import { NoteAttachment } from './attachments'
import { editNoteTags } from './edit'
import { generateContent } from './generative'
import { getFlattenedTags, TagIndicator } from './tags'
import { ACTION_CONTEXT_MENU_WIDTH, NoteInfo, ReducerState } from './types'

export const NoteDetails = <T extends Record<string, unknown>>(props: {
	rs: ReducerState<T>
}): React.JSX.Element => {
	const rs = props.rs

	// Get the details of the currently-selected note
	const selectedNote = rs.props.notes.find(x => x.ID == rs.state.selectedNote)
	const hasAttachment = Boolean(selectedNote?.Attachment?.Checksum)

	// If no note is selected
	if (!selectedNote) {
		return (
			<div className="h-full overflow-y-auto px-2 bg-neutral-300 relative">
				<span
					className={BuildClass([
						'absolute top-1/3 left-1/2 translate-x-[-50%] translate-y-[-50%]',
						'font-light text-xl text-neutral-600 text-center',
					])}
				>
					Select a note
				</span>
			</div>
		)
	}

	// Render
	const created = DateTimeObj.parse(selectedNote.Created)
	return (
		<div className="relative h-full overflow-y-auto bg-neutral-300 flex flex-col">
			{/* Show info about author and timestamp */}
			<div className="p-3 font-condensed select-none text-right pr-10">
				<CJSX cond={Boolean(selectedNote.CreatedAuthor)}>
					<span className="text-neutral-600 mr-2">
						Author: {selectedNote.CreatedAuthor}
					</span>
					<span className="text-neutral-600 inline"> • </span>
				</CJSX>
				<span
					className="text-neutral-600"
					title={created.fmtDMY({
						seconds: true,
						weekday: true,
						shortYear: false,
					})}
				>
					{created.fmtDMY({ seconds: false, weekday: true, shortYear: true })}
				</span>
			</div>

			{/* Overlay for the kebab menu icon */}
			<div className="absolute right-[5px] top-[5px] w-8 h-8">
				<KebabMenu
					className={{
						div: [
							'w-full h-full p-2 rounded-md',
							'cursor-pointer hover:bg-[hsla(0,0%,0%,0.1)]',
						],
						img: 'w-4 h-4',
					}}
					preferLeft={true}
					stopPropagation={true}
					width={ACTION_CONTEXT_MENU_WIDTH}
					items={() => getNoteActions(rs, selectedNote)}
				/>
			</div>

			{/* Custom link above the note - for linking to entities */}
			{selectedNote.Meta ? (
				<div
					className={BuildClass([
						'p-2 m-1 bg-white',
						'border border-solid border-neutral-400 rounded-md',
						'font-condensed select-none text-center',
					])}
				>
					<CJSX cond={Boolean(selectedNote.Meta.link)}>
						<a
							className="text-blue-500 hover:text-red-600 cursor-pointer"
							title={selectedNote.Meta.text}
							onClick={e => {
								e.preventDefault()
								selectedNote.Meta?.link?.()
							}}
						>
							<TextSearched
								text={selectedNote.Meta.text}
								needles={rs.state.searchText}
								tailwind={true}
							/>
						</a>
					</CJSX>
					<CJSX cond={!Boolean(selectedNote.Meta.link)}>
						<TextSearched
							text={selectedNote.Meta.text}
							needles={rs.state.searchText}
							tailwind={true}
						/>
					</CJSX>
				</div>
			) : (
				<></>
			)}

			{/* Main note section */}
			<div
				className={BuildClass({
					'overflow-y-auto': true,
					'min-h-9 max-h-[30%]': hasAttachment,
					'flex flex-col': true,
				})}
			>
				{/* Actual note title/text */}
				<pre
					className={BuildClass({
						'p-2 m-1 bg-white': true,
						'border border-solid border-neutral-400 rounded-md': true,
						'font-condensed whitespace-pre-wrap select-text': true,
						'flex-grow': true,
					})}
					onContextMenu={e => {
						e.preventDefault()
						e.stopPropagation()
						setContextMenu({
							position: {
								x: e.clientX,
								y: e.clientY,
							},
							width: ACTION_CONTEXT_MENU_WIDTH,
							items: () => getNoteActions(rs, selectedNote),
						})
					}}
				>
					{/* Title */}
					<h2
						className={BuildClass([
							'mb-3 pr-6',
							'font-bold font-condensed text-xl leading-7 text-black',
						])}
					>
						<CJSX cond={Boolean(selectedNote.Title)}>
							<TextSearched
								text={selectedNote?.Title ?? ''}
								needles={rs.state.searchText}
								tailwind={true}
							/>
						</CJSX>
						<CJSX cond={!selectedNote.Title}>
							<span className="text-neutral-500 font-normal">
								No Title
								<CJSX
									cond={
										Boolean(rs.props.editing) &&
										!(rs.props.disableGenerativeAI ?? false)
									}
								>
									<HelpTooltip title="Use generative AI to quickly produce a title based on the note contents">
										<button
											className="ml-2 grayscale hover:grayscale-0"
											onClick={() => {
												generateContent(rs, selectedNote, [
													'title',
												])
											}}
										>
											<img
												src="/static/img/i8/color-sparkles.svg"
												className="w-4 h-4"
												alt="Generative AI"
											/>
										</button>
									</HelpTooltip>
								</CJSX>
							</span>
						</CJSX>
					</h2>

					{/* Body */}
					<CJSX cond={!selectedNote.Note}>
						<span className="text-neutral-500">
							No body text
							<CJSX
								cond={
									Boolean(rs.props.editing) &&
									!(rs.props.disableGenerativeAI ?? false)
								}
							>
								<HelpTooltip title="Use generative AI to quickly produce a note based on the attachment">
									<button
										className="ml-2 opacity-70 hover:opacity-100"
										onClick={() => {
											generateContent(rs, selectedNote, ['summary'])
										}}
									>
										<img
											src="/static/img/i8/color-sparkles.svg"
											className="w-4 h-4"
											alt="Generative AI"
										/>
									</button>
								</HelpTooltip>
							</CJSX>
						</span>
					</CJSX>
					<TextSearched
						text={selectedNote?.Note?.trim() ?? ''}
						needles={rs.state.searchText}
						tailwind={true}
					/>
				</pre>

				{/* Tags */}
				<TagInfo rs={rs} note={selectedNote} />
			</div>

			{/* Attachment */}
			<CJSX cond={hasAttachment}>
				<div className="flex-1 overflow-y-auto">
					<NoteAttachment attachment={selectedNote.Attachment} />
				</div>
			</CJSX>
		</div>
	)
}

const TagInfo = <T extends Record<string, unknown>>(props: {
	rs: ReducerState<T>
	note: NoteInfo<T>
}): React.JSX.Element => {
	const flatTags = getFlattenedTags(props.rs)
	const canUpdate = Boolean(props.rs.props.tagging?.onUpdate)
	return (
		<div className="px-1 my-1 text-right flex-shrink-0">
			<span
				className={BuildClass({
					'inline-block p-1': true,
					'cursor-pointer rounded-md': canUpdate,
					'hover:bg-yellow-100': canUpdate,
					'hover:outline hover:outline-1 hover:outline-blue-500': canUpdate,
				})}
				title={canUpdate ? 'Click to edit tags' : undefined}
				onClick={() => {
					if (canUpdate) {
						editNoteTags({
							rs: props.rs,
							note: props.note,
						})
					}
				}}
			>
				<CJSX cond={props.note.Tags.length == 0}>
					<span className="text-neutral-500">
						No tags
						<CJSX cond={canUpdate}>
							<> - click to add</>
						</CJSX>
					</span>
				</CJSX>
				{_.map(props.note.Tags, x => {
					const tag = flatTags[x]
					return (
						tag && (
							<div className="inline-block text-neutral-500" key={x}>
								<TagIndicator tag={tag} includeText={true} />
							</div>
						)
					)
				})}
			</span>
		</div>
	)
}
