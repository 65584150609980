import { createPhantomTypeSchema, Maybe, MaybeT, t } from '../../../../universal'
import { React } from '../../../lib'
import { ColorPicker } from '../color'
import { FormField, FormFieldJSX, readDynamic } from './types'

type ColorText = Maybe<string>

/** Colour-picker widget */
export const FormTypeColourPicker = <F,>(
	settings: FormField<F, ColorText, ColorText> & {
		/** If set to true, the min length is ignored if the length is 0 - default FALSE */
		nullable?: boolean
		/** Allows overriding which colours are shown as options */
		colours?: Maybe<string[][]>
	},
): FormFieldJSX<F, ColorText, ColorText> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		fixer: x => x?.trim().toLowerCase() || null,
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<ColorText>(MaybeT(t.String())),
		schemaRaw: createPhantomTypeSchema<ColorText>(MaybeT(t.String())),
		toPublic: (x: ColorText) => x,
		toInternal: (x: ColorText) => x,
	},
	llmInfo: formState => ({
		stringifiedType: 'text',
		description:
			readDynamic(settings.doc, formState) ?? readDynamic(settings.lbl, formState),
		reversal: x => String(x ?? '') || null,
	}),
	jsx: props => (
		<ColorPicker
			value={props.value ?? null}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			readOnly={props.readOnly || props.disabled}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			colors={settings.colours}
		/>
	),
})
