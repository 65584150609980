import { BuildClass, Maybe, tw } from '../../../../universal'
import { React } from '../../../lib'
import { CustomPDFContainer } from '../../component-pdf-viewer'
import { FileAttachment } from './types'

export const NoteAttachment = (props: { attachment: Maybe<FileAttachment> }) => {
	// If there's no attachment, show nothing
	const attachment = props.attachment
	if (!attachment || !attachment.Checksum) {
		return <></>
	}

	// Get the URL
	const url = `https://storage.trionline.com.au/get/${attachment.Checksum}`

	// Embed an inline version of image, video, audio, or PDF
	const wrappingClassName = tw`w-full h-full text-center`
	if (image_mimes.includes(attachment.MimeType ?? '')) {
		return (
			<div className={wrappingClassName}>
				<img className="mx-auto" src={url} />
			</div>
		)
	}
	if (video_mimes.includes(attachment.MimeType ?? '')) {
		return (
			<div className={wrappingClassName}>
				<video className="mx-auto" src={url} />
			</div>
		)
	}
	if (audio_mimes.includes(attachment.MimeType ?? '')) {
		return (
			<div className={wrappingClassName}>
				<audio className="mx-auto" src={url} />
			</div>
		)
	}
	if (pdf_mimes.includes(attachment.MimeType ?? '')) {
		return <CustomPDFContainer url={url} />
	}

	// Display a link to download the attachment
	// Put the file extension in there
	return (
		<div className={wrappingClassName}>
			<a
				href={url}
				className={BuildClass([
					'w-full p-2',
					'text-blue-600 text-lg',
					'hover:text-red-600 cursor-pointer',
				])}
				target="_blank"
			>
				{getAttachmentGeneratedFilename(attachment)}
			</a>
		</div>
	)
}

export const getAttachmentGeneratedFilename = (attachment: FileAttachment) => {
	const ext_key = attachment.MimeType as keyof typeof misc_file_type_extensions
	const ext: Maybe<string> = misc_file_type_extensions[ext_key] ?? null
	return ext ? `attachment.${ext}` : 'attachment'
}

const image_mimes = [
	'image/bmp',
	'image/gif',
	'image/jpeg',
	'image/png',
	'image/webp',
	'image/x-icon',
]

const pdf_mimes = ['application/pdf']

const video_mimes = ['video/mp4', 'video/webm']

const audio_mimes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/webm']

const misc_file_type_extensions = {
	'application/octet-stream': 'bin',
	'application/postscript': 'ps',
	'application/vnd.ms-fontobject': 'eot',
	'application/x-gzip': 'gz',
	'application/x-rar-compressed': 'rar',
	'application/zip': 'zip',
	'font/ttf': 'ttf',
	'font/woff': 'woff',
	'text/html; charset=utf-8': 'html',
	'text/plain; charset=utf-16le': 'txt',
	'text/plain; charset=utf-8': 'txt',
	'text/xml; charset=utf-8': 'xml',

	// Known file extensions (for adding/editing screen)
	'application/pdf': 'pdf',
	'image/jpeg': 'jpg',
	'image/png': 'png',
	'image/gif': 'gif',
	'image/webp': 'webp',
	'image/x-icon': 'ico',
	'audio/mpeg': 'mp3',
	'audio/wav': 'wav',
	'audio/ogg': 'ogg',
	'audio/webm': 'webm',
	'video/mp4': 'mp4',
	'video/webm': 'webm',
}
