import { BuildClass } from '../../../universal'
import { React, _ } from '../../lib'
import { CJSX, ConditionalObject, Focusable, useStateSync } from './meta-types'
import { stubDiv, stubInput } from './stubs'

// Define the base component props and the set of element props allowed
type checkboxBaseProps = {
	lbl: string
	value: boolean
	onUpdate: (value: boolean) => void
	cbStyle?: 'checkbox' | 'radio'
	classNameInner?: string
	classNameLabel?: string
	inLabel?: boolean // Enables custom click-event when not in a <label />
	children?: React.JSX.Element | React.JSX.Element[]
	disableInputBox?: boolean
}
type checkboxElementProps = Omit<
	React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>,
	keyof checkboxBaseProps | 'ref' | 'type' | 'checked' | 'onChange'
>

export type CheckboxProps = checkboxBaseProps & checkboxElementProps

// The checkbox component
const CheckboxComponent = (
	props: CheckboxProps,
	ref: React.ForwardedRef<Focusable<HTMLDivElement>>,
) => {
	// DOM references
	const container: React.MutableRefObject<HTMLDivElement> = React.useRef(stubDiv)
	const checkbox: React.MutableRefObject<HTMLInputElement> = React.useRef(stubInput)

	// Define external methods and link references
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			checkbox.current?.focus()
		},
		select: () => {
			checkbox.current?.select()
		},
		getElement: () => container.current,
	}))

	// State
	const [value, setValue] = React.useState(Boolean(props.value ?? false))
	const [focused, setFocused] = React.useState(false)

	// Sync the value state and props
	useStateSync({
		stateVal: value,
		propVal: Boolean(props.value ?? false),
		setState: setValue,
		setProp: props.onUpdate,
	})

	// Methods for focus and blur - can run custom event supplied on top of internal
	const evFocus = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			;(props.onFocus ?? _.noop)(ev)
			setFocused(true)
		},
		[props.onFocus],
	)
	const evBlur = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			;(props.onBlur ?? _.noop)(ev)
			setFocused(false)
		},
		[props.onBlur],
	)

	// Cache the change event
	const onChange = React.useCallback(() => {
		setValue(checkbox.current?.checked ?? false)
	}, [props.value])

	// Cache the click event
	const onClick = React.useCallback(
		(ev: React.MouseEvent<HTMLDivElement | HTMLInputElement>) => {
			// Confirm it's editable
			if (props.disabled || props.readOnly) {
				return undefined
			}

			// Get the new value
			// If it's a radio button and already checked, do nothing
			let newVal = !value
			if (props.cbStyle === 'radio' && value) {
				newVal = value
			}

			// Focus, change, and callback
			checkbox.current?.focus()
			setValue(newVal)
			;(props.onClick ?? _.noop)(ev)

			// No other events
			ev.preventDefault()
			ev.stopPropagation()
			return false
		},
		[props.disabled, props.readOnly, props.onClick, props.cbStyle, value],
	)

	// Calculate classes
	const clDivOuter = BuildClass({
		'ui5 ui5-checkbox': true,
		[props.className ?? '']: true,
		radio: props.cbStyle === 'radio',
		focused: focused,
		disabled: props.disabled ?? false,
	})
	const clDivInner = BuildClass({
		inner: true,
		selected: value,
		disabled: props.disabled ?? false,
		[props.classNameInner ?? '']: true,
	})

	// Render
	return (
		<div
			ref={container}
			className={clDivOuter}
			title={props.title ?? undefined}
			onClick={onClick}
		>
			<div className={clDivInner}>
				{ConditionalObject(
					Boolean(props.lbl),
					<span
						className={BuildClass({
							lbl: true,
							[props.classNameLabel ?? '']: true,
						})}
					>
						{props.lbl}
					</span>,
				)}
				{props.children}
			</div>
			<CJSX cond={!props.disableInputBox}>
				<input
					ref={checkbox}
					readOnly={true}
					type={props.cbStyle ?? 'checkbox'}
					checked={value}
					onChange={onChange}
					onFocus={evFocus}
					onBlur={evBlur}
					{..._.omit(props, [
						'lbl',
						'value',
						'onUpdate',
						'inLabel',
						'children',
						'onFocus',
						'onBlur',
						'cbStyle',
						'className',
						'classNameLabel',
						'classNameInner',
					])}
				/>
			</CJSX>
		</div>
	)
}

/**
 * A basic checkbox component with a material style.
 * @param lbl - Label text to show next to the box
 * @param value - Whether the checkbox is checked
 * @param onUpdate - Event when the value changes
 * @param cbStyle - either `checkbox` (default) or `radio`
 * @param inLabel - Ignores direct mouse click events
 * @param children - Custom HTML child, instead of lbl
 * @param disableInputBox - Does not include an `input` element in DOM
 * @prop Allows any valid prop from a HTML `input` element
 */
export const Checkbox = React.forwardRef(CheckboxComponent)
