import { BuildClass, DateObj, Do, fsmData, Maybe } from '../../../universal'
import { _, React } from '../../lib'
import { AddButton, AddButtonProps, Button, ButtonTWProps } from './buttons'
import { Checkbox, CheckboxProps } from './checkbox'
import { Combobox, ComboboxProps } from './combobox'
import { ContextMenuItem } from './context-menu'
import { DateBox, DateboxProps } from './date'
import { KebabMenuToolbar } from './kebab'
import { CJSX } from './meta-types'
import { stubDiv } from './stubs'
import { SearchBox, SearchBoxProps, Textbox, TextboxProps } from './textbox'
import { useResizeObserver } from './wrappers'

/**
 * @deprecated Use ToolbarNew instead
 */
export const Toolbar = React.forwardRef(
	(
		props: {
			className?: string
			lhs?: React.JSX.Element
			rhs?: React.JSX.Element
			widthLHS?: number
			widthRHS?: number
			/** Prevents adding the "tailwind-wrapper" class */
			omitTailwindWrapper?: boolean
		},
		ref: React.ForwardedRef<HTMLDivElement>,
	) => {
		// Exactly one width should be defined
		if ((props.widthLHS == null) == (props.widthRHS == null)) {
			console.trace('Exactly one width should be defined')
		}

		// Either the LHS or RHS should be defined
		if (!props.lhs && !props.rhs) {
			console.trace('No LHS/RHS defined for the toolbar')
		}

		// Get widths
		const widths =
			props.widthLHS != null
				? { lhs: `${props.widthLHS}px`, rhs: `calc(100% - ${props.widthLHS}px)` }
				: { lhs: `calc(100% - ${props.widthRHS}px)`, rhs: `${props.widthRHS}px` }

		// Render
		return (
			<div
				ref={ref}
				className={BuildClass({
					[props.className ?? '']: true,
					'ui5-toolbar': true,
					'tailwind-wrapper': !(props.omitTailwindWrapper ?? false),
				})}
			>
				<div className="lhs side" style={{ width: widths.lhs }}>
					{props.lhs}
				</div>

				<div className="rhs side" style={{ width: widths.rhs }}>
					{props.rhs}
				</div>
			</div>
		)
	},
)

export type ToolbarItemProps = {
	/** How much width does this item take up? */
	width: number
	/** How important this item is to show when space is limited. Higher is more important. Default: 0 */
	importance?: number
	/** Whether to always put this item in the overflow menu */
	alwaysCollapse?: boolean
	/** Whether to render this item. Default: true */
	visible?: boolean
	/** If set to false, the item is hidden if short on space and not moved to the overflow */
	overflowVisible?: boolean
} & (
	| ({ type: `button` } & Omit<ButtonTWProps, 'type'>)
	| ({ type: 'button-add' } & Omit<AddButtonProps, 'type'>)
	| ({ type: `button-24` } & Omit<ButtonTWProps, 'type'>)
	| ({ type: 'textbox' } & TextboxProps)
	| ({ type: 'searchbox' } & SearchBoxProps)
	| ({ type: 'datebox' } & DateboxProps<DateObj, DateObj>)
	| ({ type: 'checkbox' } & CheckboxProps)
	| ({ type: 'combobox' } & Omit<ComboboxProps<number, false>, 'multiple'>)
	| ({ type: 'combobox-multi' } & Omit<ComboboxProps<number, true>, 'multiple'>)
	| ({ type: 'combobox-strkey' } & Omit<ComboboxProps<string, false>, 'multiple'>)
	| ({ type: 'combobox-multi-strkey' } & Omit<ComboboxProps<string, true>, 'multiple'>)
	| { type: 'label'; lbl: string; className?: string }
	| { type: 'raw-jsx'; content: React.JSX.Element; overflowOverride: ContextMenuItem }
	| { type: 'separator' }
)

type ToolbarItemMeta = {
	side: 'left' | 'right'
	item: ToolbarItemProps
	index: number
	margin: number
}

export const ToolbarNew = (props: {
	className?: string
	lhs?: Maybe<ToolbarItemProps>[]
	rhs?: Maybe<ToolbarItemProps>[]
}) => {
	const ITEM_MARGIN = 5
	const OVERFLOW_BUTTON_WIDTH = 45
	const BOX_PADDING_X = 5

	// Build and reference a container element and track its size
	const container = React.useRef<HTMLDivElement>(stubDiv)
	const rect = useResizeObserver(container.current)
	const available_width = rect.width - BOX_PADDING_X * 2

	// Track increment state to allow forcing a re-render - mostly for context menu
	const [_inc, setInc] = React.useState(0)

	// Calculate the layout based on available space, and cache with the container size
	const layout = React.useMemo(() => {
		// Cache an array of all toolbar items, sorted by importance

		// Cache an array of all toolbar items, linked to their sorted by importance
		const addSide = (
			items: Maybe<ToolbarItemProps>[],
			side: 'left' | 'right',
		): ToolbarItemMeta[] =>
			(items ?? [])
				.filter(x => x)
				.map((item, index) => ({
					side,
					item,
					index,
					margin: index > 0 ? ITEM_MARGIN : 0,
				}))
		const all_items = _.chain([
			...addSide(props.lhs, 'left'),
			...addSide(props.rhs, 'right'),
		])
			.filter(x => x.item.visible ?? true)
			.sortBy(x => x.item.importance ?? 0)
			.reverse()
			.value()

		// Get the total required width of all enabled items that won't alway collapse
		const total_required_width = _.chain(all_items)
			.filter(x => !x.item.alwaysCollapse)
			.map(item => item.item.width + item.margin)
			.sum()
			.value()

		// Track whether we need extra space to show the overflow button
		const overflow_needed = _.some([
			all_items.find(x => x.item.alwaysCollapse) != null,
			available_width < total_required_width,
		])

		// Get the available width for the toolbar
		const overflow_button_width = overflow_needed
			? OVERFLOW_BUTTON_WIDTH + ITEM_MARGIN
			: 0
		const remaining_width = available_width - overflow_button_width

		// Take as much width sorted by importance as possible
		const sortedItems = _.chain(all_items)
			.filter(x => !x.item.alwaysCollapse)
			.sortBy(x => x.item.importance ?? 0)
			.reverse()
			.value()

		// Track which items fit and which need to collapse
		const items = Do(() => {
			let currentWidth = 0
			const items: typeof all_items = []
			const collapsed: typeof all_items = []

			// Distribute items between visible and collapsed based on available width
			for (const item of sortedItems) {
				const newWidth = currentWidth + item.item.width + item.margin
				if (newWidth < remaining_width) {
					items.push(item)
					currentWidth = newWidth
				} else {
					collapsed.push(item)
				}
			}

			// Divide up the non-collapsed items between the two sides
			return {
				lhs: fsmData(items, {
					filter: x => x.side == 'left',
					sort: x => x.index,
					map: x => x,
				}),
				rhs: fsmData(items, {
					filter: x => x.side == 'right',
					sort: x => x.index,
					map: x => x,
				}),
				collapsed: fsmData(collapsed, {
					filter: x => x.item.overflowVisible ?? true,
					sort: x => x.index,
					map: x => x,
				}),
			}
		})

		// Get the width of each side so we know where to put the split for the base `Toolbar` component
		const rawWidthLHS = _.sum(items.lhs.map(x => x.item.width + x.margin))
		const rawWidthRHS =
			_.sum(items.rhs.map(x => x.item.width + x.margin)) + overflow_button_width

		// Set the split between the two sides to be in the middle of the whitespace
		// So calculate how much space is in the middle, halve it and add it to the LHS
		const split = (available_width - rawWidthLHS - rawWidthRHS) / 2
		const widthLHS = Math.floor(rawWidthLHS + split)
		const widthRHS = Math.floor(rawWidthRHS + split)

		// Render
		return { items, widthLHS, widthRHS }
	}, [available_width, props.lhs, props.rhs])

	// Render
	return (
		<Toolbar
			ref={container}
			className={props.className}
			widthLHS={layout.widthLHS}
			lhs={
				<>
					{layout.items.lhs.map((x, index) => (
						<ToolbarItem {...x.item} key={index} />
					))}
				</>
			}
			rhs={
				<>
					{layout.items.rhs.map((x, index) => (
						<ToolbarItem {...x.item} key={index} />
					))}
					<CJSX cond={layout.items.collapsed.length > 0}>
						<KebabMenuToolbar
							items={() =>
								layout.items.collapsed.map(x =>
									getToolbarItemOverflowed(x.item, () => {
										setInc(s => s + 1)
									}),
								)
							}
						/>
					</CJSX>
				</>
			}
			omitTailwindWrapper={false}
		/>
	)
}

const getToolbarItemOverflowed = (
	item: ToolbarItemProps,
	reflow: () => void,
): ContextMenuItem => {
	// Comboboxes need to control the mouse events
	// TODO - hmm, how to approach this?
	if (item.type == 'combobox') {
		return {
			label: 'COMBOBOX TODO',
		}
	}
	if (item.type == 'combobox-multi') {
		return {
			label: 'COMBOBOX MULTI TODO',
		}
	}
	if (item.type == 'combobox-multi-strkey') {
		return {
			label: 'COMBOBOX MULTI STRKEY TODO',
		}
	}
	if (item.type == 'combobox-strkey') {
		return {
			label: 'COMBOBOX STRKEY TODO',
		}
	}

	// Textbox stuff
	if (item.type == 'datebox') {
		return {
			label: 'DATEBOX TODO',
		}
	}
	if (item.type == 'textbox') {
		return {
			label: 'TEXTBOX TODO',
		}
	}
	if (item.type == 'searchbox') {
		return {
			label: 'SEARCHBOX TODO',
		}
	}

	// Buttons
	if (item.type == 'button' || item.type == 'button-add' || item.type == 'button-24') {
		return {
			label: item.lbl ?? (item.type == 'button-add' ? 'Add' : ''),
			labelDesc: item.title,
			icon: item.type == 'button-add' ? '/static/img/i8/w11-add.svg' : item.img,
			onClick: item.onClick,
		}
	}

	// Checkboxes
	if (item.type == 'checkbox') {
		return {
			label: item.lbl,
			icon: () =>
				item.value ? '/static/img/i8/material-outline-checkmark.svg' : undefined,
			dontClose: true,
			labelDesc: item.title,
			onClick: () => {
				item.onUpdate?.(!item.value)
				reflow()
			},
		}
	}

	// Labels and separators
	if (item.type == 'label') {
		return { label: item.lbl }
	}
	if (item.type == 'separator') {
		return '---'
	}

	// Raw JSX - use the overflow version if supplied
	if (item.type == 'raw-jsx') {
		return item.overflowOverride
	}

	// Type not found - should be picked up statically
	const _exhaustiveCheck: never = item
	throw new Error(`Unknown toolbar item type: ${String(_exhaustiveCheck['type'])}`)
}

const ToolbarItem = (props: ToolbarItemProps) => {
	const exclKeys = ['type', 'importance', 'alwaysCollapse', 'visible', 'width'] as const

	// Comboboxes
	if (props.type == 'combobox') {
		return (
			<Combobox
				{..._.omit(props, exclKeys)}
				multiple={false}
				style={{ width: `${props.width}px` }}
			/>
		)
	}
	if (props.type == 'combobox-strkey') {
		return (
			<Combobox
				{..._.omit(props, exclKeys)}
				multiple={false}
				style={{ width: `${props.width}px` }}
			/>
		)
	}
	if (props.type == 'combobox-multi') {
		return (
			<Combobox
				{..._.omit(props, exclKeys)}
				multiple={true}
				style={{ width: `${props.width}px` }}
			/>
		)
	}
	if (props.type == 'combobox-multi-strkey') {
		return (
			<Combobox
				{..._.omit(props, exclKeys)}
				multiple={true}
				style={{ width: `${props.width}px` }}
			/>
		)
	}

	// Textboxes
	if (props.type == 'textbox') {
		return (
			<Textbox {..._.omit(props, exclKeys)} style={{ width: `${props.width}px` }} />
		)
	}
	if (props.type == 'searchbox') {
		return (
			<SearchBox
				{..._.omit(props, exclKeys)}
				style={{ width: `${props.width}px` }}
			/>
		)
	}
	if (props.type == 'datebox') {
		return (
			<DateBox {..._.omit(props, exclKeys)} style={{ width: `${props.width}px` }} />
		)
	}

	// Buttons
	if (props.type == 'button') {
		return <Button type="borderless" {..._.omit(props, exclKeys)} />
	}
	if (props.type == 'button-24') {
		return <Button type="ui5-borderless-24" {..._.omit(props, exclKeys)} />
	}
	if (props.type == 'button-add') {
		return <AddButton {..._.omit(props, exclKeys)} />
	}

	// Checkbox
	if (props.type == 'checkbox') {
		return <Checkbox {..._.omit(props, exclKeys)} />
	}

	// Labels and separators
	if (props.type == 'label') {
		return <span className={BuildClass(['text', props.className])}>{props.lbl}</span>
	}
	if (props.type == 'separator') {
		return <></> // Only used for overflow menu
	}
	if (props.type == 'raw-jsx') {
		return props.content
	}

	// Type not found - should be picked up statically
	const _exhaustiveCheck: never = props
	throw new Error(`Unknown toolbar item type: ${String(_exhaustiveCheck['type'])}`)
}
