import { BuildClass, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import { ConditionalObject, useStateSync } from './meta-types'

export type TabProps<T extends string | number> = {
	value: T
	label: string
	content: () => React.JSX.Element
	className?: string
	tooltip?: string
	hidden?: boolean
	disabled?: boolean
	tabWidth?: number
}
type TabControlProps<T extends string | number> = {
	tabs: TabProps<T>[]
	value: T
	onUpdate: (value: T) => void
	className?: string
	toolbarBefore?: React.JSX.Element | React.JSX.Element[]
	toolbarAfter?: React.JSX.Element | React.JSX.Element[]
	position?: 'top' | 'bottom' | 'left' | 'right'
	disableFocusControl?: boolean
	style?: React.CSSProperties
}
const TabControlComponent = <T extends string | number>(
	props: TabControlProps<T>,
	ref: React.ForwardedRef<null>,
) => {
	// State for the selected tab value
	const [value, setValue] = React.useState(props.value)

	// Reference for hidden input
	const hiddenInput = React.useRef<HTMLInputElement>(null)

	// Handle updating state/props
	useStateSync<T, T>({
		stateVal: value,
		propVal: props.value,
		setState: setValue,
		setProp: props.onUpdate,
		compareFn: (p, s) => p === s,
	})

	// Focus metthod
	const actionFocus = () => {
		if (!props.disableFocusControl) {
			hiddenInput.current?.focus()
		}
	}

	// Handle moving between tabs
	const moveSelection = (delta: number) => {
		// Get the new index
		const tabs = _.filter(props.tabs, x => !x.hidden)
		const max = tabs.length - 1
		const index = tabs.map(x => x.value).indexOf(value)
		let newIndex = index + delta
		if (newIndex < 0) {
			newIndex = 0
		} else if (newIndex > max) {
			newIndex = max
		}

		// Update the value and report back to the parent
		setValue(s => tabs[newIndex]?.value ?? s)
	}

	// Render the wrapper for the tabs
	const tabWrapper = (
		<div className="tab-labels-wrapper">
			<div className={`tab-sibling tab-sibling-before`}>{props.toolbarBefore}</div>
			<div className="tabs-wrapper-inner" onClick={actionFocus}>
				<div className="ui5-tab-labels">
					{fsmData(props.tabs, {
						filter: tab => !tab.hidden,
						map: tab => (
							<div
								key={String(tab.value ?? tab.label)}
								className={BuildClass({
									[tab.label
										.replace(/\s/g, '-')
										.replace(/\./g, '')
										.toLowerCase()]: true,
									'tab-label': true,
									selected: tab.value === value,
									disabled: tab.disabled ?? false,
								})}
								title={tab.tooltip}
								onMouseDown={() => {
									if (!tab.disabled) {
										setValue(tab.value)
									}
								}}
								style={{
									maxWidth: tab.tabWidth ? `${tab.tabWidth}px` : 'none',
								}}
							>
								<div className="tab-box" />
								<div
									className="tab-text"
									title={tab.tooltip ?? tab.label}
								>
									{tab.label}
								</div>
							</div>
						),
					})}
				</div>
			</div>
			<div className={`tab-sibling tab-sibling-after`}>{props.toolbarAfter}</div>
		</div>
	)

	// Render the wrapper for the content behind the tabs
	const content = (
		<div className="tab-containers">
			{fsmData(props.tabs, {
				filter: tab => !tab.hidden && tab.value === value,
				map: tab => (
					<div
						key={tab.label}
						className={BuildClass({
							'tab-container': true,
							[tab.className ??
							String(tab.value)
								.replace(/\s/g, '')
								.replace(/\./g, '')
								.toLowerCase()]: true,
							selected: tab.value === value,
						})}
					>
						{ConditionalObject(!tab.disabled, tab.content)}
					</div>
				),
			})}
		</div>
	)

	// Render the focus controller
	const focusController = (
		<div className="focus-controller">
			<input
				ref={hiddenInput}
				onKeyDown={e => {
					switch (e.keyCode) {
						case 37:
						case 38: // Left / Up
							moveSelection(-1)
							break
						case 39:
						case 40: // Right / Down
							moveSelection(+1)
							break
						default:
							return
					}
					e.preventDefault()
					e.stopPropagation()
				}}
			/>
		</div>
	)

	// Build the children in the correct order based on layout position
	let children: React.JSX.Element[]
	children =
		props.position === 'bottom' || props.position === 'right'
			? [content, tabWrapper]
			: [tabWrapper, content]

	// Reference placeholder
	React.useImperativeHandle(ref, () => null)

	// Render DOM
	return (
		<div
			className={BuildClass({
				[props.className ?? '']: true,
				'ui5-tab-control': true,
				vertical: props.position === 'left' || props.position === 'right',
				right: props.position === 'right',
				bottom: props.position === 'bottom',
			})}
			style={props.style}
		>
			{focusController}
			{...children}
		</div>
	)
}

/**
 * A tab component - has labelled tabs with different content for each. Allows content
 * before/after the tabs, and support all 4 sides for the tab placement.
 * @param tabs The array of `TabProp` objects
 * @param value Currently-selected tab value
 * @param onUpdate Event when updating the selected tab value
 * @param className Optional class name for the top-level DOM element
 * @param toolbarBefore Optional JSX before the tabs
 * @param toolbarAfter Optional JSX after the tabs
 * @param position Position to put the tabs (top/bottom/left/right) (default top)
 * @param disableFocusControl Whether to disable focus control element (default false)
 */
export const TabControl = React.forwardRef(TabControlComponent)
