import { BuildClass, DateObj, DateTimeObj, Do, Maybe, clamp } from '../../../universal'
import { React, _ } from '../../lib'
import {
	ConditionalObject,
	Focusable,
	RSInstance,
	RSInstanceD,
	useRSInstance,
	useStateSync,
} from './meta-types'
import { ModalAnchored, ModalAnchoredInstance } from './modal'
import { stubFocusable, stubInput, stubModal } from './stubs'
import { TextboxGeneric } from './textbox'

/**
 * TODO for the date picker:
 * - Replace `UpdateState` actions with something better
 * - Handle the box closing similar to combobox - tied into one state change
 * - Mobile layout support - larger targets
 */

const defaultMinYear = 1900
const defaultMaxYear = 2100

type DateBoxInputValue = string | DateObj | DateTimeObj | Date
type DateBoxOutputValue = string | DateObj | DateTimeObj

export type DateboxProps<I extends DateBoxInputValue, O extends DateBoxOutputValue> = {
	value: Maybe<I>
	onUpdate: (value: Maybe<O>) => void
	title?: string
	autoFocus?: boolean
	className?: string
	defaultZoom?: DateboxZoomLevel
	disableArrowKeys?: boolean
	disabled?: boolean
	readOnly?: boolean
	fmt?: string // Overrides what format displays in text box
	maxYear?: number
	minYear?: number
	onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void
	onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	placeholder?: string
	style?: React.CSSProperties
}

type DateBoxInnerProps = DateboxProps<DateTimeObj, DateTimeObj> & {
	includeDate?: boolean
	includeTime?: boolean
}

type DateboxState = {
	isEditing: boolean
	isFocused: boolean
	isOpen: boolean
	zoomLevel: DateboxZoomLevel
	valueRaw: string
	value: Maybe<DateTimeObj>
	selectionPath: selectionPath
}

/**
 * What zoom level should the datebox use
 * 1. Decades
 * 2. Years
 * 3. Months
 * 4. Days (default for dates)
 * 5. Hour (default for times)
 * 6. Minutes
 */
export type DateboxZoomLevel = 1 | 2 | 3 | 4 | 5 | 6

type selectionPath = [number, number, number, number, number, number]

type DateboxRefs = {
	input: React.RefObject<Focusable<HTMLInputElement>>
	modal: React.RefObject<ModalAnchoredInstance>
}

export type DateboxInstance = {
	getElement: () => HTMLInputElement
	getModal: () => HTMLDivElement
	focus: () => void
	select: () => void
}

type ReducerState = RSInstance<DateBoxInnerProps, DateboxState, DateboxRefs>
type ReducerStateD = RSInstanceD<DateBoxInnerProps, DateboxState, DateboxRefs, Payload>

// MAIN HOOKS ############################################################################

const DateboxComponentInner = (
	props: DateBoxInnerProps,
	ref: React.ForwardedRef<Focusable<HTMLInputElement>>,
) => {
	// Reducer
	const rs = useRSInstance<DateBoxInnerProps, DateboxState, DateboxRefs, Payload>({
		props: props,
		refs: {
			input: React.useRef<Focusable<HTMLInputElement>>(stubFocusable(stubInput)),
			modal: React.useRef<ModalAnchoredInstance>(stubModal),
		},
		defaultState: getDefaultStateFromProps,
		actionToDelta: getPartialStateFromAction,
	})

	// Sync state
	// Value
	useStateSync({
		stateVal: rs.state.value,
		propVal: rs.props.value,
		setState: v => {
			const value = parseValue(props, v)
			rs.dispatch([
				Action.SetState,
				{
					value: value,
					valueRaw: getValueRaw(props, value),
					selectionPath: getSelectionPath(value),
				},
			])
		},
		setProp: v => {
			props.onUpdate(v)
		},
		compareFn: (pv, sv) => parseValue(props, pv) == sv,
	})
	// Zoom
	useStateSync({
		stateVal: rs.state.zoomLevel,
		propVal: getDefaultZoom(rs.props),
		setState: v => {
			rs.dispatch([Action.UpdateZoom, { zoom: v }])
		},
		setProp: () => {},
	})

	// Cache callback functions
	const onUpdate = React.useCallback(
		(v: string) => {
			onTextChanged(rs, v)
		},
		[rs],
	)
	const onFocus = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			onTxtFocus(rs)
			props.onFocus?.(ev)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[rs, props.onFocus],
	)
	const onBlur = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			onTxtBlur(rs)
			props.onBlur?.(ev)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[rs, props.onBlur],
	)
	const onKeyDownInner = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			onKeyDown(rs, e)
			props.onKeyDown?.(e)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rs, props.onKeyDown],
	)
	const onClickInner = React.useCallback(() => {
		onClick(rs)
	}, [rs])
	const onWheelModal = React.useCallback(
		(e: React.WheelEvent<HTMLDivElement>) => {
			if (e.deltaY < 0) {
				moveNav(rs, -1)
			} else if (e.deltaY > 0) {
				moveNav(rs, +1)
			}
		},
		[rs],
	)

	// Get the placeholder text
	const placeholder = React.useMemo(() => {
		if (props.placeholder != null) {
			return props.placeholder
		} else if (!props.includeTime) {
			return 'dd/mm/yyyy'
		} else if (!(props.includeDate ?? true)) {
			return 'hh:mm'
		}
		return 'dd/mm/yyyy, hh:mm'
	}, [props.placeholder, props.includeDate, props.includeTime])

	// Instance
	React.useImperativeHandle(ref, () => ({
		getModal: () => rs.refs.modal.current?.getElement(),
		getElement: () => rs.refs.input.current?.getElement() ?? stubInput,
		focus: () => {
			Focus(rs)
		},
		select: () => {
			Select(rs)
		},
	}))

	// Render
	const identity = React.useCallback(<T,>(x: T): T => x, [])
	return (
		<>
			<TextboxGeneric<string>
				ref={rs.refs.input}
				className={BuildClass({
					'ui5-datebox': true,
					[props.className ?? '']: true,
					unsaved: rs.state.isEditing,
					focused: rs.state.isFocused,
					disabled: Boolean(props.disabled),
				})}
				value={rs.state.valueRaw}
				onUpdate={onUpdate}
				title={props.title}
				fixOnBlur={identity}
				serialize={identity}
				deserialize={identity}
				disabled={props.disabled}
				autoFocus={props.autoFocus}
				readOnly={props.readOnly}
				placeholder={placeholder}
				onFocus={onFocus}
				onBlur={onBlur}
				onKeyDown={onKeyDownInner}
				onClick={onClickInner}
				selectOnClick={false}
				style={props.style}
			/>
			<ModalAnchored
				ref={rs.refs.modal}
				hidden={props.readOnly || !rs.state.isOpen}
				mountElement={rs.refs.input.current?.getElement() ?? stubInput}
				width={270}
				maxHeight={250}
			>
				<div className="ui5-datebox-widget" onWheel={onWheelModal}>
					{ConditionalObject(rs.state.isOpen, () => buildWidgetToolbar(rs))}
					{ConditionalObject(rs.state.isOpen, () => buildWidgetGrid(rs))}
				</div>
			</ModalAnchored>
		</>
	)
}

// RENDERERS #############################################################################

const buildWidgetToolbar = (rs: ReducerStateD) => (
	<div key="toolbar" className="toolbar noselect">
		<div
			className={BuildClass({
				lhs: true,
				side: true,
				invisible: rs.state.zoomLevel === 1,
			})}
			onMouseDown={stopProp}
			onClick={() => {
				moveNav(rs, -1)
			}}
		>
			↑
		</div>

		<div
			className="home icon"
			title="Jump to today"
			onMouseDown={stopProp}
			onClick={() => {
				rs.dispatch([
					Action.SetState,
					{
						selectionPath: getSelectionPath(DateTimeObj.now(), rs.state),
						zoomLevel: (rs.props.includeDate ?? true) ? 4 : 5,
					},
				])
			}}
		>
			<img src="/static/img/svg/home.svg" />
		</div>
		<div
			className={BuildClass({
				title: true,
				'non-clickable': rs.state.zoomLevel === 1,
			})}
			onMouseDown={stopProp}
			onClick={() => {
				moveNesting(rs, -1)
			}}
		>
			{Do(() => {
				const P = rs.state.selectionPath
				const dt = DateTimeObj.create({
					year: P[0] * 10 + P[1],
					month: P[2] ?? 1,
					day: P[3] ?? 1,
					hour: P[4] ?? 1,
					min: 0,
				})
				switch (rs.state.zoomLevel) {
					case 1:
						return '' // Top level
					case 2:
						return `${P[0]}0s` // Decade
					case 3:
						return dt.fmt('yyyy') // Year
					case 4:
						return dt.fmt('LLLL, yyyy') // Month
					case 5:
						return dt.fmt('dd/MM/yyyy') // Date
					case 6:
						const h = dt.fmt('HH')
						const d = dt.fmt('dd/MM/yy')
						return `${h}:xx, ${d}`
				}
			})}
		</div>
		<div
			className={BuildClass({
				icon: true,
				target: true,
				nohover: rs.state.value == null,
			})}
			title="Jump to current value"
			onMouseDown={stopProp}
			onClick={() => {
				if (rs.state.value != null) {
					rs.dispatch([
						Action.SetState,
						{
							selectionPath: getSelectionPath(rs.state.value, rs.state),
						},
					])
				}
			}}
		>
			<img src="/static/img/svg/target.svg" />
		</div>
		<div
			className={BuildClass({
				rhs: true,
				side: true,
				invisible: rs.state.zoomLevel === 1,
			})}
			onMouseDown={stopProp}
			onClick={() => {
				moveNav(rs, +1)
			}}
		>
			↓
		</div>
	</div>
)

const buildWidgetGrid = (rs: ReducerStateD) => {
	switch (rs.state.zoomLevel) {
		case 1:
			return buildWidgetGridDecades(rs)
		case 2:
			return buildWidgetGridYears(rs)
		case 3:
			return buildWidgetGridMonths(rs)
		case 4:
			return buildWidgetGridDays(rs)
		case 5:
			return buildWidgetGridHours(rs)
		case 6:
			return buildWidgetGridMinutes(rs)
	}
}

const buildGridOfOptions = (
	rs: ReducerState,
	param: {
		className?: string
		startDate: DateTimeObj
		rowCount: number
		colCount: number
		startDateGroup?: DateTimeObj
		headingRow?: React.JSX.Element
		getID: (date: Maybe<DateTimeObj>) => string
		getCellDate: (date: DateTimeObj, x: number, y: number) => DateTimeObj
		getCellText: (date: DateTimeObj) => string
		getCellTitle?: (date: DateTimeObj) => string
		getGroupID: Maybe<(date: DateTimeObj) => string>
		onClick: (d: DateTimeObj) => void
	},
) => {
	// Cache the current selection and today values
	const today = param.getID(DateTimeObj.now())
	const current = param.getID(rs.state.value)
	const groupID = param.getGroupID?.(param.startDateGroup ?? param.startDate)

	// Build a grid of options
	return (
		<div className="calendar-view">
			{ConditionalObject(Boolean(param.headingRow), param.headingRow)}
			{..._.times(param.rowCount, y => (
				<div
					key={y}
					className={BuildClass({
						[param.className ?? '']: true,
						'calendar-row': true,
					})}
				>
					{_.times(param.colCount, x => {
						// Cache the cell date's moment object and identifier
						const dDate = param.getCellDate(param.startDate, x, y)
						const dID = param.getID(dDate)

						// Build the cell
						return (
							<span
								key={x}
								className={BuildClass({
									day: true,
									cell: true,
									oog: Boolean(
										groupID && groupID !== param.getGroupID?.(dDate),
									),
									today: dID === today,
									current: dID === current,
								})}
								title={(param.getCellTitle ?? param.getCellText)(dDate)}
								onMouseDown={stopProp}
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									;(param.onClick ?? _.noop)(dDate)
								}}
							>
								{param.getCellText(dDate)}
							</span>
						)
					})}
				</div>
			))}
		</div>
	)
}

const buildWidgetGridDecades = (rs: ReducerStateD) =>
	buildGridOfOptions(rs, {
		startDate: DateTimeObj.create({
			year: 1900,
			month: 1,
			day: 1,
		}),
		rowCount: 5,
		colCount: 4,
		getID: x => String(Math.floor((x?.lx.year ?? -1) / 10)),
		getCellDate: (start, x, y) => start.add((y * 4 + x) * 10, 'years'),
		getCellText: x => {
			const decade = Math.floor(x.lx.year / 10)
			return `${decade}0s`
		},
		getGroupID: null,
		onClick: d => {
			rs.dispatch([
				Action.SelectItem,
				{
					selectionPath: Do(() => {
						const p = _.clone(rs.state.selectionPath)
						p[0] = Math.floor(d.lx.year / 10)
						return p
					}),
				},
			])
		},
	})

const buildWidgetGridYears = (rs: ReducerStateD) =>
	buildGridOfOptions(rs, {
		startDate: DateTimeObj.create({
			year: rs.state.selectionPath[0] * 10,
			month: 1,
			day: 1,
		}),
		rowCount: 5,
		colCount: 4,
		getID: x => String(x?.lx.year ?? -1),
		getCellDate: (start, x, y) => start.add((y - 1) * 4 + x, 'years'),
		getCellText: x => x.fmt('yyyy'),
		getGroupID: x => String(Math.floor(x.lx.year / 10)),
		onClick: d => {
			rs.dispatch([
				Action.SelectItem,
				{
					selectionPath: Do(() => {
						const p = _.clone(rs.state.selectionPath)
						p[0] = Math.floor(d.lx.year / 10)
						p[1] = d.lx.year % 10
						return p
					}),
				},
			])
		},
	})

const buildWidgetGridMonths = (rs: ReducerStateD) =>
	buildGridOfOptions(rs, {
		startDate: DateTimeObj.create({
			year: rs.state.selectionPath[0] * 10 + rs.state.selectionPath[1],
			month: 1,
			day: 1,
		}),
		rowCount: 5,
		colCount: 4,
		getID: x => x?.fmt('MM/yyyy') ?? '',
		getCellDate: (start, x, y) => start.add((y - 1) * 4 + x, 'months'),
		getCellText: x => x.fmt('MMM'),
		getCellTitle: x => x.fmt('MMMM, yyyy'),
		getGroupID: x => String(x.lx.year),
		onClick: d => {
			rs.dispatch([
				Action.SelectItem,
				{
					selectionPath: Do(() => {
						const p = _.clone(rs.state.selectionPath)
						p[0] = Math.floor(d.lx.year / 10)
						p[1] = d.lx.year % 10
						p[2] = d.lx.month
						return p
					}),
				},
			])
		},
	})

const buildWidgetGridDays = (rs: ReducerStateD) =>
	buildGridOfOptions(rs, {
		className: 'days',
		headingRow: (
			<div key="heading" className="heading-row calendar-row gridRow days">
				{['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((txt, idx) => (
					<span key={idx} className="cell">
						{txt}
					</span>
				))}
			</div>
		),
		startDate: Do(() => {
			// Get the first Monday on or before the 1st of the month
			let date_ = DateTimeObj.create({
				year: rs.state.selectionPath[0] * 10 + rs.state.selectionPath[1],
				month: rs.state.selectionPath[2],
				day: 1,
			})
			if (!date_.lx.isValid) {
				console.error('Invalid date', date_)
				return DateTimeObj.now()
			}
			while (date_.lx.isValid && date_.lx.weekday !== 1) {
				date_ = date_.subtract(1, 'days')
			}
			return date_
		}),
		startDateGroup: DateTimeObj.create({
			year: rs.state.selectionPath[0] * 10 + rs.state.selectionPath[1],
			month: rs.state.selectionPath[2],
			day: 1,
		}),
		rowCount: 6,
		colCount: 7,
		getID: x => x?.fmt('dd/MM/yyyy') ?? '',
		getCellDate: (start, x, y) => start.add(y * 7 + x, 'days'),
		getCellText: x => String(x.lx.day),
		getCellTitle: x => x.fmt('EEEE, dd/MM/yyyy'),
		getGroupID: x => x.fmt('MM/yyyy'),
		onClick: d => {
			// If time isn't included, we've found our final value
			if (!rs.props.includeTime) {
				updateValue(rs, d)
				rs.dispatch([Action.SetState, { isOpen: false }])
				return
			}

			// Otherwise, update the path (like the higher-level ones) and zoom further
			rs.dispatch([
				Action.SelectItem,
				{
					selectionPath: Do(() => {
						const p = _.clone(rs.state.selectionPath)
						p[0] = Math.floor(d.lx.year / 10)
						p[1] = d.lx.year % 10
						p[2] = d.lx.month
						p[3] = d.lx.day
						return p
					}),
				},
			])
		},
	})

const buildCircleOfOptions = (
	rs: ReducerState,
	param: {
		cl: string
		key: string
		startDate: DateTimeObj
		getID: (date: Maybe<DateTimeObj>) => string
		getSegmentDT: (date: DateTimeObj, delta: number) => DateTimeObj
		segments: {
			value: number
			text: string
			style?: any
			onClick: (v: number) => void
		}[]
	},
) => {
	// Cache the current selection and today values
	const today = param.getID(DateTimeObj.now())
	const current = param.getID(rs.state.value)

	// Build the element
	return (
		<div
			key={param.key}
			className={BuildClass({
				[param.cl]: true,
				circle: true,
			})}
		>
			{_.map(param.segments, (segment, index) => {
				// Cache the cell date's moment object and identifier
				const dDate = param.getSegmentDT(param.startDate, segment.value)
				const dID = param.getID(dDate)

				// Build the cell
				return (
					<div
						key={index}
						className={BuildClass({
							segment: true,
							today: dID === today,
							current: dID === current,
						})}
						style={{
							...segment.style,
							transform: Do(() => {
								const degs = (index * 360) / param.segments.length
								const existing = segment.style?.transform ?? ''
								return `${existing} rotate(${degs}deg)`
							}),
						}}
						onMouseDown={stopProp}
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
							segment.onClick(segment.value)
						}}
					>
						<span
							className="txt"
							style={{
								transform: Do(() => {
									const degs = (index * 360) / param.segments.length
									return `rotate(-${degs}deg)`
								}),
							}}
						>
							{segment.text}
						</span>
					</div>
				)
			})}
		</div>
	)
}

const buildWidgetGridHours = (rs: ReducerStateD) => (
	<div className="hour-picker noselect">
		{/* 00 - 11 */}
		{buildCircleOfOptions(rs, {
			cl: 'outer',
			key: 'outer',
			startDate: DateTimeObj.create({
				year: rs.state.selectionPath[0] * 10 + rs.state.selectionPath[1],
				month: rs.state.selectionPath[2],
				day: rs.state.selectionPath[3],
				hour: 0,
				min: 0,
			}),
			getID: x => x?.fmt('HH dd/MM/yyyy') ?? '',
			getSegmentDT: (start, delta) => start.add(delta, 'hours'),
			segments: _.times(12, x => ({
				value: x,
				text: x < 10 ? `0${x}` : String(x),
				onClick: v => {
					rs.dispatch([
						Action.SelectItem,
						{
							selectionPath: Do(() => {
								const p = _.clone(rs.state.selectionPath)
								p[4] = v
								return p
							}),
						},
					])
				},
			})),
		})}

		{/* 12 - 23 */}
		{buildCircleOfOptions(rs, {
			cl: 'inner',
			key: 'inner',
			startDate: DateTimeObj.create({
				year: rs.state.selectionPath[0] * 10 + rs.state.selectionPath[1],
				month: rs.state.selectionPath[2],
				day: rs.state.selectionPath[3],
				hour: 0,
				min: 0,
			}),
			getID: x => x?.fmt('HH dd/MM/yyyy') ?? '',
			getSegmentDT: (start, delta) => start.add(delta, 'hours'),
			segments: _.times(12, x => ({
				value: x + 12,
				text: String(x + 12),
				onClick: v => {
					rs.dispatch([
						Action.SelectItem,
						{
							selectionPath: Do(() => {
								const p = _.clone(rs.state.selectionPath)
								p[4] = v
								return p
							}),
						},
					])
				},
			})),
		})}

		{/* Dot for the middle */}
		<div className="dot" />
	</div>
)

const buildWidgetGridMinutes = (rs: ReducerStateD) => (
	<div className="hour-picker noselect">
		{/* Minutes */}
		{buildCircleOfOptions(rs, {
			cl: 'outer',
			key: 'outer',
			startDate: DateTimeObj.create({
				year: rs.state.selectionPath[0] * 10 + rs.state.selectionPath[1],
				month: rs.state.selectionPath[2],
				day: rs.state.selectionPath[3],
				hour: rs.state.selectionPath[4],
				min: 0,
			}),
			getID: x => {
				if (x == null) {
					return ''
				}
				const dt = x.fmt('dd/MM/yyyy HH') ?? ''
				const m = Math.floor(+x.fmt('mm') / 5)
				return `${dt}${m}`
			},
			getSegmentDT: (start, delta) => start.add(delta, 'minutes'),
			segments: _.times(12, x => ({
				value: x * 5,
				text: Do(() => {
					const v = String(x * 5)
					if (v.length < 2) {
						return `0${v}`
					}
					return v
				}),
				onClick: v => {
					// Get the final path
					const p = _.clone(rs.state.selectionPath)
					p[5] = v
					const path = clampSelectedPath(p, rs.state.zoomLevel)

					// We've found our final value
					updateValue(rs, getValueFromSelectionPath(rs, path))
					rs.dispatch([Action.SetState, { isOpen: false }])
				},
			})),
		})}

		{/* Dot for the middle */}
		<div className="dot" />
	</div>
)

// ACTIONS ###############################################################################

const updateValue = (rs: ReducerStateD, v: Maybe<DateTimeObj>) => {
	rs.dispatch([
		Action.SetState,
		{
			value: v,
			isEditing: false,
			valueRaw: getValueRaw(rs.props, v),
			selectionPath: getSelectionPath(v, rs.state),
		},
	])
}

const parseValue = (
	props: DateBoxInnerProps,
	val: Maybe<DateBoxInputValue>,
): Maybe<DateTimeObj> => {
	if (!val) {
		return null
	}
	const fmt = getValueFormat(props)
	const dtVal = Do(() => {
		if (val instanceof DateTimeObj) {
			return val
		}
		if (val instanceof DateObj) {
			return DateTimeObj.parse(val.raw)
		}
		if (val instanceof Date) {
			return DateTimeObj.parse(val.toISOString())
		}
		return DateTimeObj.parse(val, fmt)
	})
	if (dtVal != null) {
		return dtVal
	}
	console.error(
		`Could not parse given \`Datebox\` value: '${val}' with format '${fmt}'`,
	)
	return null
}

const getValueFormat = (props: DateBoxInnerProps) => {
	if (!props.includeTime) {
		return 'yyyy-MM-dd'
	} else if (!(props.includeDate ?? true)) {
		return 'HH:mm'
	}
	return 'yyyy-MM-dd HH:mm:ss'
}

const onClick = (rs: ReducerStateD) => {
	rs.dispatch([Action.SetState, { isOpen: true }])
}

const onTxtFocus = (rs: ReducerStateD) => {
	rs.dispatch([
		Action.SetState,
		{
			isFocused: true,
			isOpen: true,
			zoomLevel: getDefaultZoom(rs.props),
		},
	])
}

const onTxtBlur = (rs: ReducerStateD) => {
	// Close
	rs.dispatch([
		Action.SetState,
		{
			isFocused: false,
			isOpen: false,
		},
	])

	// If we were editing, check if there's something savable
	if (rs.state.isEditing) {
		attemptToCommitEnteredValue(rs)
	}
}

const getValueRaw = (props: DateBoxInnerProps, v: Maybe<DateTimeObj>) => {
	if (v == null) {
		return ''
	} else if (props.fmt != null) {
		return v.fmt(props.fmt)
	} else if (!props.includeTime) {
		return v.fmt('dd/MM/yyyy')
	} else if (!(props.includeDate ?? true)) {
		return v.fmt('HH:mm')
	}
	return v.fmt('dd/MM/yyyy, HH:mm')
}

const onTextChanged = (rs: ReducerStateD, value: string) => {
	// Change the textbox value, but flag that it's mid-edit
	rs.dispatch([
		Action.SetState,
		{
			isEditing: true,
			valueRaw: value,
		},
	])

	// Check if the entered value is meaningful yet
	// No value entered? That's null
	if (!value) {
		updateValue(rs, null)
		return
	}

	// Get the expected text format
	const fmt = Do(() => {
		if (rs.props.fmt != null) {
			return rs.props.fmt
		} else if (!rs.props.includeTime) {
			return 'DD/MM/YYYY'
		} else if (!(rs.props.includeDate ?? true)) {
			return 'HH:mm'
		}
		return 'DD/MM/YYYY, HH:mm'
	})

	// Try parsing it in this form
	const attempted_value = DateTimeObj.parse(value, fmt)
	if (attempted_value.lx.isValid) {
		updateValue(rs, attempted_value)
		return
	}

	// If we've come this far, there's no matching value
	// The `onUpdate` event hasn't fired and the internal value hasn't changed
}

const attemptToCommitEnteredValue = (rs: ReducerStateD) => {
	// Try to parse
	const v = attemptToParseEnteredValue(rs)

	// If something was found, save it
	if (v !== false) {
		updateValue(rs, v)
		return
	}

	// Otherwise, rollback changes
	rs.dispatch([
		Action.SetState,
		{
			isEditing: false,
			valueRaw: getValueRaw(rs.props, rs.state.value),
		},
	])
}

const attemptToParseEnteredValue = (rs: ReducerState) => {
	// Base format hasn't been successful (handled in the `onTextChanged` event)
	// Try matching to other less-exact formats

	// Start with the simple case of a basic date-only value
	let fmts: string[]
	if (!rs.props.includeTime) {
		fmts = [
			// No formatting
			'ddMMyyyy',
			'yyyyMMdd',
			'ddMMyy',
			'yyMMdd',
			// Slash/dash
			'dd/MM/yyyy',
			'yyyy-MM-dd',
			'dd/MM/yy',
			'd/M/yy',
			'd/M/y',
			'yy-M-d',
			'y-M-d',
			// Dots?
			'dd.MM.yyyy',
			'yyyy.MM.dd',
			'd.M.yy',
			'd.M.y',
			'yy.M.d',
			'y.M.d',
			// Final attempt without formatting or leading zeroes
			'dMy',
			'yMd',
		]
	}
	// Time only
	else if (!(rs.props.includeDate ?? true)) {
		fmts = [
			'HH:mm',
			'Hmm',
			'H.mm',
			'H:mm',
			'H-mm',
			'H mm',
			// With seconds?
			'HH:mm:ss',
			'Hmmss',
			'H.mm.ss',
			'H:mm:ss',
			'H-mm-ss',
			'H mm ss',
		]
	}
	// Not sure
	else {
		console.error('Must either `includeTime` or `includeDate`')
		return false
	}

	// Compare formats against the entered string
	let match: Maybe<DateTimeObj> | false
	_.forEach(fmts, fmt => {
		const v = DateTimeObj.parse(rs.state.valueRaw, fmt)
		if (v.lx.isValid) {
			match = v
			return false
		}
		return true
	})

	// Validate the year
	const maxYear = rs.props.maxYear ?? defaultMaxYear
	const minYear = rs.props.minYear ?? defaultMinYear
	if (match && (match.lx.year > maxYear || match.lx.year < minYear)) {
		match = false
	}

	// Return the match (false if nothing found)
	return match
}

const onKeyDown = (rs: ReducerStateD, e: React.KeyboardEvent<HTMLInputElement>) => {
	const delta = e.ctrlKey || e.shiftKey ? 7 : 1
	switch (e.keyCode) {
		// Up / down
		case 38:
			stopProp(e)
			arrowNav(rs, -delta)
			break
		case 40:
			stopProp(e)
			arrowNav(rs, delta)
			break

		// Page up/down
		case 33:
			stopProp(e)
			arrowNav(rs, -7 * delta)
			break
		case 34:
			arrowNav(rs, 7 * delta)
			break

		// Home/end
		case 36:
			stopProp(e)
			arrowNav(rs, -28 * delta)
			break
		case 35:
			stopProp(e)
			arrowNav(rs, 28 * delta)
			break

		// Pressing enter
		case 13:
			attemptToCommitEnteredValue(rs)
			rs.dispatch([Action.SetState, { isOpen: false }])
			break

		// Pressing escape
		case 27:
			rs.dispatch([
				Action.SetState,
				{
					isOpen: false,
					isEditing: false,
					valueRaw: getValueRaw(rs.props, rs.state.value),
				},
			])
			break

		// Exit function before `stopProp` - allows pass-through keyboard events
		default:
			return
	}

	// An action was taken - stop propagatino
	stopProp(e)
}

const arrowNav = (rs: ReducerStateD, delta: number) => {
	if (!rs.props.disableArrowKeys) {
		const newZoomLevel = clamp(getDefaultZoom(rs.props) + 1, 1, 6) as DateboxZoomLevel
		moveNav(rs, delta, newZoomLevel, selectionPath => {
			updateValue(rs, getValueFromSelectionPath(rs, selectionPath))
		})
	}
}

const getDefaultZoom = (props: DateBoxInnerProps) =>
	props.defaultZoom ?? ((props.includeDate ?? true) ? 4 : 5)

const getSelectionPath = (
	value?: Maybe<DateTimeObj>,
	state?: DateboxState,
): selectionPath => {
	const val = value ?? state?.value ?? DateTimeObj.now()
	return [
		Math.floor(val.lx.year / 10),
		val.lx.year % 10,
		val.lx.month,
		val.lx.day,
		val.lx.hour,
		Math.round(val.lx.minute / 12),
	]
}

const getValueFromSelectionPath = (rs: ReducerState, p?: selectionPath) => {
	p = p ?? rs.state.selectionPath
	return DateTimeObj.create({
		year: p[0] * 10 + p[1],
		month: p[2],
		day: p[3],
		hour: p[4],
		min: p[5],
		sec: 0,
	})
}

const moveNav = (
	rs: ReducerStateD,
	delta: number,
	zoomLevel?: Maybe<DateboxZoomLevel>,
	cb?: (path: selectionPath) => void,
) => {
	// Get the zoom level if not overridden
	zoomLevel = zoomLevel ?? rs.state.zoomLevel

	// Clone the existing path and increment by the delta
	const path = _.clone(rs.state.selectionPath)
	const index = Math.max(0, (zoomLevel - 2) as number) as 0 | 1 | 2 | 3 | 4
	path[index] += delta

	// Run dispatch to update path
	rs.dispatch([Action.UpdateSelectionPath, { path }])
	cb?.(clampSelectedPath(path, zoomLevel))
}

const clampSelectedPath = (
	path: selectionPath,
	zoomLevel: DateboxZoomLevel,
): selectionPath => {
	zoomLevel = clamp(zoomLevel, 1, 6) as DateboxZoomLevel

	// Minutes to hours
	while (path[5] < 0) {
		path[5] += 60
		path[4] -= 1
	}
	while (path[5] > 59) {
		path[5] -= 60
		path[4] += 1
	}

	// Hours to days
	while (path[4] < 0) {
		path[4] += 24
		path[3] -= 1
	}
	while (path[4] > 23) {
		path[4] -= 24
		path[3] += 1
	}

	// Months to years
	while (path[2] < 1) {
		path[2] += 12
		path[1] -= 1
	}
	while (path[2] > 12) {
		path[2] -= 12
		path[1] += 1
	}

	// Days to months
	if (zoomLevel >= 4) {
		const d = DateTimeObj.create({
			year: path[0] * 10 + path[1],
			month: path[2],
			day: 1,
		}).add(path[3] - 1, 'days')
		path[0] = Math.floor(d.lx.year / 10)
		path[1] = d.lx.year % 10
		path[2] = d.lx.month
		path[3] = d.lx.day
	}

	// Years
	while (path[1] < 0) {
		path[1] += 10
		path[0] -= 1
	}
	while (path[1] > 9) {
		path[1] -= 10
		path[0] += 1
	}

	// Decades - 1800 to 2200 only
	path[0] = clamp(path[0], 180, 220)

	// Return the fixed path
	return path
}

const moveNesting = (rs: ReducerStateD, delta: number) => {
	const zoom = rs.state.zoomLevel + delta
	const zoomClamped = clamp(zoom, 1, 6) as DateboxZoomLevel
	rs.dispatch([Action.UpdateZoom, { zoom: zoomClamped }])
}

const Focus = (rs: ReducerStateD) => {
	// Focuses the component but doesn't open anything if not already
	const starting_is_open = rs.state.isOpen
	rs.refs.input.current?.focus()
	if (!starting_is_open) {
		rs.dispatch([Action.SetState, { isOpen: starting_is_open }])
	}
}

const Select = (rs: ReducerState) => {
	// Proxy-calls `.select()` on the input element
	rs.refs.input.current?.select()
}

const stopProp = (e: React.SyntheticEvent<any, any>) => {
	e.stopPropagation()
	e.preventDefault()
}

// ACTION INDEX ##########################################################################

enum Action {
	SetState,
	ResetValueFromProps,
	UpdateSelectionPath,
	UpdateZoom,
	SelectItem,
}

type Payload =
	| [Action.SetState, Partial<DateboxState>]
	| [Action.ResetValueFromProps, { value: string[]; text: string }]
	| [Action.UpdateSelectionPath, { path: selectionPath }]
	| [Action.UpdateZoom, { zoom: DateboxZoomLevel }]
	| [Action.SelectItem, { selectionPath: selectionPath }]

const getPartialStateFromAction = (
	rs: ReducerState,
	p: Payload,
): Maybe<Partial<DateboxState>> => {
	switch (p[0]) {
		case Action.SetState:
			return p[1]
		case Action.ResetValueFromProps:
			return getDefaultStateFromProps(rs.props)
		case Action.UpdateSelectionPath:
			return { selectionPath: clampSelectedPath(p[1].path, rs.state.zoomLevel) }
		case Action.UpdateZoom:
			return { zoomLevel: p[1].zoom }
		case Action.SelectItem:
			return {
				zoomLevel: clamp(rs.state.zoomLevel + 1, 1, 6) as DateboxZoomLevel,
				selectionPath: clampSelectedPath(p[1].selectionPath, rs.state.zoomLevel),
			}
	}
}

const getDefaultStateFromProps = (props: DateBoxInnerProps): DateboxState => {
	const value = parseValue(props, props.value)
	return {
		// Form value state
		value: value,
		valueRaw: getValueRaw(props, value),
		isEditing: false,
		// Open/close state
		isOpen: false,
		isFocused: false,
		// Widget state
		zoomLevel: getDefaultZoom(props),
		selectionPath: getSelectionPath(value),
	}
}

const DateboxInner = React.forwardRef(DateboxComponentInner)

/**
 * A textbox designed to enter dates. The input/outputs are strings in this one.
 * @deprecated Switch to DateBox (capital B) where the input/outputs are `DateObj`
 */
export const Datebox = React.forwardRef(
	(
		props: DateboxProps<string | DateObj | Date, string>,
		ref: React.Ref<Focusable<HTMLInputElement>>,
	) => (
		<DateboxInner
			{...props}
			ref={ref}
			value={Do(() => {
				if (!props.value) {
					return null
				}
				if (props.value instanceof DateObj) {
					return props.value.datetime()
				}
				if (props.value instanceof Date) {
					return DateTimeObj.parse(props.value)
				}
				return DateTimeObj.parse(props.value, 'yyyy-MM-dd')
			})}
			onUpdate={v => {
				props.onUpdate(v ? v.date().fmt() : null)
			}}
			includeDate={true}
			includeTime={false}
		/>
	),
)

/** A textbox designed to enter dates. The input/outputs are `DateObj` in this one. */
export const DateBox = React.forwardRef(
	(
		props: DateboxProps<DateObj | string, DateObj>,
		ref: React.Ref<Focusable<HTMLInputElement>>,
	) => (
		<DateboxInner
			{...props}
			ref={ref}
			value={Do(() => {
				if (!props.value) {
					return null
				}
				if (props.value instanceof DateObj) {
					return props.value.datetime()
				}
				return DateTimeObj.parse(props.value, 'yyyy-MM-dd')
			})}
			onUpdate={v => {
				props.onUpdate(v ? v.date() : null)
			}}
			includeDate={true}
			includeTime={false}
		/>
	),
)

/** A textbox designed to enter dates and times */
export const DateTimeBox = React.forwardRef(
	(
		props: DateboxProps<DateTimeObj | Date | string | DateObj, DateTimeObj>,
		ref: React.Ref<Focusable<HTMLInputElement>>,
	) => (
		<DateboxInner
			{...props}
			ref={ref}
			value={Do(() => {
				if (!props.value) {
					return null
				}
				if (props.value instanceof DateTimeObj) {
					return props.value
				}
				if (props.value instanceof DateObj) {
					return props.value.datetime()
				}
				if (props.value instanceof Date) {
					return DateTimeObj.parse(props.value)
				}
				return DateTimeObj.parse(props.value, 'yyyy-MM-dd HH:mm:ss')
			})}
			onUpdate={v => {
				props.onUpdate(v)
			}}
			includeDate={true}
			includeTime={true}
		/>
	),
)
