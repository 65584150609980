import {
	AddressCard,
	ContactCard,
	DateToString,
	Maybe,
	ModelType,
	StateString,
	TBill,
	TBillC,
	THR,
	tBool,
	tDate,
	tDatetime,
} from '../../universal'
import { IRISRequestType } from './component-iris'
import { J2rEditableGridSaveDelta } from './component-react-grid'

export type DataModel = {
	FullRefresh: string
	RosterAuth: number
	addresses: ModelType<AddressCard>
	allowCriterias: ModelType<AllowCriteria>
	allowTypes: ModelType<{ ID: number; Name: string }>
	allowances: ModelType<Allowance>
	areas: ModelType<Area>
	attributes: ModelType<Attribute>
	attributePos: ModelType<AttributePos>
	attributeReq: ModelType<AttributeRequirement>
	billingLocations: ModelType<TBill.FacilityLocation>
	cancellationReasons: ModelType<CancellationReason>
	cccFilter: CCCFilter
	certifications: ModelType<Certification>
	chargeGroups: ModelType<ChargeGroup>
	classRows: number[]
	classifications: ModelType<Classification>
	clientEvents: ModelType<ClientEvent>
	clientGroups: ModelType<ClientGroup>
	clientJobCount: ModelType<ModelType<number>>
	clientLeave: ModelType<ClientLeave[]>
	clients: ModelType<Client>
	clockings: ModelType<Clocking>
	companies: ModelType<Company>
	compatibilities: ModelType<Compatibility>
	competencies: THR.ClientNode<'competencies'>
	competencyFields: THR.ClientNode<'competencyFields'>
	competencyDropdowns: THR.ClientNode<'competencyDropdowns'>
	employeeCompetencies: THR.ClientNode<'employeeCompetencies'>
	employeeCompetencyFields: THR.ClientNode<'employeeCompetencyFields'>
	employeesToHR: Record<number, number>
	contacts: ModelType<ContactCard>
	costCentres: ModelType<CostCentre>
	deletedClockings: Map<number, { Emp: string; SerialNumber: string }>
	employeeDetails: ModelType<EmployeeDetail>
	employeeNotes: ModelType<string>
	employees: ModelType<Employee>
	facilities: any
	gridExceptions: GridExceptions
	hrEmployees: Record<number, number>
	ignoredUnavailability: ModelType<IgnoredUnavailability[]>
	jobs: ModelType<Job>
	leave: ModelType<Leave>
	linkedRosters: ModelType<LinkedRoster>
	meta: Meta
	notes: ModelType<Note>
	noteTypes: ModelType<NoteType>
	pendingUnavailability: ModelType<PendingUnavailability>
	period: CurrentPeriod
	periods: ModelType<Period>
	places: ModelType<Place>
	postCodes: any
	programs: ModelType<Program>
	pubHols: { [key: string]: string }
	qfrSettings: QFRSettings
	qfrExceptions: ModelType<QFRException>
	quals: ModelType<Qualifier>
	reqTimers: {
		complete: number
		pyInner: number
		pyOuter: number
		splits: { Label: string; DurationMs: number }[]
	}
	residentAccounts: ModelType<TBillC.ResidentAccount>
	residentAdmissions: ModelType<ResidentAdmissionRoster[]>
	residentContacts: ModelType<TBill.ResidentContact>
	residentsInWaiting: ModelType<any>
	rosterClients: ModelType<RosterClient>
	rosterEmployees: ModelType<RosterEmployee>
	rosterJobs: ModelType<RosterJob>
	rosters: ModelType<Roster>
	schadsGroups: ModelType<SCHADSGroup>
	scheduledLeave: ModelType<ScheduledLeave[]>
	serviceCancellations: ModelType<ServiceCancellation>
	servicesScheduledLog: ModelType<ServiceScheduleLog[]>
	serviceTypeAllowances: ModelType<ServiceTypeAllowance>
	serviceTypes: ModelType<ServiceType>
	services: ModelType<Service>
	servicesScheduled: ModelType<ServiceScheduled>
	shiftGaps: ModelType<ShiftGap>
	shiftQueries: ModelType<ShiftQuery[]>
	shiftTags: ModelType<ShiftTag>
	shiftToAssignment: ModelType<number[]>
	shiftToSCHADSGroup: ModelType<number>
	shifts: ModelType<Shift>
	shiftsDeleted: ModelType<DeletedShift>
	shiftsShared: ModelType<number[]>
	smsAssign: ModelType<SMSAssign>
	smsHistory: ModelType<SMSHistory>
	smsNumbersFetched: string[]
	smsNumbersUsed: string[]
	static: any
	taken: Taken
	templates: ModelType<Template>
	templatesFromTimes: Record<string, number>
	this: number
	timeClockSerials: ModelType<TimeClockSerial>
	unavailabilityBlocks: UnavailabilityModel
	unavailabilityPresets: ModelType<UnavailabilityPreset>
	unmatchedClockings: ModelType<number[]>
	verifications: ModelType<Verification>
	warnings: ModelType<Warning>
}

export type UnavailabilityModel = {
	Blocks: UnavailabilityBlock[]
	Records: ModelType<UnavailabilityRecord>
	UBE: ModelType<{
		Blocks: UnavailabilityBlock[]
		ID: number
		Rows: []
	}>
}

export type AllowanceType = {
	ID: number
	Name: string
}

export type AllowCriteria = {
	AllowKey: number
	Automatic: tBool
	CasRate: number
	Criteria: number
	FreqName: string
	Frequency: 1 | 2 | 3 | 7
	HasCasRate: boolean
	HasPermRate: boolean
	Name: string
	PermRate: number
	PubHol: tBool
	RequireEdit: tBool
	RosterReimbursement: tBool
	RosterTravel: tBool
	Sat: tBool
	ServiceTravel: tBool
	Sex: 'M' | 'F' | null
	ShiftLevel: tBool
	Sun: tBool
	WeekDay: tBool
}

export type Allowance = AllowCriteria[]

export type Area = {
	ID: number
	Code: string
	Color?: string
	CostCentre?: number
	Name: string
	Sort?: number
}

export type CancellationReason = {
	ID: number
	Disabled: tBool
	Text: string
}

export type CCCFilter = {
	Companies: number[]
	CostCentres: number[]
}

export type Certification = {
	ID: number
	Name: string
	ExpiryTime: number
	IsExpiryDate: boolean
	IsMedical: boolean
	IsCompulsory: boolean
}

export type ChargeGroup = {
	ID: number
	Locations: number[]
	Name: string
}

export type Classification = {
	Active: boolean
	Award: number
	Code: string
	Color?: string
	DefaultCC: number
	GapAllowanceLength: number
	HoursForMeal: number
	ID: number
	MaxBOWLength: number
	MealLength: number
	Name: string
	PrefName: string
	ReplacementLinks: {
		Classifications: number[]
		Employees: number[]
	}
	RosterAccess: tBool
}

export type ClientEvent = any

export type ClientGroup = {
	Code: string
	ID: number
	Name: string
}

export type ClientLeave = {
	Client: number
	End?: string
	RawID: number
	Reason: string
	Start: string
}

export type Client = {
	Active: boolean
	Address: string
	AddressKey: number
	AddressNote: string
	Admission: number
	Area?: number
	ContactKey: number
	DOB: string
	Email?: string
	EndDate?: string
	Facility: number
	FacilityName: string
	FirstName: string
	FGiven: string
	FullName: string
	Gender: 'M' | 'F' | 'O'
	Group?: number
	ID: number
	Locale: string
	Location: number
	MigrationKey?: number
	Note: string
	NotePrivate: string
	Phone: string
	PostCode: number
	PrefName?: string
	Program?: number
	SelfBilling: tBool
	StartDate: tDate
	State: StateString
	Surname: string
	Title: string
}

export type Clocking = {
	Authorised?: boolean
	Assignment?: number
	CovidVerified?: tBool
	Emp: string
	Endpoint?: tBool
	ID: number
	IsAuth?: boolean
	Latitude?: number
	Longitude?: number
	OriginalTime?: string
	PhKey?: string
	SerialNumber?: string
	TS: string
	Temperature?: number
	Type?: number
}

export type Company = {
	Active: boolean
	Address: string
	City: string
	Code: string
	ID: number
	MainCo: tBool
	Name: string
	Phone: string
	PostCode: number
	State: StateString
}

export type Compatibility = {
	Client: number
	Compatibility: number
	Employee: number
	ID: number
}

export type Attribute = {
	Code: string
	ID: number
	Name: string
}

export type AttributePos = {
	Classification: number
	Competency: number
	Employee: number
	ID: number
}

export type AttributeRequirement = {
	Client: number
	Competency: number
	Compulsory: tBool
	ID: number
	IsCert: tBool
	ServiceType?: number
}

export type CostCentre = {
	Code?: string
	ID: number
	Leaf: boolean
	Name: string
	Parent?: number
}

export type DeletedShift = {
	ID: number
	ShiftAssignment: number
	Shift: number
	Roster: number
	Job?: number
	Start: string
	Finish: string
	Hours: number
	Day: number
	Leave?: number
	Qualifier?: number
	Notes: string
	Service?: number
	EnteredStart?: string
	EnteredFinish?: string
	Cancelled: 0 | 1 | 2
	TS: string
	User: string
}

export type EmployeeDetail = {
	FluVaccineDate?: string
	ID: number
	LicenceExpiryDate?: string
	LicenceNumber?: string
	LicenceType?: string
	MedicareExpiryDate?: string
	MedicareNo?: string
	Photo?: string
}

export type Employee = {
	Active: boolean
	ChildrenCheckDate?: string
	ChildrenExpiryDate?: string
	ChildrenRefNo?: string
	City: string
	CommDate: tDate
	Company: number
	Competencies: DateToString<THR.EmployeeCompetency>[]
	CovidVaccDate?: string
	DOB: string
	DeclarationDate?: string
	Deletable: tBool
	Email: string
	EmailPayslips: tBool
	EmergencyName?: string
	EmergencyPhone?: string
	FirstName: string
	FirstNameFormal: string
	FluVaccineDate?: string
	FullName: string
	ID: number
	IgnoreClockings: boolean
	InFilter: boolean
	IsAgency: boolean
	LicenceExpiryDate?: string
	LicenceNumber?: string
	LicenceType?: string
	MedicareExpiryDate?: string
	MedicareNo?: string
	Mobile: string
	NurseExpiryDate?: string
	NurseRefNo?: string
	Person?: number
	Phone: string
	Photo?: string
	PoliceCheckDate?: string
	PoliceConvictions?: tBool
	PoliceExpiryDate?: string
	PoliceRefNo?: string
	PostCode: number
	PrefName: string
	SecondCovidVaccDate?: string
	Sex: 'F' | 'M'
	State: StateString
	Street: string
	Surname: string
	TCLinks: TCLink[]
	VisaCheckDate?: string
	VisaExpiryDate?: string
	VisaIDCheck: tBool
	VisaRefNo?: string
	VisaType?: 0 | 1 | 2 | 3
}

export type EmployeeCertification = {
	Certification: number
	Date: tDate
	ExpiryDate: tDate
	Notes: string
	Reference: string
}

type GridExceptionIndex = 'services' | 'shifts' | 'shiftGaps'

export class GridExceptions {
	public readonly services: Record<keyof DataModel['services'], Set<string>>
	public readonly shifts: Record<keyof DataModel['shifts'], Set<string>>
	public readonly shiftGaps: Record<keyof DataModel['shifts'], Set<string>>

	public constructor() {
		this.services = {}
		this.shiftGaps = {}
		this.shifts = {}
	}

	private add(index: GridExceptionIndex, key: number, exception: string) {
		const obj = this[index][key]
		if (obj) {
			obj.add(exception)
		} else {
			this[index][key] = new Set<string>([exception])
		}
	}

	public addService(key: number, exception: string): void {
		this.add('services', key, exception)
	}

	public addShift(key: number, exception: string): void {
		this.add('shifts', key, exception)
	}

	public addShiftGap(key: number, exception: string): void {
		this.add('shiftGaps', key, exception)
	}

	// TODO - unused?
	// private getValue(index: GridExceptionIndex) {
	// 	return _.reduce(
	// 		this[index],
	// 		(acc, record, key) => ({ ...acc, [key]: record.values() }),
	// 		{},
	// 	)
	// }
}

export type IgnoredUnavailability = {
	ID: number
	Date: tDate
	User: string
}

export type Job = {
	Active: boolean
	CC: number[]
	Classification: number
	Company: number
	ContractHours: number
	ContractHoursMax?: number
	DefaultArea: number
	DynamicCC: false
	Employee: number
	EndDate?: string
	ID: number
	IsDefault: boolean
	LegacyKey?: number
	PHBR: tBool
	PayType: JobPayType
	StartDate: tDate
	WorkType: 'P' | 'C' | 'F'
}

export type Leave = {
	Casual: tBool
	Code: string
	Costed: tBool
	Enabled: tBool
	ID: number
	Name: string
	PayrollCategory: string
	PrivacyName: string
	ShortName: string
	Transferred: tBool
}

export type LinkedRoster = {
	ID: number
	MasterRoster: number
	LinkedRoster: number
	SubRoster: number
}

export type Meta = {
	CompanyName: string
	DefaultActual: tBool
	Facility: number
	HasBilling?: boolean
	IsCDC: boolean
	LastRecalc?: string
	LastRecalcLong?: string
	Name: string
	Settings: { [key: string]: MetaSetting }
	SharedClockings: tBool
	SubBilling?: number
	SubRoster: number
	SysVals: {
		SchemaDate: tDate
		CDCMaxGap?: number
	}
	SubRosterMasters?: ModelType<SubRosterMaster>
}

export type MetaSetting = {
	Description: string
	Hidden: boolean
	Name: string
	Type: string
	Value: any
}

export type Note = {
	ID: number
	Author: Maybe<string> // Reads from the number in the DB to get the user's name
	CreatedBy: Maybe<string>
	Deleted: tBool
	Text: string
	Title: string
	CreationTime: tDatetime
	ArchivedBy: Maybe<number>
	ArchivedTime: Maybe<tDatetime>
	DeletedTime: Maybe<tDatetime>
	EntityType: number | null
	EntityID: number
	EmployeeViewable: tBool
	Priority: tBool
	NoteType: number
}

export type NoteType = {
	ID: number
	Name: string
}

export type PendingUnavailability = {
	Approved: false
	DateEnd: string
	DateStart: string
	Employee: number
	ID: number
	Notes: string
	Recurring: 1 | 7 | 14
	Start: string
	Stop: string
}

export type CurrentPeriod = {
	Length: number
	Master: boolean
	Start?: string
}

export type Period = {
	End?: string
	ID: number
	Name?: string
	Run?: number
	Start?: string
}

export type Place = {
	Address: string
	ID: number
	Locale: string
	Name: string
}

export type Program = {
	Code: string
	ID: number
	Name: string
}

export type Qualifier = {
	Casual: tBool
	Code: string
	Enabled: tBool
	ID: number
	Name: string
	PayrollShiftType: number
}

export type QFRException = {
	ID: number
	Key: string
	Classification: Maybe<number>
	Job: Maybe<number>
	QFRGroup: number
	Split: number
	IgnoreAreas: tBool
}

export type QFRSettingBase = {
	ID: number
	Classifications: (keyof DataModel['classifications'])[]
	DisplayName: string
	Areas: (keyof DataModel['areas'])[]
	Multiplier: number
	RequiredMinutes: Maybe<number>
	Exceptions: QFRException[]
}

export type QFRSettings = {
	directCare: {
		registeredNurses: QFRSettingBase
		enrolledNurses: QFRSettingBase
		personalCare: QFRSettingBase
		careManagement: QFRSettingBase
	}
	alliedHealth: {
		physiotherapist: QFRSettingBase
		occupationalTherapist: QFRSettingBase
		speechPathologist: QFRSettingBase
		podiatrist: QFRSettingBase
		dietetic: QFRSettingBase
		other: QFRSettingBase
		assistants: QFRSettingBase
		lifestyle: QFRSettingBase
	}
	catering: {
		cooks: QFRSettingBase
		kitchenAssistants: QFRSettingBase
	}
	[key: string]: { [key: string]: QFRSettingBase }
}

export type ResidentAdmissionRoster = {
	StartDate: tDate
	EndDate?: string
}

export type RosterClient = {
	Client: number
	ID: number
	Roster: number
}

export type RosterEmployee = {
	Certifications: ModelType<EmployeeCertification>
	Description: string
	FluVacDate?: string
	ID: number
	Name: string
	PoliceExpiryDate?: string
	TCID?: string
}

export type RosterJob = {
	Allowances: any
	ID: number
	Job: number
	Roster: number
}

export type Roster = {
	End?: string
	ID: number
	Name?: string
	PayPeriod?: number
	PayrollState?: number
	Run?: number
	Start?: string
	State: number
}

export type SCHADSGroup = {
	ID: number
	Color: string
}

export type ScheduledLeave = {
	Application?: number
	Finish: string
	ScheduledID: number
	Start: string
	Type: string
}

export type ServiceTypeAllowance = object

export type ServiceType = {
	ChargeGroup: number
	Code: string
	Color: string
	ID: number
	Name: string
	Active: tBool
}

export type Service = {
	Cancelled?: tBool
	CancelType?: ServiceCancellation
	Changed?: tBool
	ChangedVerified?: tBool
	Client?: number
	Day?: number
	Event?: number
	ID?: number
	NDISCode?: string
	Notes?: number[]
	PlaceStart?: number
	PlaceStop?: number
	Reference?: string
	RequestContact?: number
	RequestDate?: string
	RequestedBy?: string
	RequiredStaff?: number
	Roster?: number
	RosterClient?: number
	ScheduledKey?: number
	ScheduleUnlinked: tBool
	ServiceType?: number
	Shifts?: number[]
	ShiftsCancelled?: number[]
	Start?: string
	Stop?: string
	Type?: number
	VerifiedBy?: string
	VerifiedStart?: string
	VerifiedStop?: string
}

export type ServiceScheduled = {
	BreakCycle: number
	Client: number
	Created: tDate
	CreatedBy: Maybe<string>
	DateEnd?: string
	DateStart: string
	ID: number
	Job?: number
	Reference?: string
	NDISCode: string
	RequestContact?: string
	RequestDate?: string
	RequestedBy?: string
	RequiredStaff: number
	ServiceType: number
	Start: string
	Stop: string
	Updated: string
	WeekCycle: 1 | 2 | 3 | 4 | 5 | 6
	WeekCycleMultiplier: number
	Weekdays: string
	ScheduleNote: string
	ServiceNote: string
	ServiceNotePrivate: string
}

export type ServiceScheduleLog = ServiceScheduled & {
	ScheduleID: number
	EditedBy: string
}

export type ShiftGap = {
	ID: number
	ShiftAfter: number
	ShiftBefore: number
	TravelKM: number
	TravelMinutes: number
	IsAllowanceGap: tBool
	AdjustedManually: tBool
}

export type ShiftQuery = {
	Employee: number
	Timestamp: string
}

export type ShiftTag = {
	Code: string
	Color: string
	ID: number
	Name: string
	Priority: tBool
}

export type Shift = {
	Allowances: ModelType<{
		Cost: number
		ID: number
		Units: number
	}>
	ActualStart?: string
	ActualFinish?: string
	Area?: number
	Cancelled?: ShiftCancellation
	CanonFinish?: string
	CanonStart?: string
	Classification: number
	ClockStart: number
	ClockFinish: number
	CostAllow?: number
	CostHour?: number
	CostMultiplier?: number
	Date?: string
	Day?: number
	EmployeeChanged: tBool
	EnteredFinish?: string
	EnteredStart?: string
	Event?: number
	Finish: string
	Hours: number
	ID: number
	IncidentalApproved: tBool
	IncidentalCharge?: number
	Job?: number
	KmAllowance?: number
	Leave?: number
	Notes?: number[]
	PaidFinish?: string
	PaidStart?: string
	PayrollShiftType: number
	Qualifier?: number
	Roster: number
	RosterJob: number
	Service?: number
	ServiceCancelled?: number
	Shift: number
	Start: string
	Tags: number[]
	Template: string
	Verified: tBool
	VerifyTime?: string
	VerifyUser?: string
	Warnings: number[]
}

export type SMSAssign = {
	Assignment: number
	AutoAccept: tBool
	AutoRespond: tBool
	Closed: string
	Created: string
	Employees: SMS[]
	ID: number
	Service?: number
	WinningEmployee: number
}

export type SMS = {
	Delivered: tBool
	Employee: number
	ID: number
	Message: string
	MobileNo: string
	Response: string
	ResponseTime: string
	SMSThreadKey: string
	Thread: number
}

export type SMSHistory = {
	Delivered: string
	ID: number
	IsIncoming: tBool
	Message: string
	Mobile: string
	SenderUsername: string
	Timestamp: string
}

export type SubRosterMaster = {
	Key: number
	Name: string
	Rosters: { ID: number; Name: string }[]
}

export type TCLink = {
	Emp: number
	ID: number
	SN?: string
	TID: string
}

export type Template = {
	Code: string
	Color?: string
	Finish: string
	Hours: number
	ID: number
	Name: string
	Start: string
}

export type TimeClockSerial = {
	Name: string
	Serial: string
}

export type UnavailabilityBlock = {
	Day: number
	Employee: number
	ID: number
	Start: string
	Stop: string
}

export type UnavailabilityRecord = {
	Approved: tBool
	DateEnd?: string
	DateStart: string
	Employee: number
	EmployeeRequested: tBool
	ID: number
	Notes: string
	Recurring?: 1 | 7 | 14
	Start: string
	Stop: string
}

export type UnavailabilityPreset = {
	End: string
	ID: number
	Name: string
	Start: string
}

export type Verification = {
	Assignment: number
	ClockFinish?: number
	ClockStart?: number
	OriginalFinish: string
	OriginalHours: number
	OriginalStart: string
	VerifyNote: string
	VerifiyTime: string
	VerifyUser: string
}

export type Warning = {
	Description: string
	ID: number
	Name: string
	Type: 1 | 2 | 3
}

export type Taken = {
	RosterAuth: number
	addresses: number
	allowTypes: number
	allowances: number
	areas: number
	billingLocations: number
	cccFilter: number
	chargeGroups: number
	classifications: number
	clientEvents: number
	clientGroups: number
	clientJobCount: number
	clientLeave: number
	clients: number
	clockings: number
	companies: number
	compatibilities: number
	competencies: number
	competencyPos: number
	competencyReq: number
	contacts: number
	costCentres: number
	employeeDetails: number
	employeeNotes: number
	employees: number
	jobs: number
	leave: number
	meta: number
	notes: number
	pendingUnavailability: number
	period: number
	periods: number
	places: number
	programs: number
	pubHols: number
	quals: number
	residentAccounts: number
	residentAdmissions: number
	residentContacts: number
	residentsInWaiting: number
	rosterClients: number
	rosterEmployees: number
	rosterJobs: number
	rosters: number
	scheduledLeave: number
	serviceTypeAllowances: number
	serviceTypes: number
	services: number
	servicesScheduled: number
	shiftGaps: number
	shiftQueries: number
	shiftTags: number
	shiftToAssignment: number
	shifts: number
	shiftsShared: number
	smsAssign: number
	smsHistory: number
	smsNumbersUsed: number
	templates: number
	timeClockSerials: number
	unavailabilityBlocks: number
	unavailabilityPresets: number
	verifications: number
	warnings: number
}

// Non-data-model types

export type RosterMetaData = {
	HC?: boolean
	HCTest?: boolean
	buildTimeout?: number
	clipboard?: {
		is_copy: boolean
		items: { key: number; elem: HTMLElement; index: number }[]
	}
	enable_dragging?: boolean
	enable_editing?: boolean
	enable_selection?: boolean
	explicit_clocking_matches?: ModelType<[number, number]>
	firstLoad?: boolean
	pathCache?: object
	preset_edit?: boolean
	preset_views?: ModelType<View>
	prop?: {
		Propagate: boolean
		Rosters: number[]
	}
	recalcInProgress?: boolean
	recalcTimer?: number
	recalcTotalsTimer?: number
	refreshDynamicDataModelNeeded?: boolean
	scrollLock?: boolean
	shiftEditing?: [JQuery<HTMLElement>, Shift[], boolean]
	templateSearch?: string
	view?: ViewModel
}

export type View = {
	Created: string
	Creator: string
	Data: ViewModel
	ID: number
	IsSystemView: 0 | 1
	Name: string
}

export type ViewModel = {
	clock_tolerances: [number, number, number, number]
	colorBy: ViewColorBy
	compact: boolean
	compareMode: boolean
	days_to_show: DayNumbers[]
	doubleSpans?: boolean
	enable_ghosting: boolean
	filterAreas?: number[]
	filterClassifications?: number[]
	filterClientGroups?: number[]
	filterCostCentres?: number[]
	filterServiceTypes?: number[]
	filterServices?: number[]
	filterTemplates?: number[]
	firstColumnWidth?: number
	ghostShifts: boolean
	groupingType: ViewGroupBy
	hideEmpty?: boolean
	hideMissingTCIDRows?: boolean
	hidePHBR?: boolean
	ignoreShiftDuration?: boolean
	label_items: number[]
	run?: number
	shift_blips: number[]
	showAgency: boolean
	showCancelled: boolean
	showCasual: boolean
	showEmpJobs: boolean
	showLeave: boolean
	showNavSidebar?: boolean
	showPermanent: boolean
	showShiftGaps: boolean
	showUnassigned: boolean
	showUnavailability: boolean
	showUnverified: boolean
	showVerified: boolean
	showWorked: boolean
	structure_label: StructureLabel
	structure_shift: StructureShift
	task_items: number[]
	task_tolerances: [number, number]
	total_items: number[]
}

export type StructureLabel = 'j' | 'e' | 'c' | 'rt' | 'at' | 'tg'
export type StructureShift = 's' | 'ss' | 'x' | 't'
export const DayNumbersArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] as const
export type DayNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14

export enum JobPayType {
	PayByShift = 1,
	PayByTask = 2,
}

export enum ShiftCancellation {
	NotCancelled = 0,
	CancelledUnpaid = 1,
	CancelledPaid = 2,
}

export enum ServiceCancellation {
	NotCancelled = 0,
	CancelledCharged = 1,
	CancelledNotCharged = 2,
}

export enum ViewColorBy {
	ShiftTemplate,
	Area,
	ServiceType,
	ServiceAssigned,
	SCHADShiftGroup,
}

export enum ViewGroupBy {
	NoGrouping,
	Area,
	Classification,
	ShiftTemplate,
	ServiceType,
}

export type RosterRequest<FuncID extends number> = IRISRequestType<1, FuncID>

export type RosterRequestQuery =
	| {
			funcID: 0
			message: string
			stack: string
			url: string
			line: number
			__returnData?: object
	  }
	| {
			funcID: 1
			jobs: number[]
			clients: number[]
			__returnData?: {
				rosterJobs: ModelType<RosterJob>
				rosterClients: ModelType<RosterClient>
			}
	  }
	| { funcID: 2; view: ViewModel; __returnData?: object }
	| {
			funcID: 3
			name: string
			view: ViewModel
			__returnData?: { key: string; data: View }
	  }
	| { funcID: 4; key: number; __returnData?: object }
	| {
			funcID: 5
			model: J2rEditableGridSaveDelta<
				ServiceType & {
					ChargeGroupName?: string
				}
			>
			__returnData?: { serviceTypes: ModelType<ServiceType> }
	  }
	| {
			funcID: 6
			model: J2rEditableGridSaveDelta<Area>
			__returnData?: { areas: ModelType<Area> }
	  }
	| {
			funcID: 7
			values: Partial<Client> & { RosterNote: string; RosterNotePrivate: string }
			__returnData?: {
				clients: ModelType<Client>
				addresses: ModelType<AddressCard>
				contacts: ModelType<ContactCard>
			}
	  }
	| {
			funcID: 8
			roster: number
			existing: boolean
			creation_data: {
				Job: number
				Classification: number
				Day: number
			}[]
			class_keys: number[]
			shifts: number[]
			data: {
				core: {
					Start: string
					Finish: string
					Hours: number
				}
				allowances: number[]
				tasks: number[]
				taskKeys: number[]
			}
			__returnData?: {
				shifts: ModelType<Shift>
				rosterClients: ModelType<RosterClient>
				services: ModelType<Service>
				shiftGaps: ModelType<ShiftGap>
				toReassign: number[]
			}
	  }
	| {
			funcID: 9
			shifts: number[]
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
				shiftsDeleted: ModelType<DeletedShift>
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 10
			roster: number
			requests: {
				classification?: number
				day: number
				is_copy: boolean
				job?: number
				rc?: number
				service_key?: number
				shift_key?: number
			}[]
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
				keys: number[]
			}
	  }
	| {
			funcID: 11
			start: string
			stop: string
			serviceType: number
			client: number
			placeStart: number
			placeStop: number
			staff: number
			requestedBy: string
			requestContact: string
			reference: string
			ndisCode: string
			input: any
			roster: number
			__returnData?: {
				services: ModelType<Service>
				rosterClients: ModelType<RosterClient>
				shifts: ModelType<Shift>
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| (({ funcID: 14 } | { funcID: 103 }) & {
			roster: number
			__returnData?: {
				data: Partial<DataModel>
				timerComplete: number
				timerPythonInner: number
				timerPythonOuter: number
				timerIRISSplits: {
					Label: string
					DurationMs: number
				}[]
			}
	  })
	| {
			funcID: 15
			model: J2rEditableGridSaveDelta<Program>
			__returnData?: {
				programs: ModelType<Program>
			}
	  }
	| {
			funcID: 17
			model: J2rEditableGridSaveDelta<ClientGroup>
			__returnData?: {
				clientGroups: ModelType<ClientGroup>
			}
	  }
	| {
			funcID: 18
			model: J2rEditableGridSaveDelta<Attribute>
			__returnData?: {
				competencies: ModelType<Attribute>
			}
	  }
	| {
			funcID: 19
			roster: number
			services: number[]
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
				shiftsDeleted: ModelType<DeletedShift>
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 20
			services: number[]
			job: number
			shiftKeys: number[]
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
				rosterJobs: ModelType<RosterJob>
				updatedShiftKeys: number[]
				newKeys: number[]
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 22
			changes: {
				Key: any
				LinkedClfnAdd: unknown[]
				LinkedEmplAdd: unknown[]
				LinkedClfnRemove: unknown[]
				LinkedEmplRemove: unknown[]
				PrefName: any
			}
			__returnData?: {
				classifications: ModelType<Classification>
			}
	  }
	| {
			funcID: 23
			name: string
			html: string
			landscape: boolean
			__returnData?: {
				file_key: string
				directoryPath: string
			}
	  }
	| {
			funcID: 24
			values: {
				City: any
				CommDate: string
				DOB: string
				Email: any
				EmergencyName: any
				EmergencyPhone: any
				FirstName: any
				Gender: string
				ID: string
				IsAgency: string
				Mobile: string
				Notes: any
				PostCode: string
				PrefName: any
				State: any
				Street: any
				Surname: any
			}
			__returnData?: {
				employees: ModelType<Employee>
				employeeNotes: ModelType<string>
			}
	  }
	| {
			funcID: 25
			model: J2rEditableGridSaveDelta<Template>
			__returnData?: {
				templates: ModelType<Template>
			}
	  }
	| {
			funcID: 26
			job: number
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
				rosterJobs: ModelType<RosterJob>
			}
	  }
	| {
			funcID: 27
			key: number
			name: string
			__returnData?: {
				rosters: ModelType<Roster>
			}
	  }
	| {
			funcID: 28
			key: number
			name: string
			__returnData?: {
				rosters: ModelType<Roster>
			}
	  }
	| {
			funcID: 29
			key: number
			__returnData?: {
				rosters: ModelType<Roster>
			}
	  }
	| {
			funcID: 30
			source: number
			pp: number
			name: string
			__returnData?: {
				new_key: number
				rosters: ModelType<Roster>
			}
	  }
	| {
			funcID: 31
			key: number
			state: number
			__returnData?: {
				rosters: ModelType<Roster>
			}
	  }
	| {
			funcID: 32
			employee: any
			company: any
			classification: any
			costCentre: any
			workType: any
			payBy: any
			startDate: any
			contractHours: any
			contractHoursMax: any
			__returnData?: {
				jobs: ModelType<Job>
				employees: ModelType<Employee>
			}
	  }
	| {
			funcID: 33
			shifts: number[]
			__returnData?: {
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 34
			job: number
			shifts: number[]
			__returnData?: {
				shifts: ModelType<Shift>
				rosterJobs: ModelType<RosterJob>
			}
	  }
	| {
			funcID: 35
			employees: number[]
			numbers?: string[]
			message: string
			shift: number
			service: number
			__returnData?: {
				smsHistory: ModelType<SMSHistory>
				smsAssign: ModelType<SMSAssign>
				assignKey: number
			}
	  }
	| {
			funcID: 36
			model: J2rEditableGridSaveDelta<Compatibility>
			__returnData?: {
				compatibilities: ModelType<Compatibility>
			}
	  }
	| {
			funcID: 37
			shift: number
			shift_key: number
			time: string
			roster: number
			endpoint: number
			desc: string
			coords?: {
				latitude: number
				longitude: number
				accuracy: number
			}
			__returnData?: {
				data: Shift
			}
	  }
	| {
			funcID: 38
			assignments: number[]
			note: string
			__returnData?: {
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 40
			services: number[]
			notes: [string, string]
			clashes: [boolean, boolean]
			__returnData?: {
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 41
			model: J2rEditableGridSaveDelta<
				ClientLeave & {
					ID?: number
					Admission?: number
				}
			>
			__returnData?: {
				clientLeave: ModelType<ClientLeave[]>
			}
	  }
	| {
			funcID: 43
			shifts: {
				key: number
				startType: number
				finishType: number
				clockStart?: number
				clockFinish?: number
				startTime: string
				finishTime: string
				desc?: string
			}[]
			__returnData?: {
				shifts: ModelType<Shift>
				verifications: ModelType<Verification>
			}
	  }
	| {
			funcID: 44
			shifts: number[]
			__returnData?: {
				shifts: ModelType<Shift>
				verifications: ModelType<Verification>
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 45
			shifts: number[]
			__returnData?: {
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 46
			clockings: string[]
			roster: number
			__returnData?: {
				clockings: ModelType<Clocking>
			}
	  }
	| {
			funcID: 47
			job: number
			area: number
			__returnData?: {
				jobs: ModelType<Job>
			}
	  }
	| {
			funcID: 48
			view: ViewModel
			view_keys: {
				key: string
				name: string
				updated: string
			}
			__returnData?: {
				key: number
				data: View
			}
	  }
	| {
			funcID: 49
			roster: number
			limit: 150
			__returnData?: {
				smsAssign: ModelType<SMSAssign>
				smsHistory: ModelType<SMSHistory>
			}
	  }
	| {
			funcID: 50
			thread: number
			employee: number
			job: number
			__returnData?: {
				rosterJobs: ModelType<RosterJob>
				shifts: ModelType<Shift>
				smsAssign: ModelType<SMSAssign>
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 51
			shifts: number[]
			gaps: number[]
			employee: number
			day: number
			__returnData?: {
				shifts: ModelType<Shift>
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 52
			shifts: {
				key: number
				start: string
				finish: string
			}[]
			__returnData?: {
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 53
			pairs: any[]
			__returnData?: {
				result: [number, number]
			}
	  }
	| {
			funcID: 54
			roster: Maybe<number>
			text?: string
			entity?: Maybe<number>
			entityType?: Maybe<number>
			priority: boolean
			employeeViewable: boolean
			key?: number
			__returnData?: {
				notes: ModelType<Note>
				key: number
				warnings: ModelType<number[]>
			}
	  }
	| {
			funcID: 55
			key: number
			entityType: number
			entityID: number
			rosterKey: Maybe<number>
			__returnData?: {
				notes: ModelType<Note>
				warnings: ModelType<number[]>
			}
	  }
	| {
			funcID: 57
			surname: string
			firstname: string
			dob: string
			startdate: string
			location: any
			__returnData?: {
				clients: ModelType<Client>
				contacts: ModelType<ContactCard>
				residentAccounts: ModelType<TBillC.ResidentAccount>
				key: number
			}
	  }
	| {
			funcID: 58
			key: number
			employee: number
			start: string
			end: string
			start_date: string
			end_date: string
			recurring: string
			notes: string
			__returnData?: {
				unavailabilityBlocks: UnavailabilityModel
			}
	  }
	| {
			funcID: 59
			key: number
			__returnData?: {
				unavailabilityBlocks: UnavailabilityModel
			}
	  }
	| {
			funcID: 61
			roster: number
			day: number
			job: number
			classification: number
			start: string
			finish: string
			desc: string
			__returnData?: {
				data: Shift
			}
	  }
	| {
			funcID: 62
			roster: number
			shift: number
			desc?: string
			travel?: string
			charge?: string
			__returnData?: {
				shiftID: number
				data: Shift
			}
	  }
	| {
			funcID: 64
			rosterClient: number
			__returnData?: {
				rosterClients: ModelType<RosterClient>
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 65
			serviceCharged: number
			employeePaid: number
			cancelReason: number
			services: number[]
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 68
			key: number | null
			model: {
				Client?: any
				Job: any
				ServiceType: any
				DateStart: any
				DateEnd: any
				WeekCycle: any
				Start: any
				Stop: any
				RequiredStaff: any
				RequestedBy: any
				RequestDate: any
				RequestContact: any
				Reference: any
				NDISCode: string
				WeekCycleMultiplier: number
				BreakCycle: number
				Day1?: tBool | boolean
				Day2?: tBool | boolean
				Day3?: tBool | boolean
				Day4?: tBool | boolean
				Day5?: tBool | boolean
				Day6?: tBool | boolean
				Day7?: tBool | boolean
			}
			__returnData?: {
				servicesScheduled: ModelType<ServiceScheduled>
				rosterClients: ModelType<RosterClient>
				services: ModelType<Service>
				shifts: ModelType<Shift>
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 69
			key: number
			deleteServices: boolean
			__returnData?: {
				servicesScheduled: ModelType<ServiceScheduled>
				services: ModelType<Service>
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 70
			changed: {
				[key: string]: Partial<{
					ID: number
					Accepted: boolean
					Deleted: boolean
					Date: string
					Client: number
					ServiceType: number
					Service: number
					Start: string
					Stop: string
					Description: number[]
					EmployeeName: string
					Incidental: number
					KmAllowance: number
					Message: any
				}>
			}
			roster: number
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 71
			services: any[]
			__returnData?: {
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 72
			changes: Record<number, number>
			__returnData?: {
				services: ModelType<Service>
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 73
			model: J2rEditableGridSaveDelta<AttributePos>
			__returnData?: {
				competencyPos: ModelType<AttributePos>
			}
	  }
	| {
			funcID: 74
			model: J2rEditableGridSaveDelta<{
				Competency: string
				Client: number
				Compulsory: tBool
				ID: number
				IsCert: tBool
				ServiceType?: number
			}>
			__returnData?: {
				competencyReq: ModelType<AttributeRequirement>
			}
	  }
	| {
			funcID: 75
			model: J2rEditableGridSaveDelta<UnavailabilityPreset>
			__returnData?: {
				unavailabilityPresets: ModelType<UnavailabilityPreset>
			}
	  }
	| {
			funcID: 76
			model: J2rEditableGridSaveDelta<Place>
			__returnData?: {
				places: ModelType<Place>
			}
	  }
	| {
			funcID: 77
			model: J2rEditableGridSaveDelta<ServiceTypeAllowance>
			__returnData?: { serviceTypeAllowances: ModelType<ServiceTypeAllowance> }
	  }
	| {
			funcID: 78
			model: J2rEditableGridSaveDelta<RosterEmployee>
			__returnData?: { rosterEmployees: ModelType<RosterEmployee> }
	  }
	| {
			funcID: 79
			services: number[]
			__returnData?: {
				services: ModelType<Service>
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 81
			key: number
			exportType: number
			params: any
			__returnData?: {
				hashCode: string
				output: string
				name: string
			}
	  }
	| {
			funcID: 82
			__returnData?: {
				exports: any
				structure: any
			}
	  }
	| {
			funcID: 84
			ledgerType: 1 | 2
			key: number
			__returnData?: {
				records: {
					Timestamp: string
					UserID: number
					Name: string
					Description: string
					Details: string
					FunctionID: number
				}[]
			}
	  }
	| {
			funcID: 86
			shifts: Record<
				number,
				Partial<Shift> & {
					Client?: number
					Description?: string
					ServiceType?: number
				}
			>
			deleted: number[]
			newShifts: any
			roster: number
			rosterJob: number
			day: number
			__returnData?: {
				shifts?: ModelType<Shift>
				services?: ModelType<Service>
				shiftsDeleted?: ModelType<DeletedShift>
			}
	  }
	| {
			funcID: 87
			shiftKey: number
			empl: number
			roster: number
			empls: ModelType<Employee>
			__returnData?: {
				shiftsInterested: number[]
			}
	  }
	| {
			funcID: 88
			mobile: string
			__returnData?: {
				messages: ModelType<SMSHistory>
			}
	  }
	| {
			funcID: 89
			services: string[]
			roster: number
			__returnData?: {
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 90
			data: any
			__returnData?: object
	  }
	| {
			funcID: 91
			shifts: number[]
			warning: number
			__returnData?: {
				shifts: number[]
				warning: number
			}
	  }
	| {
			funcID: 92
			roster: number
			shiftsToJob: Record<number, number>
			__returnData?: {
				shifts: ModelType<Shift>
				rosterJobs: ModelType<RosterJob>
			}
	  }
	| {
			funcID: 93
			model: J2rEditableGridSaveDelta<{
				TCIDKey: number
				EmpID: number
				SerialNumber: string
				TCID: string
				disabled: boolean
			}>
			__returnData?: {
				employees: ModelType<Employee>
			}
	  }
	| {
			funcID: 94
			model: J2rEditableGridSaveDelta<TimeClockSerial>
			__returnData?: {
				timeClockSerials: ModelType<TimeClockSerial>
			}
	  }
	| {
			funcID: 95
			model: any
			__returnData?: {
				employees: ModelType<Employee>
			}
	  }
	| {
			funcID: 96
			shift: number
			clocking: number
			endpoint: tBool
			roster: number
			__returnData?: {
				clockings: ModelType<Clocking>
			}
	  }
	| {
			funcID: 97
			employees: number[]
			latitude: number
			longitude: number
			accuracy: number
			__returnData?: {
				clockings: any[]
			}
	  }
	| {
			funcID: 98
			model: J2rEditableGridSaveDelta<ShiftTag>
			__returnData?: {
				shiftTags: ModelType<ShiftTag>
			}
	  }
	| {
			funcID: 99
			roster: number
			tags: {
				added: any[]
				removed: any[]
			}
			shifts: number[]
			__returnData?: {
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 100
			roster: number
			__returnData?: {
				clients: ModelType<Client>
				rosterClients: ModelType<RosterClient>
				contacts: ModelType<ContactCard>
				residentAdmissions: ModelType<ResidentAdmissionRoster[]>
				residentContacts: ModelType<TBill.ResidentContact>
				residentsInWaiting: ModelType<any>
				services: ModelType<Service>
				servicesScheduled: ModelType<ServiceScheduled>
			}
	  }
	| {
			funcID: 101
			medicareNo: string
			medicareExpiry: string
			tfn: string
			emplCodes: number[]
			__returnData?: {
				medicareNo: string
				medicareExpiry: string
				tfn: string
			}
	  }
	| {
			funcID: 102
			__returnData?: object
	  }
	| {
			funcID: 104
			__returnData?: {
				static: any
				facilities: any
			}
	  }
	| {
			funcID: 105
			key: number
			emplCodes: number[]
			start: string
			end: string
			start_date: string
			end_date: string
			recurring: string
			isEmployee: boolean
			notes: string
			__returnData?: {
				unavailability: UnavailabilityModel
			}
	  }
	| {
			funcID: 106
			key: number
			emplCodes: number[]
			isEmployee: boolean
			__returnData?: {
				unavailability: UnavailabilityModel
			}
	  }
	| {
			funcID: 107
			model: any
			roster: number

			__returnData?: {
				pendingUnavailability: ModelType<PendingUnavailability>
				unavailabilityBlocks: UnavailabilityModel
			}
	  }
	| {
			funcID: 108
			ID: number
			__returnData?: {
				clientEvent: ClientEvent
			}
	  }
	| {
			funcID: 109
			data: ClientEvent
			__returnData?: {
				ID: number
				clientEvents: ModelType<ClientEvent>
			}
	  }
	| {
			funcID: 110
			roster: number
			ID: number
			__returnData?: {
				clientEvents: ModelType<ClientEvent>
				shifts: ModelType<Shift>
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 111
			__returnData?: object
	  }
	| {
			funcID: 112
			emplKey: number
			hashCode: string
			__returnData?: {
				employees: ModelType<Employee>
				employeeDetails: ModelType<EmployeeDetail>
			}
	  }
	| {
			funcID: 113
			emplKey: number
			__returnData?: {
				employees: ModelType<Employee>
				employeeDetails: ModelType<EmployeeDetail>
			}
	  }
	| {
			funcID: 114
			model: J2rEditableGridSaveDelta<CancellationReason>
			__returnData?: {
				cancellationReasons: ModelType<CancellationReason>
			}
	  }
	| {
			funcID: 115
			model: J2rEditableGridSaveDelta<Certification>
			__returnData?: {
				certifications: ModelType<Certification>
				employees: ModelType<Employee>
			}
	  }
	| {
			funcID: 116
			employees: ModelType<{
				policeCheck: {
					Convictions: Maybe<0 | 1 | 2>
					Date: Maybe<string>
					Expiry: Maybe<string>
					Ref: Maybe<string>
					StatDec: Maybe<string>
				}
				visa: {
					Date: Maybe<string>
					Expiry: Maybe<string>
					IDCheck: Maybe<0 | 1>
					Reference: Maybe<string>
					Type: Maybe<0 | 1 | 2 | 3>
				}
				edited: { [certificationID: number]: EmployeeCertification }
				appended: number[]
				deleted: number[]
				policeCheckEdited: boolean
				nurseReg: {
					RefNo: string
					Expiry: string
				}
				nurseRegEdited: boolean
				visaEdited: boolean
			}>
			rosterEmployees: ModelType<{
				policeCheck: {
					Convictions: Maybe<0 | 1 | 2>
					Date: Maybe<string>
					Expiry: Maybe<string>
					Ref: Maybe<string>
					StatDec: Maybe<string>
				}
				visa: {
					Date: Maybe<string>
					Expiry: Maybe<string>
					IDCheck: Maybe<0 | 1>
					Reference: Maybe<string>
					Type: Maybe<0 | 1 | 2 | 3>
				}
				edited: { [certificationID: number]: EmployeeCertification }
				appended: number[]
				deleted: number[]
				policeCheckEdited: boolean
				nurseReg: {
					RefNo: string
					Expiry: string
				}
				nurseRegEdited: boolean
				visaEdited: boolean
			}>
			__returnData?: {
				employees: ModelType<Employee>
				rosterEmployees: ModelType<RosterEmployee>
			}
	  }
	| {
			funcID: 117
			data: {
				jobs: number[]
				clients: number[]
			}
			event: number
			roster: number
			day: number
			__returnData?: {
				shifts: ModelType<Shift>
				services: ModelType<Service>
				rosterJobs: ModelType<RosterJob>
				rosterClients: ModelType<RosterClient>
			}
	  }
	| {
			funcID: 118
			client: number
			__returnData?: {
				schedules: ModelType<ServiceScheduled>
			}
	  }
	| {
			funcID: 119
			__returnData?: { rosters: ModelType<SubRosterMaster> } | { msg: string }
	  }
	| {
			funcID: 120
			subRoster: number
			roster: number
			delta: any
			__returnData?: {
				linkedRosters: ModelType<LinkedRoster>
			}
	  }
	| {
			funcID: 121
			startTime: string
			finishTime: string
			day: number
			job: number
			roster: number
			__returnData?: {
				shift: Shift
				shiftGaps: ModelType<ShiftGap>
			}
	  }
	| {
			funcID: 122
			gap: number
			isBrokenShift: boolean
			roster: number
			__returnData?: {
				shiftGaps: ModelType<ShiftGap>
				shifts: ModelType<Shift>
			}
	  }
	| {
			funcID: 123
			delta: J2rEditableGridSaveDelta<SCHADSGroup>
			__returnData?: {
				schadsGroups: ModelType<SCHADSGroup>
			}
	  }
	| {
			funcID: 124
			__returnData?: { message: string }
	  }
	| {
			funcID: 125
			data: QFRSettings
			newGroups: string[]
			newSubGroups: Record<string, string[]>
			deletedGroups: string[]
			deletedSubGroups: Record<string, string[]>
			__returnData?: {
				qfrSettings: QFRSettings
			}
	  }
	| {
			funcID: 126
			key: number
			date: string
			roster: number
			__returnData?: {
				unavailabilityBlocks: UnavailabilityModel
				ignoredUnavailability: ModelType<IgnoredUnavailability[]>
			}
	  }
	| {
			funcID: 127
			roster: number
			services: number[]
			linkValue: boolean
			__returnData?: {
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 128
			key: number
			__returnData?: {
				records: ModelType<ServiceScheduleLog[]>
			}
	  }
	| {
			funcID: 133
			schedule: number
			roster: number
			scheduleNote: string
			privateNote: string
			publicNote: string
			__returnData?: {
				servicesScheduled: ModelType<ServiceScheduled>
				services: ModelType<Service>
			}
	  }
	| {
			funcID: 134
			model: any
			__returnData?: {
				qfrExceptions: ModelType<QFRException>
				qfrSettings: QFRSettings
			}
	  }
	| {
			funcID: 135
			noteID: number
			isArchiving: boolean
			__returnData?: {
				notes: ModelType<Note>
			}
	  }
	| {
			funcID: 136
			__returnData?: {
				notes: ModelType<Note>
			}
	  }
