import { BuildClass, Do, Maybe } from '../../../universal'
import { React, _ } from '../../lib'
import { List } from './list'
import { ConditionalObject, useStateSync } from './meta-types'
import { ResizeSplitter, ResizeSplitterInstance } from './resize-split'
import { stubResizeSplitterInstance } from './stubs'

const DEFAULT_WIDTH = 160

export type SidebarProps<T extends string | number> = {
	value: T | number | null
	onUpdate: (value: T | number | null) => void
	items: SidebarItem<T>[]
	buildForm: (value: T | number | null) => React.JSX.Element
	newLabel?: string
	className?: string
	stubMessage?: string
	showFormOnNull?: boolean
	/** @deprecated Use `pane` for dimensional arguments */
	width?: number
	/** @deprecated Use `pane` for dimensional arguments */
	minWidth?: number
	pane?: {
		defaultWidth?: number
		minWidth?: number
		maxWidth?: number
		collapsible?: boolean
	}
	widths?: Maybe<number>[]
	onUpdateWidths?: (widths: Maybe<number>[]) => void
	onDelete?: (item: T) => void
}
export type SidebarItem<T extends string | number> = {
	value: T
	text: string
	className?: string
	deletable?: boolean
}
const SidebarControlCmpt = <T extends string | number>(
	props: SidebarProps<T>,
	ref: React.ForwardedRef<{ getElement: () => Maybe<HTMLDivElement> }>,
): React.JSX.Element => {
	// Value state - sync with props
	const [value, setValue] = React.useState(props.value)
	useStateSync({
		stateVal: value,
		propVal: props.value,
		setState: setValue,
		setProp: props.onUpdate,
		compareFn: (a, b) => a == b,
	})

	// Track the resize split widths
	const [widths, setWidths] = React.useState<Maybe<Maybe<number>[]>>(props.widths)
	useStateSync({
		propVal: props.widths,
		stateVal: widths,
		setProp: props.onUpdateWidths ?? _.noop,
		setState: setWidths,
	})

	// Get the class of the top-level DOM element
	const buildClass = BuildClass({
		'ui5-sidebar': true,
		[props.className ?? '']: true,
	})

	// Build the sidebar list with lazy rendering
	const buildSidebar = (
		<List<T | number, false>
			className="sidebar"
			value={value}
			onUpdate={setValue}
			multiple={false}
			height={30}
			rows={_.compact([
				ConditionalObject(Boolean(props.newLabel), {
					value: -1,
					className: 'new',
					height: 41,
					content: () => <div className="new-inner">{props.newLabel}</div>,
				}),
				...props.items.map(x => ({
					value: x.value,
					className: x.className,
					content: () =>
						props.onDelete != null && (x.deletable ?? false) ? (
							<div className="flex">
								<span className="flex-1 max-w-[calc(100%-16px)] overflow-ellipsis overflow-hidden">
									{x.text}
								</span>
								<span className="flex-1 max-w-4 max-h-4 hover:bg-red-400">
									<img
										src="/static/img/i8/color-trash-delete.svg"
										alt="Delete Item"
										onClick={e => {
											e.preventDefault()
											e.stopPropagation()
											if (props.onDelete == null) {
												console.error(
													'Cannot delete item without onDelete function',
												)
												return
											}
											props.onDelete(x.value)
										}}
									/>
								</span>
							</div>
						) : (
							<>{x.text}</>
						),
				})),
			])}
		/>
	)

	// Builds the form
	const buildForm = Do(() => {
		if (value || props.showFormOnNull) {
			return props.buildForm(value)
		} else if (props.stubMessage != null) {
			return (
				<div className="stub-message-wrapper">
					<div className="stub-message">{props.stubMessage}</div>
				</div>
			)
		}
		return <div />
	})

	// Imperative handle
	const elRef = React.useRef<ResizeSplitterInstance>(stubResizeSplitterInstance)
	React.useImperativeHandle(ref, () => ({
		getElement: () => elRef.current.getElement(),
		getResizeSplitter: () => elRef.current,
	}))

	// Final top-level render
	return (
		<ResizeSplitter
			value={widths}
			onUpdate={setWidths}
			className={buildClass}
			ref={elRef}
			panes={[
				{
					defaultWidth:
						// eslint-disable-next-line deprecation/deprecation
						props.pane?.defaultWidth ?? props.width ?? DEFAULT_WIDTH,
					// eslint-disable-next-line deprecation/deprecation
					minWidth: props.pane?.minWidth ?? props.minWidth ?? DEFAULT_WIDTH,
					maxWidth: props.pane?.maxWidth ?? null,
					flexWidth: null,
					collapsible: props.pane?.collapsible ?? false,
					content: buildSidebar,
				},
				{
					defaultWidth: null,
					minWidth: null,
					maxWidth: null,
					flexWidth: 1,
					collapsible: false,
					content: <div className="ui5-sidebar-form">{buildForm}</div>,
				},
			]}
		/>
	)
}

/** Sidebar list with an optional  */
export const SidebarControl = React.forwardRef(SidebarControlCmpt)
