import { Do, Maybe, timer } from '../../universal'
import { $, React, _ } from '../lib'
import { contentComponent, openNavLinkMaybe } from './component-frame'
import { MFALoginForm } from './component-mfa-login'
import { reactFlyout } from './component-react'
import { runeSpeedTestBroadcast, showBroadcastMessage } from './component-trionline'
import { NewSystemFlyoutInstance } from './flyouts'
import { RosterRequestQuery } from './types-roster'
import { Button } from './ui5'

const irislog = (s: string) => {
	const css1 = 'color:#f96'
	const css2 = 'color:#888'
	console.log(`%c[IRIS] %c${s}`, css1, css2)
}

// IRIS request API types
export type IRISResponseProgressSection = {
	Key: string
	Name: string
	IsDone: Maybe<boolean>
	Weighting: number
	Sections: IRISResponseProgressSection[]
}
export type IRISResponseProgress = {
	Section: string
	Progress: number
	Total: number
	Sections: IRISResponseProgressSection[]
}
export type IRISResponseYes = {
	completed: true
	message: string
	[others: string]: any
}
export type IRISResponseNo = {
	completed: false
	message: string
	[others: string]: any
}
export type IRISResponseAny = IRISResponseYes | IRISResponseNo

// IRIS client connection object
// Request handler types
export type IRISRequestData<ProgID extends number, FuncID extends number> = Extract<
	Extract<AppRequestQuery, { progID: ProgID }>['_type'],
	{ funcID: FuncID }
>
export type IRISRequestYesHandler<ProgID extends number, FuncID extends number> = (
	data: IRISResponseYes &
		Extract<
			Extract<AppRequestQuery, { progID: ProgID }>['_type'],
			{ funcID: FuncID }
		>['__returnData'],
) => void
export type IRISRequestAnyHandler<ProgID extends number, FuncID extends number> = (
	data: (IRISResponseYes | IRISResponseNo) &
		Extract<
			Extract<AppRequestQuery, { progID: ProgID }>['_type'],
			{ funcID: FuncID }
		>['__returnData'],
) => void

export type IRISRequestType<ProgID extends number, FuncID extends number> = {
	data: IRISRequestData<ProgID, FuncID>
	yes?: IRISRequestYesHandler<ProgID, FuncID>
	no?: (data: IRISResponseNo) => void
	any?: IRISRequestAnyHandler<ProgID, FuncID>
	prog?: (data: IRISResponseProgress) => void
}

// TODO : Add types for other programs
type AppRequestQuery =
	| {
			// System
			progID: 0
			_type: any
			// { __returnData?: {} } & {
			// 	funcID?: number
			// 	requestID?: string
			// 	progID?: 0
			// }
	  }
	| {
			// Roster
			progID: 1
			_type: {
				funcID?: number
				requestID?: string
				progID?: 1
				roster?: number
				facilityTriOnline?: [number, number]
			} & RosterRequestQuery
	  }
	| {
			// Billing
			progID: 2
			_type: any
			// { __returnData?: {} } & {
			// 	funcID?: number
			// 	requestID?: string
			// 	progID?: 2
			// }
	  }

type irisHostInfo = {
	reverseFlask: string
	reverseSvrts: string
	public: string
}

export class IrisClient {
	isConnecting: boolean
	isConnected: boolean
	responseRegister: {}
	messageQueueTimeout: any
	messageQueue: any[]
	initialised: boolean
	disconnections: number
	history: {}
	messageCount: number
	cachedHosts: Maybe<irisHostInfo>
	excludeSpinner: {
		0: any[] // All system requests for now
		1: number[]
	}
	gcTimer: any
	wsObject: Maybe<WebSocket>
	reconnectAttempts: number
	responseReigster: any

	// Sets the initiate state of the connection object
	constructor() {
		this.isConnecting = false
		this.isConnected = false
		this.responseRegister = {}
		this.messageQueueTimeout = null
		this.messageQueue = []
		this.initialised = false
		this.disconnections = 0
		this.history = {}
		this.messageCount = 0
		this.cachedHosts = null
		this.reconnectAttempts = 0

		// Hold an exclusive list for progID/funcID that will prevent the spinner from showing
		this.excludeSpinner = {
			0: _.times(999, x => x + 1), // All system requests for now
			1: [0, 14, 23, 30, 31, 49, 53, 56, 80, 81, 82, 84, 88],
		}

		// Run the garbage collection every 60 seconds
		// First one runs after 5s
		const gcfn = () => {
			this.garbageCollect()
		}
		this.gcTimer = setInterval(gcfn, 60000)
		setTimeout(gcfn, 5000)
	}

	// Runs the newly instantiated connection
	Run() {
		if (this.initialised) {
			throw new Error('IRIS connection already running')
		}
		this.initialised = true
		this.Connect()
	}

	async getIRISHosts(): Promise<Maybe<irisHostInfo>> {
		return new Promise(async resolve => {
			// Check cache
			if (this.cachedHosts) {
				resolve(this.cachedHosts)
				return
			}

			// Fetch from server
			const response = await fetch('/reverse-host-iris/')
			if (response.status >= 400) {
				resolve(this.cachedHosts)
			}
			const dataStream = await response.body?.getReader().read()
			const data = await new TextDecoder().decode(dataStream?.value)
			this.cachedHosts = JSON.parse(data)
			resolve(this.cachedHosts)
		})
	}

	// Fetches the websocket URI based on the current subdomain and protocol
	// Also bundles some parameters to send to IRIS to identify the user
	async getWebsocketURI(): Promise<Maybe<string>> {
		return new Promise(async resolve => {
			// Get the page that the user is requesting
			const page = Do(() => {
				const P = location.href.split('#')[0] ?? ''
				return P.split('://')[1]?.substring(location.host.length) ?? ''
			})

			// Get the session key from the cookie
			const session_key = getCookies().get('SessionKey') ?? ''

			// Get the endpoint for IRIS
			// Get the subdomain to know which node to connect to
			// Build connection URI
			const hosts = await this.getIRISHosts()
			if (!hosts) {
				irislog('No IRIS hosts found')
				resolve(null)
				return
			}
			const reverseHostFlask = hosts.reverseFlask ?? location.host
			const reverseHostSvrts = hosts.reverseSvrts ?? location.host
			const fullHost = hosts.public ?? 'wss://iris.trionline.com.au:443'
			const url = `${fullHost}/?d=${page}|||${session_key}|||${reverseHostFlask}|||${reverseHostSvrts}`

			// Run the callback function
			resolve(url)
		})
	}

	// Attempts to connect to the websocket - skips if already reconnecting
	async Connect() {
		// Skip if we're already reconnecting
		if (this.isConnecting === true) {
			return
		}
		irislog('Connecting to IRIS...')
		this.isConnecting = true

		// Wait 2 seconds to get the URL - if it fails, clear and try connecting again
		let urlFetched = false
		setTimeout(() => {
			if (!urlFetched) {
				irislog('Performing reset of connection to IRIS')
				this.isConnecting = false
				this.Connect()
			}
		}, 2000)

		// Get the connection URL
		const uri = await this.getWebsocketURI()
		urlFetched = true

		// Skip if we're not signed in
		if (uri == null) {
			return
		}

		// Allow overriding of IRIS connection if class appended to body
		if ($('html').hasClass('noiris')) {
			return
		}

		// Create the websocket object and event handlers
		this.wsObject?.close()
		this.wsObject = new WebSocket(uri)
		this.wsObject.onopen = () => {
			this.onConnect()
		}
		this.wsObject.onclose = () => {
			this.onDisconnect()
		}
		this.wsObject.onmessage = e => {
			this.onReceive(e.data)
		}
	}

	// Efvent for when the connection connects - may be a reconnect as well
	onConnect() {
		this.isConnecting = false
		this.isConnected = true
		this.reconnectAttempts = 0
		irislog('Connected to IRIS')
		errorReconnectingFlyout?.Close()
		clearTimeout(this.messageQueueTimeout)
		this.messageQueueTimeout = null
		this.messageQueue.forEach(cmd => {
			this.sendRaw(cmd)
		})
		this.messageQueue = []
	}

	// Event for when the connection disconnects - handles auto-reconnecting
	onDisconnect() {
		this.isConnecting = false

		// We've disconnected - reconnect after a brief pause
		if (this.isConnected) {
			this.isConnected = false
			irislog('Disconnected from IRIS')
			this.reconnectAttempts = 0
			this.disconnections += 1
			if (this.disconnections < 10) {
				timer(500, async () => this.Connect())
			}

			// We failed to connect - try again after a period of time
		} else {
			this.reconnectAttempts = (this.reconnectAttempts ?? 0) + 1
			irislog(`Attempting to reconnect to IRIS: #${this.reconnectAttempts}`)
			// 500ms for the first 5s
			// After that, 3s - for 30s
			// After that, display an alert and wait 10s between each attempt
			let duration = 10000
			if (this.reconnectAttempts < 10) {
				duration = 500
			} else if (this.reconnectAttempts < 20) {
				duration = 3000
			} else {
				showErrorReconnectingMessage()
			}
			timer(duration, async () => this.Connect())
		}
	}

	// Send a message raw to the websockte - a lower level method
	// Generally the user will want the `Send` method of this object
	sendRaw(obj: IRISRequestData<any, any>) {
		if (!this.wsObject || this.wsObject.readyState > 1 || !this.isConnected) {
			this.messageQueue.push(obj)
			this.Connect()
			return
		}
		try {
			const reqJSON = JSON.stringify(obj)
			this.wsObject.send(reqJSON)
		} catch (e) {
			console.error(e)
			this.messageQueue.push(obj)
			if (this.messageQueueTimeout === null) {
				this.messageQueueTimeout = timer(15000, () => {
					// TODO - should this be an alert?
					irislog('Failed to reconnect to IRIS')
				})
			}
		}
	}

	// Sends an IRIS request with callback responses for success and failure
	Send<ProgID extends number, FuncID extends number>(
		obj: IRISRequestType<ProgID, FuncID>,
	) {
		// Sanitize input and fill default values
		if (!obj.data) {
			throw new Error('Data cannot be empty')
		}
		if (typeof obj.data !== 'object') {
			throw new Error('Data must be an object')
		}

		// Add a piece of random hex data to the request
		// Any responses to this request will use this request ID
		// Allowing us to determine the callbacks
		obj.data.requestID = Math.random().toString(36).substr(2)

		// Register the event handlers when a response is received
		this.responseRegister[obj.data.requestID] = {
			created: _.now(),
			yes: obj.yes ?? _.noop,
			no:
				obj.no ??
				(data => {
					console.error(data)
				}),
			any: obj.any ?? _.noop,
			prog: obj.prog ?? _.noop,
		}

		// Register the history item
		this.history[obj.data.requestID] = {
			In: obj.data,
			Sent: _.now(),
			Out: null,
			Received: null,
		}

		// Convert the request object to a JSON string and send on the websocket
		this.sendRaw(obj.data)

		// If this isn't a system message increment the counter
		if (!(this.excludeSpinner[obj.data.progID] ?? []).includes(obj.data.funcID)) {
			this.messageCount++
		}

		// If there is more than one current non-system message then show the spinner
		if (this.messageCount > 0) {
			return document.querySelector('.lds-dual-ring')?.classList.remove('hidden')
		}
	}

	// Same as `Send` method, but returns a promise object. Only data is supplied
	// since the callbacks are defined when resolving the promise
	async SendPromise(data) {
		return new Promise<void>((resolve, reject) => {
			this.Send({
				data,
				yes: () => {
					resolve()
				},
				no: () => {
					reject()
				},
			})
		})
	}

	// Event to run when a message is received from the server
	onReceive(message): void {
		// Decode the response into JSON if possible
		let responseObj = null
		try {
			responseObj = JSON.parse(message)
		} catch (error) {
			console.error(`Error decoding -> ${message}`)
			return
		}

		// If we receive an `InvalidSession` error - refresh page and sign out
		if (responseObj.error === 'InvalidSession' && !window.allowIRISWithoutLogin) {
			console.info('Page refresh - invalid session')
			location.reload()
			return
		}

		// Determine which callback were requested to run
		const reqID = responseObj.requestID
		const callbacks = this.responseRegister[reqID]
		if (!callbacks) {
			// Log that we've received an unsolicited broadcast
			irislog(`Received broadcast: ${responseObj.progID}/${responseObj.funcID}`)

			// If it is program ID 0, function ID 5 (broadcast message), show dialog
			// Otherwise, handle it with the set function for the page
			if (responseObj.progID === 0 && responseObj.funcID === 5) {
				// Check if it's a redirect message
				if (responseObj.message.startsWith('redirect:')) {
					const url = responseObj.message.substring('redirect:'.length).trim()
					if (
						typeof openNavLinkMaybe !== 'undefined' &&
						openNavLinkMaybe !== null
					) {
						openNavLinkMaybe(url)
					} else {
						location.href = url
					}
					return
				}

				// Show message on-screen
				showBroadcastMessage(responseObj.message)
				return
			}

			// Run speed test - results are relayed to the requesting ID
			if (responseObj.progID === 0 && responseObj.funcID === 12) {
				runeSpeedTestBroadcast(responseObj)
				return
			}

			// Force refresh the page (no cache)
			if (responseObj.progID === 0 && responseObj.funcID === 16) {
				console.info('Forcing page refresh from IRIS')
				location.reload()
				return
			}

			// If the page has a custom event handler for receiving unsolicited
			// messages, execute that now. Otherwise do nothing an exit early
			;(window.irisOnUnsolicited ?? _.noop)(responseObj)
			return
		}

		// If this is a progress report, handle quickly and abort before
		// we clear the request - more responses are expected to come
		if (responseObj.isProgressReport) {
			callbacks.prog(responseObj.progress)
			return
		}

		// Work out how long the round trip took, and log to the response object
		responseObj.timerComplete = _.now() - callbacks.created

		// If this response is saying that MFA is required, display the form
		// Either defer executing the failure function until the form closes
		// Or when MFA is confirmed, re-run the request
		if (responseObj.mfaRequired) {
			reactFlyout('MFA Elevation Required', [380, 360], {
				tag: MFALoginForm,
				onCloseFailed: () => {
					callbacks.no(responseObj)
					callbacks.any(responseObj)
				},
				onComplete: () => {
					IRIS.Send({
						data: responseObj._original,
						any: callbacks.any,
						no: callbacks.no,
						yes: callbacks.yes,
					})
				},
			})

			// Otherwise, run the callback functions method if it is completed
		} else {
			if (responseObj.completed) {
				callbacks.yes(responseObj)
			} else {
				callbacks.no(responseObj)
			}
			callbacks.any(responseObj)
		}

		// If this is a page with a window manager, update the title
		contentComponent()?.UpdateTitle?.()

		// Copy the response to the history
		// If it's not found, they were probably disconnected for an hour and it's fine
		const req = this.history[reqID]
		if (req != null) {
			req.Out = responseObj
			req.Received = _.now()
			req.Taken = req.Received - req.Sent
		}

		// Remove the callback now that it has been completed
		delete this.responseRegister[reqID]

		// If this is not a system message then decrement the count
		if (
			!(this.excludeSpinner[responseObj._original.progID] ?? []).includes(
				responseObj._original.funcID,
			)
		) {
			this.messageCount--
		}

		// If we have less than one non-system message remove the spinner
		if (this.messageCount < 1) {
			return document.querySelector('.lds-dual-ring')?.classList.add('hidden')
		}
	}

	// Helper method that deals with string/byte conversions for decompression
	utf8ArrayToStr(array) {
		let out = ''
		const len = array.length
		let i = 0
		while (i < len) {
			var char2
			const c = array[i++]
			const mod = c >> 4
			if (mod >= 0 && mod <= 7) {
				// 0xxxxxxx
				out += String.fromCharCode(c)
			} else if (mod === 12 || mod === 13) {
				// 110x xxxx 10xx xxxx
				char2 = array[i++]
				out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
				break
			} else if (mod === 14) {
				// 1110 xxxx 10xx xxxx 10xx xxxx
				char2 = array[i++]
				const char3 = array[i++]
				out += String.fromCharCode(
					((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
				)
				break
			}
		}
		return out
	}

	// Returns the history of messages sent and received on the connection
	showHistory() {
		return _.sortBy(this.history, 'Sent')
	}

	garbageCollect() {
		// Keep track of the amount of space saved
		let saved_space = 0

		// Loop over the response register
		// Any ping requests (specific function types) will be removed after 5 minutes
		_.forEach(this.responseRegister, (v: any, k) => {
			const ms_ago = _.now() - v.Created
			if (ms_ago > 5 * 60 * 1000) {
				const { progID } = this.history[k].In
				const { funcID } = this.history[k].In
				if (progID === 0 && funcID === 4) {
					saved_space += +JSON.stringify(this.responseReigster[k])
					delete this.responseRegister[k]
					return
				}
			}
		})

		// Loop over each history item
		_.forEach(this.history, (v1?: any, k1?: any) => {
			// If no value, skip early
			if (v1 == null) {
				return
			}

			// If this item is older than 60 minutes - remove it
			const ms_ago = _.now() - v1.Sent
			if (ms_ago > 60 * 60 * 1000) {
				saved_space += JSON.stringify(v1).length
				delete this.history[k1]
				return
			}

			// If there's any output to investigate
			if (v1.Out != null) {
				// Null the following keys - they're redundant
				const keys_to_remove = ['_original', 'requestID']
				keys_to_remove.forEach(key => {
					if (v1.Out[key] != null) {
						saved_space += JSON.stringify(v1.Out[key]).length
						return delete v1.Out[key]
					}
					return undefined
				})

				// Null any output data nodes larger than 1KB
				_.forEach(v1.Out, (v2, k2) => {
					if (v2 != null) {
						const size = JSON.stringify(v2).length
						if (size > 1024) {
							v2 = null
							saved_space += size
							IRIS.history[k1].Out[k2] = v2
						}
					}
				})

				// Update the history node
				IRIS.history[k1].Out = v1.Out
			}
		})

		// Remove how much space has been saved
		if (saved_space > 2048) {
			const kb_saved = Math.round(saved_space / 1024)
			irislog(`GC Completed - KB reclaimed: ${kb_saved}`)
		}
	}

	// Ping debugging
	Ping(is_native) {
		this.Send({
			data: {
				progID: 0,
				funcID: is_native ? 9 : 2,
				string: 'testing',
			},
			yes: data => {
				console.log(data)
			},
			no: data => {
				console.log(data)
			},
		})
	}
}

// "Error reconnecting to server" message
let showingErrorReconnectingMessage = false
let errorReconnectingFlyout: Maybe<NewSystemFlyoutInstance> = null
const showErrorReconnectingMessage = () => {
	// Skip if already shown
	if (showingErrorReconnectingMessage) {
		return
	}

	// Build the flyout form
	const title = 'Network Error'
	const flyoutForm = (
		<form
			onSubmit={e => {
				e.preventDefault()
				errorReconnectingFlyout?.Close()
				return false
			}}
		>
			<p>Error reconnecting to server</p>
			<div style={{ textAlign: 'center' }}>
				<Button type="submit" lbl="OK" style={{ width: '120px' }} />
			</div>
		</form>
	)

	// Show the alert box, registering the on-close callbacks
	showingErrorReconnectingMessage = true
	errorReconnectingFlyout = reactFlyout(title, [260, 170], flyoutForm, {
		// Helper function for when the box is closed - reset the mutex
		closeCallback: () => {
			showingErrorReconnectingMessage = false
		},
	})
}

const getCookies = () => {
	const cookieObj = new Map<string, string>()
	document.cookie.split('; ').forEach(cookie => {
		const keyValue = cookie.split('=')
		const key = decodeURIComponent(keyValue[0])
		const value = decodeURIComponent(keyValue[1])
		cookieObj.set(key, value)
	})
	return cookieObj
}

// Automatically connect to IRIS
export var IRIS = new IrisClient()
IRIS.Run()
