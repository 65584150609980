import { Maybe } from '../../../../universal'
import { _ } from '../../../lib'
import { ContextMenuItem } from '../context-menu'
import { addNewNoteForm } from './add'
import { archiveNote, deleteNote } from './delete'
import { downloadNotes } from './download'
import { editNote, editNoteTags } from './edit'
import { viewNoteHistory } from './history'
import { NoteInfo, ReducerState } from './types'

export const getMetaActions = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
): ContextMenuItem[] =>
	_.compact([
		rs.props.adding
			? {
					label: 'Add',
					icon: '/static/img/i8/w11-add.svg',
					onClick: () => {
						addNewNoteForm(rs)
					},
				}
			: null,
		rs.props.onRefreshClick
			? {
					label: 'Refresh',
					icon: '/static/img/i8/ios16-refresh.svg',
					onClick: () => {
						rs.updateState({ loadingCoverMessage: 'Refreshing...' })
						rs.props.onRefreshClick?.(() => {
							rs.updateState({ loadingCoverMessage: null })
						})
					},
				}
			: null,

		rs.props.adding || rs.props.onRefreshClick ? '---' : null,
		{
			label: 'Show Archived',
			icon: () =>
				rs.state.showArchived
					? '/static/img/i8/material-outline-checkmark.svg'
					: undefined,
			dontClose: true,
			onClick: () => {
				rs.updateState({
					showArchived: !rs.state.showArchived,
				})
			},
		},
		{
			label: 'Show Deleted',
			icon: () =>
				rs.state.showDeleted
					? '/static/img/i8/material-outline-checkmark.svg'
					: undefined,
			dontClose: true,
			onClick: () => {
				rs.updateState({
					showDeleted: !rs.state.showDeleted,
				})
			},
		},
		rs.props.tagging
			? {
					label: 'Show Tag Indicators',
					labelDesc:
						'Whether the coloured circle tag indicators should be shown in the note list',
					icon: () =>
						rs.state.showTagIndicators
							? '/static/img/i8/material-outline-checkmark.svg'
							: undefined,
					dontClose: true,
					onClick: () => {
						rs.updateState({
							showTagIndicators: !rs.state.showTagIndicators,
						})
					},
				}
			: null,
		'---',
		{
			label: 'Download',
			icon: '/static/img/i8/color-download-from-the-cloud.svg',
			onClick: () => {
				downloadNotes(rs)
			},
		},
		rs.props.editing
			? {
					label: 'Generate',
					icon: '/static/img/i8/color-sparkles.svg',
					labelDesc: 'Generate title/body for all notes that lack them',
					onClick: () => {
						// TODO - implement multi-action
						// generateMissingContent(rs, rs.props.notes)
					},
				}
			: null,
	])

export const getNoteActions = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
	note: NoteInfo<T>,
): ContextMenuItem[] => {
	// Identify what kind of generative AI options are available
	const hasTitle = Boolean(note.Title)
	const hasNote = Boolean(note.Note)
	const canTranslate = hasTitle || hasNote
	const generationInfo = getGenerativeActionType({ rs, note })

	// Get the additional actions
	const additionalActions = rs.props.additionalActions?.(note) ?? []

	// Return the options
	const divider = '---' as const
	return _.compact([
		// Basic edit form without any generative properties
		rs.props.editing
			? {
					label: 'Edit note',
					labelDesc: 'Edit resident note',
					icon: '/static/img/i8/color-edit.svg',
					onClick: () => {
						editNote({ rs, note })
					},
				}
			: null,
		// Basic tag editing - only needed if we don't have an explicit edit window
		!rs.props.editing && rs.props.tagging?.onUpdate
			? {
					label: 'Edit tags',
					labelDesc: 'Edit the tags for the note',
					icon: '/static/img/i8/color-edit.svg',
					onClick: () => {
						editNoteTags({ rs, note })
					},
				}
			: null,

		// Copy the title/note to the clipboard
		{
			label: 'Copy text',
			labelDesc: 'Copies the title and note to the clipboard',
			icon: '/static/img/i8/color-copy.svg',
			onClick: () => {
				const title = note.Title ?? ''
				const body = note.Note ?? ''
				const text = `${title}\n\n${body}`.trim()
				navigator.clipboard.writeText(text).catch(console.error)
			},
		},
		rs.props.editing ? divider : null,
		// Generative AI options
		rs.props.editing
			? {
					label: generationInfo?.label ?? '',
					labelDesc: generationInfo?.labelDesc,
					icon: '/static/img/i8/color-sparkles.svg',
					hidden: !generationInfo,
					onClick: generationInfo?.action,
				}
			: null,
		// Translate - technically generative AI but a separate thing
		rs.props.editing
			? {
					label: 'Translate',
					labelDesc: 'Translate the note to the selected language',
					icon: '/static/img/i8/color-translate.svg',
					hidden: !canTranslate,
					onClick: () => {
						editNote({
							rs,
							note,
							options: {
								translate: true,
							},
						})
					},
				}
			: null,

		// Injected custom actions
		additionalActions.length > 0 ? divider : null,
		...additionalActions,

		rs.props.deleting || rs.props.history ? divider : null,
		// View historical revisions of the note
		rs.props.history
			? {
					label: 'View history',
					labelDesc: 'View the previous edits of this note',
					icon: '/static/img/i8/w11c-history.svg',
					onClick: () => {
						viewNoteHistory(note)
					},
				}
			: null,
		// Archive note - will ask for confirmation
		rs.props.archiving
			? {
					label: note.Archived ? 'Unarchive note' : 'Archive note',
					icon: '/static/img/i8/color-archive.svg',
					onClick: () => {
						archiveNote(rs, note)
					},
				}
			: null,

		// Delete note - will ask for confirmation
		rs.props.deleting
			? {
					label: 'Delete note',
					labelDesc: 'Delete resident note',
					icon: '/static/img/i8/color-trash-delete.svg',
					onClick: () => {
						deleteNote(rs, note.ID)
					},
				}
			: null,
	])
}

const getGenerativeActionType = <T extends Record<string, unknown>>(input: {
	rs: ReducerState<T>
	note: NoteInfo<T>
}): Maybe<{
	label: string
	labelDesc: string
	action: () => void
}> => {
	const { rs, note } = input

	// Identify what kind of generative AI options are available
	const hasAttachment = Boolean(note.Attachment?.Checksum)
	const hasTitle = Boolean(note.Title)
	const hasNote = Boolean(note.Note)
	const canGenerate = !(rs.props.disableGenerativeAI ?? false)
	const canGenerateTitle = !hasTitle && (hasAttachment || hasNote) && canGenerate
	const canGenerateSummary = !hasNote && (hasAttachment || hasTitle) && canGenerate

	// Only one action can be shown
	if (canGenerateTitle && canGenerateSummary) {
		return {
			label: 'Generate Summary',
			labelDesc:
				'Generate a title and summary for the note based on the attachment',
			action: () => {
				editNote({
					rs,
					note,
					options: {
						generateTitle: true,
						generateSummary: true,
					},
				})
			},
		}
	}
	if (canGenerateTitle) {
		return {
			label: 'Generate Title',
			labelDesc:
				'Generate a title for the note based on the attachment and body text',
			action: () => {
				editNote({
					rs,
					note,
					options: {
						generateTitle: true,
					},
				})
			},
		}
	}
	if (canGenerateSummary) {
		return {
			label: 'Generate Summary',
			labelDesc: 'Generate a summary based on the attachment and title',
			action: () => {
				editNote({
					rs,
					note,
					options: {
						generateSummary: true,
					},
				})
			},
		}
	}

	// Nothing available
	return null
}
