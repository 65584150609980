import { Do, timer } from '../../universal'
import { $, _, showdown } from '../lib'
import { updateFacilityUI } from './component-facility-switcher'
import { IRIS } from './component-iris'
import { j2h } from './component-j2h'
import { sendErrorsToTelegram } from './component-main'
import { registerServiceWorker } from './component-sw-client'
import { to_is_locked, to_page_unlock } from './flyouts-old'
import { moment } from './moment-wrapper'

// This covers things that every single page of TriOnline must do
// Particularly related to logging activity and sending push messages
// GLOBALS
export const TOGLOB: any = {
	UPDATE_INTERVAL: 5000,
	onFlyoutsClose: null,
	showFlyoutAnimation: true,
	lockLevel: 0,
	timer_start: null,
	timers: [],
	converter: null,
}

// Test the browser
// TODO - integrate into the frame
export const testBrowser = (auto_msg?) => {
	const valid = Do(() => {
		try {
			Array.from(new Set([1, 2, 3]))
			new Map([
				[1, 2],
				[3, 4],
			])
			_.assign({})
			JSON.parse('{}')
		} catch (error) {
			return false
		}
		return true
	})
	if (!valid && auto_msg) {
		$('#hub_content')
			.empty()
			.append(
				j2h({
					tag: 'p',
					html: `\
Your web browser is out of date and does not support viewing \
rosters. Please update your browser. If you are using iOS, \
ensure you are updated to the latest version available for your \
device.\
`,
				}),
			)
	}
	return valid
}

// INITIALISE
const to_initialise = () => {
	// If not on the roster (it has its own), report errors to Telegram
	if (location.pathname !== '/roster/') {
		sendErrorsToTelegram()
	}

	// Set up the page lock DOM element
	{
		TOGLOB.$lock = [
			$(j2h({ ID: 'trionline_pagelock', class: 'toPageLock' })),
			$(j2h({ ID: 'trionline_pagelock_2', class: 'toPageLock' })),
			$(j2h({ ID: 'trionline_pagelock_3', class: 'toPageLock' })),
			$(j2h({ ID: 'trionline_pagelock_4', class: 'toPageLock' })),
			$(j2h({ ID: 'trionline_pagelock_5', class: 'toPageLock' })),
		]
		const $b = $('body')
		TOGLOB.$lock.forEach($x => $b.append($x))
	}

	// Init stuff
	registerServiceWorker()
	refreshActivity()

	// Set up event handlers for refreshing activity
	$(window).on({
		keyup: refreshActivity,
		mousemove: refreshActivity,
		click: refreshActivity,
		scroll: refreshActivity,
	})

	// Timers start
	setsplit()

	// Remind the server that we're still here every 10 seconds
	TOGLOB.pageID = getPageID()
	updateUserTimer(true)
	setInterval(updateUserTimer, TOGLOB.UPDATE_INTERVAL)

	// Keypress event
	$(window).on('keydown', e => {
		// ESC key to close fly-outs
		if (e.which === 27 && to_is_locked()) {
			if (!TOGLOB.$lock[1].hasClass('locked')) {
				to_page_unlock()
			} else if (!TOGLOB.$lock[0].hasClass('locked')) {
				to_page_unlock()
			}
		}

		// F3 key to activate search
		if (e.which === 114) {
			e.preventDefault()
			$('#hub_search_q').focus().select()
		}

		// Shortcut key "tidle" on ANY page allows you to change facility
		if (e.which === 192 && e.shiftKey === true) {
			// Skip if not signed in
			if ($('#login').val() === '0') {
				return
			}
			e.preventDefault()
			// Only create if one does not exist
			const $existing = $('#facility_picker_universal')
			if ($existing.length === 0) {
				updateFacilityUI()
			}
		}
	})

	$('#admin_choice').click(e => {
		e.preventDefault()
		e.stopImmediatePropagation()
		e.stopPropagation()
		if (!$(e.currentTarget).hasClass('inactive')) {
			updateFacilityUI()
		}
	})

	// Log the timestamp that the page was loaded
	$('body').append(
		$(
			j2h({
				tag: 'input',
				ID: 'to_page_load_timestamp',
				value: moment().unix() * 1000,
				attr: { type: 'hidden' },
			}),
		),
	)

	// Initialise the markdown converter if one exists
	try {
		// Initialise the markdown interpreter
		TOGLOB.converter = new showdown.Converter({
			headerLevelStart: 3,
			ghMentionsLink: '#mention',
		})
		TOGLOB.converter.setFlavor('github')
	} catch (error) {}
}
// Do nothing

// TIMERS
var setsplit = () => {
	TOGLOB.timer_start = Date.now()
}

export const clearSplits = () => {
	TOGLOB.timers = []
	setsplit()
}

export const tsplit = lbl => {
	if (!TOGLOB.timers) {
		TOGLOB.timers = []
	}
	const now = Date.now()
	const this_time = now - TOGLOB.timer_start
	let total = undefined
	total =
		TOGLOB.timers.length === 0
			? this_time
			: now - TOGLOB.timers[0][1] + TOGLOB.timers[0][2]
	TOGLOB.timers.push([lbl, now, this_time, total])
	setsplit()
}

export const gsplit = () => {
	if (console.table) {
		console.table(TOGLOB.timers)
	} else {
		$.each(TOGLOB.timers, (_index, data) => {
			console.log(data)
		})
	}
}

export const showBroadcastMessage = msg => {
	let footer = 'Click anywhere outside of the box to dismiss this message.'
	footer += ' We apologise for any inconvenience.'
	let click_enabled = false
	$('body').append(
		j2h({
			class: 'trionline_broadcast_message',
			click: (_e, $elem) => {
				if (click_enabled) {
					$elem.remove()
				}
			},
			children: [
				{
					click: e => e.stopPropagation(),
					children: [
						{ class: 'heading', text: 'Message from System' },
						{ class: 'text', text: msg },
						{ class: 'footer', text: footer },
					],
				},
			],
		}),
	)
	timer(1000, () => {
		click_enabled = true
	})
}

// Refreshing activity

var refreshActivity = () => {
	TOGLOB.lastActive = _.now()
}

let consecutiveRefreshRequests = 0

var updateUserTimer = is_first => {
	// Skip if running in minitol
	if (['127.0.0.1', 'dev.localhost', '192.168.1.200'].includes(location.host)) {
		return
	}

	// Get whether this is the first send of the page load, and
	// how many milliseconds list the last detected user activity
	is_first = is_first ? 1 : 0
	const since_last_update = _.now() - TOGLOB.lastActive

	// Send to the server
	consecutiveRefreshRequests -= 1
	IRIS.Send({
		data: {
			progID: 0,
			funcID: 4,
			data: {
				req: location.href,
				width: window.innerWidth,
				height: window.innerHeight,
				initial: is_first,
				inactivity: since_last_update,
				page: TOGLOB.pageID,
			},
		},
		no: data => {
			const page = location.href.substring(
				location.host.length + location.protocol.length + 2,
			)
			const basePage = page.split('?')[0].split('#')[0]
			const allowed = [
				'/',
				'/about/',
				'/about/features/',
				'/privacy/',
				'/acct/new-password/',
			]
			if (_.includes(allowed, basePage)) {
				return
			}
			if (data.message === 'Sign-In Required' && !window.allowIRISWithoutLogin) {
				consecutiveRefreshRequests += 2
				if (consecutiveRefreshRequests > 6) {
					console.info('Forcing page refresh from sign-in required')
					location.reload()
				}
			}
		},
	})
}

/**
 * @deprecated This is for pre-React layouts
 */
export const applyLoadingUISmall = $elem =>
	$elem.empty().append(
		j2h({
			tag: 'img',
			class: 'ajax_ico',
			attr: {
				src: '/static/img/ajax-loader.gif',
				alt: 'Loading...',
			},
		}),
	)

/**
 * @deprecated This is for pre-React layouts
 */
export const applyLoadingUILarge = $elem => {
	// Ensure that $elem is not already the large loading UI
	const $children = $elem.children()
	if ($children.length === 1 && $children.hasClass('loadingSpinnerLarge')) {
		return
	}

	// Create the spinner
	// Empty its DOM and replace with the loading spinner
	$elem.empty().append(
		j2h({
			class: 'loadingSpinnerLarge',
			children: [
				{ class: 'circle blue' },
				{ class: 'circle red' },
				{ class: 'circle yellow' },
				{ class: 'circle green' },
			],
		}),
	)
}

// Function to pulse an element - usually to indicate something has changed
// Or that there was an error, depending on colour
export const stdPulse = (
	$elem = $('body'),
	colour = '#ffa',
	duration = 200,
	callback: () => void = _.noop,
) => {
	// Save the current background colour and transition
	let orig_transition = $elem.css('transition')
	let orig_bgcolour = $elem.css('background')
	orig_transition = ''
	orig_bgcolour = ''

	// Set a transition and the new background colour
	$elem.css('transition', `background ${duration / 1000}s`)
	$elem.css('background', colour)

	// On a timer, change the background colour back
	// Then remove the styles and set back to the original
	timer(duration, () => $elem.css('background', orig_bgcolour))
	timer(duration * 2, () => {
		$elem.css('transition', orig_transition)
		callback()
	})
}

// TABLE FUNCTIONS
/**
 * @deprecated This is for pre-React layouts
 */
export const tableSortable = $table => {
	// Set IDs for each row
	$table.find('tr').each((x, elem) => {
		$(elem).attr('data-sort-key', x)
	})

	// Add handlers and styles to the header row
	const $header_cells = $table.find('tr').first().addClass('sortable').children()
	$header_cells
		.addClass('noselect')
		.each((_i, elem) => {
			const $this = $(elem)
			if ($this.children().length === 1 && $this.children('span').length === 1) {
				return
			}
			const text = $this.text()
			$this.html(`<span>${text}</span>`)
		})
		.off('click')
		.on('click', e => {
			let $table

			// Get cell and column index
			const $cell = $(e.currentTarget)
			$table = $cell.closest('table')
			const col_index = $cell[0].cellIndex

			// Calculate the sorting order
			// 1 is ascending, 2 is descending
			let order: string | number = $cell.attr('data-order')
			order = !order ? 1 : 3 - +order
			$cell.attr('data-order', order)

			// Create a two-tuple of ID and value sorting
			// Also get the existing sort index for later
			let sorting_data = []
			const existing_sort_index = []
			$table
				.find('tr')
				.slice(1)
				.each((_i, elem) => {
					let $cell
					const $row = $(elem)
					const sortKey = +$row.attr('data-sort-key')

					// Get the cell's value
					$cell = $row.children().eq(col_index)
					let value = $cell.attr('data-sort-data')
					if (value === undefined) {
						if ($cell.children('abbr').length === 1) {
							value = $cell.children('abbr').attr('title')
						} else if ($cell.children('input').length === 1) {
							value = $cell.children('input').first().val()
						} else {
							value = $cell.text()
						}
					}
					value = _.trim(value)
					sorting_data.push([sortKey, value])
					existing_sort_index.push(sortKey)
				})

			// Check the data type
			const getDataType = v => {
				let dtype = undefined
				if (parseFloat(v).toString() === v && v.length > 0) {
					dtype = 'int'
				} else if (v.split('/').length === 3 && [8, 10].indexOf(v.length) >= 0) {
					dtype = 'date'
				} else if (
					(v.charAt(0) === '$' &&
						parseFloat(v.substring(2).replace(',', '')) ===
							v.substring(2).replace(',', '')) ||
					(v.charAt(2) === '$' &&
						parseFloat(v.substring(4).replace(',', '')) ===
							v.substring(4).replace(',', '') &&
						v.charAt[0] === '-') ||
					v === '$ -'
				) {
					dtype = 'dollar'
				} else {
					dtype = 'string'
				}
				return dtype
			}

			let data_type_test_value = undefined
			try {
				data_type_test_value = sorting_data[0][1]
			} catch (error) {
				e = error
				data_type_test_value = ''
			}
			let dtype = getDataType(data_type_test_value)

			const getDollarAmount = v => {
				if (v.charAt(0) === '-') {
					return 0 - parseFloat(v.substring(4).replace(',', ''))
				} else if (_.trim(v) === '$ -') {
					return 0.0
				}
				return parseFloat(v.substring(2).replace(',', ''))
			}

			const stringToDate = s => {
				s = s.split('/')
				const y = s[2]
				const m = s[1]
				const d = s[0]
				return `${y}-${m}-${d}`
			}

			// Convert the sorting table types
			sorting_data.forEach((item, x) => {
				const key = item[0]
				const val = item[1]
				switch (dtype) {
					case 'int': {
						sorting_data[x] = [key, parseFloat(val)]

						break
					}
					case 'dollar': {
						sorting_data[x] = [key, getDollarAmount(val)]

						break
					}
					case 'date': {
						sorting_data[x] = [key, stringToDate(val)]

						break
					}
					// No default
				}
			})
			dtype = ['int', 'dollar'].indexOf(dtype) !== -1 ? 'numeric' : 'string'

			// Sort the data
			if (_ !== undefined) {
				sorting_data = _.sortBy(sorting_data, x => {
					if (x[1] === '') {
						return 'zzzzzzzzzzzzzzzzz'
					}
					return x[1]
				})
			} else {
				sorting_data.sort((a, b) => {
					if (dtype === 'numeric') {
						return a[1] - b[1]
					}
					return a[1].localeCompare(b[1])
				})
			}

			// Reverse if ascending (we place in reverse)
			if (order === 1) {
				sorting_data.reverse()
			}

			// Get the new sort index
			const new_sort_index = _.map(sorting_data, x => x[0])

			// If the new sort index is the same as the existing sort index, reverse again
			existing_sort_index.reverse()
			if (JSON.stringify(new_sort_index) === JSON.stringify(existing_sort_index)) {
				order = 3 - +order
				$cell.attr('data-order', order)
				new_sort_index.reverse()
			}

			// Place rows from the bottom
			const $header = $table.find('tr').first()
			new_sort_index.forEach(key => {
				const $row = $table.find(`tr[data-sort-key="${key}"]`)
				$header.after($row)
			})

			// Update the cell class to reflect if it's ascending or descending
			order = +order
			$cell.parent().children().removeClass('sort_asc sort_dsc sorted')
			if (order === 1) {
				$cell.addClass('sort_asc sorted')
			} else if (order === 2) {
				$cell.addClass('sort_dsc sorted')
			}
		})
}
/**
 * @deprecated This is for pre-React layouts
 */
export const createTableRow = fields =>
	$(
		j2h({
			tag: 'tr',
			children: fields.map(field => ({ tag: 'td', html: field })),
		}),
	)

// GRID FUNCTIONS
/**
 * @deprecated This is for pre-React layouts
 */
export const newGrid = () => $(j2h({ class: 'grid' }))

/**
 * @deprecated This is for pre-React layouts
 */
export const createGridRow = fields =>
	$(
		j2h({
			class: 'gridRow',
			children: fields.map(field => ({
				tag: 'span',
				class: 'gridCell',
				text: field,
				title: field,
			})),
		}),
	)

// HASHES AND PAGE ID
/**
 * @deprecated You should use the guid function
 */
const myHashCode = S => {
	let hash = 0
	let chr = undefined
	if (S.length === 0) {
		return hash
	}
	_.range(S.length).forEach(i => {
		chr = S.charCodeAt(i)
		hash = (hash << 5) - hash + chr
		return (hash |= 0)
	})
	return hash
}

var getPageID = () => {
	const s = Date.now().toString()
	return myHashCode(s)
}

export const addImageLightbox = $container => {
	// Add the lightbox class for styling the images with the hand cursor
	// Index all images on this page to start
	$container.addClass('lightbox-container')
	const $all_images = $container.find('img:not(.nolightbox)')

	// Ensure the event is applied
	return $all_images
		.off('click', openingImageLightbox)
		.on('click', openingImageLightbox)
}

// Add an event handler to any images to open up large on the screen
var openingImageLightbox = e => {
	const $img = $(e.currentTarget)
	const img_query = 'img:not(.nolightbox)'
	const $all_images = $img.closest('.lightbox-container').find(img_query)

	// Function to move to an adjcaent image in the set
	const shiftImage = delta => {
		// Get current element and index
		const curr_src = $('.image_lightbox').find(img_query).attr('src')
		const $curr = $all_images.filter(`[src='${curr_src}']`)
		let index = $all_images.index($curr)
		const old_index = index + 0

		// Mutate the index in the chosen direction
		index += delta
		if (index < 0) {
			index = 0
		}
		if (index >= $all_images.length) {
			index = $all_images.length - 1
		}

		// If it's the same, skip
		if (index === old_index) {
			return
		}

		// Update the image
		const new_src = $all_images.eq(index).attr('src')
		$('.image_lightbox').find(img_query).attr('src', new_src)
	}

	// Create lightbox with image in centre
	$('body').append(
		j2h({
			class: 'image_lightbox',
			ev: {
				click: (_e, $el) => $el.remove(),
				keydown: (e, $el) => {
					switch (e.which) {
						case 39: {
							shiftImage(1)

							break
						}
						case 37: {
							shiftImage(-1)

							break
						}
						case 27: {
							$el.remove()

							break
						}
						// No default
					}
				},
			},
			attr: { tabindex: 1 },
			children: [
				{
					tag: 'img',
					attr: { src: $img.attr('src') },
				},
			],
		}),
	)

	// Focus the image
	$('body').find('.image_lightbox').focus()
}

// Wrapper for a native AJAX function instead of using JQuery
export var trionlineAjax = obj => {
	// Fallback values
	if (obj == null) {
		obj = {}
	}
	if (obj.url == null) {
		obj.url = './'
	}
	obj.method = (obj.method ?? obj.type ?? 'GET').toUpperCase()
	obj.yes = obj.yes ?? obj.success ?? _.noop
	obj.no = obj.no ?? obj.error ?? _.noop
	obj.any = obj.any ?? _.noop

	// Parse the parameters
	const paramsTemp = _.map(obj.data ?? {}, (v, k) => [
		encodeURIComponent(k),
		encodeURIComponent(v),
	])
	const paramsArray = _.map(paramsTemp, x => `${x[0]}=${x[1]}`)
	const params = paramsArray.join('&')

	// GET methods put the parameters on the URL
	if (params && obj.method === 'GET') {
		obj.url += `?${params}`
	}

	// Create the AJAX object
	const xhttp = new XMLHttpRequest()

	// Event handler for change of state
	xhttp.onreadystatechange = e => {
		const target = e.currentTarget as any
		if (target.readyState === 4) {
			if (target.status === 200) {
				obj.any(e)
				obj.yes(target.responseText ?? null)
			} else {
				obj.any(e)
				obj.no(target)
			}
		}
	}

	// Open
	xhttp.open(obj.method, obj.url, true)

	// Add custom headers
	_.forEach(obj.headers, (v, k) => {
		xhttp.setRequestHeader(k, v)
	})

	// Send the request
	// POST methods attach the parameters as the request body
	xhttp.setRequestHeader('Pragma', 'no-cache')
	if (obj.method === 'POST') {
		xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		xhttp.send(params)
	} else {
		xhttp.send()
	}
	return xhttp
}

// Runs a speed test from an IRIS broadcast and sends back the results
export const runeSpeedTestBroadcast = async responseObj =>
	new Promise(runSpeedTest).then(result => {
		IRIS.Send({
			data: {
				progID: 0,
				funcID: 15,
				broadcastUser: responseObj.OriginConnection,
				kbps: result,
			},
		})
	})

// Run a speed test in the background to test internet speed
export var runSpeedTest = resolve => {
	const concurrent = 4

	// Size is an amount that yields 2.2MB
	const size = 50000
	const gzip_KB = 1090

	// Create a promise that returns the time taken
	const createRequestAttempt = async () =>
		new Promise((resolve, reject) => {
			// Take the timer and launch the request
			const start: any = new Date()
			trionlineAjax({
				url: `https://${location.host}/blankdata/${size}/`,

				// Reject
				no: data => {
					reject(data)
				},
				// Get the timing result and resolve
				yes: data => {
					const overall_taken = (new Date() as any) - start
					const server_taken = +data.split('|')[0]
					const http_taken = overall_taken - server_taken
					const KBPS = Math.floor((gzip_KB / http_taken) * 1000)
					resolve(KBPS)
				},
			})
		})

	// Run N tests simultaneously
	const promises = _.range(concurrent).map(async () => createRequestAttempt())

	// Run the promise and display the result
	Promise.all(promises).then(KBPS => {
		console.log(`Speed results: ${KBPS.join(', ')} - total: ${_.sum(KBPS)} KB/s`)
		resolve(_.sum(KBPS))
	})

	// Return nothing so it looks cleaner in the console
	console.log('Running speed test...')
}

$(document).ready(to_initialise)
