import { createPhantomTypeSchema, Maybe, MaybeT, t } from '../../../../universal'
import { _, React } from '../../../lib'
import { Textbox } from '../textbox'
import { FormField, FormFieldJSX, readDynamic } from './types'

// NOTE: these need to be partial because of no strict null checks :(
const NumericWrapperT = t.Object({
	rawValue: t.Union([t.String(), t.Null()]),
	numeric: t.Union([t.Number(), t.Null()]),
	isValid: t.Union([t.Boolean(), t.Null()]),
})
type NumericWrapper = t.StaticDecode<typeof NumericWrapperT>

// Custom settings for a numeric textbox
type NumericSettings<F> = FormField<F, Maybe<number>, NumericWrapper> & {
	/** Minimum allowed value */
	min?: number
	/** Maximum allowed value */
	max?: number
	/** Placeholder text to show when the textbox is empty */
	placeholder?: string
	/** Optionally format to N decimal places (also rounds) */
	decimals?: number
	/** By default, if the entered string ends with `k`, it converts to 1000s */
	disableKSuffix?: boolean
	/** By default, commas are added to separate the thousands when blurring */
	disableThousandCommas?: boolean
	/** Prefix string to go before the number in the textbox (good for "$ ")*/
	prefix?: string
	/** Suffix string to go after the number in the textbox */
	suffix?: string
}

/** Numeric textbox */
export const FormTypeNumeric = <F,>(
	settings: NumericSettings<F>,
): FormFieldJSX<F, Maybe<number>, NumericWrapper> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		validators: [
			{
				req: x => Boolean(x.isValid),
				msg: 'invalid number',
			},
			{
				req: x => {
					const min = settings.min
					return x.numeric == null || min == null || x.numeric >= min
				},
				msg: `min allowed: ${settings.min}`,
			},
			{
				req: x => {
					const max = settings.max
					return x.numeric == null || max == null || x.numeric <= max
				},
				msg: `max allowed: ${settings.max}`,
			},
		],
		fixer: x => {
			// If the stringified number ends with a k, drop it and try to multiply by 1000
			let number = x.numeric
			let isValid = x.isValid
			if (!settings.disableKSuffix && x.rawValue?.endsWith('k')) {
				const num = Number(x.rawValue.slice(0, -1))
				if (!isNaN(num)) {
					number = num * 1000
					isValid = true
				}
			}

			// Don't touch anything if it's not a valid number
			if (!isValid) {
				return x
			}

			// Add rounding and comma formatting
			// The commas are optional and are stripped afterwards if needed
			const intl = new Intl.NumberFormat('en-AU', {
				minimumFractionDigits: settings.decimals ?? 0,
				maximumFractionDigits: settings.decimals ?? 0,
			})
			let s = number != null ? intl.format(number) : ''
			if (settings.disableThousandCommas) {
				s = s.replace(/,/g, '')
			}

			// Add prefix/suffix
			s = `${settings.prefix ?? ''}${s}${settings.suffix ?? ''}`

			// Return the new object based on the updated string
			return {
				rawValue: s,
				numeric: number,
				isValid: true,
			}
		},
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<Maybe<number>>(MaybeT(t.Number())),
		schemaRaw: createPhantomTypeSchema<NumericWrapper>(NumericWrapperT),
		toPublic: x => x.numeric,
		toInternal: x => ({
			isValid: x == null || !isNaN(x),
			numeric: x == null || isNaN(x) ? null : x,
			rawValue: (x ?? '').toString(),
		}),
	},
	llmInfo: formState => ({
		stringifiedType: 'number',
		description:
			readDynamic(settings.doc, formState) ?? readDynamic(settings.lbl, formState),
		// displayValueMap: x ,
		reversal: x => {
			const n = Number(x)
			// const valid = !isNaN(n)
			return n
			// return {
			// 	isValid: !valid,
			// 	numeric: valid ? n : null,
			// 	rawValue: String(x),
			// }
		},
	}),
	jsx: props => (
		<Textbox
			value={props.value.rawValue ?? ''}
			onUpdate={v => {
				const num = extractRawNumberFromNumericString(
					settings,
					String(v),
				).parsedNumber
				props.onUpdate({
					rawValue: v,
					numeric: isNaN(Number(num)) ? null : (num ?? null),
					isValid: !isNaN(Number(num)),
				})
			}}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			placeholder={settings.placeholder}
			readOnly={props.readOnly}
			disabled={props.disabled}
			onFocus={e => {
				// Get the current selection points
				const cStart = e.target.selectionStart
				const cEnd = e.target.selectionEnd

				// Convert back to just the number when focusing
				const s = String(e.target.value)
				const num = extractRawNumberFromNumericString(settings, s)

				// Set the new value and update the selection points
				if (!isNaN(num.parsedNumber)) {
					props.onUpdate({
						rawValue: String(num.parsedNumber),
						numeric: num.parsedNumber,
						isValid: true,
					})
					// Update selection points on next frame if still focused
					requestAnimationFrame(() => {
						if (document.activeElement === e.target) {
							const ncStart = num.posMap[cStart ?? -1] ?? null
							const ncEnd = num.posMap[cEnd ?? -1] ?? null
							e.target.setSelectionRange(ncStart, ncEnd)
						}
					})
				}

				// Run the user-defined focus function (generally won't exist)
				props.onFocus?.()
			}}
			onBlur={props.onBlur}
			data-1p-ignore={true}
		/>
	),
})

// Converts a raw string for a numeric textbox into the core number
// Will return NaN if it's invalid
// Also returns a mapping of old positional indices to new so focusing doesn't cursor-jack in a jarring way
const extractRawNumberFromNumericString = <F,>(
	settings: NumericSettings<F>,
	str: string,
): {
	parsedNumber: Maybe<number>
	posMap: Record<number, number>
} => {
	// Track the old to new positional mapping for seamless cursor-jacking
	let s = str
	if (!s) {
		return {
			parsedNumber: null,
			posMap: {},
		}
	}
	const posMap = _.fromPairs(_.range(s.length + 1).map(i => [i, i]))

	// Strip the suffix
	if (settings.suffix && s.endsWith(settings.suffix)) {
		const cutoff = s.length - settings.suffix.length
		s = s.slice(0, cutoff)
		_.forEach(posMap, (v, k) => {
			if (v >= cutoff) {
				posMap[k] = cutoff
			}
		})
	}

	// Strip the prefix
	const prefix = settings.prefix
	if (prefix && s.startsWith(prefix)) {
		s = s.slice(prefix.length)
		_.forEach(posMap, (v, k) => {
			posMap[k] = v < prefix.length ? 0 : v - prefix.length
		})
	}

	// Iterate over each character and remove the commas and whitespace
	// Each time a comma is removed, the positional mapping is combined at that point
	_.forEach(s, (c, i) => {
		if (c === ',' || c === ' ') {
			_.forEach(posMap, (v, k) => {
				if (v > i) {
					posMap[k] = v - 1
				}
			})
		}
	})
	s = s.replace(/,/g, '')

	// Leading zeroes are going to be removed, which also impacts posMap
	let firstNonZero = -1
	_.forEach(s, (c, i) => {
		if (c !== '0' && firstNonZero === -1) {
			firstNonZero = i
		}
	})
	if (firstNonZero > 0) {
		s = s.slice(firstNonZero)
		_.forEach(posMap, (v, k) => {
			posMap[k] = v - firstNonZero
		})
	}

	// Return the number and positional mapping
	return {
		parsedNumber: Number(s),
		posMap: posMap,
	}
}
