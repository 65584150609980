import { Maybe, tDate } from '../../../../universal'
import { React } from '../../../lib'
import { ListGridInstance } from '../list-grid'
import { Focusable, RSInstanceD } from '../meta-types'

export const ACTION_CONTEXT_MENU_WIDTH = 170

export type FileAttachment = {
	Checksum: string
	MimeType: string
}

export type NoteInfo<T extends Record<string, unknown>> = {
	ID: string
	Title: Maybe<string>
	Note: string
	Attachment: Maybe<FileAttachment>
	Created: tDate
	CreatedAuthor: Maybe<string>
	LastEdited: tDate
	LastEditedAuthor: Maybe<string>
	ExtraInfo: T
	Archived: boolean
	Deleted: boolean
	Tags: string[]
	Meta: Maybe<{
		text: string
		link: Maybe<() => void>
	}>
}

export type NoteRevisionHistorical = {
	NoteID: string
	Created: tDate
	Author: string
	Title: Maybe<string>
	Note: string
	Attachment: Maybe<FileAttachment>
}

export type NoteTag = {
	ID: string
	DisplayName: string
	Description: string
	Colour: Maybe<string>
}

export type NoteTagGroup = {
	GroupName: string
	Tags: NoteTag[]
	IsCompulsory: boolean
	Colour: Maybe<string>
}

export type NotesComponentProps<T extends Record<string, unknown>> = {
	/** Class name to add to the DOM - can be used for tailwind styles */
	className?: string
	/** Global option to disable all generative AI. @default false */
	disableGenerativeAI?: boolean
	/** The array of note data */
	notes: NoteInfo<T>[]
	/** Details required to allow tagging notes. Null means tagging isn't allowed */
	tagging: Maybe<{
		/** The array of note categories */
		options: (NoteTag | NoteTagGroup)[]
		/**
		 * The function to call when the user is updating a note tags.
		 * If null, the user cannot edit tags
		 */
		onUpdate:
			| null
			| ((model: {
					additions: {
						noteID: string
						tagID: string
					}[]
					deletions: {
						noteID: string
						tagID: string
					}[]
					onComplete: (successOrError: true | string) => void
			  }) => void)
	}>
	/** The serialised, controllable state */
	state?: {
		/** The selected note */
		selectedNote: Maybe<string>
		/** Search text */
		searchText: string
		/** Whether to show archived notes */
		showArchived: boolean
		/** Whether to show deleted notes */
		showDeleted: boolean
		/** Whether to show the tag colour circles in the note list */
		showTagIndicators: boolean
		/** The sizes of the resizable panes */
		paneSizes: Maybe<Maybe<number>[]>
		/** Event when the selected note or search text updates */
		onUpdate: (
			delta: Partial<Omit<NotesComponentState, 'loadingCoverMessage'>>,
		) => void
	}
	/** Action to run when the user clicks the refresh button. If not given, no button is shown */
	onRefreshClick: null | ((callback: () => void) => void)
	/** Details required to allow adding new notes. Null means adding isn't allowed */
	adding: null | {
		/** The title to display when adding a new note */
		formTitle: string
		/** The prompt to use for the form component when adding a new note */
		formPrompt: string
		/** The function to call when the user is adding a new note */
		onAdd: (input: {
			note: {
				Title: string
				Note: string
				Tags: string[]
				Attachment: Maybe<string>
			}
			/** Callback function - first param is the new ID, second is the result of the operation */
			onComplete: (newID: Maybe<string>, successOrError: true | string) => void
		}) => void
	}
	/** Details required to allow editing existing notes. Null means editing isn't allowed */
	editing: null | {
		/** The title to display when editing a note */
		formTitle: string
		/** The prompt to use for the form component when editing a note */
		formPrompt: string
		/** The function to call when the user is editing a note. Note that tag editing is handled by a separate handler */
		onEdit: (input: {
			noteID: string
			changes: Partial<{
				title: string
				note: string
				attachment: Maybe<string>
			}>
			onComplete: (successOrError: true | string) => void
		}) => void
	}
	/** Details required to allow archiving notes. Null means archiving isn't allowed */
	archiving: null | {
		/** The function to call when the user is archiving a note */
		onArchive: (
			noteID: string,
			isArchiving: boolean,
			onComplete: (successOrError: true | string) => void,
		) => void
	}
	/** Details required to allow deleting notes. Null means deleting isn't allowed */
	deleting: null | {
		/** Are deleted notes retained under "Show Deleted"? */
		retainCopy: boolean
		/** The function to call when the user is deleting a note */
		onDelete: (
			noteID: string,
			onComplete: (successOrError: true | string) => void,
		) => void
	}
	/** Given to the language model to tailor the title/summary to the specific usage */
	summaryContext?: {
		/** The prompt to use for the title field - summarises a note/attachment */
		title?: string
		/** The prompt to use for the note field - summarises an attachment if present */
		note?: string
	}
	/** Information about managing edit history. Null means edit histories aren't stored */
	history: null | {
		/** The function to call to get the history of notes */
		fetchOldRevisions: (noteIDs: string[]) => Promise<NoteRevisionHistorical[]>
	}
}

export type NotesComponentState = {
	searchText: string
	showArchived: boolean
	showDeleted: boolean
	showTagIndicators: boolean
	selectedNote: Maybe<string>
	paneSizes: Maybe<Maybe<number>[]>
	loadingCoverMessage: Maybe<string>
}

export type NotesComponentRefs<T extends Record<string, unknown>> = {
	grid: React.RefObject<ListGridInstance<NoteInfo<T>, false>>
	searchBox: React.RefObject<Focusable<HTMLInputElement>>
}

export type ReducerState<T extends Record<string, unknown>> = RSInstanceD<
	NotesComponentProps<T>,
	NotesComponentState,
	NotesComponentRefs<T>,
	object
>
