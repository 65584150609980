import { Alerts } from '../../component-main'
import { reactForceAllUpdate } from '../../component-react'
import { NoteInfo, ReducerState } from './types'

export const archiveNote = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
	note: NoteInfo<T>,
) => {
	Alerts.Confirm({
		msg: 'Are you sure you want to archive this note?',
		yes: () => {
			// Send request to server to archive this note
			rs.props.archiving?.onArchive(note.ID, !note.Archived, response => {
				if (response) {
					reactForceAllUpdate()
				} else {
					Alerts.Alert({ msg: response })
				}
			})
		},
	})
}

export const deleteNote = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
	noteID: string,
) => {
	Alerts.Confirm({
		msg: rs.props.deleting?.retainCopy
			? 'Are you sure you want to mark this note as deleted?'
			: 'Are you sure you want to permanently delete this note?',
		yes: () => {
			// Send request to server to remove this leave
			rs.props.deleting?.onDelete(noteID, response => {
				if (response) {
					reactForceAllUpdate()
				} else {
					Alerts.Alert({ msg: response })
				}
			})
		},
	})
}
