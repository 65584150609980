import { BuildClass, Maybe, MaybeT, t, TW_OVERFLOW_ELLIPSIS } from '../../../universal'
import { _, React } from '../../lib'
import { uploadFileIris } from '../component-upload'
import { LoadingSpinnerLarge } from './loading'
import { CJSX, MatchRender, RSInstanceD, useRSInstance, useStateSync } from './meta-types'
import { stubInput } from './stubs'

export const FileUploadItemT = t.Object({
	Filename: t.String(),
	Checksum: MaybeT(t.String()),
})
export type FileUploadItem = {
	Filename: string
	Checksum: Maybe<string>
}

type FileUploadProps = {
	className?: string
	/** Whether this file uploader accepts multiple */
	multiple: boolean
	/** Stores the value of the file(s) uploaded and set */
	value: FileUploadItem[]
	/**
	 * Update event when the file list changes.
	 * The `isReady` flag is for when the files are uploaded and ready.
	 */
	onUpdate: (files: FileUploadItem[]) => void
	/** Placeholder text. If none given, defaults to "Click or drag files here" */
	placeholder?: string
	/** Height of the element */
	height?: number
}

type FileUploadState = {
	files: FileUploadItem[]
	isUploadingProgress: Maybe<number>
	isFocused: boolean
	isDraggingOver: boolean
}

type FileUploadRefs = {
	inputRef: React.RefObject<HTMLInputElement>
}

type ReducerState = RSInstanceD<FileUploadProps, FileUploadState, FileUploadRefs, object>

const getDefaultState = (p: FileUploadProps): FileUploadState => ({
	files: p.value ?? [],
	isUploadingProgress: null,
	isDraggingOver: false,
	isFocused: false,
})

export const FileUpload = (props: FileUploadProps): React.JSX.Element => {
	// Manage state
	const rs: ReducerState = useRSInstance({
		props,
		defaultState: getDefaultState,
		refs: {
			inputRef: React.useRef<HTMLInputElement>(stubInput),
		},
		actionToDelta: null,
	})

	// Update the file state when props changed
	useStateSync({
		propVal: props.value,
		stateVal: rs.state.files,
		setState: files => rs.updateState({ files }),
		setProp: files => props.onUpdate(files),
	})

	// Wrapper call for uploadFiles
	const uploadFilesWrapped = (fileList: Maybe<FileList>) => {
		uploadFiles({
			fileList: fileList,
			onStart: () => {
				rs.updateState({ isUploadingProgress: 0 })
			},
			onUpdateProgress: perc => {
				rs.updateState({ isUploadingProgress: perc })
			},
			onComplete: uploadedFiles => {
				rs.updateState({
					files: uploadedFiles,
					isUploadingProgress: null,
				})
				rs.props.onUpdate(uploadedFiles)
			},
		})
	}

	// Render
	return (
		<div
			className={BuildClass({
				[rs.props.className ?? '']: true,
				'relative h-full font-light text-[#888]': true,
				'border-2 border-dashed rounded-md': true,
				'cursor-pointer': true,
				'bg-[#ffe] hover:bg-[#ffb]':
					!rs.state.isDraggingOver && !rs.state.isFocused,
				'bg-[#ffb]': rs.state.isFocused && !rs.state.isDraggingOver,
				'bg-[#ff9]': rs.state.isDraggingOver,
				'border-[#ccc] hover:border-[#aaa]':
					!rs.state.isDraggingOver && !rs.state.isFocused,
				'border-[#00f]': rs.state.isFocused && !rs.state.isDraggingOver,
				'border-[#000]': rs.state.isDraggingOver,
			})}
			style={{ height: rs.props.height }}
			onDragEnter={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: true })
			}}
			onDragOver={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: true })
			}}
			onDragLeave={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: false })
			}}
			onDrop={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: false })
				uploadFilesWrapped(ev.dataTransfer.files)
			}}
		>
			<input
				className="pointer-events-none w-0 h-0 overflow-hidden"
				type="file"
				ref={rs.refs.inputRef}
				multiple={rs.props.multiple}
				onFocus={() => {
					rs.updateState({ isFocused: true })
				}}
				onBlur={() => {
					rs.updateState({ isFocused: false })
				}}
				onChange={async ev => {
					uploadFilesWrapped(ev.target.files)
					ev.target.value = ''
				}}
			/>
			<span className="absolute inline-block top-1/2 w-full translate-y-[-50%] text-center">
				<MatchRender
					check={[
						// Uploading progress indicator
						{
							when: rs.state.isUploadingProgress !== null,
							then: <>Uploading {rs.state.isUploadingProgress}%</>,
						},
						// Shown while dragging a file over
						{
							when: rs.state.isDraggingOver,
							then: <>Drop to upload</>,
						},
						// Show the currently-uploaded files that are selected
						{
							when: rs.state.files.length > 0,
							then: (
								<>
									{rs.state.files.map(file => (
										<AttachedFileLine
											key={file.Checksum}
											file={file}
											onRemove={() => {
												const files = rs.state.files.filter(
													f => f.Checksum !== file.Checksum,
												)
												rs.updateState({ files: files })
												rs.props.onUpdate(files)
											}}
										/>
									))}
								</>
							),
						},
						// Default placeholder
						{
							when: true,
							then: (
								<>{rs.props.placeholder ?? 'Click or drag files here'}</>
							),
						},
					]}
				/>
			</span>
		</div>
	)
}

const AttachedFileLine = (props: {
	file: FileUploadItem
	onRemove: () => void
}): React.JSX.Element => {
	const [isDownloadHovered, setIsDownloadHovered] = React.useState(false)
	const [isDeleteHovered, setIsDeleteHovered] = React.useState(false)
	return (
		<div
			className={BuildClass({
				'flex items-center m-1 rounded-sm': true,
				'bg-red-200': isDeleteHovered,
				'bg-blue-200': isDownloadHovered,
				'hover:bg-[#9df]': !isDeleteHovered && !isDownloadHovered,
			})}
		>
			{/* Filename */}
			<span
				className={BuildClass({
					[TW_OVERFLOW_ELLIPSIS]: true,
					'flex-grow align-baseline': true,
					'leading-6 font-normal': true,
					'text-neutral-800': true,
				})}
			>
				{props.file.Filename}
			</span>
			{/* Download icon */}
			<span
				className={BuildClass({
					'flex-shrink-0 align-baseline': true,
					'w-6 h-6 p-1 ml-1': true,
					'border border-transparent rounded-sm': true,
					'cursor-pointer hover:border-[#00F] hover:bg-blue-300': true,
				})}
				onMouseEnter={() => {
					setIsDownloadHovered(true)
				}}
				onMouseLeave={() => {
					setIsDownloadHovered(false)
				}}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					fetch(`https://storage.trionline.com.au/get/${props.file.Checksum}/`)
						.then(x => x.blob())
						.then(file => {
							const url = window.URL.createObjectURL(file)

							// Create a temporary anchor element
							const a = document.createElement('a')
							a.href = url
							a.download = props.file.Filename || 'download' // Use filename if available

							// Append to document, click it, and clean up
							document.body.appendChild(a)
							a.click()

							// Remove the element and revoke the object URL
							setTimeout(() => {
								document.body.removeChild(a)
								window.URL.revokeObjectURL(url)
							}, 0)
						})
				}}
			>
				<img
					className="w-4 h-4"
					src="/static/img/i8/color-copy.svg"
					alt="Download attachment"
				/>
			</span>
			{/* Delete icon */}
			<span
				className={BuildClass({
					'flex-shrink-0 align-baseline': true,
					'w-6 h-6 p-1 ml-1': true,
					'border border-transparent rounded-sm': true,
					'cursor-pointer hover:border-[#f00] hover:bg-red-300': true,
				})}
				onMouseEnter={() => {
					setIsDeleteHovered(true)
				}}
				onMouseLeave={() => {
					setIsDeleteHovered(false)
				}}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					props.onRemove()
				}}
			>
				<img
					className="w-4 h-4"
					src="/static/img/i8/w11-minus.svg"
					alt="Remove attachment"
				/>
			</span>
		</div>
	)
}

const uploadFiles = async (input: {
	fileList: Maybe<FileList>
	onStart: () => void
	onUpdateProgress: (percentage: number) => void
	onComplete: (file: FileUploadItem[]) => void
}): Promise<FileUploadItem[]> => {
	input.onStart()
	const files = Array.from(input.fileList ?? [])

	// Track collective progress so we can report a global average
	// This could potentially weight file sizes but it's probably not a big deal
	const progress = _.times(files.length, () => 0)

	// Build the promise array for uploading each file
	const uploadedFiles = await Promise.all(
		files.map(
			async (file, index) =>
				new Promise<FileUploadItem>(async resolve => {
					const uploader = uploadFileIris({
						fileObj: file,
						// Track the progress event to update the global average
						onProgress: e => {
							progress[index] = e.percentage
							const perc = Math.floor(_.sum(progress) / files.length)
							input.onUpdateProgress(perc)
						},
						// Unpack the checksum and filename to resolve promise
						onComplete: e => {
							resolve({
								Filename: e.file.name,
								Checksum: e.checksum,
							})
						},
					})
					uploader()
				}),
		),
	)

	// Update the selected file state and clear the uploading progress
	input.onComplete(uploadedFiles)
	return uploadedFiles
}

type FileUploadDropZoneProps = {
	className?: string
	disabled?: boolean
	draggingOverMessage?: string
	multiple: boolean
	onUpload: (files: FileUploadItem[]) => Promise<void>
	children: React.ReactNode
}
type FileUploadDropZoneState = {
	isUploadingProgress: Maybe<number>
	isFocused: boolean
	isDraggingOver: boolean
	isProcessing: boolean
}
type ReducerStateDropZone = RSInstanceD<
	FileUploadDropZoneProps,
	FileUploadDropZoneState,
	FileUploadRefs,
	object
>

const getDefaultStateDropZone = (): FileUploadDropZoneState => ({
	isUploadingProgress: null,
	isDraggingOver: false,
	isFocused: false,
	isProcessing: false,
})

/** Wraps some content to create a drop zone for drag+drop file uploads */
export const FileUploadDropZone = (props: FileUploadDropZoneProps): React.JSX.Element => {
	// Manage state
	const rs: ReducerStateDropZone = useRSInstance({
		props,
		defaultState: getDefaultStateDropZone,
		refs: {
			inputRef: React.useRef<HTMLInputElement>(stubInput),
		},
		actionToDelta: null,
	})

	// Wrapper call for uploadFiles
	const uploadFilesWrapped = (fileList: Maybe<FileList>) => {
		uploadFiles({
			fileList: fileList,
			onStart: () => {
				rs.updateState({ isUploadingProgress: 0 })
			},
			onUpdateProgress: perc => {
				rs.updateState({ isUploadingProgress: perc })
			},
			onComplete: uploadedFiles => {
				rs.updateState({
					isUploadingProgress: null,
					isProcessing: true,
				})
				rs.props
					.onUpload(uploadedFiles)
					.then(() => {
						rs.updateState({ isProcessing: false })
					})
					.catch(console.error)
			},
		})
	}

	// Render
	return (
		<div
			className={BuildClass({
				'relative h-full': true,
				[props.className ?? '']: true,
			})}
			onDragEnter={ev => {
				ev.preventDefault()
				if (!props.disabled) {
					rs.updateState({ isDraggingOver: true })
				}
			}}
			onDragOver={ev => {
				ev.preventDefault()
				if (!props.disabled) {
					rs.updateState({ isDraggingOver: true })
				}
			}}
			onDragLeave={ev => {
				ev.preventDefault()
				if (!props.disabled) {
					rs.updateState({ isDraggingOver: false })
				}
			}}
			onDrop={ev => {
				ev.preventDefault()
				if (!props.disabled) {
					rs.updateState({ isDraggingOver: false })
					uploadFilesWrapped(ev.dataTransfer.files)
				}
			}}
		>
			{/* Wrapping element for all covers */}
			<div
				className={BuildClass({
					'absolute z-10 top-0 left-0 h-full w-full': true,
					'pointer-events-none opacity-0': !Boolean(
						rs.state.isDraggingOver ||
							rs.state.isUploadingProgress !== null ||
							rs.state.isProcessing,
					),
				})}
			>
				{/* Hidden input */}
				<input
					className="pointer-events-none w-0 h-0 overflow-hidden"
					type="file"
					tabIndex={-1}
					ref={rs.refs.inputRef}
					multiple={rs.props.multiple}
					onChange={async ev => {
						if (!props.disabled) {
							uploadFilesWrapped(ev.target.files)
							ev.target.value = ''
						}
					}}
				/>

				{/* Cover when dragging over or showing processing progress */}
				<div
					className={BuildClass({
						'absolute z-10 top-0 left-0 m-[2px]': true,
						'h-[calc(100%-4px)] w-[calc(100%-4px)]': true,
						'text-center grid place-items-center': true,
						'border-2 border-dashed rounded-md': true,
						'border-[#000]': true,
						'bg-[hsla(60,100%,80%,0.5)]': true,
						'text-neutral-600 text-2xl': true,
					})}
				>
					{/* Cover to show when dragging over */}
					<CJSX cond={rs.state.isDraggingOver}>
						<>{props.draggingOverMessage ?? 'Drop to upload files'}</>
					</CJSX>
					{/* Cover to show while uploading */}
					<CJSX cond={!rs.state.isDraggingOver}>
						<div className="text-center">
							{/* Loading spinner */}
							<LoadingSpinnerLarge />

							{/* Text under the loading spinner */}
							<div className="text-neutral-600 text-2xl">
								<CJSX cond={rs.state.isProcessing}>
									<>Processing...</>
								</CJSX>
								<CJSX cond={!rs.state.isProcessing}>
									<>{rs.state.isUploadingProgress}%</>
								</CJSX>
							</div>
						</div>
					</CJSX>
				</div>
			</div>

			{/* Pass-through of usual children */}
			{props.children}
		</div>
	)
}
