import {
	AddressCard,
	AddressCardT,
	ContactCard,
	ContactCardT,
	createPhantomTypeSchema,
} from '../../../../universal'
import { React } from '../../../lib'
import { AddressWidget, ContactWidget } from '../../component-address-contact'
import { FormField, FormFieldJSX } from './types'

const base_desc_address =
	'Represent as an object with the following keys: `Address`, `Locale`, `State`, `PostCode`. Addresses are Australian and states use 3-character codes.'
const base_desc_contact =
	'Represent as an object with the following keys: `Email`, `Phone`, `Mobile`, `Fax`, `Website`.'

/** Address card */
export const FormTypeAddress = <F,>(
	settings: FormField<F, AddressCard, AddressCard> & { isPostal: boolean },
): FormFieldJSX<F, Partial<AddressCard>, Partial<AddressCard>> =>
	({
		...settings,
		valueDefaults: {
			def: () => ({
				ID: undefined,
				Address: null,
				Locale: null,
				State: null,
				PostCode: null,
				Complete: false,
				Note: null,
			}),
			// TODO - ensure that either it's all there or none of it is???
			validators: [],
		},
		height: 36,
		typeMap: {
			schemaRaw: createPhantomTypeSchema<AddressCard>(AddressCardT),
			schemaPublic: createPhantomTypeSchema<AddressCard>(AddressCardT),
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: {
			stringifiedType: 'address',
			description: `${base_desc_address} ${settings.doc ?? settings.lbl}`,
			reversal: (x, v) => ({
				ID: undefined,
				Address: x.Address ?? v.Address ?? '',
				Complete: v.Complete ?? false,
				Locale: x.Locale ?? v.Locale ?? '',
				State: x.State ?? v.State ?? '',
				PostCode: x.PostCode ?? v.PostCode ?? '',
			}),
		},
		jsx: props => (
			<AddressWidget
				value={props.value}
				onUpdate={props.onUpdate}
				cl={props.className ?? undefined}
				title={props.title ?? undefined}
				isPostal={settings.isPostal}
			/>
		),
	}) as FormFieldJSX<F, Partial<AddressCard>, Partial<AddressCard>>

/** Contact card */
export const FormTypeContact = <F,>(
	settings: FormField<F, ContactCard, ContactCard>,
): FormFieldJSX<F, Partial<ContactCard>, Partial<ContactCard>> =>
	({
		...settings,
		valueDefaults: {
			def: () => ({
				ID: undefined,
				Email: '',
				Phone: '',
				Mobile: '',
				Fax: '',
				Website: '',
			}),
			// TODO - ensure that either it's all there or none of it is???
			validators: [],
		},
		height: 36,
		typeMap: {
			schemaRaw: createPhantomTypeSchema<ContactCard>(ContactCardT),
			schemaPublic: createPhantomTypeSchema<ContactCard>(ContactCardT),
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: {
			stringifiedType: 'contact-card',
			description: `${base_desc_contact} ${settings.doc ?? settings.lbl}`,
			reversal: (x, v) => ({
				ID: undefined,
				Email: x.Email ?? v.Email,
				Phone: x.Phone ?? v.Phone,
				Mobile: x.Mobile ?? v.Mobile,
				Fax: x.Fax ?? v.Fax,
				Website: x.Website ?? v.Website,
			}),
		},
		jsx: props => (
			<ContactWidget
				value={props.value}
				onUpdate={props.onUpdate}
				cl={props.className ?? undefined}
				title={props.title ?? undefined}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
			/>
		),
	}) as FormFieldJSX<F, Partial<ContactCard>, Partial<ContactCard>>
