import { React } from '../lib'

/**
	* A simple wrapper that takes a lambda function to force a re-render on external state changes.
	* This can be useful for older components that rely on the DATA global in a program but have
	* nothing inside them to trigger a re-render.
	* Example:
	* ```ts
	* const OldComponent = (props) => (
		* <Signal>
			* {rerender => (
				* <NewComponent
				*  data={DATA.globalDataObject}
				*  onUpdate={value => {
					* DATA.globalDataObject = value
					* rerender()
				*  }}
				*  ...
				* />
			* )}
		* </Signal>
	* )
	* ```
	* Note: this wrapper is not necessary for many components, like EditableGrid or FormComponent
	* that maintain their own internal state, but can be useful if the parent older component has
	* a list or something similar that relies on the updated state.
*/

export const Signal = (props: {
	children: (rerender: () => void) => React.ReactNode
}): React.JSX.Element => {
	const [signal, setSignal] = React.useState(0)
	const updateSignal = () => {
		setSignal(signal + 1)
	}
	return <>{props.children(updateSignal)}</>
}
