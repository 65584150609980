import { React, _ } from '../../../lib'
import { FormType } from '../form'
import { CJSX } from '../meta-types'
import { NoteTag, ReducerState } from './types'

/** Get all flattened tags grouped by ID */
export const getFlattenedTags = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
): Record<string, NoteTag> =>
	_.chain(rs.props.tagging?.options)
		.flatMap(x => {
			if ('GroupName' in x) {
				return x.Tags.map(childTag => ({
					...childTag,
					Colour: childTag.Colour ?? x.Colour,
				}))
			}
			return [x]
		})
		.keyBy(x => x.ID)
		.value()

export const getTagColour = (tag: NoteTag) => hexToHsl(tag.Colour ?? '888888')

const hexToHsl = (hex: string): { h: number; s: number; l: number } => {
	// Unpack the RGB parts
	const rgb = hex.replace(/[^0-9a-f]/g, '')

	// Ensure we have at least 6 digits, pad with zeros if needed
	const normalizedRgb = rgb.padEnd(6, '0')
	const r = parseInt(normalizedRgb.slice(0, 2), 16) / 255
	const g = parseInt(normalizedRgb.slice(2, 4), 16) / 255
	const b = parseInt(normalizedRgb.slice(4, 6), 16) / 255

	// Calculate the lightness
	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)
	const l = (max + min) / 2

	// Calculate the saturation and hue
	let h = 0
	let s = 0

	if (max !== min) {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
		if (max === r) {
			h = (g - b) / d + (g < b ? 6 : 0)
		} else if (max === g) {
			h = (b - r) / d + 2
		} else {
			h = (r - g) / d + 4
		}
		h /= 6
	}

	// Return the HSL values
	return {
		h: Math.round(h * 360),
		s: Math.round(s * 100),
		l: Math.round(l * 100),
	}
}

const buildHSL = (colour: { h: number; s: number; l: number }) => {
	const h = colour.h
	const s = colour.s
	const l = Math.max(0, Math.min(100, colour.l))
	return `hsl(${h},${s}%,${l}%)`
}

const modifyBrightness = (
	colour: { h: number; s: number; l: number },
	amount: number,
) => ({
	...colour,
	l: Math.max(0, Math.min(100, colour.l + amount)),
})

export const TagIndicator = (props: { tag: NoteTag; includeText: boolean }) => {
	// Get the tag's base colour and use HSL manipulation to get a darker border/shadow
	const colourBase = getTagColour(props.tag)
	const colourTopGrad = modifyBrightness(colourBase, 5)
	const colourBottomGrad = modifyBrightness(colourBase, -5)
	const colourBorder = modifyBrightness(colourBase, -20)
	const colourShadow = modifyBrightness(colourBase, -20)

	// Render
	return (
		<>
			<span className="inline-block h-4 pt-[2px] mx-1 align-middle">
				<span
					className="inline-block h-[10px] w-[10px] rounded-full bg-neutral-500 align-top"
					style={{
						background: `linear-gradient(to bottom, ${buildHSL(colourTopGrad)}, ${buildHSL(colourBottomGrad)})`,
						borderColor: buildHSL(colourBorder),
						boxShadow: `0 0 0 1px ${buildHSL(colourShadow)}`,
					}}
					title={props.tag.DisplayName}
				/>
			</span>
			<CJSX cond={props.includeText}>
				<>{props.tag.DisplayName}</>
			</CJSX>
		</>
	)
}

export const FormFieldNoteTags = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
) =>
	FormType.DropdownMultiStringKey({
		lbl: 'Tags',
		tooltip:
			'Select one or more tags to help categorise the note. If left empty, the system will apply tentative tags for you to approve based on the content.',
		placeholder: '(Optional)',
		hidden: !rs.props.tagging,
		options:
			rs.props.tagging?.options.map(x => {
				if ('GroupName' in x) {
					return {
						value: null,
						selectable: false,
						text: x.GroupName,
						options: x.Tags.map(y => ({
							value: y.ID,
							text: y.DisplayName,
							tooltip: y.Description,
						})),
					}
				}
				return {
					value: x.ID,
					text: x.DisplayName,
					tooltip: x.Description,
				}
			}) ?? [],
	})
