import { DateObj, DateTimeObj, Maybe, TypeBoxPhantom } from '../../../../universal'
import { _ } from '../../../lib'
import { formButtonTypeSubset } from '../buttons'
import { FormManager } from './manager'

/** Alias for the field object parameter - used as a generic */
export type FormPropsFields = { [key: string]: FormFieldJSX<any, any> }

/** Reads the dynamic value behind a T | ((formState: F) => T) type, checking if it's a function */
export const readDynamic = <T, F>(value: T | ((formState: F) => T), formState: F): T =>
	_.isFunction(value) ? value(formState) : value

/**
 * This should be generated by the `FormType` function, but can be used to create
 * custom form field types if needed.
 *
 * This effectively wraps the basic `FormField` type with the generated type fields.
 * The intent is that forms are defined with `FormType.XXXX` functions, so the types
 * can be better inferred, and any type-specific options (e.g. combobox options, )
 */
export type FormFieldJSX<F, T, R = T> = FormField<F, T, R> & {
	/** Component-level fallbacks for the values. Individual form definitions take precedence */
	valueDefaults: FormFieldValue<T, R>
	/** This allows us to know how tall the form is */
	height: number
	/** Overrides the `FormRow` lbl to '' - really just for checkboxes, who show their own label */
	emptyLabel?: boolean
	/** Removes the label so the component can take the full width */
	removeLabel?: boolean
	/** Maps the internal type and public types to each other */
	typeMap: {
		/** Data type definer, used to infer the public form state signature */
		schemaPublic: TypeBoxPhantom<T>
		/** Data type definer, used to infer the internal form state signature */
		schemaRaw: TypeBoxPhantom<R>
		/** Maps the internal type to the public type */
		toPublic: (internalType: R) => T
		/** Maps the public type to the internal type */
		toInternal: (publicType: T) => R
	}
	/**
	 * Function to generate the JSX for the UI component
	 *
	 * The input object is a set of props that the component needs to render, passing through
	 * properties to the actual UI component, depending on the data type.
	 */
	jsx: (
		input: {
			lbl: string
			value: R
			className: Maybe<string>
			onUpdate: (v: R) => void
			disabled: boolean
			readOnly: boolean
			title: Maybe<string>
			onFocus: () => void
			onBlur: () => void
		},
		formState: F,
	) => React.JSX.Element
	/** Fields that are needed to do LLM form-fill */
	llmInfo: LLMInfo<T, R> | ((formState: F) => LLMInfo<T, R> | null) | null
}

export type LLMInfo<T, R = T> = {
	/** A stringified representation of the primtive type, used for LLM form-fill */
	stringifiedType: string
	/** Description of the field given to the LLM - generally taken from the user's definition */
	description: string
	/** An optional array of enum values - stringified */
	enums?: (string | number)[]
	/** Defines whether the array of values given to `enums` was complete or a sample */
	enumsAreComplete?: boolean
	/**
	 * Function that reverses what the LLM will give as a result back into our structured format
	 * This is needed for dropdowns to go from text back to ID. Also useful for address/contact
	 * cards where it might need some massaging
	 */
	reversal: (valueFromLLM: T, existingValue: R) => T
}

/** The basic form field value definitions - validators, fixers, and a default generator */
export type FormFieldValue<T, R = T> = {
	/** Default value generator */
	def: () => T
	/** Runs when the value is "locked-in" (generally by blurring) */
	fixer?: (currentValue: R) => R
	/** Runs whenever the value changes */
	fixerImmediate?: (currentValue: R) => R
	/** Checks whether the entered value can be accepted */
	validators: Maybe<{
		/** Function that takes in the current value and returns whether it's valid */
		req: (currentValue: R) => boolean
		/** Error to show if it's not - don't include the field name */
		msg?: Maybe<string>
	}>[]
}

/**
 * Basic field defition done by the user. Also optionally defines any values from
 * `FormFieldValue`, which override/combine with whatever the type has by default
 */
export type FormField<F, T, R = T> = {
	/** Label to show in the `FormRow` */
	lbl: string | ((formState: F) => string)
	/** DOM class for the `FormRow` */
	className?: string | ((formState: F) => string)
	/** Description of the field's purpose that can be given to an LLM */
	doc?: string | ((formState: F) => string)
	/** The tooltip to show when hovering the label (or help icon if enabled) */
	tooltip?: string | ((formState: F) => string)
	/** Whether this field should be hidden */
	hidden?: boolean | ((formState: F) => boolean)
	/** Whether the field is read only */
	readOnly?: boolean | ((formState: F) => boolean)
	/** Whether the field is disabled */
	disabled?: boolean | ((formState: F) => boolean)
	/** Whether to show a help icon to hint for the user to look at the tooltip */
	helpIcon?: boolean | ((formState: F) => boolean)
} & Partial<FormFieldValue<T, R>>

type FormAction<T extends object> = {
	/** Label to show on the button */
	lbl: string
	/** Button type - mostly just determines the colour */
	type: formButtonTypeSubset
	/** Event to run when the button is clicked - the action */
	onClick: (form: {
		/** The current form state */
		state: T
		/** Function to set a message to show at the bottom of the form */
		setMessage: (msg: string, type?: FormMsgType) => void
		/** Function to set the loading status */
		setLoading: (loading: boolean) => void
		/** Marks the form as having saved successfully - generally used to close flyout */
		markAsSuccessful: () => void
	}) => void
	/** Whether the button should be disabled if there ARE changes */
	requiresNoChanges?: boolean
	/** Whether the button should be disabled if there ARE NOT changes */
	requiresChanges?: boolean
	/** If this function is defined and returns false, the button is disabled */
	checkIfUsable?: (formState: T) => boolean
}

/** Base props for a `FormComponent` */
export type FormProps<F extends FormPropsFields> = {
	/** DOM class for the `form` element */
	className?: string
	/** The default value state for the form. Often easier than adding `def` to each field */
	defaultValue?: () => Partial<InferValueFromFieldsRaw<F>>
	/** Value - can be left null if the form doesn't need to be controlled by a parent */
	value: Maybe<InferValueFromFieldsRaw<F>>
	/** Update event for the `value` prop when controlling the form from a parent component */
	onUpdate: (v: InferValueFromFieldsRaw<F>) => void
	/** Object mapping keys to fields to show in the form. This includes their keys for the value object */
	fields: F
	/** How much width should be given to the label in the `FormRow` for every field */
	lblWidth: number
	/** How much width should be given to each action button at the bottom */
	buttonWidth?: number
	/** Highlights for edited fields can be disabled. Highlights are always shown for errors */
	disableChangeHighlights?: boolean
	/** This is passed to the transcription model when doing a form fill */
	voicePrompt?: string
	/** This is passed to the LLM when doing a form fill */
	formPrompt: string
	/** Disables all the LLM form filling. Defaults to whether the user is external to SN/T */
	disableFormFill?: boolean
	/** If enabled, allows the form to save when nothing has changed from default values. Default: false */
	allowSavingWithoutChanges?: boolean
	/**
	 * By default buttons are shown, but if the form is controlled you might want to hide
	 * them so you can show your own buttons as part of a larger form including elements
	 * that aren't included in this `FormComponent`
	 */
	excludeButtons?: boolean
	/** Overrides the save button label to something else */
	lblSave?: string
	/** Overrides the save button tooltip to something else */
	lblSaveTooltip?: string
	/**
	 * Basic save handler for the form
	 * @param model The form values in a plain object
	 * @param callback Callback function to run when the save is successful
	 * The callback function should return either `true` or an error message string
	 */
	onSave: (
		model: {
			current: InferValueFromFieldsPublic<F>
			original: InferValueFromFieldsPublic<F>
			delta: Partial<InferValueFromFieldsPublic<F>>
		},
		callback: (result: true | string) => void,
	) => void
	/** Whether to exclude showing the reset button - default FALSE */
	excludeReset?: boolean
	/** Save and reset are included by default, but this allows extra buttons (e.g. delete) */
	extraActions?: FormAction<InferValueFromFieldsPublic<F>>[]
	/** Runs whenever any field changes - used to change one field based on another change */
	formFixer?: FormFixer<F>
	/** Runs when a save handler callback is successful */
	onSuccessfulSave?: () => void
	/** Runs when the form first mounts - useful for flyouts to auto-focus first element */
	onMount?: () => void
}

export type FormFixer<F extends FormPropsFields> = (
	oldState: InferValueFromFieldsRaw<F>,
	newState: InferValueFromFieldsRaw<F>,
	changedField: keyof F,
) => Maybe<Partial<InferValueFromFieldsRaw<F>>>

/**
 * Derives/infers the simplified object type for the form state - PUBLIC VERSION
 * Outputs a POJSO with the same keys as the input fields, but with the type
 * Without `Simplify`, intellisense works, but the tooltip definition is a mess to read
 */
export type InferValueFromFieldsPublic<T extends FormPropsFields> = Simplify<{
	[K in keyof T]: T[K]['typeMap']['schemaPublic']['_type']
}>
/**
 * Derives/infers the simplified object type for the form state - INTERNAL VERSION
 * Outputs a POJSO with the same keys as the input fields, but with the type
 * Without `Simplify`, intellisense works, but the tooltip definition is a mess to read
 */
export type InferValueFromFieldsRaw<T extends FormPropsFields> = Simplify<{
	[K in keyof T]: T[K]['typeMap']['schemaRaw']['_type']
}>
type Simplify<T> = T extends DateObj | DateTimeObj
	? T
	: T extends object
		? { [K in keyof T]: Simplify<T[K]> }
		: T

// Handle the message state as a single entity so it's easier to ensure consistent state
export type FormMsgType = 'good' | 'bad' | 'neutral'
export type FormMessage = { msg: string; type: FormMsgType }

export type FormManagerInstance<F extends FormPropsFields> = {
	manager: FormManager<F>
	currentState: InferValueFromFieldsRaw<F>
	setCurrentState: (newState: InferValueFromFieldsRaw<F>) => void
	setFocused: (focused: string | null) => void
	hasUnsavedChanges: boolean
}
