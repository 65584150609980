import { Maybe, MaybeT, createPhantomTypeSchema, t } from '../../../../universal'
import { React, _ } from '../../../lib'
import { ComboBoxOptions, Combobox } from '../combobox'
import { FormField, FormFieldJSX, readDynamic } from './types'

/** This is common to both `FormType.Dropdown` and `FormType.DropdownMulti` */
export type FormCombobox<T extends string | number, F> = {
	/**
	 * Options that go into the combobox.
	 * If you use the functional version, explicitly define your form state type, since it can't be inferred
	 * If you find a way to infer it, let Billy know
	 */
	options: ComboBoxOptions<T> | ((formState: F) => ComboBoxOptions<T>)
	/**
	 * A function to quickly get the text for an option ID.
	 *
	 * The component could search `options` for an O(n) lookup, but this is faster
	 * since you can generally just do a map lookup in O(log n).
	 *
	 * Forms scale less than grids so this is optional - performance likely not an issue
	 */
	getText?: (value: T) => string
	/** Placeholder to show when nothing is selected */
	placeholder?: string
	/** The null option on the combobox will be given this label */
	nullable?: string
	/** If there's only one available option, auto-select it. Default: TRUE */
	autoPickOnly?: boolean
}

const MAX_ITEMS_FOR_ENUM = 20

type flatOption = [string | number, string]
const getEnumInfo = <R,>(options: ComboBoxOptions<string | number>) => {
	const options_flat: flatOption[] = options.flatMap(x => {
		if ('options' in x) {
			return x.options.map(y => [y.value, y.text] as flatOption)
		}
		return [[x.value, x.text]] as flatOption[]
	})
	let text_values = options_flat.map(x => x[1])
	const can_fit_all = text_values.length <= MAX_ITEMS_FOR_ENUM
	if (!can_fit_all) {
		text_values = _.sampleSize(text_values, MAX_ITEMS_FOR_ENUM)
	}
	return {
		enums: text_values,
		enumsAreComplete: can_fit_all,
		reversal: (value: unknown): R => {
			if (!_.isArray(value)) {
				return options_flat.find(y => y[1] == value)?.[0] as R
			}
			return value.map(v => options_flat.find(y => y[1] == v)?.[0]) as R
		},
	}
}

/** Enum dropdown - single select with numeric keys */
export const FormTypeDropdown = <F,>(
	settings: FormField<F, Maybe<number>, Maybe<number>> & FormCombobox<number, F>,
): FormFieldJSX<F, Maybe<number>, Maybe<number>> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<Maybe<number>>(MaybeT(t.Number())),
		schemaRaw: createPhantomTypeSchema<Maybe<number>>(MaybeT(t.Number())),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: formState => ({
		stringifiedType: 'string',
		description:
			readDynamic(settings.doc, formState) ?? readDynamic(settings.lbl, formState),
		...getEnumInfo<number>(readDynamic(settings.options, formState)),
	}),
	jsx: (props, formState) => (
		<Combobox<number, false>
			multiple={false}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={readDynamic(settings.options, formState)}
			nullable={settings.nullable}
			placeholder={settings.placeholder}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})

/** Enum dropdown - single select with string keys */
export const FormTypeDropdownStringKey = <F,>(
	settings: FormField<F, Maybe<string>, Maybe<string>> & FormCombobox<string, F>,
): FormFieldJSX<F, Maybe<string>, Maybe<string>> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<Maybe<string>>(MaybeT(t.String())),
		schemaRaw: createPhantomTypeSchema<Maybe<string>>(MaybeT(t.String())),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: formState => ({
		stringifiedType: 'string',
		description:
			readDynamic(settings.doc, formState) ?? readDynamic(settings.lbl, formState),
		...getEnumInfo<string>(readDynamic(settings.options, formState)),
	}),
	jsx: (props, formState) => (
		<Combobox<string, false>
			multiple={false}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={readDynamic(settings.options, formState)}
			nullable={settings.nullable}
			placeholder={settings.placeholder}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})

/** Enum dropdown - multi select with numeric keys */
export const FormTypeDropdownMulti = <F,>(
	settings: FormField<F, number[], number[]> & FormCombobox<number, F>,
): FormFieldJSX<F, number[], number[]> => ({
	...settings,
	valueDefaults: {
		def: () => [],
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<number[]>(t.Array(t.Number())),
		schemaRaw: createPhantomTypeSchema<number[]>(t.Array(t.Number())),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: formState => ({
		stringifiedType: 'string[]',
		description:
			readDynamic(settings.doc, formState) ?? readDynamic(settings.lbl, formState),
		...getEnumInfo<number[]>(readDynamic(settings.options, formState)),
	}),
	jsx: (props, formState) => (
		<Combobox<number, true>
			multiple={true}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={readDynamic(settings.options, formState)}
			nullable={settings.nullable}
			placeholder={settings.placeholder}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})

/** Enum dropdown - multi select with string keys */
export const FormTypeDropdownMultiStringKey = <F,>(
	settings: FormField<F, string[], string[]> & FormCombobox<string, F>,
): FormFieldJSX<F, string[], string[]> => ({
	...settings,
	valueDefaults: {
		def: () => [],
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<string[]>(t.Array(t.String())),
		schemaRaw: createPhantomTypeSchema<string[]>(t.Array(t.String())),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: formState => ({
		stringifiedType: 'string[]',
		description:
			readDynamic(settings.doc, formState) ?? readDynamic(settings.lbl, formState),
		...getEnumInfo<string[]>(readDynamic(settings.options, formState)),
	}),
	jsx: (props, formState) => (
		<Combobox<string, true>
			multiple={true}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={readDynamic(settings.options, formState)}
			nullable={settings.nullable}
			placeholder={settings.placeholder}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})
