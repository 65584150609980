import { fsmData } from '../../../universal'
import { React, _ } from '../../lib'

type startEnd = { start: number; end: number }

/**
 * Returns a React fragment of text with interlaced `<em>` tags for highlighting match
 * @param text The full text to display
 * @param needles An array of needles to search for within the text
 */
export const TextSearched = (props: {
	/** The full text to display. Needles are checked against it */
	text: string
	/** Either pass in an array or a string will be split into a needle array */
	needles: null | string | string[]
	/** This is applied only to the <em/> elements for matching needles */
	className?: string
	/** If set to true, applies a default tailwind classname for the em styles */
	tailwind?: boolean
}): React.JSX.Element => {
	// As an optimisation, return the text if needles is empty - skip any search checks
	if (!props.needles || props.needles.length == 0) {
		return <>{props.text}</>
	}

	// Get the fallback default classname if nothing is supplied
	// If running with tailwind, make it highlighted with black/bold text
	const defaultClassName = props.tailwind ? 'bg-[#ff0] text-[#000] font-bold' : ''

	// Not case sensitive
	const text = props.text.toLowerCase()
	const needles = fsmData(normaliseNeedles(props.needles), {
		filter: _.identity,
		map: x => x.toLowerCase(),
	})

	// Identify all matches of needles
	const matches: startEnd[] = []
	_.forEach(needles, needle => {
		let index = text.indexOf(needle)
		while (index !== -1) {
			matches.push({ start: index, end: index + needle.length })
			index = text.indexOf(needle, index + 1)
		}
	})

	// As another optimisation, skip if no matches were found
	if (matches.length == 0) {
		return <>{props.text}</>
	}

	// Combine overlapping matches
	const matchesCombined: startEnd[] = []
	matches.sort((a, b) => a.start - b.start)
	_.forEach(matches, match => {
		// Skip first element, it has no previous one to combine with
		if (matchesCombined.length == 0) {
			matchesCombined.push(match)
			return
		}
		// If an item's start is on/before the previous end, combine
		const last = matchesCombined[matchesCombined.length - 1]
		if (last == undefined) {
			return
		} else if (match.start <= last.end) {
			last.end = _.max([match.end, last.end]) ?? -1
		} else {
			matchesCombined.push(match)
		}
	})

	// Interlace match segments in <em> with non-match segments without
	const segments = []
	let lastIndex = 0
	_.forEach(matchesCombined, match => {
		if (match.start != lastIndex) {
			segments.push(
				<React.Fragment key={lastIndex}>
					{props.text.slice(lastIndex, match.start)}
				</React.Fragment>,
			)
		}
		segments.push(
			<em key={match.start} className={props.className ?? defaultClassName}>
				{props.text.slice(match.start, match.end)}
			</em>,
		)
		lastIndex = match.end
	})
	if (lastIndex != props.text.length) {
		segments.push(
			<React.Fragment key={lastIndex}>
				{props.text.slice(lastIndex, props.text.length)}
			</React.Fragment>,
		)
	}
	return <>{segments}</>
}

/**
 * Searches some text for an array of needles and returns whether anything was found
 * If there are no valid needles, implicitly returns true
 * Can be paired with the `TextSearched` component for UI display with highlights
 */
export const searchText = (
	/** The full text to display. Needles are checked against it */
	text: string,
	/** Either pass in an array or a string will be split into a needle array */
	needles: null | string | string[],
	/** Whether every needles must match or only one (default false - only one) */
	requireAll: boolean = false,
): boolean => {
	// Optimise no needle case
	if (!needles || needles.length == 0) {
		return true
	}

	// Convert both to lowercase
	text = text.toLowerCase()
	needles = fsmData(normaliseNeedles(needles), {
		filter: _.identity,
		map: x => x.toLowerCase(),
	})

	// No needles (after filter) - default pass
	if (needles.length == 0) {
		return true
	}

	// Check which needles match and based on `matchAny` return whether any/all were found
	const found = _.uniq(_.map(needles, needle => text.indexOf(needle) != -1))
	return requireAll ? !found.includes(false) : found.includes(true)
}

const normaliseNeedles = (needles: string | string[]): string[] => {
	if (!needles) {
		return []
	}
	if (_.isArray(needles)) {
		return needles
	}
	return (needles ?? '').split(/\s+/).filter(x => x)
}

/** Wraps the text in some preset styles for a stub message like "Please select a resident" */
export const StubText = (props: { children: React.JSX.Element | string }) => {
	return (
		<div className="w-full h-full pt-5 bg-neutral-200">
			<div className="text-center font-light text-2xl text-neutral-500">
				{props.children}
			</div>
		</div>
	)
}
