import { BuildClass, timer } from '../../../universal'
import { React, ReactDOM, _ } from '../../lib'
import { Modal, ModalAnchored } from './modal'
import { stubDiv } from './stubs'

export const HelpIcon = (props: {
	title: string
	className?: string
}): React.JSX.Element => (
	<HelpTooltip title={props.title} timeout={200}>
		<span
			tabIndex={-1}
			className={BuildClass({
				'ui5-help-icon': true,
				[props.className ?? '']: true,
			})}
		>
			<img alt="Help" src="/static/img/svg/small-help-icon.svg" />
		</span>
	</HelpTooltip>
)

/**
 * HelpTooltip
 *
 * Wrap a React JSX element with this component to make it display a help message.
 *
 * ## Usage
 * Nest the component you are adding the help message to.
 *
 * ```tsx
 * <HelpTooltip title="You can eat this">
 *   <TastyApple kind="granny smith" />
 * </HelpTooltip>
 * ```
 *
 * You can use JSX in the title prop.
 *
 * ```tsx
 * <HelpTooltip title={<Button>Click me, if you can! >:)</Button>}>
 *   <TastyApple kind="granny smith" />
 * </HelpTooltip>
 * ```
 */
export const HelpTooltip = (props: {
	// / The title is the content of the help message
	title: string | React.JSX.Element
	// / The JSX Element you are adding the help tooltip to
	children: React.JSX.Element
	timeout?: number
}): React.JSX.Element => {
	// Refs
	const element = React.useRef<HTMLElement>(stubDiv)
	const refHoverTimeout = React.useRef<number>()
	const refDimensions = React.useRef({ width: 0, height: 0 })
	const refTestBox = React.useRef<HTMLDivElement>(stubDiv)

	// Track whether visible
	const [visible, setVisible] = React.useState(false)

	// Track the dimensions of the tooltip box based on the text
	// It renders off-screen and we read the box size
	const [, setInc] = React.useState(0)
	React.useEffect(() => {
		const el = refTestBox.current
		if (!el) {
			return
		}
		const box = el.getBoundingClientRect()
		const newSize = { width: box.width, height: box.height }
		if (!_.isEqual(newSize, refDimensions.current)) {
			refDimensions.current = { width: box.width, height: box.height }
			setInc(v => v + 1)
		}
	}, [refTestBox.current, props.title])

	// Add the mouseover event
	const evMouseOver = React.useCallback(() => {
		refHoverTimeout.current = timer(props.timeout ?? 500, () => {
			setVisible(true)
		})
	}, [refHoverTimeout.current, props.timeout, setVisible])
	React.useEffect(() => {
		element.current?.addEventListener('mouseover', evMouseOver)
		return () => {
			element.current?.removeEventListener('mouseover', evMouseOver)
		}
	}, [element.current, evMouseOver])

	// Add the mouseout event
	const evMouseOut = React.useCallback(() => {
		clearTimeout(refHoverTimeout.current)
		setVisible(false)
	}, [refHoverTimeout.current, setVisible])
	React.useEffect(() => {
		element.current?.addEventListener('mouseout', evMouseOut)
		return () => {
			element.current?.removeEventListener('mouseout', evMouseOut)
		}
	}, [element.current, evMouseOut])

	// Render
	return (
		<>
			{React.cloneElement(props.children, { ref: element })}
			<ModalAnchored
				mountElement={element.current}
				hidden={!visible}
				width={refDimensions.current.width}
				maxHeight={refDimensions.current.height}
				className="ui5-help-tooltip"
				alignX="center"
				flipByDefault={true}
			>
				<div className="inner">{props.title}</div>
			</ModalAnchored>
			<Modal>
				<div className="ui5-help-tooltip" ref={refTestBox}>
					<div className="inner">{props.title}</div>
				</div>
			</Modal>
		</>
	)
}

type SimpleHelpTooltipProps = {
	tip: string | React.ReactNode
	children: React.ReactNode
	direction?: 'left' | 'right' | 'top' | 'bottom'
	offsetX?: number
	offsetY?: number
}

/**
 * SimpleHelpTooltip
 *
 * A more rudimentary tooltip that does not require you to proliferate refs throughout your custom components.
 * You may need to set the direction and offset.
 *
 * ## Usage
 * Nest the component you are adding the help message to.
 *
 * ```tsx
 * <SimpleHelpTooltip tip="You can eat this">
 *   <Apple kind="granny smith" />
 * </SimpleHelpTooltip>
 * ```
 *
 * You can also use JSX in the tip
 *
 * ```tsx
 * <SimpleHelpTooltip tip=<p><em>You</em> can eat this</p>>
 *   <Apple kind="granny smith" />
 * </SimpleHelpTooltip>
 * ```

 */
export const SimpleHelpTooltip = ({
	children,
	tip,
	direction = 'top',
	offsetX = 0,
	offsetY = 0,
}: SimpleHelpTooltipProps) => {
	const [isVisible, setIsVisible] = React.useState(false)
	const [position, setPosition] = React.useState({ top: 0, left: 0 })
	const triggerRef = React.useRef<HTMLDivElement>(stubDiv)
	const tooltipRef = React.useRef<HTMLDivElement>(stubDiv)

	React.useEffect(() => {
		if (isVisible && triggerRef.current && tooltipRef.current) {
			const triggerRect = triggerRef.current.getBoundingClientRect()
			const tooltipRect = tooltipRef.current.getBoundingClientRect()

			let top = 0
			let left = 0

			switch (direction) {
				case 'top':
					top = triggerRect.top - tooltipRect.height - 10 + offsetY
					left = triggerRect.left + triggerRect.width / 2 + offsetX
					break
				case 'bottom':
					top = triggerRect.bottom + 10 + offsetY
					left = triggerRect.left + triggerRect.width / 2 + offsetX
					break
				case 'left':
					top = triggerRect.top + triggerRect.height / 2 + offsetY
					left = triggerRect.left - tooltipRect.width - 10 + offsetX
					break
				case 'right':
					top = triggerRect.top + triggerRect.height / 2 + offsetY
					left = triggerRect.right + 10 + offsetX
					break
			}

			setPosition({
				top: top + window.scrollY,
				left: left + window.scrollX,
			})
		}
	}, [isVisible, direction, offsetX, offsetY])

	const getTransform = () => {
		switch (direction) {
			case 'top':
			case 'bottom':
				return 'translateX(-50%)'
			case 'left':
			case 'right':
				return 'translateY(-50%)'
		}
	}

	const uiRoot = document.querySelector('.ui-root')
	if (!uiRoot) {
		throw new Error('ui-root not found')
	}
	return (
		<>
			<div
				ref={triggerRef}
				onMouseEnter={() => {
					setIsVisible(true)
				}}
				onMouseLeave={() => {
					setIsVisible(false)
				}}
			>
				{children}
			</div>
			{isVisible &&
				ReactDOM.createPortal(
					<div className="tailwind-wrapper">
						<div
							ref={tooltipRef}
							className={`absolute z-20 px-3 py-1 text-sm text-white bg-gray-800 rounded-md
                max-w-[200px] w-max break-words`}
							style={{
								top: `${position.top}px`,
								left: `${position.left}px`,
								transform: getTransform(),
							}}
						>
							{tip}
						</div>
					</div>,
					uiRoot,
				)}
		</>
	)
}
