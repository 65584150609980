import { Maybe } from '../../../../universal'
import { React, _ } from '../../../lib'
import { reactFlyout } from '../../component-react'
import { NewSystemFlyoutInstance } from '../../flyouts'
import { FormComponent } from './render'
import { FormProps, FormPropsFields } from './types'

export const openFlyoutForm = <F extends FormPropsFields>(
	input: Omit<FormProps<F>, 'value' | 'onUpdate' | 'excludeReset'> & {
		/** Title of the flyout */
		title: string
		/**
		 * Dimensions of the flyout of the form [width, maxHeight]
		 * Note that the actual height might be less if there aren't enough components to fill it
		 */
		size: [number, Maybe<number>]
		/**
		 * By default, flyout forms will focus the first element.
		 * This is usually annoying if the first one is a combobox since it obscures the form
		 * TODO - look at a way to make the combobox focus without expanding
		 */
		disableAutoFocus?: boolean
	},
): void => {
	// Calculate the height required for the flyout, capping it at user-defined max
	const [width, maxHeight] = input.size
	const fieldHeight = _.chain(input.fields)
		// We have to include if it's a function since it might start using the space later
		.filter(x => _.isFunction(x.hidden) || !x.hidden)
		.map(x => x.height)
		.sum()
		.value()
	const buttonHeight = 70
	const titleHeight = 42
	const paddingHeight = 20
	const fullHeight = fieldHeight + buttonHeight + titleHeight + paddingHeight
	const height = _.min([fullHeight, maxHeight ?? 99999]) ?? 99999

	// Open the flyout and render the form
	reactFlyout(
		null,
		[width, height],
		<FormComponentFlyoutWrapper value={null} onUpdate={() => {}} {...input} />,
	)
}

/**
 * A form component for a flyout. Includes the title, handles auto-focus on mount, and
 * swaps the reset button for a cancel button.
 */
const FormComponentFlyoutWrapper = <F extends FormPropsFields>(
	props: FormProps<F> & {
		title: string
		disableAutoFocus?: boolean
		flyout?: NewSystemFlyoutInstance
	},
): React.JSX.Element => (
	<div className="tailwind-wrapper">
		{/* Flyout title with styling */}
		<div className="font-condensed text-[32px] leading-10 font-light px-[2px]">
			{props.title}
		</div>
		{/* The wrapped form component */}
		<FormComponent
			{..._.omit(props, ['title', 'disableAutoFocus', 'flyout'])}
			onMount={() => {
				// Auto-focus the first input element
				// If disabled, just focus the form element instead
				if (!props.disableAutoFocus) {
					const firstInput = props.flyout?.Element.querySelector(
						'input:not(:disabled), select:not(:disabled), textarea:not(:disabled)',
					) as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
					firstInput?.focus()
				} else {
					props.flyout?.Element.querySelector('form')?.focus()
				}

				// Run the user-defined onMount event
				props.onMount?.()
			}}
			// Close flyout once save is done
			onSuccessfulSave={() => {
				props.onSuccessfulSave?.()
				props.flyout?.Close()
			}}
			// Do a cancel button instead of a reset button
			// The cancel button closes the flyout without making any changes
			excludeReset={true}
			extraActions={[
				{
					lbl: 'Cancel',
					type: 'standard',
					onClick: () => {
						props.flyout?.Close()
					},
				},
				...(props.extraActions ?? []),
			]}
		/>
	</div>
)
